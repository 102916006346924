/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.22 5h6.51a1.24 1.24 0 0 1 1.25 1.24v6.52a1.24 1.24 0 0 1-1.25 1.25H9.22a1.24 1.24 0 0 1-1.25-1.25V6.24A1.24 1.24 0 0 1 9.22 5zm0 1.24v6.52h6.51V6.24H9.22z"/><path pid="1" d="M6.24 16.98h6.52a1.24 1.24 0 0 0 1.25-1.25v-.77a.62.62 0 0 0-1.25 0v.77H6.24V9.22h.81a.62.62 0 1 0 0-1.25h-.8A1.24 1.24 0 0 0 5 9.22v6.51a1.24 1.24 0 0 0 1.24 1.25z"/>'
  }
})
