<template lang="pug">
  .ui.search-drop-list.users(
    :class="{drop}",
    v-click-outside="() => toggleDrop(false)")

    .header.search(@click="toggleDrop(true)")
      ui-input(
        :class="$slots.suffix ? 'has-suffix' : null"
        :disabled="true",
        ref="input",
        :placeholder="placeholder",
        :value="inputView")

      slot(name="suffix")

    .body(v-if="drop")
      .filter
        ui-svg(name="search")
        input(
          ref="filterInout",
          autofocus,
          :placeholder="$t('ui.searchDropList.search')",
          v-model="search")

      ui-scrollbar.list
        .item(
          v-for="(item, i) in listFilter",
          :key="i",
          @click="pickUser(item)")

            .selected(:class="{active: item.selected}")

            ui-avatar(
              :size="2.3",
              :user="item")

            .name {{ item.firstName }}

      .select-all(@click="handleSelectAll")
        .selected(:class="{active: selectedAll}")
        .name {{ $t('ui.searchDropList.selectAll') }}
</template>

<script>
import helpers from '@/helpers'

export default {
  props: {
    users: {
      type: Array,
      default: () => [],
      require: false,
    },

    value: {
      type: Array,
      default: () => [],
      require: false,
    },

    placeholder: {
      default: '',
    },
  },

  data() {
    return {
      search: '',
      drop: false,
    }
  },

  methods: {
    handleSelectAll() {
      this.$emit('input', this.selectedAll ? [] : this.list)
    },

    toggleDrop(state) {
      this.drop = state
      if (state) {
        this.$nextTick(() => {
          this.$refs.filterInout.focus()
        })
      }
    },

    pickUser(user) {
      this.$emit(
        'input',
        this.value.find(e => e.id === user.id)
          ? this.value.filter(e => e.id !== user.id)
          : [...this.value, user]
      )
    },
  },

  computed: {
    selectedAll() {
      return this.list.length == this.value.length
    },

    inputView() {
      return this.value.length > 0
        ? this.list
            .filter(e => e.selected)
            .map(e => e.firstName)
            .join(', ')
        : undefined
    },

    list() {
      return this.users.map(e => {
        return {
          firstName: e.firstName,
          avatar: e.avatar,
          id: e.id,
          selected: this.value.find(user => user.id === e.id) ? true : false,
        }
      })
    },

    listFilter() {
      return this.list.filter(e => {
        return this.search.length > 0
          ? e.firstName.toLowerCase().includes(this.search.toLowerCase())
          : true
      })
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
