<template lang="pug">
  .ui.input-c(:class="rootClasses")
    .header
      slot(name="before")
      input(
        ref="input",
        :name="name",
        :type="type",
        :placeholder="placeholder",
        :tabindex="tabindex",
        :readonly="readonly",
        :maxlength="maxlength",
        v-model="model",
        @keyup.enter="e => $emit('enter', e)",
        @keydown.tab="e => $emit('tab', e)",
        @blur="e => $emit('blur', e)")

      slot(name="after")
    
    .body
      slot(name="body")

    .footer
      slot(name="footer")
</template>

<script>
export default {
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      require: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      require: false,
      default: false,
    },
    name: {
      type: String,
      require: false,
      default: null,
    },
    maxlength: {
      type: Number,
      require: false,
      default: 524288,
    },

    error: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rootClasses() {
      const { error } = this;
      return {
        'input-c--has_error': error,
      };
    },

    model: {
      get() {
        return this.value
      },

      set(newValue) {
        this.$emit('input', newValue)

        // if (this.autoResizeInput) this.autoResizeInput()
      },
    },
  },

  methods: {
    blur() {
      this.$nextTick(() => {
        this.$refs.input.blur()
      })
    },

    focus() {
      this.$refs.input.focus()
    },
  },

  mounted() {
    if (this.autofocus) {
      this.focus()
    }
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
