/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M17.05 10.8l-.94-.53a1.51 1.51 0 010-2.62l.94-.53c.78-.45 1.01-1.34.54-2.02l-.78-1.27a1.52 1.52 0 00-2.11-.53l-.94.53c-1.02.6-2.35-.16-2.35-1.28V1.5c0-.82-.7-1.5-1.56-1.5H8.28c-.86 0-1.56.68-1.56 1.5v.98c0 1.12-1.33 1.87-2.35 1.27l-.94-.45a1.55 1.55 0 00-2.11.53L.53 5.1a1.6 1.6 0 00.55 2.1l.94.52a1.45 1.45 0 010 2.56l-.94.52c-.78.45-1.02 1.35-.55 2.02l.79 1.28c.47.75 1.4.97 2.1.53l.95-.46c1.02-.6 2.35.15 2.35 1.28v1.05c0 .82.7 1.5 1.56 1.5h1.57c.86 0 1.56-.68 1.56-1.5v-.97c0-1.13 1.33-1.88 2.35-1.28l.94.53c.78.45 1.72.15 2.11-.53l.78-1.28c.4-.82.16-1.72-.54-2.17zM9.06 12a3.08 3.08 0 01-3.13-3c0-1.65 1.41-3 3.13-3a3.08 3.08 0 013.13 3c0 1.65-1.4 3-3.13 3z" _fill="#34335C"/>'
  }
})
