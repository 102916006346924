/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'reload': {
    width: 17,
    height: 17,
    viewBox: '0 0 13 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.705.033a.155.155 0 0 1 .194 0 .163.163 0 0 1 .053.187L6.18 2.17h.66v-.013c.237 0 .476.02.706.046.153.016.318.036.447.067 2.838.54 4.992 3.034 4.992 6.024a6.122 6.122 0 0 1-2.186 4.693L9.59 11.555a4.254 4.254 0 0 0 1.52-3.261 4.27 4.27 0 0 0-4.02-4.254H6.18l.773 1.95a.163.163 0 0 1-.053.187c-.056.043-.138.037-.194-.006L2.873 3.228a.16.16 0 0 1-.06-.126c0-.049.021-.09.06-.12L6.705.033zM0 8.7c0-1.697.675-3.277 1.906-4.447L3.2 5.611A4.22 4.22 0 0 0 1.873 8.7c0 2.267 1.781 4.121 4.019 4.253h.913l-.773-1.95a.153.153 0 0 1 .053-.18.161.161 0 0 1 .193 0l3.833 2.942a.16.16 0 0 1 .06.127c0 .048-.022.09-.06.12L6.278 16.96a.155.155 0 0 1-.193 0 .153.153 0 0 1-.053-.18l.773-1.95-.66-.006v.013c-.237 0-.476-.014-.706-.04a3.77 3.77 0 0 1-.447-.073C2.154 14.183 0 11.689 0 8.7z" _fill="#CBD2D8"/>'
  }
})
