/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7.667 1.238A3.086 3.086 0 0 0 4.57 4.333 3.086 3.086 0 0 0 7.667 7.43a3.086 3.086 0 0 0 3.095-3.096 3.086 3.086 0 0 0-3.095-3.095zm0 7.429a4.343 4.343 0 0 1-4.334-4.334A4.343 4.343 0 0 1 7.667 0 4.343 4.343 0 0 1 12 4.333a4.343 4.343 0 0 1-4.333 4.334zm2.504 6h4.562c-.01-.486-.006-.996-.075-1.37-.102-.557-.275-.964-.634-1.327-.572-.577-1.765-1.06-4.048-1.234-.06-.004-.12-.01-.182-.013-.117-.008-.24-.014-.361-.02-.125-.007-.25-.012-.38-.017l-.295-.01c-.244-.005-.493-.01-.756-.01H8c-3.612 0-5.305.579-6.023 1.304-.36.363-.533.77-.635 1.327-.07.374-.065.884-.075 1.37h8.904zm5.695-1.63c.136.739.134 1.505.134 2.296 0 .369-.276.667-.615.667H.616C.275 16 0 15.702 0 15.333c0-.791-.002-1.557.133-2.296.136-.74.434-1.467 1.004-2.042.758-.766 1.918-1.241 3.696-1.476v-.001l.024-.003a17.41 17.41 0 0 1 1.364-.129c.272-.017.553-.031.85-.04l.067-.001c.277-.008.562-.012.861-.012h.002c.299 0 .583.005.86.012l.068.002c3.133.082 4.894.597 5.934 1.648.57.575.867 1.302 1.003 2.042z" _fill="#34335C" fill-rule="evenodd"/>'
  }
})
