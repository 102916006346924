/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cancel-circle-o': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.7 12l3.4 3.4a1.2 1.2 0 11-1.7 1.7L12 13.7l-3.4 3.4a1.2 1.2 0 11-1.7-1.7l3.4-3.4-3.4-3.4a1.2 1.2 0 111.7-1.7l3.4 3.4 3.4-3.4a1.2 1.2 0 111.7 1.7L13.7 12zM12 24a12 12 0 110-24 12 12 0 010 24zm0-2.4a9.6 9.6 0 100-19.2 9.6 9.6 0 000 19.2z" _fill="#7A93A9"/>'
  }
})
