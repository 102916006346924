class Intercom {
  constructor(config) {
    this.config = config

    this.mount()
    this.visible = false
  }

  boot(params) {
    window.Intercom('boot', {
      ...this.config,
      ...params,
    })
  }

  hide() {
    this.visible = false
    window.Intercom('hide')
  }

  show() {
    this.visible = true
    window.Intercom('show')
  }

  showMessages() {
    window.Intercom('showMessages')
  }

  showNewMessage() {
    window.Intercom('showNewMessage')
  }

  onHide(callback) {
    window.Intercom('onHide', callback)
  }

  onShow(callback) {
    window.Intercom('onShow', callback)
  }

  onUnreadCountChange(callback) {
    window.Intercom('onUnreadCountChange', callback)
  }

  trackEvent(event) {
    window.Intercom('trackEvent', event)
  }

  getVisitorId() {
    return window.Intercom('getVisitorId')
  }

  startTour(tourId) {
    window.Intercom('startTour', tourId)
  }

  mount() {
    const APP_ID = this.config.app_id
    ;(function() {
      var w = window
      var ic = w.Intercom
      if (typeof ic === 'function') {
        ic('reattach_activator')
        ic('update', w.intercomSettings)
      } else {
        var d = document
        var i = function() {
          i.c(arguments)
        }
        i.q = []
        i.c = function(args) {
          i.q.push(args)
        }
        w.Intercom = i
        var l = function() {
          var s = d.createElement('script')
          s.type = 'text/javascript'
          s.async = true
          s.src = 'https://widget.intercom.io/widget/' + APP_ID
          var x = d.getElementsByTagName('script')[0]
          x.parentNode.insertBefore(s, x)
        }
        if (w.attachEvent) {
          w.attachEvent('onload', l)
        } else {
          w.addEventListener('load', l, false)
        }
      }
    })()
  }
}

export default {
  install(Vue, config) {
    let intercom = new Intercom(config)

    Vue.mixin({
      beforeCreate: function() {
        Vue.util.defineReactive(this, '$intercom', intercom)
      },
    })
  },
}
