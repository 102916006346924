/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cross': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6.586 8L.293 1.707A1 1 0 1 1 1.707.293L8 6.586 14.293.293a1 1 0 0 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 1 1-1.414-1.414L6.586 8z"/>'
  }
})
