<template lang="pug">
  .zoom.quarter.zoom-c(ref="zoomList")
    .item(
      v-for="(month, i) in list",
      ref="zoomItems"
      :key="i")

      .title(@click="handleClickOnMonth(month.m)")
        span {{ month.m | moment('MMMM YYYY') }}

      .list
        .item(
          v-for="(week, j) in month.weeks",
          :key="j",
          @click="handleClickOnWeek(week)",
          :style="getWeekStyle(week, 8, 'em')",
          :class="{active: week.isSame($utils.$time.now, 'week')}")

          span
            .number  W{{ week | moment('w') }} 
            .dates {{ week.clone().startOf('week') | moment('DD') }}-{{ week.clone().endOf('week') | moment('DD') }}
</template>

<script>
import ZoomMixin from './mixin'
import GridMixin from '../mixins/grid'

export default {
  mixins: [ZoomMixin, GridMixin],

  data() {
    // the short quarter
    const shortestPeriodDate = '2022-08-01';
    const delimiter = 'quarters';
    const startAt = this.$moment(shortestPeriodDate, 'YYYY-MM-DD').startOf(delimiter);
    const endAt = this.$moment(shortestPeriodDate, 'YYYY-MM-DD').endOf(delimiter);

    return {
      view: {
        startAt,
        endAt,

        periods: 1,

        delimiter: 'quarters',

        zoomHover: val => {
          return {
            startAt: val.clone().startOf('day'),
            endAt: val.clone().endOf('day'),
          }
        },

        roundGridStartAt: momentDate => {
          return momentDate.clone().startOf('day');
        },

        roundGridEndAt: momentDate => {
          return momentDate.clone().endOf('day');
        },

        getGridStartAt: endAt => {
          const startAt = endAt.clone().subtract(1, 'days');
          return startAt.hours() === 23 && startAt.minutes() === 59
            ? startAt.add(1, 'minutes')
            : startAt;
        },

        getGridEndAt: startAt => {
          const endAt = startAt.clone().add(1, 'days');
          return endAt.hours() === 0 && endAt.minutes() === 0
            ? endAt.subtract(1, 'minutes')
            : endAt;
        },
      },
    }
  },

  computed: {
    list() {
      return Array.from(
        this.$moment
          .range(
            this.periodsRange.startAt.clone(),
            this.periodsRange.endAt.clone()
          )
          .by('month')
      ).map(m => {
        return {
          m,
          weeks: this._.uniq(
            Array.from(
              this.$moment
                .range(m.clone().startOf('month'), m.clone().endOf('month'))
                .by('days')
            ).map(e => e.endOf('week').valueOf())
          )
            .map(e => this.$moment(e).startOf('week'))
            .filter(e => m.isSame(e.clone().endOf('week'), 'month')),
        }
      })
    },

    items() {
      return []
        .concat(...this.list.map(e => e.weeks))
        .sort((a, b) => a.valueOf() - b.valueOf())
    },

    firstItem() {
      return this.items[0]?.startOf('week');
    },

    lastItem() {
      return this.items[this.items.length - 1]?.endOf('week');
    },
  },
}
</script>
