import Vue from 'vue'

import UiSearchDropListUsers from './Users'
Vue.component('UiSearchDropListUsers', UiSearchDropListUsers)

import UiSearchDropListUserIds from './UserIds'
Vue.component('UiSearchDropListUserIds', UiSearchDropListUserIds)

import UiSearchDropListProjects from './Projects'
Vue.component('UiSearchDropListProjects', UiSearchDropListProjects)

import UiSearchDropList from './List'
Vue.component('UiSearchDropList', UiSearchDropList)