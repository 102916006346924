/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chats': {
    width: 25,
    height: 21,
    viewBox: '0 0 25 21',
    data: '<g _fill="#34335C" fill-rule="evenodd"><path pid="0" d="M15.8 11.683a6.847 6.847 0 0 1-3.418.901h-1.465a.682.682 0 0 0-.527.194c-1.051.977-2.11 1.95-3.173 2.917-.428.388-1.072.416-1.43.025a1.092 1.092 0 0 1-.262-.66 36.904 36.904 0 0 1 0-2.325c.03-.134-.07-.264-.222-.29a.25.25 0 0 0-.022-.002C2.435 11.786.787 10.116.196 7.665c-.57-2.102.12-4.313 1.83-5.864C3.172.679 4.798.03 6.512.01c1.008-.024 2.019 0 3.027 0 .925 0 1.848-.013 2.773 0 2.522.042 4.394 1.073 5.666 2.955 1.987 2.954.984 6.901-2.178 8.717"/><path pid="1" d="M24.879 13.565c-.49 2.18-1.805 3.683-4.12 4.33-.147.026-.242.159-.214.296l.003.015v1.816c.046.397-.198.774-.595.918a.966.966 0 0 1-1.077-.283c-.808-.805-1.63-1.614-2.461-2.403a.907.907 0 0 0-.51-.205c-.807-.07-1.629-.012-2.404-.185C11.955 17.526 10.85 16.6 10 15.2c.205-.08.42-.133.64-.162 1.032-.015 2.064.025 3.074-.235 2.843-.734 4.796-2.42 5.886-4.962.38-.898.555-1.859.84-2.84.57.065 1.117.25 1.6.541 2.213 1.238 3.35 3.654 2.839 6.024"/></g>'
  }
})
