<template lang="pug">
  .zoom.days(ref="zoomList")
    .item(
      v-for="(day, i) in days",
      ref="zoomItems"
      :key="i")

      .title(@click="handleClickOnWeek(day.d)")
        span
          b.week {{ day.d | moment('dd') }} &#8203;
          .month-day {{ day.d | moment('DD MMM') }} {{ $t('planningComponent.week') }} {{ day.d | moment('w') }}

      .list
        .item(
          v-for="(h, j) in day.hours",
          :key="j",
          :class="{ active: h.isSame($utils.$time.now, 'hours')}")

          span.no-clickable {{ h | moment('HH:mm') }}
</template>

<script>
import ZoomMixin from './mixin'

export default {
  mixins: [ZoomMixin],

  data() {
    // the short DST day
    const shortestPeriodDate = '2022-03-27';
    const delimiter = 'days';
    const startAt = this.$moment(shortestPeriodDate, 'YYYY-MM-DD').startOf(delimiter);
    const endAt = this.$moment(shortestPeriodDate, 'YYYY-MM-DD').endOf(delimiter);

    return {
      view: {
        startAt,
        endAt,

        periods: 1,

        delimiter: 'days',

        zoomHover: val => {
          let m = val.minutes() > 30 ? 2 : 1,
            startAt = val.startOf('hours').add(m * 30 - 30, 'minutes'),
            endAt = startAt.clone().add(30, 'minutes')

          if (!endAt.isSame(startAt, 'day'))
            endAt = startAt.clone().endOf('day')

          return {
            startAt,
            endAt,
          }
        },

        roundGridStartAt: momentDate => {
          const minutesSteps = Math.floor(momentDate.minutes() / 30);
          return momentDate.clone().startOf('hour').add(minutesSteps * 30, 'minutes');
        },

        roundGridEndAt: momentDate => {
          const minutesSteps = Math.ceil(momentDate.minutes() / 30);
          if (momentDate.hours() === 23 && minutesSteps === 2) {
            return momentDate.clone().endOf('hour');
          }
          return momentDate.clone().startOf('hour').add(minutesSteps * 30, 'minutes');
        },

        getGridStartAt: endAt => {
          const startAt = endAt.clone().subtract(30, 'minutes');
          return startAt.hours() === 23 && startAt.minutes() === 29
            ? startAt.add(1, 'minutes')
            : startAt;
        },

        getGridEndAt: startAt => {
          const endAt = startAt.clone().add(30, 'minutes');
          return endAt.hours() === 0 && endAt.minutes() === 0
            ? endAt.subtract(1, 'minutes')
            : endAt;
        },
      },
    }
  },

  computed: {
    days() {
      return Array.from(
        this.$moment
          .range(
            this.periodsRange.startAt.clone(),
            this.periodsRange.endAt.clone()
          )
          .by('days')
      ).map(d => {
        const hours = [
          ...this.$moment
            .range(d.clone().startOf('day'), d.clone().endOf('day'))
            .by('hours')
        ];
        return {
          d,
          hours,
        }
      })
    },

    items() {
      return []
        .concat(...this.days.map(e => e.hours))
        .sort((a, b) => a.valueOf() - b.valueOf())
    },

    firstItem() {
      return this.items[0]?.startOf('day');
    },

    lastItem() {
      return this.items[this.items.length - 1]?.endOf('day');
    },
  },
}
</script>
