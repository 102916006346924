/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clipboard': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M10.733 0H4.216A1.245 1.245 0 002.97 1.244v6.518a1.244 1.244 0 001.245 1.245h6.517a1.244 1.244 0 001.245-1.245V1.244A1.244 1.244 0 0010.733 0zM4.216 7.762V1.244h6.517v6.518H4.216z" _fill="#34335C"/><path pid="1" d="M1.244 11.978h6.518a1.245 1.245 0 001.245-1.245v-.777a.622.622 0 10-1.245 0v.777H1.244V4.216h.81a.622.622 0 100-1.245h-.81A1.244 1.244 0 000 4.216v6.517a1.244 1.244 0 001.244 1.245z" _fill="#34335C"/>'
  }
})
