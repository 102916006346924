<template lang="pug">
  .ui.icon(
    @click="e => $emit('click', e)")

    img(
      :src="src",
      :alt="alt")
</template>

<script>
export default {
  props: {
    alt: {
      default: "",
      type: String
    },

    src: {
      default: null,
      type: String
    }
  }
}
</script>

<style lang="sass" src="./styles.sass"></style>
