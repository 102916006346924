/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'documents': {
    width: 5,
    height: 6,
    viewBox: '0 0 5 6',
    data: '<defs><path pid="0" id="svgicon_documents_a" d="M0 .63h4.437V6H0z"/><path pid="1" id="svgicon_documents_b" d="M0 6.37h4.437V1H0z"/></defs><g _fill="none" fill-rule="evenodd"><path pid="2" d="M4.033.285H.404A.117.117 0 0 0 .32.32a.117.117 0 0 0-.035.084v4.562c0 .032.013.063.035.084a.117.117 0 0 0 .084.035h3.63a.119.119 0 0 0 .084-.035.12.12 0 0 0 .034-.084V.406a.12.12 0 0 0-.119-.12M.404.125h3.63a.28.28 0 0 1 .278.28v4.56a.28.28 0 0 1-.08.197.282.282 0 0 1-.198.081H.404a.278.278 0 0 1-.279-.278V.404a.279.279 0 0 1 .28-.279" _fill="#34335C"/><path pid="3" d="M.404.41L.41 4.966l3.624-.006L4.027.41H.404zm3.63 4.96H.404A.405.405 0 0 1 0 4.965V.404A.404.404 0 0 1 .404 0h3.63a.404.404 0 0 1 .403.405v4.561a.411.411 0 0 1-.117.285.41.41 0 0 1-.286.118z" _fill="#34335C"/><path pid="4" _fill="#34335C" d="M.205 3.665h4.027v-.16H.205z"/><path pid="5" _fill="#34335C" d="M.08 3.79h4.277v-.41H.08zM.205 1.865h4.027v-.16H.205z"/><path pid="6" _fill="#34335C" d="M.08 1.99h4.277v-.41H.08zM1.583.883a.08.08 0 0 0-.08.08c0 .045.036.08.08.08h1.273a.08.08 0 0 0 .08-.08.08.08 0 0 0-.08-.08H1.583z"/><path pid="7" d="M2.856 1.168H1.583a.205.205 0 0 1 0-.41h1.273a.205.205 0 0 1 0 .41M1.583 2.605a.08.08 0 0 0-.08.08c0 .044.036.08.08.08h1.273a.08.08 0 0 0 .08-.08.08.08 0 0 0-.08-.08H1.583z" _fill="#34335C"/><path pid="8" d="M2.856 2.89H1.583a.205.205 0 0 1 0-.41h1.273a.205.205 0 0 1 0 .41M1.583 4.327a.08.08 0 0 0-.08.08c0 .044.036.08.08.08h1.273a.08.08 0 0 0 .08-.08.08.08 0 0 0-.08-.08H1.583z" _fill="#34335C"/><path pid="9" d="M2.856 4.612H1.583a.205.205 0 0 1 0-.41h1.273a.205.205 0 0 1 0 .41" _fill="#34335C"/></g>'
  }
})
