/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sort-list': {
    width: 17,
    height: 14,
    viewBox: '0 0 17 14',
    data: '<path pid="0" d="M15.79 2.58H7.1A1.1 1.1 0 016 1.48c0-.6.5-1.1 1.1-1.1h8.69c.61 0 1.11.5 1.11 1.1 0 .6-.5 1.1-1.11 1.1zM2.8 2.91H1.12A1.1 1.1 0 010 1.81V1.1C0 .5.5 0 1.11 0h1.7c.61 0 1.11.5 1.11 1.1v.71c0 .61-.5 1.1-1.11 1.1zm12.99 5.12H7.1A1.1 1.1 0 016 6.93c0-.6.5-1.1 1.1-1.1h8.69c.61 0 1.11.5 1.11 1.1 0 .6-.5 1.1-1.11 1.1zM2.8 8.39H1.12A1.1 1.1 0 010 7.29v-.72c0-.6.5-1.1 1.11-1.1h1.7c.61 0 1.11.5 1.11 1.1v.72c0 .6-.5 1.1-1.11 1.1zm12.99 5.11H7.1A1.1 1.1 0 016 12.42c0-.6.5-1.1 1.1-1.1h8.69c.61 0 1.11.5 1.11 1.1 0 .6-.5 1.1-1.11 1.1zm-12.99.37H1.12A1.1 1.1 0 010 12.77v-.72c0-.6.5-1.1 1.11-1.1h1.7c.61 0 1.11.5 1.11 1.1v.72c0 .6-.5 1.1-1.11 1.1z" _fill="#34335C"/>'
  }
})
