import _ from 'lodash'

export default {
  viewTasks: ({ $store, $router }) => {
    const canViewInfo = _.get(
      $store,
      'state.User.permissions.tasks.canViewInfo',
      false
    )

    if (canViewInfo) return

    $router.push({ name: 'dashboard' })

    throw `Don't access to tasks`
  },
}
