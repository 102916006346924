<template lang="pug">
  .ui.drop-area(@drop="handleDropFile")
    .body
      .img
      .description {{ description }}
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      default: 'Drop'
    }
  },

  methods: {
    handleDropFile(event) {
      event.stopPropagation();
      event.preventDefault();

      if(!event.dataTransfer.files) return;

      this.$emit('files', [...event.dataTransfer.files].map(file => {
        return {
          file,
          name: file.name,
          type: this.$utils.getExtention(file.name),
          access: false,
          errors: [],
          success: false,
          progress: 0,
          edit: false,
          cancelToken: this.$utils.genUUID()
        };
      }))
    }
  }
}
</script>

<style lang="sass" src="./styles.sass"></style>