import Vue from 'vue'
import DraggEmitter from './emitter'
import DraggItem from './draggItem'
import DraggList from './draggList'
import DraggScroll from './draggScroll'
import Store from './store'

import './dragShadow'

window.st = Store
Vue.directive('dragg-item', {
  bind: function(el, binding) {
    if (!binding.value) return null

    const {
      id,
      parentId,
      namespace,
      disabled = false,
      params = { preventClick: false },
      ignoreElementSelector = null,
    } = JSON.parse(binding.value)

    if (id && !disabled) {
      // TODO: Research
      setTimeout(() => {
        Store.draggItems.set(
          id,
          new DraggItem({
            el,
            id,
            parentId,
            namespace,
            params,
            ignoreElementSelector,
          })
        )
      })
    }
  },

  unbind: function(el) {
    const id = el.getAttribute('data-dragg-item')
    Store.draggItems.delete(id)
  },
})

Vue.directive('dragg-scroll', {
  bind: function(el, binding) {
    if (!binding.value) return null

    const { id, namespace = null } = JSON.parse(binding.value)

    if (id) {
      Store.draggScroll.set(id, new DraggScroll({ el, id, namespace }))
    }
  },

  unbind: function(el, binding) {
    const { id } = JSON.parse(binding.value)

    Store.draggScroll.delete(id)
  },
})

Vue.directive('dragg-list', {
  bind: function(el, binding) {
    if (!binding.value) return null

    const { id, namespace, options } = JSON.parse(binding.value)

    if (id) {
      // TODO: Research
      setTimeout(() => {
        Store.draggList.set(id, new DraggList({ el, id, namespace, options }))
      }, 100)
    }
  },

  unbind: function(el) {
    const id = el.getAttribute('data-dragg-list')
    Store.draggList.delete(id)
  },
})

Vue.mixin({
  beforeCreate() {
    Vue.util.defineReactive(this, '$dragg', {
      emitter: DraggEmitter,
    })
  },
})
