/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'archive': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.958 11.708c.02.084.042.167.02.25v6.958a.88.88 0 0 1-.874.875H1.708a.88.88 0 0 1-.875-.875v-6.958c0-.083.021-.166.042-.25L4.145.625A.895.895 0 0 1 5 0h10.812c.396 0 .75.25.855.625l3.291 11.083zM5.666 1.771l-2.75 9.27h3.105c.687 0 1.23.563 1.23 1.23v.479h6.333v-.48c0-.686.562-1.228 1.229-1.228h3.104l-2.75-9.271h-9.5zM2.604 18.02H18.25v-5.23h-2.876v.48c0 .666-.562 1.208-1.229 1.208H6.708a1.214 1.214 0 0 1-1.229-1.208v-.48H2.604v5.23zm7.188-7.479l-1.98-2a.868.868 0 0 1 0-1.25.868.868 0 0 1 1.25 0l.48.479V4.292a.88.88 0 0 1 .875-.875.88.88 0 0 1 .874.875v3.5l.48-.48a.868.868 0 0 1 1.25 0 .868.868 0 0 1 0 1.25l-1.98 1.98a.881.881 0 0 1-.624.25.881.881 0 0 1-.625-.25z" _fill="#34335C" fill-rule="evenodd"/>'
  }
})
