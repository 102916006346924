/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sort-block': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M16 1.66C16 .74 15.26 0 14.34 0H10.6c-.92 0-1.66.74-1.66 1.66V5.4c0 .92.74 1.66 1.66 1.66h3.74c.92 0 1.66-.74 1.66-1.66V1.66zm-.94 3.74c0 .4-.32.72-.72.72H10.6a.72.72 0 01-.72-.72V1.66c0-.4.32-.72.72-.72h3.74c.4 0 .72.32.72.72V5.4z" _fill="#34335C"/><path pid="1" d="M16 3.5a3.5 3.5 0 10-7 0 3.5 3.5 0 007 0zm-9 0a3.5 3.5 0 10-7 0 3.5 3.5 0 007 0zm0 9a3.5 3.5 0 10-7 0 3.5 3.5 0 007 0zm9 0a3.5 3.5 0 10-7 0 3.5 3.5 0 007 0z" _fill="#34335C"/><path pid="2" d="M7.06 1.66C7.06.74 6.32 0 5.4 0H1.66C.74 0 0 .74 0 1.66V5.4c0 .92.74 1.66 1.66 1.66H5.4c.92 0 1.66-.74 1.66-1.66V1.66zM6.12 5.4c0 .4-.32.72-.72.72H1.66a.72.72 0 01-.72-.72V1.66c0-.4.32-.72.72-.72H5.4c.4 0 .72.32.72.72V5.4zM16 10.6c0-.92-.74-1.66-1.66-1.66H10.6c-.92 0-1.66.74-1.66 1.66v3.74c0 .92.74 1.66 1.66 1.66h3.74c.92 0 1.66-.74 1.66-1.66V10.6zm-.94 3.74c0 .4-.32.72-.72.72H10.6a.72.72 0 01-.72-.72V10.6c0-.4.32-.72.72-.72h3.74c.4 0 .72.32.72.72v3.74zm-8-3.74c0-.92-.74-1.66-1.66-1.66H1.66C.74 8.94 0 9.68 0 10.6v3.74C0 15.26.74 16 1.66 16H5.4c.92 0 1.66-.74 1.66-1.66V10.6zm-.94 3.74c0 .4-.32.72-.72.72H1.66a.72.72 0 01-.72-.72V10.6c0-.4.32-.72.72-.72H5.4c.4 0 .72.32.72.72v3.74z" _fill="#34335C"/>'
  }
})
