<template lang="pug">
  .user-title
    .title
      button.action(@click="$emit('iconClick')")
        ui-icon(:class="icon")

      .text
        .data
          .data-title
            span {{ title }}

          .data-subtitle {{ subtitle }}

        .data-additional
          slot(name="title")

    slot
</template>

<script>
export default {
  props: {
    icon: {
      type: Array,
      default: () => ['back', 'theme'],
    },

    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass" src="@sass/components/user-title.sass"></style>
