import Vue from 'vue'

import UiQuill from '@ui/components/quill'
Vue.component('UiQuill', UiQuill)

import UiSelectMembers from './SelectMembers'
Vue.component('UiSelectMembers', UiSelectMembers)

import UiWarningBlock from './WarningBlock'
Vue.component('UiWarningBlock', UiWarningBlock)

import UiFindMember from './FindMember'
Vue.component('UiFindMember', UiFindMember)

import UiButtonC from './Button'
Vue.component('UiButton', UiButtonC)

import UiInputC from './Input'
Vue.component('UiInputC', UiInputC)

import UiSelectMultiple from './SelectMultiple'
Vue.component('UiSelectMultiple', UiSelectMultiple)

import UiLineLoader from './LineLoader'
Vue.component('UiLineLoader', UiLineLoader)

import UiFormTextarea from '@ui/form/modules/textarea'
import UiFormInput from '@ui/form/modules/Input'
import UiFormInputAutofill from '@ui/form/modules/autofill'

import UiCheckbox from '@ui/controls/checkbox'
import UiRadioInput from '@ui/controls/radio-input'
import UiCounter from '@ui/controls/counter'
import UiScrollbar from '@ui/controls/scrollbar'

import UiLink from '@ui/controls/link'

import UiInput from '@ui/controls/input'
import UiInputTextarea from '@ui/controls/input/modules/textarea.vue'
import UiInputPrice from '@ui/controls/input/price.vue'
import UiInputFormula from '@ui/controls/input/formula.vue'

import UiDatePicker from '@ui/controls/date-picker'
import UiToggle from '@ui/controls/toggle'
import UiButton from '@ui/controls/button'
import UiTextButton from '@ui/controls/text-button'
import UiUsersCounter from '@ui/controls/users-counter'
import UiEditable from '@ui/controls/editable'
import UiIcon from '@ui/controls/icon'

import UiDateInput from '@ui/components/date-input'
import UiTimeInput from '@ui/components/time-input'
import UiLocation from '@ui/components/location'
import UiEmailInput from '@ui/components/email-input'
import UiPickColor from '@ui/components/pick-color'
import UiUsersRowAvatars from '@ui/components/users-row-avatars'
import UiAvatar from '@ui/components/avatar'
import UiAvatarSelect from '@ui/components/avatar-select'
import UiErrorsBlock from '@ui/components/errors-block'
import UiFindAndSelectUser from '@ui/components/find-and-select-user'
import UiSelectStatus from '@ui/controls/select-status'
import UiDropSelect from '@ui/components/drop-select'
import UiCustomSelect from '@ui/components/custom-select'
import UiEditFormRow from '@ui/components/edit-form-row'
import UiEditField from '@ui/components/edit-field'
import UiEditFormField from '@ui/components/edit-form-field'
import UiStick from '@ui/components/stick'
import UiSvg from '@ui/controls/svg'
import UiDrogArea from '@ui/DropArea/Index'
import UiTag from './Tag'
import UiMenuButton from './MenuButton'

import '@ui/components/SearchDropList'

Vue.component('ui-form-textarea', UiFormTextarea)
Vue.component('ui-form-input', UiFormInput)
Vue.component('ui-form-autofill', UiFormInputAutofill)

Vue.component('ui-checkbox', UiCheckbox)
Vue.component('ui-radio-input', UiRadioInput)
Vue.component('ui-counter', UiCounter)
Vue.component('ui-scrollbar', UiScrollbar)

Vue.component('ui-link', UiLink)

Vue.component('ui-input', UiInput)
Vue.component('ui-input-price', UiInputPrice)
Vue.component('ui-input-formula', UiInputFormula)
Vue.component('ui-textarea', UiInputTextarea)

Vue.component('ui-date-picker', UiDatePicker)
Vue.component('ui-toggle', UiToggle)
Vue.component('ui-button', UiButton)
Vue.component(UiTextButton.name, UiTextButton)
Vue.component('ui-users-counter', UiUsersCounter)
Vue.component('ui-editable', UiEditable)

Vue.component('ui-date-input', UiDateInput)
Vue.component('ui-time-input', UiTimeInput)
Vue.component('ui-location', UiLocation)
Vue.component('ui-email-input', UiEmailInput)
Vue.component('ui-pick-color', UiPickColor)
Vue.component('ui-users-row-avatars', UiUsersRowAvatars)
Vue.component('ui-avatar', UiAvatar)
Vue.component('ui-avatar-select', UiAvatarSelect)
Vue.component('ui-errors-block', UiErrorsBlock)
Vue.component('ui-find-and-select-user', UiFindAndSelectUser)
Vue.component('ui-select-status', UiSelectStatus)
Vue.component('ui-drop-select', UiDropSelect)
Vue.component('ui-custom-select', UiCustomSelect)
Vue.component('ui-edit-form-row', UiEditFormRow)
Vue.component(UiEditField.name, UiEditField)
Vue.component(UiEditFormField.name, UiEditFormField)
Vue.component('ui-icon', UiIcon)
Vue.component('ui-stick', UiStick)
Vue.component('ui-svg', UiSvg)
Vue.component('ui-drop-area', UiDrogArea)


import UITimePicker from './TimePicker'
Vue.component('ui-time-picker', UITimePicker)

import UIFindList from './FindList'
Vue.component('UIFindList', UIFindList)

Vue.component(UiTag.name, UiTag);
Vue.component(UiMenuButton.name, UiMenuButton);
