/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nl': {
    width: 16,
    height: 16,
    viewBox: '0 0 640 480',
    data: '<path pid="0" fill="#21468b" d="M0 0h640v480H0z"/><path pid="1" fill="#fff" d="M0 0h640v320H0z"/><path pid="2" fill="#ae1c28" d="M0 0h640v160H0z"/>'
  }
})
