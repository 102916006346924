<template lang="pug">
  router-link.ui-link(
    v-if="to"
    :to="to"
    :class="rootClasses"
    v-bind="$attrs"
    v-on="$listeners"
  )
    .ui-link__prepend(v-if="$scopedSlots.prepend")
      slot(name="prepend")
    .ui-link__text
      slot
  a.ui-link(
    v-else
    :class="rootClasses"
    v-bind="$attrs"
    v-on="$listeners"
  )
    .ui-link__prepend(v-if="$scopedSlots.prepend")
      slot(name="prepend")
    .ui-link__text
      slot
</template>

<script>
export default {
  name: "UiLink",

  inheritAttrs: false,

  props: {
    /**
     * @values info, light
     */
    kind: {
      type: String,
      default: undefined,
    },

    /**
     * The size of the link
     * @values small
     */
    size: {
      type: String,
      default: undefined,
    },

    to: {
      type: [String, Object],
      default: undefined,
    },

    /**
     * @values text-line
     */
    underline: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    rootClasses() {
      const { kind, size, underline } = this;
      return {
        [`ui-link--kind_${kind}`]: Boolean(kind),
        [`ui-link--size_${size}`]: Boolean(size),
        [`ui-link--underline_${underline}`]: Boolean(underline),
      };
    },
  },
};
</script>

<style lang="sass" src="./styles.sass"></style>
