import _ from 'lodash'

import Vue from 'vue'
import Store from '@/store'
import Router from '@/routes'

import projectMid from './project'
import tasksMid from './tasks'
import formsMid from './forms.js';

const middlewaresList = {
  ...projectMid,
  ...tasksMid,
  ...formsMid,
};

Vue.mixin({
  mounted() {
    if (this.fetchData) {
      this.fetchData()

      this.$events.on('network:refresh', this.fetchData)
    }
  },

  async beforeCreate() {
    if (!this.middlewares) this.middlewares = []
    if (!this.requirePermissions) this.requirePermissions = []

    if (this.$options.middlewares) {
      this.$options.middlewares.forEach(name => {
        if (middlewaresList.hasOwnProperty(name)) {
          try {
            middlewaresList[name]({
              $store: Store,
              $router: Router,
              $route: Router.currentRoute,
            })
          } catch (error) {
            debug('Error in middleware:', error)

            // Hide component
            this.$nextTick(() => {
              if (this.$el) {
                this.$el.hidden = true
              }
            })
          }
        }
      })
    }

    const userPer = _.get(Store, 'state.User.permissions')
    if (userPer) {
      _.get(this, '$options.requirePermissions', []).forEach(namePer => {
        if (!_.get(userPer, namePer, false)) {
          Router.push({
            name: 'home',
          })

          // Hide component
          this.$nextTick(() => {
            if (this.$el) {
              this.$el.hidden = true
            }
          })
        }
      })
    } else {
      debug('Not found user permissions in middlewares')
    }
  },

  beforeDestroy() {
    if (this.fetchData) {
      this.$events.off('network:refresh', this.fetchData)
    }
  },
})
