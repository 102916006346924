/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share': {
    width: 20,
    height: 26,
    viewBox: '0 0 20 26',
    data: '<path pid="0" d="M.833 26A.823.823 0 0 1 0 25.187V8.938c0-.448.373-.812.833-.812h5.834c.46 0 .833.364.833.813a.823.823 0 0 1-.833.812h-5v14.625h16.666V9.75h-5a.823.823 0 0 1-.833-.813c0-.448.373-.812.833-.812h5.834c.46 0 .833.364.833.813v16.25a.823.823 0 0 1-.833.812H.833zm10-23.02v15.705c0 .45-.37.815-.833.815a.82.82 0 0 1-.833-.815V2.98L6.869 5.467a.849.849 0 0 1-1.176.06.797.797 0 0 1-.062-1.146L9.447.25a.74.74 0 0 1 1.106 0L14.37 4.38a.797.797 0 0 1-.062 1.147.849.849 0 0 1-1.176-.06L10.833 2.98z" _fill="#34335C" fill-rule="evenodd"/>'
  }
})
