/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'document': {
    width: 12,
    height: 16,
    viewBox: '0 0 12 16',
    data: '<path pid="0" d="M7.637.297v3.705h4.042L7.636.297z" _fill="#34335C"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M7.636 5c-.602 0-1.09-.448-1.09-1V0H1.09C.488 0 0 .448 0 1v14c0 .552.488 1 1.09 1h9.82c.602 0 1.09-.448 1.09-1V5H7.636zm1.091 7H3.273c-.302 0-.546-.224-.546-.5s.244-.5.546-.5h5.454c.302 0 .546.224.546.5s-.244.5-.546.5zM3.273 9h5.454c.302 0 .546-.224.546-.5S9.029 8 8.727 8H3.273c-.302 0-.546.224-.546.5s.244.5.546.5z" _fill="#34335C"/>'
  }
})
