<template lang="pug">
  table.calendar-month-body
    thead
      th(
        v-for="(day, key) in daysInWeek",
        :key="key",
        :day="day")

        .calendar-week-header {{ day }}
    
    tbody
      tr(
        v-for="(week, i) in calendar",
        :key="i")

        td.calendar-week-number {{ week[0].week() }}

        td(v-for="(day, j) in week", :key="j")
          calendar-date(
            :day="day",
            :dateView="date",
            :selectedDate="selectedDate",
            @click="$emit('select-date', day)")
</template>

<script>
import CalendarWeek from './CalendarWeek'
import CalendarDate from './CalendarDate'

export default {
  components: {
    CalendarWeek,
    CalendarDate,
  },

  props: {
    date: {
      default: null,
    },

    selectedDate: {
      default: null,
    },
  },

  computed: {
    calendar() {
      let calendar = []

      const startDay = this.date
        .clone()
        .startOf('month')
        .startOf('week')
      const endDay = this.date
        .clone()
        .endOf('month')
        .endOf('week')

      let date = startDay.clone().subtract(1, 'day')

      while (date.isBefore(endDay, 'day')) {
        calendar.push(
          Array(7)
            .fill(0)
            .map(() => date.add(1, 'day').clone())
        )
      }

      return calendar
    },
  },

  data() {
    return {
      daysInWeek: ['', ...this.$moment.weekdaysMin(true)]
    }
  },
}
</script>
