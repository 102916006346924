/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.91 11a1 1 0 01-.53-.21l-4.02-3.2a.85.85 0 01-.13-1.27 1 1 0 011.37-.08L8 8.93l3.4-2.7a1 1 0 011.37.07.85.85 0 01-.13 1.27l-4.02 3.2c-.2.16-.45.24-.71.22z" _fill="#1E1D4B"/>'
  }
})
