/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloud-upload': {
    width: 25,
    height: 22,
    viewBox: '0 0 25 22',
    data: '<defs><path pid="0" d="M18.466 17.185a1.007 1.007 0 0 1-1.266-.662 1.015 1.015 0 0 1 .66-1.272c2.602-.821 3.92-2.93 3.92-6.269 0-3.835-3.107-6.954-6.928-6.954-3.322 0-6.182 2.376-6.802 5.649l-.206 1.089-1.062-.304a3.731 3.731 0 0 0-1.024-.146 3.75 3.75 0 0 0-3.739 3.752 3.75 3.75 0 0 0 3.74 3.753l.205-.015c.564-.022 1.033.4 1.062.958.03.56-.397 1.036-.955 1.066l-.067.006a3.102 3.102 0 0 1-.246.011C2.583 17.847 0 15.255 0 12.067c0-3.186 2.583-5.779 5.758-5.779.184 0 .368.01.551.028C7.456 2.602 10.902 0 14.852 0 19.786 0 23.8 4.03 23.8 8.982c0 4.265-1.844 7.102-5.334 8.203zm-7.212-7.322c.003-.003.004-.009.007-.012a1.009 1.009 0 0 1 1.433 0l.005.01 2.787 2.796a1.017 1.017 0 0 1 0 1.434 1.008 1.008 0 0 1-1.428 0L12.99 13.02v6.38c0 .56-.452 1.014-1.01 1.014-.559 0-1.01-.454-1.01-1.013v-6.386l-1.073 1.077a1.004 1.004 0 0 1-1.427 0 1.015 1.015 0 0 1 0-1.434l2.784-2.794z" id="svgicon_cloud-upload_a"/></defs><g transform="translate(.333 1)" _fill="none" fill-rule="evenodd"><mask id="svgicon_cloud-upload_b" _fill="#fff"><use xlink:href="#svgicon_cloud-upload_a"/></mask><use _stroke="#FFF" stroke-width=".3" _fill="#FF805D" xlink:href="#svgicon_cloud-upload_a"/><g mask="url(#svgicon_cloud-upload_b)" _fill="#FFF" fill-rule="nonzero"><path pid="1" d="M-10-10h43v40h-43z"/></g></g>'
  }
})
