/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bg': {
    width: 16,
    height: 16,
    viewBox: '0 0 640 480',
    data: '<g fill-rule="evenodd" stroke-width="1pt"><path pid="0" fill="#d62612" d="M0 320h640v160H0z"/><path pid="1" fill="#fff" d="M0 0h640v160H0z"/><path pid="2" fill="#00966e" d="M0 160h640v160H0z"/></g>'
  }
})
