import Vue from 'vue'
import UsersProvider from '@provider/user'
import * as TYPES from '@/store/types'

// initial state
const state = {
  chats: [],
  statuses: {},
  loaded: true,
}

// getters
const getters = {
  teamChatsStatuses: state => state.statuses,
  teamChats: state => state.chats,
  loadedTeamChats: state => state.loaded,
}

// actions
const actions = {
  getUsersChat({ commit }, search) {
    return new Promise((resolve, reject) => {
      UsersProvider.getUsersChat(search)
        .then(chats => {
          commit(TYPES.DIRECT_CHATS.INIT_CHATS, chats)
          commit(TYPES.DIRECT_CHATS.SWITCH_LOADED, false)
          resolve(chats)
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [TYPES.DIRECT_CHATS.INIT_CHATS](state, chats) {
    state.chats = chats
  },

  [TYPES.DIRECT_CHATS.SWITCH_LOADED](state, status = false) {
    state.loaded = status
  },

  [TYPES.DIRECT_CHATS.SWITCH_CHAT_STATUS](state, { id, status }) {
    if (!id) return

    Vue.set(state.statuses, id, status)
    // state.statuses[id] = status
  },

  [TYPES.DIRECT_CHATS.INIT_CHAT_STATUSES](state, list) {
    list.forEach(({ id, status }) => {
      Vue.set(state.statuses, id, status)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
