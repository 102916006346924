<template lang="pug">
  ui-button.circle.success(
    @click="openChecklist"
  )
    ui-svg.white(name="plus")
</template>

<script>
export default {
  name: 'ChecklistsAddChecklistButton',

  methods: {
    openChecklist(event) {
      this.openModal({
        name: 'CreateChecklist',
        params: this.$utils.defineNonReactive({
          el: event.currentTarget,
          scroll: 'project-info-scroll',
          chatId: this.$route.params.chatId,
          projectId: this.$route.params.projectId,
          showImportTaskLink: true,
          position: '3 5',
          callback: () => {},
        }),
      })
    },
  }
}
</script>
