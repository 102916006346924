<template lang="pug">
  .line-item(
    @click="handleEditPlanItem",
    @contextmenu="handleContextMenu",
    @mouseenter="handleMouseenter"
    @mouseleave="handleMouseleave"
    @mousedown.left="handleMousedown",
    :style="styles",
    :class="{ resize: item.isResize, dragging: item.isDragging, search: item.isSearch }",
    v-tooltip="tooltip")

    .drag-left(
      @mousedown.stop.left="handleResize($event, 'left')"
      @touchstart="handleResize($event, 'left', true)"
    ) |
    .name
      span {{ item.name }}
    .drag-right(
      @mousedown.stop.left="handleResize($event, 'right')"
      @touchstart="handleResize($event, 'right', true)"
    ) |
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import helpers from '@/helpers'
import CancelSourceManager from '@/plugins/cancelSourceManager';

const RESIZING_START_DELTA = 10;

const addBreaksIfTextIsNotEmpty = (text, count = 1) => {
  const breaks = Array.from({ length: count }, () => '<br />').join('');
  return text ? `${text}${breaks}` : text;
};

export default {
  name: "PlanningLinesPlanningItem",

  props: {
    item: {
      type: Object,
      required: true,
    },

    dataGenLines: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      styles: {},
      duplicateLoading: false,
      hoursText: null,
    }
  },

  computed: {
    ...mapGetters('Projects', ['getProjectById']),

    ...mapGetters('Registrations', ['hoursRegistrationsByProjectId']),

    ...mapGetters('User', ['permissions']),

    cancelKey() {
      return `planning-item:${this.item.id}`;
    },

    projectId() {
      const { projectId, checklist } = this.item;
      const { mainProjectId } = checklist ?? {};
      return mainProjectId ?? (projectId || null);
    },

    project() {
      const { projectId } = this;
      return this.getProjectById(projectId);
    },

    projectHoursRegistrations() {
      const { projectId } = this;
      if (!projectId) return [];
      return this.hoursRegistrationsByProjectId[projectId] ?? [];
    },

    budgetHours() {
      return this.project?.hoursBudget ?? null;
    },

    realizedHours() {
      if (!this.projectHoursRegistrations.length) return null;
      return this.projectHoursRegistrations.reduce((hours, registration) => hours + registration.amount, 0);
    },

    remainingHours() {
      return this.budgetHours !== null && this.realizedHours !== null
        ? this.budgetHours - this.realizedHours
        : null;
    },

    tooltip() {
      let text = `${this.$utils.clearTags(this.item?.name ?? '')}`;
      const { hoursText } = this;

      if (hoursText) {
        text = addBreaksIfTextIsNotEmpty(text, 1) + `<span style="font-size: 0.75em; line-height: 1.5">${hoursText}</span>`;
      }

      if (this.item?.description) {
        text = addBreaksIfTextIsNotEmpty(text, 2) + `${this.$utils.clearTags(this.item?.description) ?? ''}`;
      }

      if (!text || this.item.isResize) {
        return null;
      }

      return {
        text,
        position: 'bottom left',
        style: {
          maxWidth: '35em',
          maxHeight: '50em',
        },
      }
    },
  },

  watch: {
    dataGenLines: {
      deep: true,
      handler() {
        this.genStyles()
      },
    },

    item: {
      deep: true,
      handler() {
        this.genStyles()
      },
    },
  },

  methods: {
    setHoursText() {
      const hours = Object.entries({
        [this.$t('planningItem.budgetHours')]: this.budgetHours,
        [this.$t('planningItem.realizedHours')]: this.realizedHours,
        [this.$t('planningItem.remainingHours')]: this.remainingHours,
      })
        .filter(([_, value]) => value !== null)
        .map(([key, value]) => [key, this.formatHours(value)]);

      this.hoursText = hours.length ? hours.map(values => values.join(': ')).join('<br/>') : '';
    },

    formatHours(hours) {
      return this.$utils.formatNumWithComma(hours, 2);
    },

    async handleMouseenter() {
      const { projectId } = this;

      if (
        this.loading
        || this.projectHoursRegistrations.length
        || !projectId
      ) {
        this.setHoursText();

        return;
      }

      const { cancelKey } = this;

      const runWithCancelSource = CancelSourceManager.withCancelSource(cancelKey);

      this.loading = true;

      try {
        await runWithCancelSource(
          ({ cancelSource }) => this.getRegistrations({ projectId, cancelToken: cancelSource.token })
        );

        // The optimal calculation for registration hours (does not trigger reactivity and Dep`s)
        this.setHoursText();
      } catch (error) {
        if (!CancelSourceManager.isCancel(error)) {
          throw error;
        }
      } finally {
        this.loading = false;
      }
    },

    handleMouseleave() {
      const { cancelKey } = this;
      CancelSourceManager.cancelById(cancelKey, true);
    },

    handleMousedown() {
      this.$emit('start-dragging');
    },

    handleContextMenu(e) {
      if (this.item.isDragging) return;

      e.preventDefault()
      e.stopPropagation()

      const list = []

      if (this.projectId) {
        list.push({
          placeholder: this.$t(
            'planningComponent.itemProjectConextMenu.openProject'
          ),
          callback: () => {
            this.$utils.openProject({
              id: this.projectId,
            })
          },
        })
      }

      if (this.permissions.planning.planItem.canCreate) {
        list.push({
          placeholder: this.$t(
            'planningComponent.itemProjectConextMenu.duplicateItem'
          ),
          callback: () => {
            if (this.duplicateLoading === false) {
              this.duplicateLoading = true
              this.duplicateItem(this.item.id).finally(() => {
                this.duplicateLoading = false
              })
            }
          },
        })
      }

      this.openModal({
        name: 'ContextMenu',
        params: {
          position: 'top center',
          list,
        },
      })
    },

    genStyles() {
      let visible = this.getVisibleLine()

      if (visible)
        this.styles = {
          backgroundColor: this.item.color,
          width: `${this.getWidthTime()}px`,
          left: `${this.getLeftOffset()}px`,
        }
      else {
        this.styles = {
          left: '0px',
          width: '0px',
          display: 'none',
        }
      }
    },

    getVisibleLine() {
      if (
        this.dataGenLines.startAt > this.item.endAt ||
        this.item.startAt > this.dataGenLines.endAt
      )
        return false
      return true
    },

    getWidthTime() {
      const startAt =
          this.dataGenLines.startAt < this.item.startAt
            ? this.item.startAt
            : this.dataGenLines.startAt,
        endAt =
          this.dataGenLines.endAt > this.item.endAt
            ? this.item.endAt
            : this.dataGenLines.endAt

      let dstSec = 0
      //let dstSec = !startAt.isDST() && endAt.isDST() ? 3600 : 0

      return (
        (this.$moment.duration(endAt.diff(startAt)).asSeconds() + dstSec) *
        this.dataGenLines.pxTime
      )
    },

    getLeftOffset() {
      let startLine = this.dataGenLines.startAt.clone().startOf('seconds')
      let endLine = this.dataGenLines.endAt.clone().endOf('seconds')
      let startItem = this.item.startAt.clone().startOf('seconds')

      let dstSec = 0
      //if (startLine.isDST() && endLine.isDST()) dstSec = 0
      //else if (startItem.isDST()) dstSec = 3600

      let sec = (startItem - startLine) / 1000 + dstSec
      let offset = sec < 0 ? 0 : sec * this.dataGenLines.pxTime

      return offset
    },

    handleEditPlanItem() {
      if (this.item.isDragging) return;
      if (this.item.isResize) return

      this.openModal({
        name: 'PlanningChangeItem',
        params: {
          item: this.item,
        },
      })
    },

    handleResize(event, side, isTouch = false) {
      if (this.item.isDragging) return;

      event.preventDefault()
      event.stopPropagation()

      const checkAbilityToStartResize = deltaInPx => Math.abs(deltaInPx) > RESIZING_START_DELTA;

      const startResizing = () => {
        this.$emit('resize', true);
      }

      const originalId = this.item.id,
        originalRect = this.$el.getBoundingClientRect(),
        originalStartAt = this.item.startAt,
        originalEndAt = this.item.endAt,
        backupDuedate = { ...this.item }

      const { pxTime } = this.dataGenLines;

      const limitGridItemDate = side === 'right'
        ? this.dataGenLines.getGridEndAt(originalStartAt.clone())
        : this.dataGenLines.getGridStartAt(originalEndAt.clone())

      let doDrag = (event) => {
        const eventPositionX = helpers.getEventPageX(event);

        requestAnimationFrame(() => {
          if (this.item.id != originalId) return

          if (side == 'right') {
            const pxDelta = eventPositionX - originalRect.right;
            const deltaInSeconds = Math.floor(pxDelta / pxTime);

            if (!this.item.isResize) {
              if (checkAbilityToStartResize(pxDelta)) {
                startResizing();
              } else {
                return;
              }
            }

            const tmpEndAt = originalEndAt.clone().add(deltaInSeconds, 'seconds');
            let newEndAt = limitGridItemDate > tmpEndAt ? limitGridItemDate : tmpEndAt;
            newEndAt = this.dataGenLines.roundGridEndAt(newEndAt);

            if (newEndAt <= this.item.startAt) return
            if (newEndAt - this.item.endAt == 0) return

            this.updateLocalDuedatePlanItem({
              id: this.item.id,
              endAt: newEndAt,
              isResize: true,
            })
          }

          if (side == 'left') {
            const pxDelta = eventPositionX - originalRect.left;
            const deltaInSeconds = Math.floor(pxDelta / pxTime);

            if (!this.item.isResize) {
              if (checkAbilityToStartResize(pxDelta)) {
                startResizing();
              } else {
                return;
              }
            }

            const tmpEndAt = originalStartAt.clone().add(deltaInSeconds, 'seconds');
            let newStartAt = limitGridItemDate < tmpEndAt ? limitGridItemDate : tmpEndAt;
            newStartAt = this.dataGenLines.roundGridStartAt(newStartAt);

            if (newStartAt >= this.item.endAt) return
            if (newStartAt - this.item.startAt == 0) return

            this.updateLocalDuedatePlanItem({
              id: this.item.id,
              startAt: newStartAt,
              isResize: true,
            })
          }
        })
      }

      let stopDrag = () => {
        if (side == 'right' && this.item.startAt > this.item.endAt)
          this.updateLocalDuedatePlanItem({
            id: this.item.id,
            ...this.dataGenLines.zoomHover(this.item.startAt),
            isResize: true,
          })
        else if (side == 'left' && this.item.startAt > this.item.endAt)
          this.updateLocalDuedatePlanItem({
            id: this.item.id,
            ...this.dataGenLines.zoomHover(this.item.endAt),
            isResize: true,
          })

        this.updatePlanItem({
          id: this.item.id,
          startAt: this.item.startAt.format('YYYY-MM-DD HH:mm'),
          endAt: this.item.endAt.format('YYYY-MM-DD HH:mm'),
        }).catch(error => {
          this.$notify.error(this.$utils.response(error))
          this.updateLocalDuedatePlanItem({
            ...backupDuedate,
            isResize: false,
          });
        })

        if (isTouch) {
          document.removeEventListener('touchmove', doDrag, false)
          document.removeEventListener('touchend', stopDrag, false)
          document.removeEventListener('touchcancel', stopDrag, false)
        } else {
          document.removeEventListener('mousemove', doDrag, false)
          document.removeEventListener('mouseup', stopDrag, false)
        }

        this.$emit('resize', false)
      }

      if (isTouch) {
        document.addEventListener('touchmove', doDrag, false)
        document.addEventListener('touchend', stopDrag, false)
        document.addEventListener('touchcancel', stopDrag, false)
      } else {
        document.addEventListener('mousemove', doDrag, false)
        document.addEventListener('mouseup', stopDrag, false)
      }
    },

    ...mapActions('Registrations', ['getRegistrations']),

    ...mapActions('PlanningResourcePlanItems', [
      'updatePlanItem',
      'updateLocalDuedatePlanItem',
      'duplicateItem',
    ]),
  },

  mounted() {
    this.genStyles()
  },
}
</script>
