/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'google': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.64 9.2c0-.63-.06-1.25-.16-1.84H9v3.49h4.84a4.14 4.14 0 0 1-1.8 2.71v2.26h2.92a8.78 8.78 0 0 0 2.68-6.62z" _fill="#4285F4"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M9 18a8.6 8.6 0 0 0 5.96-2.18l-2.91-2.26a5.4 5.4 0 0 1-8.09-2.85h-3v2.33A9 9 0 0 0 9 18z" _fill="#34A853"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M3.96 10.71a5.41 5.41 0 0 1 0-3.42V4.96h-3a9 9 0 0 0 0 8.08l3-2.33z" _fill="#FBBC05"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M9 3.58c1.32 0 2.5.45 3.44 1.35l2.58-2.59A9 9 0 0 0 .96 4.95l3 2.34A5.36 5.36 0 0 1 9 3.58z" _fill="#EA4335"/>'
  }
})
