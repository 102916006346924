/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'send': {
    width: 16,
    height: 16,
    viewBox: '0 0 1792 1792',
    data: '<path pid="0" d="M1764 11c22 16 31 37.3 27 64l-256 1536c-3.3 19.3-14 34.3-32 45-9.3 5.3-19.7 8-31 8-7.3 0-15.3-1.7-24-5l-453-185-242 295c-12 15.3-28.3 23-49 23-8.7 0-16-1.3-22-4-12.7-4.7-22.8-12.5-30.5-23.5S640 1741.3 640 1728v-349l864-1059-1069 925-395-162c-24.7-9.3-38-27.7-40-55-1.3-26.7 9.3-46.3 32-59L1696 9c10-6 20.7-9 32-9 13.3 0 25.3 3.7 36 11z" _fill="#fff"/>'
  }
})
