/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'send-o': {
    width: 22,
    height: 21,
    viewBox: '0 0 22 21',
    data: '<path pid="0" d="M21.042.28c.577.35.905.975.852 1.626l-1.429 17.216a1.764 1.764 0 0 1-.988 1.442 1.93 1.93 0 0 1-1.805-.045L12.3 17.492l-1.447 2.567c-.317.576-.941.936-1.621.935-.112 0-.222-.009-.331-.026-.791-.125-1.406-.725-1.52-1.482l-.859-5.345a1.986 1.986 0 0 1 .627-1.768l10.572-9.748-16.032 8.62a.347.347 0 0 0-.189.378c.03.152.16.268.321.286l1.68.22a.75.75 0 0 1 .514.268c.126.153.18.347.152.539a.706.706 0 0 1-.302.477.774.774 0 0 1-.571.12l-1.684-.221c-.794-.1-1.43-.675-1.578-1.426-.148-.75.226-1.508.927-1.876L19.13.23a1.928 1.928 0 0 1 1.913.05zM9.544 19.393l1.475-2.618-2.825-1.59.655 4.093c.02.154.146.275.306.296a.357.357 0 0 0 .389-.181zm9.44-.376l1.402-16.887-12.204 11.26a.947.947 0 0 0-.083.096l10.326 5.81c.11.061.247.065.36.009a.353.353 0 0 0 .199-.288z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
