<template lang="pug">
  .planning-component
    .loaded-data(v-show="loadedData")

    .header
      .sidebar-head
      component(
        v-scroll="handleScrollOnPlanningZoom",
        ref="zoomComponent",
        :is="listZoomComponents[planningData.zoomView]",
        v-model="zoomPlan")

    .body
      .planning-sidebar-wrap
        .planning-sidebar(
          ref="planningSidebarScrollbar",
          v-on-dom-update="initBindScrolls",
          v-scroll="handleScrollOnPlanningSidebar")

          component(
            v-for="(item, i) in list",
            :key="i",
            v-size-sync="`planning-${i}.h.child`",
            :is="item.header.component",
            :params="item.header.params")

      .planning-data(
        ref="planningDataScrollbar",
        @mouseup="handleMouseUpOnPlanningData",
        v-scroll="handleScrollOnPlanningData")

        CurrentTimeLine(
          :dataGenLines="dataGenLines",
          :zoomPlan="zoomPlan")

        .planning-data-inner(v-detect-resize="handlePlanningResize")
          component(
            :is="listGreedComponents[planningData.zoomView]",
            :zoomPlan="zoomPlan")

          component(
            v-for="(item, i) in list",
            :key="i",
            :dataGenLines="dataGenLines",
            v-size-sync="`planning-${i}.h.parent`",
            :is="item.body.component",
            :params="item.body.params"
            @resource-mousemove="handleResourceMousemove(item.body.params)"
            @start-dragging="handleStartDraggingItem($event, item.body.params)"
          )
</template>

<script>
import UserSidebarPlanning from './components/UserSidebarPlanning'
import GroupSidebarPlanning from './components/GroupSidebarPlanning'
import CurrentTimeLine from './components/CurrentTimeLine'

import ZoomDay from './zoom/Day'
import ZoomWeek from './zoom/Week'
import ZoomMonth from './zoom/Month'
import ZoomQuarter from './zoom/Quarter'

import GreedDay from './greed/Day'
import GreedWeek from './greed/Week'
import GreedMonth from './greed/Month'
import GreedQuarter from './greed/Quarter'

import LinesPlanning from './components/LinesPlanning'
import GroupPlanning from './components/GroupPlanning'

import PlanningScrollbarMixin from './mixins/planningScrollbar'
import { mapState, mapActions, mapGetters } from 'vuex'

const DRAGGING_START_DELTA = 20;

const checkResourcesChanges = (resources1, resources2) => {
  return resources1.length !== resources2.length
    || resources1.some(r1 => resources2.every(r2 => Number(r2.id) !== Number(r1.id)));
};

const getDraggingState = () => ({
  id: null,
  originalDuration: null,
  originalStartAt: null,
  originalEndAt: null,
  originalResources: [],
  originalRestResources: [],
});

export default {
  mixins: [PlanningScrollbarMixin],

  inject: ['planningData'],

  components: {
    UserSidebarPlanning,
    GroupSidebarPlanning,
    CurrentTimeLine,

    LinesPlanning,
    GroupPlanning,

    ZoomDay,
    ZoomWeek,
    ZoomMonth,
    ZoomQuarter,

    GreedDay,
    GreedWeek,
    GreedMonth,
    GreedQuarter,
  },

  props: {
    list: {
      require: false,
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isDragging: false,
      draggingState: getDraggingState(),

      zoomPlan: {
        startAt: this.$moment(),
        endAt: this.$moment(),
        zoomWidth: 0,
      },

      loadedDataTimes: {
        startAt: null,
        endAt: null,
      },

      listZoomComponents: ['ZoomDay', 'ZoomWeek', 'ZoomMonth', 'ZoomQuarter'],
      listGreedComponents: [
        'GreedDay',
        'GreedWeek',
        'GreedMonth',
        'GreedQuarter',
      ],
    }
  },

  created() {
    let delAllTest = async () => {
      let groupsIds = this._.union(
        this.list
          .filter(e => e.header.component == 'GroupSidebarPlanning')
          .map(e => e.header.params.id)
      )

      let resIds = this._.union(
        this.list
          .filter(e => e.header.component == 'UserSidebarPlanning')
          .map(e => e.header.params.id)
      )

      await Promise.all(
        resIds.map(
          id =>
            new Promise((resolve, reject) => {
              this.deletePlanningResource({ id })
                .then(resolve)
                .catch(reject)
            })
        )
      )

      await Promise.all(
        groupsIds.map(
          id =>
            new Promise((resolve, reject) => {
              this.deletePlanningResourceGroup({ id })
                .then(resolve)
                .catch(reject)
            })
        )
      )
    }

    let createTestData = async () => {
      let newGroup = await this.addPlanningResourceGroup({
        name: 'TEST AUTO',
      })

      const itemWidth = 30

      for (let i = 1; i < 10; i++) {
        await this.addPlanningResource({
          resourceGroupId: newGroup.id,
          name: `${i}`,
          userId: undefined,
          position: i,
        }).then(e => {
          let due = {
            startAt: this.$moment(),
            endAt: this.$moment().add(itemWidth, 'days'),
          }

          for (let j = 0; j < 10; j++) {
            this.addPlanItem({
              name: `${e.id} - ${j}`,

              color: this.$utils.$colors.list[1],
              users: [],

              resourceIds: [e.id],
              startAt: due.startAt
                .add(itemWidth, 'days')
                .format('YYYY-MM-DD HH:mm'),
              endAt: due.endAt
                .add(itemWidth, 'days')
                .format('YYYY-MM-DD HH:mm'),
            })
          }
        })
      }
      function addItems(fromDate, count) {
        const colors = app.$utils.$colors.list;
        const userId = app.$store.state.User.user.id;
        const resourceId = 31650;

        const date1 = app.$moment(fromDate);
        const date2 = app.$moment(fromDate).add(12, 'hour');

        const formatDate = moment => moment.format('YYYY-MM-DD HH:mm');
        const shiftDate = moment => moment.add(1, 'day');

        const getRandomColor = () => {
          const count = colors.length;
          return colors[Math.ceil(Math.random() * count) % count];
        }

        const color1 = getRandomColor();
        const color2 = getRandomColor();

        for (let i = 0; i < count; i++) {
          app.$store.dispatch("PlanningResourcePlanItems/addPlanItem", {
            name: `User task - 1.${i}`,
  
            color: color1,
            users: [userId],
  
            resourceIds: [resourceId],
  
            startAt: formatDate(date1),
            endAt: formatDate(shiftDate(date1)),
          });

          app.$store.dispatch("PlanningResourcePlanItems/addPlanItem", {
            name: `User task - 2.${i}`,
  
            color: color2,
            users: [userId],
  
            resourceIds: [resourceId],
  
            startAt: formatDate(date2),
            endAt: formatDate(shiftDate(date2)),
          });

          shiftDate(date1);
          shiftDate(date2);
        }
      }
    }

    // delAllTest()
    // createTestData()
  },

  computed: {
    periodInSeconds() {
      const { startAt, endAt } = this.zoomPlan;
      if (!startAt || !endAt) return 1;
      return (endAt - startAt) / 1000;
    },

    pxTime() {
      const { periodInSeconds } = this;
      const { zoomWidth } = this.zoomPlan;
      return zoomWidth / periodInSeconds;
    },

    dataGenLines() {
      return {
        pxTime: this.pxTime,
        ...this.zoomPlan,
      }
    },

    draggingItem() {
      const { id } = this.draggingState;
      return this.planningItems.find(item => item.id === id);
    },

    ...mapState('PlanningResourcePlanItems', {
      loadedData: state => state.loading,
    }),

    ...mapGetters('PlanningResourcePlanItems', {
      planningItems: 'planningItems',
    }),

    ...mapGetters('PlanningResources', [
      'planningResourcesByIds',
    ]),
  },

  methods: {
    startDragging() {
      const {
        id,
        originalDuration,
        originalStartAt,
        originalEndAt,
        originalResources,
      } = this.draggingState;

      const { pxTime } = this.dataGenLines;

      const startPosition = this.$utils.$mouse.x;

      const drag = () => {
        requestAnimationFrame(() => {
          const { draggingItem } = this;
          if (!draggingItem) return;

          const deltaInPx = this.$utils.$mouse.x - startPosition;
          const deltaInSeconds = Math.floor(deltaInPx / pxTime);

          if (!draggingItem.isDragging && Math.abs(deltaInPx) < DRAGGING_START_DELTA) {
            return;
          }

          let newStartAt = originalStartAt.clone().add(deltaInSeconds, 'seconds');
          let newEndAt = newStartAt.clone().add(originalDuration, 'milliseconds');

          // --------------------------------------------
          // Implementation of item sticking by grid
          // let roundedStartAt = this.dataGenLines.roundGridStartAt(newStartAt);
          // let roundedEndAt = this.dataGenLines.roundGridEndAt(newEndAt);

          // if (newStartAt - roundedStartAt < roundedEndAt - newEndAt) {
          //   newEndAt = roundedStartAt.clone().add(originalDuration, 'milliseconds');
          //   roundedEndAt = this.dataGenLines.roundGridEndAt(newEndAt);
          // } else {
          //   newStartAt = roundedEndAt.clone().add(-originalDuration, 'milliseconds');
          //   roundedStartAt = this.dataGenLines.roundGridStartAt(newStartAt);
          // }

          // if (newStartAt - roundedStartAt < roundedEndAt - newEndAt) {
          //   newStartAt = roundedStartAt;
          //   newEndAt = newStartAt.clone().add(originalDuration, 'milliseconds');
          // } else {
          //   newEndAt = roundedEndAt;
          //   newStartAt = newEndAt.clone().add(-originalDuration, 'milliseconds');
          // }
          // END: Implementation of item sticking by grid
          // --------------------------------------------

          const hasChanges =
            newStartAt - draggingItem.startAt !== 0
            || newEndAt - draggingItem.endAt !== 0;

          if (!hasChanges) return;

          this.updateLocalDuedatePlanItem({
            id,
            startAt: newStartAt,
            endAt: newEndAt,
            isDragging: true,
          });
        });
      };

      const stopDragging = () => {
        document.removeEventListener('mousemove', drag);
        document.removeEventListener('mouseup', stopDragging);

        const {
          startAt,
          endAt,
          resources,
        } = this.draggingItem;

        const newStartAt = startAt - originalStartAt !== 0
          ? startAt.format('YYYY-MM-DD HH:mm')
          : undefined;
        const newEndAt = endAt - originalEndAt !== 0
          ? endAt.format('YYYY-MM-DD HH:mm')
          : undefined;

        const hasResourceChanges = checkResourcesChanges(originalResources, resources);

        if (newStartAt || newEndAt || hasResourceChanges) {
          const resourceIds = hasResourceChanges
            ? resources.map(resource => resource.id)
            : undefined;

          this.updatePlanItem({
            id,
            resourceIds,
            startAt: newStartAt,
            endAt: newEndAt,
          })
            .catch(error => {
              this.$notify.error(this.$utils.response(error));
              this.updateLocalDuedatePlanItem({
                id,
                startAt: originalStartAt,
                endAt: originalEndAt,
                resources: originalResources,
                isDragging: false,
              });
            });
        }

        this.draggingState = getDraggingState();
      }

      document.addEventListener('mousemove', drag);
      document.addEventListener('mouseup', stopDragging);
    },
    handleResourceMousemove(resourceParams) {
      if (!this.draggingItem) return;

      requestAnimationFrame(() => {
        const { draggingState, draggingItem } = this;

        if (!draggingItem) return;

        const currentResourceId = resourceParams.id;

        const hasResource = draggingItem.resources.some(resource => resource.id === currentResourceId);

        if (!hasResource) {
          const currentResource = this.planningResourcesByIds[currentResourceId];
          const newResources = [
            ...draggingState.originalRestResources,
            this._.cloneDeep(currentResource)
          ];

          this.updateLocalPlanItemResources({
            id: draggingState.id,
            resources: newResources,
          });
        }
      })
    },
    handleStartDraggingItem(item, resourceParams) {
      const { draggingState } = this;
      draggingState.id = item.id;
      draggingState.originalDuration = item.endAt - item.startAt;
      draggingState.originalStartAt = item.startAt;
      draggingState.originalEndAt = item.endAt;
      draggingState.originalResources = [...item.resources];
      draggingState.originalRestResources = item.resources.filter(resource => resource.id !== resourceParams.id);

      this.startDragging();
    },
    loadNewData({ startAt, endAt }) {
      return this.planningData
        .loadData({
          startAt,
          endAt,
        });
    },

    /** Testing actions */
    ...mapActions('PlanningResourceGroups', [
      'addPlanningResourceGroup',
      'deletePlanningResourceGroup',
    ]),
    ...mapActions('PlanningResources', [
      'addPlanningResource',
      'deletePlanningResource',
    ]),
    ...mapActions('PlanningResourcePlanItems', [
      'addPlanItem',
      'updatePlanItem',
      'updateLocalPlanItemResources',
      'updateLocalDuedatePlanItem',
    ]),
  },
}
</script>

<style lang="sass" src="./sass/src.sass"></style>
