<template lang="pug">
  .ui.search-drop-list.users(
    :class="{drop}",
    v-click-outside="() => toggleDrop(false)")

    .header.search(@click="toggleDrop(true)")
      ui-input(
        :disabled="true",
        ref="input",
        :placeholder="placeholder",
        :value="inputView")

    .body(v-if="drop")
      .filter
        ui-svg(name="search")
        input(
          ref="filterInout",
          autofocus,
          :placeholder="$t('ui.searchDropList.search')",
          v-model="search")

      ui-scrollbar.list
        .item(
          v-for="(item, i) in listFilter",
          :key="i",
          @click="pickProject(item)")
            .selected(:class="{active: item.selected}")
            .name {{ item.name }}

      .select-all(@click="handleSelectAll")
        .selected(:class="{active: selectedAll}")
        .name {{ $t('ui.searchDropList.selectAll') }}
</template>

<script>
export default {
  props: {
    projects: {
      type: Array,
      default: () => [],
      require: false,
    },

    value: {
      type: Array,
      default: () => [],
      require: false,
    },

    placeholder: {
      default: '',
    },
  },

  data() {
    return {
      search: '',
      drop: false,
    }
  },

  methods: {
    handleSelectAll() {
      this.$emit('input', this.selectedAll ? [] : this.list)
    },

    toggleDrop(state) {
      this.drop = state
      if (state) {
        this.$nextTick(() => {
          this.$refs.filterInout.focus()
        })
      }
    },

    pickProject(project) {
      this.$emit(
        'input',
        this.value.find(e => e.id === project.id)
          ? this.value.filter(e => e.id !== project.id)
          : [...this.value, project]
      )
    },
  },

  computed: {
    selectedAll() {
      return this.list.length == this.value.length
    },

    inputView() {
      return this.value.length > 0
        ? this.list
            .filter(e => e.selected)
            .map(e => e.name)
            .join(', ')
        : undefined
    },

    list() {
      return this.projects.map(e => {
        return {
          name: e.name,
          id: e.id,
          selected: this.value.find(user => user.id === e.id) ? true : false,
        }
      })
    },

    listFilter() {
      return this.list.filter(e => {
        return this.search.length > 0
          ? e.name.toLowerCase().includes(this.search.toLowerCase())
          : true
      })
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
