<template lang="pug">
  .current-time-line(:style="style")
    .circle
    .current-line
</template>

<script>
export default {
  inject: ['planningScrollbar'],

  props: {
    dataGenLines: {
      require: true,
    },
  },

  data() {
    return {
      style: {},
    }
  },

  watch: {
    dataGenLines: {
      deep: true,
      handler() {
        this.genStyles()
      },
    },

    planningScrollbar: {
      deep: true,
      handler() {
        this.genStyles()
      },
    },

    '$utils.$time.now': {
      deep: true,
      handler() {
        this.genStyles()
      },
    },
  },

  methods: {
    genStyles() {
      if (this.getVisibleLine())
        this.style = {
          height: this.planningScrollbar.height + 'px',
          left: `${this.getLeftOffset()}px`,
        }
      else
        this.style = {
          left: '0px',
          display: 'none',
        }
    },

    getVisibleLine() {
      if (
        this.dataGenLines.startAt > this.$moment() ||
        this.$moment() > this.dataGenLines.endAt
      )
        return false
      return true
    },

    getLeftOffset() {
      // let dstSec = this.$utils.$time.now.isDST() ? 3600 : 0
      let dstSec = 0

      let sec =
        this.$moment
          .duration(this.$utils.$time.now.diff(this.dataGenLines.startAt))
          .asSeconds() + dstSec

      return sec < 0 ? 0 : sec * this.dataGenLines.pxTime
    },
  },

  mounted() {
    this.genStyles()
  },
}
</script>
