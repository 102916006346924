import Vue from 'vue'
import _ from 'lodash'

function setMMWidth(el, val) {
  el.style.minWidth = `${val}px`
  el.style.width = `${val}px`
  el.style.maxWidth = `${val}px`
}

function parseOptions(binding) {
  const orientate = _.get(binding, 'value.orientate', 'v h')

  return {
    canResizeWidth: orientate.includes('h'),
    canResizeHeight: orientate.includes('v'),
    minW: _.get(binding, 'value.minW', 20),
    initW: _.get(binding, 'value.width', null),
  }
}

function emitEv(el, eventName) {
  const width = parseFloat(window.getComputedStyle(el).width),
    height = parseFloat(window.getComputedStyle(el).height)

  el.dispatchEvent(new CustomEvent(eventName, { detail: { width, height } }))
}

Vue.directive('resize-block', {
  bind: function(el, binding, vnode) {
    el.classList.add('resizable')

    let startX, startY, startWidth, startHeight

    const { canResizeHeight, canResizeWidth, minW, initW } = parseOptions(
      binding
    )

    const resizer = document.createElement('div')

    resizer.classList.add('resizer')
    el.appendChild(resizer)

    new ResizeObserver(() => {
      // requestAnimationFrame(() => {
      emitEv(el, 'resizeBlock')
      // })
    }).observe(el)

    if (initW) {
      setMMWidth(el, initW)
    } else
      vnode.context.$nextTick(() => {
        emitEv(el, 'resizeBlock')
      })

    const initDrag = e => {
      e.preventDefault()
      e.stopPropagation()

      startX = e.clientX
      startY = e.clientY

      startWidth = parseFloat(window.getComputedStyle(el).width)
      startHeight = parseFloat(window.getComputedStyle(el).height)

      document.documentElement.addEventListener('mousemove', doDrag, false)
      document.documentElement.addEventListener('mouseup', stopDrag, false)
    }

    const doDrag = e => {
      requestAnimationFrame(() => {
        let width = startWidth + e.clientX - startX,
          height = startHeight + e.clientY - startY

        if (width < minW) width = minW

        if (canResizeWidth) setMMWidth(el, width)
        if (canResizeHeight) el.style.height = `${height}px`
      })
    }

    const stopDrag = e => {
      e.preventDefault()
      e.stopPropagation()

      document.documentElement.removeEventListener('mousemove', doDrag, false)
      document.documentElement.removeEventListener('mouseup', stopDrag, false)

      emitEv(el, 'stopResizeBlock')
    }

    resizer.addEventListener('mousedown', initDrag, false)
    resizer.onclick = function(event) {
      event.preventDefault()
      event.stopPropagation()
    }
  },

  update: function(el, binding, vnode) {
    const { initW } = parseOptions(binding)

    setMMWidth(el, initW)
  },
})
