/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" d="M14.3 13.43l-2.9-3.05a6.6 6.6 0 0 0 1.31-3.95C12.71 2.88 9.98 0 6.61 0 3.24 0 .5 2.88.5 6.43c0 3.54 2.74 6.42 6.1 6.42 1.33 0 2.55-.45 3.55-1.2l2.92 3.08a.85.85 0 0 0 1.24 0 .95.95 0 0 0 0-1.3zm-12.06-7a4.49 4.49 0 0 1 4.37-4.6c2.4 0 4.36 2.07 4.36 4.6a4.48 4.48 0 0 1-4.36 4.59 4.49 4.49 0 0 1-4.37-4.6z" _fill="#fff"/>'
  }
})
