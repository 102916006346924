<template lang="pug">
  button.ui-menu-button(
    type="button"
    :class="rootClasses"
    :disabled="disabled"
    @click="openMenu"
  )
    slot
      ui-svg.dark(name="dots")
</template>

<script>
export default {
  name: "UiMenuButton",

  props: {
    options: {
      type: Array,
      default: undefined,
    },

    /**
     * @values big
     */
    size: {
      type: String,
      default: undefined,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rootClasses() {
      const { size } = this;
      return {
        [`ui-menu-button--size_${size}`]: Boolean(size),
      };
    },
  },

  methods: {
    openMenu(e) {
      if (this.disabled) return;

      if (this.options === undefined) {
        this.$emit('click', e);
        return;
      }

      this.openModal({
        name: 'ContextMenu',
        // fix dependency traversal (for reactive data)
        params: this.$utils.defineNonReactive({
          scroll: 'page',
          el: e.target,
          position: 'top left',
          list: this.options,
        }),
      });
    },
  }
};
</script>

<style lang="sass" src="./styles.sass"></style>
