/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'unmute': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.918 5.031h-4.26c-.61 0-2.626.38-2.857 2.875v7.188a2.87 2.87 0 002.857 2.875h4.26l6.958 4.9a.71.71 0 00.74.05.719.719 0 00.384-.638V.72a.72.72 0 00-.384-.638.711.711 0 00-.74.049L9.918 5.03z" _fill="#000"/>'
  }
})
