<template lang="pug">
  .ui.location-input(
    v-click-outside="close"
    :class="{ drop, error }"
  )
    .header(@click="openAndFocus")
      slot(name="before")

      .placeholder(v-if="location")
        | {{ location }}
      .placeholder.empty(v-else)
        | {{ placeholder }}

      slot(name="after")

    .body(v-if="drop")
      .find
        ui-svg.bali-hai(name="search")
        input(
          ref="searchInput"
          :value="searchModel"
          :readonly="readonly"
          :placeholder="searchPlaceholder"
          @input="handleInput"
        )

      .results(v-loaded="loaded")
        template(v-if="!loaded")
          ui-scrollbar.list(v-if="items.length")
            .item(
              v-for="(item, index) in items"
              :key="index"
              @click="selectItem(item)"
            )
              | {{ item }}

          .no-search(v-else)
            | {{ noResults }}

</template>

<script>
export default {
  name: 'UiLocation',

  props: {
    search: {
      type: String,
      default: '',
    },

    location: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    searchPlaceholder: {
      type: String,
      default: '',
    },

    noResults: {
      type: String,
      default: '',
    },

    readonly: {
      type: Boolean,
      require: false,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loaded: false,
      drop: false,
      localSearch: '',
      items: [],
    };
  },

  computed: {
    locationModel: {
      get() {
        return this.location;
      },
      set(location) {
        this.$emit('update:location', location);
      },
    },

    searchModel: {
      get() {
        return this.localSearch;
      },
      set(search) {
        this.localSearch = search;
        this.$emit('update:search', search);
      }
    }
  },

  watch: {
    search: {
      immediate: true,
      handler(search) {
        if (this.localSearch !== search) {
          this.localSearch = search;
          this.fetchSuggestions();
        }
      },
    },
  },

  methods: {
    fetchSuggestions() {
      this.loaded = true;
      this.$placeAutocomplete
        .find(this.localSearch)
        .then((items) => {
          this.items = items.map(i => i.description);
        })
        .finally(() => {
          this.loaded = false;
        });
    },

    handleInput(event) {
      if (this.readonly) return;
      this.searchModel = event.target.value;
      this.fetchSuggestions();
    },

    selectItem(item) {
      this.locationModel = item;
      this.close();
    },

    focus() {
      if (this.readonly) return;
      this.$refs.searchInput?.focus();
    },

    /**
     * @public
     */
    close() {
      this.drop = false;
    },

    /**
     * @public
     */
    openAndFocus() {
      if (this.readonly) return;
      this.drop = true;
      this.$nextTick(() => {
        this.focus();
      });
    },
  },
};
</script>

<style lang="sass" src="./styles.sass"></style>
