<template lang="pug">
.app-truncate(
  v-tooltip="tooltipOptions"
)
  span.app-truncate__content(ref="content")
    span.app-truncate__text(ref="text")
      | {{ displayText }}
</template>

<script>
import { observe } from '@/helpers/resize-observer';

export default {
  __unobserveResizing__: null,

  name: "AppTruncate",

  props: {
    text: {
      type: String,
      default: ""
    },

    maxLines: {
      type: Number,
      default: 1
    },

    /**
     * @values start, middle, end
     */
    type: {
      type: String,
      default: "middle",
    },

    separator: {
      type: String,
      default: "…"
    },

    titleTip: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      truncateIndex: null,
      isShownTruncatedText: false
    };
  },

  computed: {
    truncatedText() {
      if (!this.truncateIndex) {
        return '';
      }

      if (this.type === 'end') {
        return (
          this.text.slice(0, this.truncateIndex).trim() +
          this.separator
        );
      }
      
      if (this.type === 'start') {
        return (
          this.separator +
          this.text.slice(-this.truncateIndex).trim()
        );
      }

      const middleIndex = Math.floor(this.truncateIndex / 2);
      const startLength = Math.max(middleIndex, 1);
      const endLength = Math.max(middleIndex, 1);

      return (
        this.text.slice(0, startLength).trim() +
        this.separator +
        this.text.slice(-endLength).trim()
      );
    },

    isTruncated() {
      return this.text && this.truncateIndex !== this.text.length;
    },

    displayText() {
      return this.isTruncated ? this.truncatedText : this.text;
    },

    tooltipOptions() {
      return {
        text: this.text,
        textOverflow: !this.isTruncated,
      };
    },
  },

  watch: {
    text() {
      this.bind();
    },

    maxLines() {
      this.update();
    },

    separator() {
      this.update();
    },

    isShownTruncatedText() {
      this.update();
    }
  },

  mounted() {
    this.bind();
  },

  activated() {
    this.bind();
  },

  deactivated() {
    this.unbind();
  },

  beforeDestroy() {
    this.unbind();
  },

  methods: {
    getLinesCount() {
      return [...this.$refs.content.getClientRects()].length;
    },

    checkOverflow() {
      const { maxLines } = this;
      const linesCount = this.getLinesCount();
      return maxLines < linesCount;
    },

    setText() {
      this.$refs.text.textContent = this.displayText;
    },

    truncate(truncateIndex) {
      this.truncateIndex = truncateIndex;
      this.setText();
    },

    findTruncateIndex() {
      let from = 0;
      let to = this.truncateIndex;

      while (to - from > 1) {
        const target = Math.floor((to + from) / 2);

        this.truncate(target);

        if (this.checkOverflow()) {
          to = target;
        } else if (target !== from) {
          from = target;
        }
      }

      this.truncate(from);
    },

    update() {
      this.truncate(this.text.length);

      if (this.checkOverflow()) {
        this.findTruncateIndex();
      }
    },

    observe() {
      this.__unobserveResizing__ = observe(this.$el, this.update);
    },

    unbind() {
      this.__unobserveResizing__?.();
    },

    bind() {
      this.unbind();

      this.observe();
      this.update();
    }
  }
};
</script>

<style lang="sass">
.app-truncate
  overflow: hidden
</style>
