<template lang="pug">
  .ui.search-drop-list.list(
    :class="{drop}",
    v-click-outside="() => toggleDrop(false)")

    .header.search(@click="toggleDrop(true)")
      ui-input(
        :disabled="true",
        ref="input",
        :placeholder="placeholder",
        :value="inputView")

    .body(v-if="drop")
      .filter
        ui-svg(name="search")
        input(
          ref="filterInout",
          :placeholder="$t('ui.searchDropList.search')",
          v-model="search")

      ui-scrollbar.list
        .item(
          v-for="(item, i) in listFilter",
          :key="i",
          @click="pickItem(item)")
            .selected(:class="{active: item.selected}")
            .name {{ item.placeholder }}
      
      .select-all(@click="handleSelectAll")
        .selected(:class="{active: selectedAll}")
        .name {{ $t('ui.searchDropList.selectAll') }}
</template>

<script>
import helpers from '@/helpers'

export default {
  props: {
    listData: {
      type: Array,
      default: () => [],
      require: false,
    },

    value: {
      type: Array,
      default: () => [],
      require: false,
    },

    placeholder: {
      default: '',
    },
  },

  data() {
    return {
      search: '',
      drop: false,
    }
  },

  methods: {
    handleSelectAll() {
      this.$emit('input', this.selectedAll ? [] : this.list)
    },

    toggleDrop(state) {
      this.drop = state
      if (state) {
        this.$nextTick(() => {
          this.$refs.filterInout.focus()
        })
      }
    },

    pickItem(item) {
      this.$emit(
        'input',
        this.value.find(e => e.id === item.id)
          ? this.value.filter(e => e.id !== item.id)
          : [...this.value, item]
      )
    },
  },

  computed: {
    selectedAll() {
      return this.list.length == this.value.length
    },

    inputView() {
      return this.value.length > 0
        ? this.list
            .filter(e => e.selected)
            .map(e => e.placeholder)
            .join(', ')
        : undefined
    },

    list() {
      return this.listData.map(e => {
        return {
          ...e,
          selected: this.value.find(item => item.id === e.id) ? true : false,
        }
      })
    },

    listFilter() {
      return this.list.filter(e => {
        return this.search.length > 0
          ? e.placeholder.toLowerCase().includes(this.search.toLowerCase())
          : true
      })
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
