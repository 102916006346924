<template lang="pug">
  .ui.input.input-textarea(
    :class="rootClasses"
    @click="e => $emit('click', e)"
  )
    textarea(
      v-auto-resize="autoresize",
      :autofocus="autofocus",
      :tabindex="tabindex",
      ref="input",
      :name="name",
      :placeholder="placeholder",
      v-model="model",
      :style="{'resize': resize ? 'vertical' : 'none'}",
      :readonly="readonly",

      @keyup="e => $emit('keyup', e)",
      @keydown="e => $emit('keydown', e)",
      @keyup.enter="e => $emit('enter', e)",
      @blur="e => $emit('blur', e)")

    slot
</template>

<script>
import InputMixin from "../default";

export default {
  mixins: [InputMixin],

  props: {
    autoresize: {
      type: Boolean,
      default: false
    },
    resize: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      require: false,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rootClasses() {
      return {
        error: this.error,
      };
    },
  },

  methods: {
    focus() {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    }
  }
}
</script>
