<template lang="pug">
  .ui.errors-block(:class="{active: errors.length > 0}")
    .error-message(
      v-for="(err, i) in errors",
      :key="i") {{ err }}
</template>

<script>
export default {
  props: {
    errors: {
      require: false,
      default: () => []
    }
  }
};
</script>

<style lang="sass" src="./styles.sass"></style>
