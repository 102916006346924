/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'projects-circle': {
    width: 29,
    height: 30,
    viewBox: '0 0 29 30',
    data: '<defs><path pid="0" d="M8.878 12.245l5.918 2.449 5.918-2.45-5.918-2.448-5.918 2.449zm9.97 4.47l2.844 1.195a.351.351 0 0 1 .206.326.351.351 0 0 1-.206.327l-6.78 2.842a.3.3 0 0 1-.233 0L7.9 18.563a.351.351 0 0 1-.206-.327c0-.144.082-.274.206-.326l2.844-1.194L7.9 15.539a.346.346 0 0 1-.206-.322c0-.142.082-.27.206-.322l2.865-1.185L7.9 12.558a.336.336 0 0 1-.206-.313c0-.139.082-.263.206-.313l6.78-2.726a.315.315 0 0 1 .232 0l6.78 2.726c.124.05.206.174.206.313a.336.336 0 0 1-.206.313l-2.865 1.152 2.865 1.185c.124.052.206.18.206.322 0 .143-.082.27-.206.322l-2.844 1.177zm-.788.327l-3.148 1.302a.304.304 0 0 1-.233 0l-3.148-1.302a.334.334 0 0 1-.17.165l-2.45 1.029 5.885 2.468 5.885-2.468-2.45-1.029a.334.334 0 0 1-.17-.165zm-6.426-2.983a.32.32 0 0 1-.111.078l-2.612 1.08 5.885 2.435 5.885-2.435-2.612-1.08a.32.32 0 0 1-.111-.078l-3.046 1.225a.312.312 0 0 1-.233 0l-3.045-1.225z" id="svgicon_projects-circle_a"/></defs><g transform="translate(-.255)" _fill="#FF805D" fill-rule="evenodd"><path pid="1" d="M14.796 29.694c7.681 0 13.908-6.442 13.908-14.388S22.477.918 14.796.918C7.115.918.888 7.36.888 15.306s6.227 14.388 13.908 14.388zm0-1.837c-6.7 0-12.133-5.62-12.133-12.55 0-6.933 5.432-12.552 12.133-12.552 6.7 0 12.133 5.62 12.133 12.551 0 6.932-5.432 12.551-12.133 12.551z" fill-opacity=".4" fill-rule="nonzero"/><use xlink:href="#svgicon_projects-circle_a"/></g>'
  }
})
