<template lang="pug">
  .ui.editable
    //- @input="update",

    //- @keydown.enter="send",
    
    //- @mousedown="updatePos",
    //- @mouseup="updatePos",
    //- @keydown="updatePos",
    //- @keyup="updatePos"

    quill-editor(
      ref="editor",
      :options="editorOptions",
      @input="handleInput",
      v-model="model")
</template>

<script>
export default {
  props: {
    value: {
      require: true,
      type: String,
    },

    autofocus: {
      require: false,
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editorOptions: {
        toolbar: null,
        placeholder: this.placeholder,
      },

      modelData: '',
    }
  },

  watch: {
    value() {
      if (this.value != this.getText()) {
        this.model = this.value
      }
    },
  },

  methods: {
    handleInput(data) {
      debug('[>]', data)
    },

    insertEmoji(emoji) {
      if (!this.quill) return

      this.quill.focus()

      const range = this.quill.getSelection()
      this.quill.insertEmbed(range.index, 'emoji', emoji.native)
      this.quill.setSelection(range.index + 1, 1)
    },

    getText() {
      let text = '',
        content = this.quill.getContents()

      content.ops.forEach(e => {
        if (e.insert.emoji) {
          text += e.insert.emoji
        } else {
          text += e.insert
        }
      })

      return text
    },

    enterNewLine() {
      if (!this.quill) return

      const range = this.quill.getSelection()
      this.quill.insertText(range.index, '\n', 'user')
      this.quill.setSelection(range.index + 1, 'silent')
    },

    handleKeys(event) {
      switch (event.keyCode) {
        case 13:
          if (event.ctrlKey) {
            this.emit('enter.ctrl', event)
          } else if (event.shiftKey) {
            this.emit('enter.shift', event)
          } else {
            this.emit('enter', event)
          }

          break

        default:
          break
      }
    },
  },

  computed: {
    editor() {
      return this.$refs.editor
    },

    quill() {
      return this.$refs.editor.quill
    },

    model: {
      get() {
        return this.modelData
      },

      set(newVal) {
        this.modelData = newVal
        this.$emit('input', this.getText())
      },
    },
  },

  mounted() {
    if (this.autofocus) {
      this.quill.focus()
    }

    this.model = this.value

    this.quill.container.addEventListener('keydown', this.handleKeys)
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
