/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clock-history': {
    width: 23,
    height: 22,
    viewBox: '0 0 23 22',
    data: '<path pid="0" d="M12.14.12C6.7.12 2.19 4.14 1.4 9.36H0l2.56 3.19L5.1 9.36H3.33a8.93 8.93 0 018.8-7.33 8.92 8.92 0 110 17.85 8.93 8.93 0 01-7.7-4.37l-1.56 1.08A10.86 10.86 0 0023 10.96C23 4.98 18.13.12 12.14.12zm-3.2 12.43h4.8V6.17h-1.92v4.46H8.94" _fill="#34335C"/>'
  }
})
