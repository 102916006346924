/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'envelope': {
    width: 16,
    height: 12,
    viewBox: '0 0 16 12',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" _fill="#FFF" d="M-500-362H940v900H-500z"/><path pid="1" d="M1.674.667C.757.667 0 1.423 0 2.34v7.442c0 .918.757 1.674 1.674 1.674h12.652c.917 0 1.674-.756 1.674-1.674V2.34c0-.918-.757-1.674-1.674-1.674H1.674zm.14 1.116h12.372l-5.82 5.07c-.226.196-.495.197-.72 0l-5.832-5.07zm-.698.872l3.948 3.43-3.948 3.686V2.655zm13.768.006v7.116l-3.936-3.692 3.936-3.424zm-4.785 4.157l3.75 3.523H2.145l3.768-3.517 1 .866c.622.54 1.564.542 2.186 0l1-.872z" _fill="#34335C"/></g>'
  }
})
