/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'planning-calendar': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<g _fill="#34335C"><path pid="0" d="M17.74 1.86h-1.38V1a.9.9 0 00-.9-.9c-.5 0-.89.4-.89.9v.85H7.42V1a.9.9 0 00-.89-.9c-.5 0-.9.4-.9.9v.85H4.27A4.05 4.05 0 00.21 5.9v11.92a4.06 4.06 0 004.05 4.06h13.48a4.05 4.05 0 004.05-4.06V5.9a4.06 4.06 0 00-4.05-4.05zM4.26 3.62h1.38v.85c0 .48.39.9.9.9.5 0 .88-.4.88-.9v-.85h7.15v.85c0 .48.4.9.9.9s.9-.4.9-.9v-.85h1.37A2.28 2.28 0 0120 5.92v1.16H1.99V5.91C2 4.65 3 3.62 4.26 3.62zM17.74 20.1H4.26a2.3 2.3 0 01-2.29-2.3V8.86h18.04v8.96c0 1.28-1.01 2.29-2.27 2.29z"/><path pid="1" d="M6.37 10.93H5.4c-.71 0-1.26.57-1.26 1.26 0 .71.57 1.26 1.26 1.26h.98c.71 0 1.26-.57 1.26-1.26a1.24 1.24 0 00-1.26-1.26zm5.13 0h-.98c-.71 0-1.26.57-1.26 1.26 0 .71.57 1.26 1.26 1.26h.98c.71 0 1.26-.57 1.26-1.26 0-.69-.57-1.26-1.26-1.26zm5.11 0h-.98c-.71 0-1.26.57-1.26 1.26 0 .71.57 1.26 1.26 1.26h.98c.71 0 1.27-.57 1.27-1.26a1.24 1.24 0 00-1.27-1.26zM6.37 15.45H5.4a1.261 1.261 0 000 2.52h.98c.71 0 1.26-.58 1.26-1.26a1.24 1.24 0 00-1.26-1.26zm5.13 0h-.98a1.261 1.261 0 000 2.52h.98a1.261 1.261 0 000-2.52z"/></g>'
  }
})
