/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.09 5a1 1 0 01.53.21l4.02 3.2a.85.85 0 01.13 1.27 1 1 0 01-1.37.08L8 7.07l-3.4 2.7a1 1 0 01-1.37-.07.85.85 0 01.13-1.27l4.02-3.2a1 1 0 01.71-.22V5z" _fill="#1E1D4B"/>'
  }
})
