/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'upload': {
    width: 22,
    height: 25,
    viewBox: '0 0 22 25',
    data: '<path pid="0" d="M20.596 6.25c-1.971-2.083-5.125-2.083-7.095 0l-8.574 8.958a3.271 3.271 0 0 0 0 4.48c1.183 1.25 3.055 1.25 4.238 0l7.095-7.5a1.047 1.047 0 0 0 0-1.459c-.394-.416-.986-.416-1.38 0l-7.095 7.5c-.394.417-.985.417-1.38 0a1.047 1.047 0 0 1 0-1.458l8.574-9.063c1.182-1.25 3.055-1.25 4.237 0a3.271 3.271 0 0 1 0 4.48l-8.573 8.958c-1.971 2.083-5.125 2.083-7.095 0-1.971-2.084-1.971-5.417 0-7.5L14.88 1.77a1.047 1.047 0 0 0 0-1.458c-.394-.417-.985-.417-1.38 0L2.07 12.291c-2.76 2.916-2.76 7.604 0 10.52 2.759 2.917 7.193 2.917 9.952 0l8.574-8.958c1.872-2.083 1.872-5.52 0-7.604z" _fill="#34335C" fill-rule="nonzero"/>'
  }
})
