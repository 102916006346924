class UtilsMouse {
  constructor() {
    this.x = 0
    this.y = 0

    this.isPressed = false

    document.addEventListener('mousedown', () => {
      this.isPressed = true
    })
    document.addEventListener('mouseup', () => {
      this.isPressed = false
    })

    document.addEventListener('mousemove', event => {
      requestAnimationFrame(() => {
        this.x = event.pageX
        this.y = event.pageY
      })
    })

    document.addEventListener('touchmove', event => {
      requestAnimationFrame(() => {
        this.x = event.pageX
        this.y = event.pageY
      })
    })
  }

  isPressedOn(el) {
    return this.isCollide(el) && this.isPressed
  }

  collides(x, y, r, b, x2, y2, r2, b2) {
    return !(r <= x2 || x > r2 || b <= y2 || y > b2)
  }

  isCollide(el) {
    const rect = el.getBoundingClientRect()

    return this.collides(
      rect.x,
      rect.y,
      rect.x + rect.width,
      rect.y + rect.height,
      this.x,
      this.y,
      this.x,
      this.y
    )
  }
}

export default new UtilsMouse()
