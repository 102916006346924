<template lang="pug">
  .ui.input(:class="{ error }" @click="e => $emit('click', e)")
    input(
      :tabindex="tabindex",
      ref="input",
      :name="name",
      type="text",
      :placeholder="placeholder",
      v-model="model",
      :readonly="readonly",
      :disabled="deactivated",
      @paste.prevent="handlePaste"
      @focus="e => $emit('focus', e)",
      @keyup.enter="e => $emit('enter', e)",
      @blur="e => $emit('blur', e)")

    slot
</template>

<script>
const getClipboardData = (event, format) => {
  const clipboardData = event.clipboardData ?? window.clipboardData;
  return clipboardData?.getData(format) ?? null;
}

const extractNumberValue = (value) => {
  const filteredValue = value.replace(/[^\d-.,]/g, "");
  const isValidFormat = /^(-)?([0-9]+[.,]?)*$/.test(filteredValue);
  if (!isValidFormat) {
    return null;
  }

  const string = filteredValue === '-' ? '-0' : filteredValue;
  const parts = string.split(/[,.]/g).filter(p => Boolean(p));
  const fractional = parts.pop();

  const number = parts.length
    ? `${parts.join('')}.${fractional}`
    : fractional;

  return Number(number);
}

export default {
  props: {
    tabindex: {
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      require: false,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * ATTENTION: please, use this prop instead of "disabled"
     *
     * TODO: Replace "deactivated" prop by "disabled"
     * Needs to check all usages of this component and make sure that nothings
     * broken
     */
    deactivated: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      require: false,
      default: null
    },
    error: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: ""
    }
  },

  watch: {
    model(newValue, oldValue) {
      const
        countCommas = (newValue ? newValue.match(/\,/g) || [] : []).length,
        compInt = this.$utils.formatStrToNum(newValue);

      if(newValue && /\./g.test(newValue)) {
        this.model = countCommas == 0 ? newValue.replace(".", ",") : oldValue;
        return null;
      }

      if(compInt >= 0 || compInt < 0) {
        this.$emit("input", compInt);
      } else {
        this.model = oldValue;
      }
    },

    value(newValue, oldValue) {
      if(this.$utils.formatStrToNum(this.model) != newValue) {
        this.model = this.$utils.formatNumToStr(newValue);
      }
    }
  },

  methods: {
    handlePaste(event) {
      const text = getClipboardData(event, "text");
      const value = extractNumberValue(text);
      this.model = `${value}` ?? text;
    },

    focus() {
      this.$refs.input.focus();
    },

    verify(val) {
      return /([\d]+|[\.]|[\,])/.test(val);
    }
  },

  mounted() {
    this.model = this.$utils.formatNumToStr(this.value);

    if(this.autofocus) {
      this.focus();
    }
  }
}
</script>