/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'uk': {
    width: 16,
    height: 16,
    viewBox: '0 0 640 480',
    data: '<g fill-rule="evenodd" stroke-width="1pt"><path pid="0" fill="gold" d="M0 0h640v480H0z"/><path pid="1" fill="#0057b8" d="M0 0h640v240H0z"/></g>'
  }
})
