export default {
  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    tabindex: {
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      require: false,
      default: null
    },
    readonly: {
      type: Boolean,
      require: false,
      default: false
    },
    name: {
      type: String,
      require: false,
      default: null
    }
  },

  computed: {
    model: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  },

  methods: {
    focus() {},
  },

  mounted() {
    if(this.autofocus) {
      this.focus();
    }
  },
};