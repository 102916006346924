/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'message-pop-circle': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<g _fill="#FF805D" fill-rule="evenodd"><path pid="0" d="M15.306 29.694c7.946 0 14.388-6.442 14.388-14.388S23.252.918 15.306.918.918 7.36.918 15.306 7.36 29.694 15.306 29.694zm0-1.837c-6.932 0-12.55-5.62-12.55-12.55 0-6.933 5.618-12.552 12.55-12.552s12.551 5.62 12.551 12.551c0 6.932-5.62 12.551-12.55 12.551z" fill-opacity=".4" fill-rule="nonzero"/><path pid="1" d="M20.97 9.184c.929 0 1.683.748 1.683 1.67v6.682c0 .922-.754 1.67-1.684 1.67h-5.443l-3.239 2.133a.565.565 0 0 1-.561.028.557.557 0 0 1-.297-.49v-1.67h-.562c-.93 0-1.683-.749-1.683-1.67v-6.683c0-.922.754-1.67 1.683-1.67H20.97zm.56 8.352v-6.682a.555.555 0 0 0-.56-.557H10.867c-.31 0-.56.25-.56.557v6.682c0 .308.25.557.56.557h1.123a.555.555 0 0 1 .561.557v1.208l2.498-1.67a.565.565 0 0 1 .308-.095h5.613a.555.555 0 0 0 .561-.557zm-5.01-3.787a.959.959 0 0 1 .23.824.899.899 0 0 1-.505.663.794.794 0 0 1-.793-.082.937.937 0 0 1-.378-.756 1.317 1.317 0 0 1 0-.183.627.627 0 0 1 .05-.164.763.763 0 0 1 .077-.165c.031-.048.065-.093.101-.137a.82.82 0 0 1 .61-.28c.23 0 .45.102.609.28zm-2.829-.015a.98.98 0 0 1 .185 1.006c-.131.344-.44.568-.783.566a.776.776 0 0 1-.32-.073.834.834 0 0 1-.278-.193.966.966 0 0 1-.25-.653c0-.245.09-.48.25-.653a.835.835 0 0 1 .278-.193.792.792 0 0 1 .918.193zm5.651 0c.244.263.317.66.185 1.006-.13.344-.44.568-.784.566a.778.778 0 0 1-.32-.073.97.97 0 0 1-.279-.193l-.101-.138a.771.771 0 0 1-.076-.166.605.605 0 0 1-.067-.165 1.336 1.336 0 0 1 0-.184c.003-.244.09-.477.244-.653a.97.97 0 0 1 .279-.193.794.794 0 0 1 .92.193z"/></g>'
  }
})
