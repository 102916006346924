/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-plus-fill': {
    width: 15,
    height: 12,
    viewBox: '0 0 15 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.04 6.55H8.2c1.65 0 3.04 1.11 3.04 2.41V12H0V8.96c0-1.3 1.4-2.4 3.04-2.4zM15 4.2a.6.6 0 01-.62.58H13.3v1.01c0 .3-.31.58-.62.58a.6.6 0 01-.62-.58v-1h-1.08a.6.6 0 01-.62-.59c0-.33.26-.58.62-.58h1.08V2.55a.6.6 0 01.62-.57c.31 0 .62.29.62.57v1.06h1.08c.36 0 .62.25.62.58zM5.62 0c1.7 0 3.1 1.3 3.1 2.9 0 1.58-1.4 2.88-3.1 2.88-1.7 0-3.1-1.3-3.1-2.89S3.93 0 5.63 0z" _fill="#34335C"/>'
  }
})
