import { isNil } from 'lodash';
import Provider from "@provider";
import {
  UserModel,
  UserInvitedModel,
  CompanyModel
} from "@/models";
import { getUtcDayFromZonedDay, getUtcTimeFromZonedTime } from '@/helpers/dates';
import { format } from '@/helpers/dates/format';

export default {
  updateLogo: ({companyId, form}) => {
    return new Promise((resolve, reject) => {
      Provider.api.post(`/companies/${companyId}/logo`, form, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => resolve(new CompanyModel(response.data)))
      .catch(reject);
    });
  },

  getCompany: (companyId) => {
    return new Promise((resolve, reject) => {
      if(!companyId) {
        resolve([]);
      } else {
        Provider.api.get(`/companies/${companyId}`)
          .then(response => resolve(new CompanyModel(response.data)))
          .catch(error => reject(error));
      }
    });
  },

  updateCompany: ({
    id,
    realtimeTranslations,
    companyName,
    streetName,
    houseNumber,
    postalCode,
    place,
    phoneNumber,
    specialism,
    amountPeople,
    weekWorkingHours,
    notificationHoursDays,
    notificationHoursTime,
    usageReasons,
    registrationFromTill,
    enableProjectTemplates,
    useProjectAutonum,
    projectAutonumStr,
    projectAutonumStart,
    projectAutonumInt,
    formsEnabled,
  }) => {
    if (notificationHoursTime && notificationHoursDays?.length) {
      const date = new Date();
      const time = notificationHoursTime;

      notificationHoursDays = notificationHoursDays
        .map(day => getUtcDayFromZonedDay({ date, day, time }));
      notificationHoursTime = format(getUtcTimeFromZonedTime({ date, time }), 'HH:mm');
    }

    return new Promise((resolve, reject) => {
      Provider.api.put(`/companies/${id}`, {
        realtime_translations: !isNil(realtimeTranslations) ? Number(realtimeTranslations) : undefined,
        name: companyName,
        address_street: streetName,
        address_house_number: houseNumber,
        address_zip_code: postalCode,
        address_city: place,
        telephone_number: phoneNumber,
        specialism,
        amount_people: amountPeople,
        week_working_hours: weekWorkingHours,
        notification_hours_days: notificationHoursDays,
        notification_hours_time: notificationHoursTime,
        usage_reasons: usageReasons,
        registration_from_till: registrationFromTill,
        enable_project_templates: enableProjectTemplates,
        use_project_autonum: useProjectAutonum,
        project_autonum_str: projectAutonumStr,
        project_autonum_start: projectAutonumStart,
        project_autonum_int: projectAutonumInt,
        forms_enabled: formsEnabled,
      })
      .then(response => resolve(new CompanyModel(response.data)))
      .catch(error => reject(error));
    });
  },

  getCompanyMembers: ({companyId, search}) => {
    return new Promise((resolve, reject) => {
      if(!companyId) {
        resolve([]);
      } else {
        Provider.api.get(`/companies/${companyId}/members`, {params: {
          search
        }})
        .then(response => resolve(response.data.map(e => new UserModel(e))))
        .catch(error => reject(error));
      }
    });
  },

  // TODO: Recheck
  // getCompanyMembersInvited: ({companyId, search}) => {
  //   return new Promise((resolve, reject) => {
  //     if(!companyId) {
  //       resolve([]);
  //     } else {
  //       Provider.api.get(`/companies/${companyId}/invitedmembers`, {params: {
  //         search
  //       }})
  //       .then(response => resolve(response.data.map(e => new UserInvitedModel(e))))
  //       .catch(error => reject(error));
  //     }
  //   });
  // },

  companyInviteExternalUser: ({companyId, form}) => {
    return new Promise((resolve, reject) => {
      Provider.api.post(`/companies/${companyId}/invitemembers`, {
        role_id: form.roleId,
        is_guest: form.isGuest,
        email: form.email,
        first_name: form.firstName,
        last_name: form.lastName
      })
      .then(response => resolve(new UserInvitedModel(response.data)))
      .catch(error => reject(error));
    });
  },

  deleteCompanyMember: ({companyId, memberId}) => {
    return new Promise((resolve, reject) => {
      Provider.api.delete(`/companies/${companyId}/members/${memberId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
    });
  },

  updatePlanning: ({
    id,
    properties
  }) => {
    return new Promise((resolve, reject) => {
      Provider.api.put(`/companies/${id}`, {
        planning_conf_add_to_project_days: properties.planningDays,
        planning_conf_add_to_project_chat: properties.planningChat,
        planning_conf_add_from_project_registrations: properties.planningRegistrations
      })
      .then(response => resolve(new CompanyModel(response.data)))
      .catch(error => reject(error));
    })
  },

  updateWorkingHours: ({
    id,
    workingHours,
  }) => {
    return Provider.api.put(`/companies/${id}`, {
      company_work_hours: workingHours,
    })
      .then(response => new CompanyModel(response.data));
  },
};
