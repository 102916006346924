<template lang="pug">
component.text-button(
  v-bind="buttonAttrs"
  :is="component"
  :class="rootClasses"
  v-on="$listeners"
)
  .text-button__prefix(v-if="$scopedSlots.prefix")
    slot(name="prefix")
  .text-button__text
    slot
</template>

<script>
export default {
  name: 'UiTextButton',

  inheritAttrs: false,

  props: {
    /**
     * The kind of the text button
     * @values info, primary, success
     */
    kind: {
      type: String,
      default: undefined,
    },

    /**
     * The type of the button element
     */
    type: {
      type: String,
      default: 'button',
    },

    /**
     * The font size of the text button
     * @values larger
     */
    size: {
      type: String,
      default: undefined,
    },

    /**
     * The text weight of the text button
     * @values bold
     */
    weight: {
      type: String,
      default: undefined,
    },

    /**
     * The text button has hover or focus effect
     */
    actionEffect: {
      type: Boolean,
      default: false,
    },

    /**
     * Disable the text button
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Make inline button
     */
    inline: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rootClasses() {
      return {
        'text-button--inline': this.inline,
        'text-button--action-effect': this.actionEffect,
        [`text-button--kind_${this.kind}`]: Boolean(this.kind),
        [`text-button--weight_${this.weight}`]: Boolean(this.weight),
        [`text-button--size_${this.size}`]: Boolean(this.size),
      };
    },

    component() {
      return this.inline ? 'a' : 'button';
    },

    buttonAttrs() {
      return this.inline
        ? { ...this.$attrs, role: 'button', tabindex: '0' }
        : { ...this.$attrs, type: this.type, disabled: this.disabled };
    },
  },
};
</script>

<style lang="sass" src="./styles.sass"></style>
