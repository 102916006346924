<template lang="pug">
  .line-planning(
    @dblclick="handleDoubleClick",
    @mousemove="handleMouseMove",
    @mouseleave="handleMouseLeave"
    :style="style")

    .abs-line(v-for="(line, i) in list", :key="i")
      Item(
        v-for="(item, j) in line",
        :key="item.id",
        :item="item",
        @resize="handleResize",
        @start-dragging="handleStartDragging(item)"
        :dataGenLines="dataGenLines")

    .grp-lin
    .item-hover(ref="itemHover")
</template>

<script>
import { mapGetters } from 'vuex'
import Item from './Item'
import HoverZoomMixin from './mixins/hoverZoom'

export default {
  name: "PlanningLinesPlanning",

  inject: ['planningScrollbar'],
  mixins: [HoverZoomMixin],

  components: {
    Item,
  },

  props: {
    params: {
      require: false,
      default: null,
    },

    dataGenLines: {
      require: true,
      default: 0,
    },
  },

  data() {
    return {
      freezeList: [],
    }
  },

  computed: {
    style() {
      return {
        width: this.planningScrollbar.width + 'px',
      }
    },

    list() {
      if (this.freezeList.length > 0) return this.freezeList

      const resourceItems = this.planningItemsByResourceIds[this.params.id] ?? [];

      return this.genTimeLineArr(
        resourceItems.slice().sort((a, b) => a.startAt - b.startAt)
      )
    },

    ...mapGetters('PlanningResourcePlanItems', {
      planningItemsByResourceIds: 'planningItemsByResourceIds',
    }),
  },

  methods: {
    handleStartDragging(item) {
      this.$emit('start-dragging', item);
    },

    handleResize(state) {
      this.freezeList = state ? [...this.list] : []
    },

    genTimeLineArr(arr, global = []) {
      if (arr.length == 0) return global

      let res = [arr.shift()]

      let fin = true
      while (fin) {
        let nextI = arr.findIndex(
          e => e.startAt > res[res.length - 1].endAt - 60000
        )

        if (nextI == -1) fin = false
        else {
          res.push(arr[nextI])
          arr.splice(nextI, 1)
        }
      }

      global.push(res)

      return this.genTimeLineArr(arr, global)
    },
  },
}
</script>
