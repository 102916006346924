<template lang="pug">
  .zoom.weeks.zoom-c(ref="zoomList")
    .item(
      v-for="(week, i) in weeks",
      ref="zoomItems"
      :key="i")

      .title(@click="handleClickOnMonth(week.w)")
        span
          b {{ $t('planningComponent.week') }} {{ week.w | moment('w') }} &#8203;
          .month-day {{ week.w.startOf('week') | moment('DD MMM') }} - {{ week.w.endOf('week') | moment('DD MMM YYYY') }}

      .list
        .item(
          v-for="(d, j) in week.days",
          :key="j",
          @click="handleClickOnDay(d)",
          :style="getDayStyle(d, 12, 'em')",
          :class="{ active: d.isSame($utils.$time.now, 'day'), holiday: !d.isBusinessDay() }")

          span {{ d | moment('dd DD MMM') }}
</template>

<script>
import ZoomMixin from './mixin'
import GridMixin from '../mixins/grid';

export default {
  mixins: [ZoomMixin, GridMixin],

  data() {
    // the short DST week
    const shortestPeriodDate = '2022-03-27';
    const delimiter = 'week';
    const startAt = this.$moment(shortestPeriodDate, 'YYYY-MM-DD').startOf(delimiter);
    const endAt = this.$moment(shortestPeriodDate, 'YYYY-MM-DD').endOf(delimiter);

    return {
      view: {
        startAt,
        endAt,

        periods: 1,

        delimiter: 'week',

        zoomHover: val => {
          let m = Math.max(1, Math.ceil(val.hours() / 6)),
            startAt = val.startOf('day').hours(m * 6 - 6, 'hours'),
            endAt = startAt.clone().hours(m * 6, 'hours')

          if (!endAt.isSame(startAt, 'day'))
            endAt = startAt.clone().endOf('day')

          return {
            startAt,
            endAt,
          }
        },

        roundGridStartAt: momentDate => {
          const hoursSteps = Math.floor(momentDate.hours() / 6);
          return momentDate.clone().startOf('day').hours(hoursSteps * 6);
        },

        roundGridEndAt: momentDate => {
          const hoursSteps = Math.ceil(momentDate.hours() / 6);
          if (hoursSteps === 4) {
            return momentDate.clone().endOf('day');
          }
          return momentDate.clone().startOf('day').hours(hoursSteps * 6);
        },

        getGridStartAt: endAt => {
          const hours = endAt.hours();
          const startAt = endAt.clone().hours(hours - 6);
          return startAt.hours() === 17 && startAt.minutes() === 59
            ? startAt.add(1, 'minutes')
            : startAt;
        },

        getGridEndAt: startAt => {
          const hours = startAt.hours();
          const endAt = startAt.clone().hours(hours + 6);
          return endAt.hours() === 0 && endAt.minutes() === 0
            ? endAt.subtract(1, 'minutes')
            : endAt;
        },
      },
    }
  },

  computed: {
    weeks() {
      return Array.from(
        this.$moment
          .range(
            this.periodsRange.startAt.clone(),
            this.periodsRange.endAt.clone()
          )
          .by('weeks')
      ).map(e => {
        return {
          w: e,
          days: Array.from(
            this.$moment
              .range(e.clone().startOf('week'), e.clone().endOf('week'))
              .by('days')
          ),
        }
      })
    },

    items() {
      return []
        .concat(...this.weeks.map(e => e.days))
        .sort((a, b) => a.valueOf() - b.valueOf())
    },

    firstItem() {
      return this.items[0]?.startOf('day');
    },

    lastItem() {
      return this.items[this.items.length - 1]?.endOf('day');
    },
  },
}
</script>
