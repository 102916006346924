<template lang="pug">
  .ui.pick-color
    .item(
      v-for="(color, i) in colors",
      :key="i",
      :style="{backgroundColor: color}",
      :class="{selected: isSelected(color)}",
      @click="model=color")

    input(v-model="model")
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    }
  },

  methods: {
    isSelected(color) {
      return this.model && this.model.toLowerCase() === color.toLowerCase();
    }
  },

  data() {
    return {
      colors: [
        "#ff805d",
        "#FB6C88",
        "#CBD2D8",
        "#D7AC81",
        "#3DEEAC",
        "#FFD45F",
        "#6082FA",
        "#417505"
      ]  
    };
  },

  computed: {
    model: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  }
};
</script>

<style lang="sass" src="./styles.sass"></style>
