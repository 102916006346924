/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fr': {
    width: 16,
    height: 16,
    viewBox: '0 0 640 480',
    data: '<path pid="0" fill="#fff" d="M0 0h640v480H0z"/><path pid="1" fill="#002654" d="M0 0h213.3v480H0z"/><path pid="2" fill="#ce1126" d="M426.7 0H640v480H426.7z"/>'
  }
})
