/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'move-to-folder': {
    width: 19,
    height: 16,
    viewBox: '0 0 19 16',
    data: '<g fill-rule="nonzero" _fill="#34335C"><path pid="0" d="M14.587 12.364h-2.348v1.454h2.348V16l3.913-2.91-3.913-2.908z"/><path pid="1" d="M.5 13.818c0 .4.352.727.783.727h9.247a4.434 4.434 0 0 1-.247-1.454c0-2.611 2.277-4.727 5.087-4.727.848 0 1.645.195 2.347.536V3.636c0-.4-.352-.727-.782-.727h-6.261c-.43 0-.994-.262-1.252-.582L8.13.727 8.013.582C7.754.262 7.191 0 6.761 0H1.283C.853 0 .5.327.5.727v13.091z"/></g>'
  }
})
