<template lang="pug">
  .item.checklists-block-item(
    v-dragg-item="getDraggChecklist",
    :class="classes",
    :data-test-checklist-name="`${checklist.name}`"
  )
    .header
      ui-svg.dark.icon(name="checklist")
      // TODO: replace with ChecklistHeader
      .name
        .lock(v-if="checklist.access")
          ui-svg.theme(name="lock")
        .options-rectangle
          span.options-rectangle__name.pointer(
            :class="{ transparent: showEditForm }",
            @click="handleClickToEditName") {{ checklist.name }} 

          .caunter(:class="{ transparent: showEditForm }") ({{ countCheckedChecklistItems }}/{{ checklist.items.length }})

          span.favourite(v-if="checklist.favourite")
            ui-svg.favourite-icon(name="star")

          .signed-label(
            v-if="isSigned"
          ) {{ $t('checklists.delivered') }}

          .btn-toggle
            ui-svg.pointer(
              name="arrow",
              @click="toggleIsClosed",
              :class="{ 'rotate-down': isClosed }")
          .options-rectangle__buttons
            .options-rectangle__item(@click="handleClickToEditName")
              ui-svg(
                name="edit-checklist"
              ).dark
            .options-rectangle__item(@click="clone($event)")
              ui-svg(
                name="clipboard"
              ).dark
            .options-rectangle__item(@click="openAddUsersToChat($event)") 
              ui-svg(
                name="add-user"
              ).dark

      .form-edit-name(
        v-if="showEditForm",
        v-click-outside="() => showEditForm=false")

        ui-form-input(
          ref="formName",
          @update="(value) => updateNameChacklist(value)",
          :editable="checklist.canEdit",
          @softUpdate="showEditForm=false",
          :value="checklist.name",
          @cancel="showEditForm=false")

      .controls
        template(v-if="!isCompact")
          ui-button.grey.small.action-list(
            v-if="hideCheckedItems && first",
            @click="hideChecklistItems"
          ) {{ $t('sidebar.checklists.hideitems') }}

          ui-button.grey.small.action-list(
            v-if="getterFlagHideChecklistCheckedItems && first && countCheckedItems",
            @click="showChecklistItems"
          ) {{ $t("sidebar.checklists.showItems") }} ({{countCheckedItems}})

        AddChecklistButton(v-if="first && canCreate")

        .action.pointer-btn(
          v-if="hasMenuButton"
          @click="handleContextMenu")
          ui-svg.dark(name="dots")
          .ref(ref="refApproveRemoveModal")

    transition(name="fadeHeight")
      .body(v-if="!isClosed")
          
        .list(v-dragg-list="getDraggChecklistList")
          .items-list(v-dragg-scroll="JSON.stringify({id: 'checklists'})")
            ChecklistItemWrap(
              v-for="item in checklistItems"
              :key="`${item.position}@${item.id}`",
              :class="{'active': checklistOptionsIsShown}" 
              :checked="isCheckedOnWholeChecklists(item)"
              :checklist="checklist"
              :item="item"
              @checkbox:click="onCheckedUsersForWholeChecklists(item,$event)"
            )

          FormAddChecklistItem(
            v-if="!isSigned"
            :checklistId="checklist.id"
          )

    .footer
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ChecklistItemWrap from './ChecklistItemWrap'
import FormAddChecklistItem from './FormAddChecklistItem'
import AddChecklistButton from './AddChecklistButton.vue'

import ChecklistContextMenuMixin from './mixins/checklistContextMenu'
import { ChecklistSizeVariants } from './constants.js'
import { hasSignature } from '../../helpers/checklists.js';

export default {
  name: "check-list-item-view",
  components: {
    ChecklistItemWrap,
    FormAddChecklistItem,
    AddChecklistButton,
  },

  mixins: [ChecklistContextMenuMixin],

  props: {
    checklist: {
      require: true,
    },
    first: {
      type: Boolean,
      default: true,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },

    /**
     * @values compact, regular
     */
    size: {
      type: String,
      default: ChecklistSizeVariants.REGULAR,
    },
  },

  data() {
    return {
      showEditForm: false
    }
  },

  computed: {
    ...mapState('ChecklistOptionsPanel', {
      checklistOptionsCheckedItems: "checkedItems"
    }),
    isCompact() {
      return this.size === ChecklistSizeVariants.COMPACT;
    },

    classes() {
      return {
        lock: this.checklist.access,
        completed:
          this.countCheckedChecklistItems > 0 &&
          this.countCheckedChecklistItems == this.checklist.items.length,
      }
    },

    getDraggChecklist() {
      return JSON.stringify({
        id: this.checklist.id,
        parentId: 'checklists',
        namespace: 'dragg-checklist',
        ignoreElementSelector: '.form-edit-name',
      })
    },

    getDraggChecklistList() {
      return JSON.stringify({
        id: this.checklist.id,
        namespace: 'dragg-checklist-item',
      })
    },

    hideCheckedItems() {
      return (
        this.countCheckedItems > 0 && !this.getterFlagHideChecklistCheckedItems
      )
    },

    countCheckedItems() {
      return this.getterCountCheckedItems({
        chatId: this.$route.params.chatId,
        projectId: this.$route.params.projectId,
      })
    },

    countCheckedChecklistItems() {
      return this.checklist.items.filter(e => e.checked).length
    },

    checklistItems() {
      return [
        ...(this.getterFlagHideChecklistCheckedItems
          ? this.checklist.items.filter(e => !e.checked)
          : this.checklist.items),
      ].sort((a, b) => a.position - b.position)
    },

    isSigned() {
      return hasSignature(this.checklist);
    },
    
    isClosed() {
      return this.checklist.isClosed;
    },

    ...mapGetters('Checklists', [
      'getterFlagHideChecklistCheckedItems',
      'getterCountCheckedItems',
    ]),
    ...mapState('ChecklistOptionsPanel', {
      checklistOptionsIsShown: 'showPanel'
    }),
    ...mapGetters('User', ['me'])
  },
  methods: {
    ...mapActions("ChecklistOptionsPanel",{
      toogleOptionsPanelForCheckList: "toogleOpenPanel",
      updateCheckedItemsForWholeChecklists: "updateCheckedItems"
    }),
    clone(event) {
      this.openModal({
        name: 'CreateChecklist',
        params: this.$utils.defineNonReactive({
          el: event.currentTarget,
          checklist: this.checklist,
          position: '1 7',
          chatId: this.checklist.chatId,
          projectId: this.checklist.projectId,
          callback: () => {},
        }),
      });
    },
    isCheckedOnWholeChecklists(item) {
      return !!this.checklistOptionsCheckedItems.find(i=>i.id===item.id)
    },
    onCheckedUsersForWholeChecklists(item,checked) {
      this.updateCheckedItemsForWholeChecklists({item,checked})
    },
    getUsersThatPresentedInAllItems(items) {
        const userCounts = {};
        const commonUsers = [];
        for (const item of items) {
          for (const user of item.users) {
            const { id } = user;
            userCounts[id] = (userCounts[id] || 0) + 1;
          }
        }
        for (const userId in userCounts) {
          if (userCounts[userId] === items.length) {
            commonUsers.push({ id: parseInt(userId) });
          }
        }
        return commonUsers;
    },
    openAddUsersToChat(event) {
      this.openModal({
        name: 'SelectUsers',
        params: this.$utils.defineNonReactive({
          el: event.currentTarget,
          position: '1 7',
          members: this.getUsersThatPresentedInAllItems(this.checklistItems),
          callback: async ({ newMembersAsUsers, removeMembers }) => {
            const isAddingUsers = newMembersAsUsers?.length;
            const isRemoveUsers = removeMembers?.length;
            const addPromises = [];
            const deletePromises = [];
            this.checklistItems.forEach( async(checklistItem)=>{
              if (isAddingUsers)
                addPromises.push(this.addChecklistItemMembers({
                  checklistId: checklistItem.checklistId,
                  checklistItemId: checklistItem.id,
                  users: newMembersAsUsers,
                }))
              if (isRemoveUsers)
                deletePromises.push(this.deleteChecklistItemMembers({
                  checklistId: checklistItem.checklistId,
                  checklistItemId: checklistItem.id,
                  userIds: removeMembers,
                }))
            })
            
            return Promise.allSettled([...addPromises,...deletePromises])
          },
        }),
      })
    },
    toggleIsClosed() {
      this.updateChecklistExpandedState({
        checklistId: this.checklist.id,
        value: !this.isClosed,
      });
    },

    handleClickToEditName(e) {
      this.showEditForm = true

      this.$nextTick(() => {
        this.$refs.formName.focus()
      })
    },

    updateNameChacklist(name) {
      this.showEditForm = false

      this.updateChecklist({
        id: this.checklist.id,
        name,
      }).catch(error => {
        this.$notify.error(this.$utils.response('error'))
      })
    },

    ...mapActions('Checklists', [
      'hideChecklistItems',
      'showChecklistItems',
      'addChecklistItemMembers',
      'deleteChecklistItemMembers',
      'updateChecklist',
      'updateChecklistExpandedState',
    ]),
  },
}
</script>
<style lang="sass">
.checklists-block-item
  .checklists-item-wrap
    margin: 0.6em 0
    position: relative
    flex-direction: row
    align-items: center

    &.ghost
      position: fixed

    &:hover .checklists-item-wrap__checkbox,
    &.active .checklists-item-wrap__checkbox
      opacity: 1

    &__checkbox
      position: absolute
      padding-right: 0.5em
      right: 100%
      top: 0
      bottom: 0
      display: flex
      align-items: center
      opacity: 0

      &:active
        opacity: 1

      &.active + .checklist-item .border-container
        border: 0.18em solid #b2c0cc !important
</style>