<template lang="pug">
  .greed.quarter(:style="style")
    .item(
      v-for="week in weeksWithDays"
      :style="week.style"
    )
      .lines-dotted
        .line-down-dotted(
          v-for="(holiday, index) in week.days"
          :key="index"
          :class="{ holiday }"
        )
</template>

<script>
import GreedMixin from './mixin'
import GridMixin from '../mixins/grid'

export default {
  mixins: [GreedMixin, GridMixin],

  computed: {
    weeksWithDays() {
      return this.items.map(momentDate => {
        const date = momentDate.clone();
        const style = this.getWeekStyle(date, 8, 'em');

        const days = Array.from({ length: 7 }, (_, index) => {
          const isBusinessDay = date.add(Number(!!index), 'day').isBusinessDay();
          return !isBusinessDay;
        });

        return {
          style,
          days,
        }
      });
    }
  }
}
</script>