<template lang="pug">
  .ui.date-picker
    .input-box(@click="show=true")
      ui-input(
        v-model="displayInput",
        type="text",
        :readonly="true",
        placeholder="pick")

      .icon-calendar
    
    transition(name="fade", mode="out-in")
      .modal-picker(
        v-if="show",
        v-click-outside="closePick")

        .header
          .btn.prev(@click="prevMonth")
          .select-year
            .selected {{ monthNames[model.getMonth()] }}
              span.year(@click="selectYear=!selectYear") {{ model.getFullYear() }}
          .btn.next(@click="nextMonth")

        .body
          .days(v-for="(w, i) in days", :key="i") {{ i }}
            .week(v-for="(day, j) in w", :key="j")
              .day(
                :class="{active: day==model.getDate()}",
                @click="selectDay(day)") {{ day }}

          transition(name="fade", mode="out-in")
            .years(v-if="selectYear")
              ui-scrollbar.scroll
                .year(
                  v-for="(year, i) in pickYears",
                  :key="i",
                  :class="{active: year==model.getFullYear()}"
                  @click="setYear(year)") {{ year }}
</template>

<script>
export default {
  props: {
    value: {
      type: Date,
      default: null
    },

    min: {
      type: Date,
      default: null
    },

    max: {
      type: Date,
      default: null
    }
  },

  data() {
    return {
      show: false,
      selectYear: false,
      weekDays: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      pickDate: new Date(Date.now())
    };
  },

  watch: {
    value() {
      this.initPickDate();
    },

    show() {
      if (this.show == false) {
        this.selectYear = false;
      }
    }
  },

  methods: {
    closePick() {
      this.show = false;
    },

    initPickDate() {
      if (this.value) {
        this.pickDate = new Date(this.value);
      } else {
        this.pickDate = new Date(Date.now());
      }
    },

    selectDay(day) {
      let date = new Date(this.model);
      date.setDate(day);
      this.model = date;
    },

    setYear(year) {
      this.model = new Date(year, 0, 1);
      this.selectYear = false;
    },

    nextMonth() {
      this.model =
        this.model.getMonth() == 11
          ? new Date(this.model.getFullYear() + 1, 0, 1)
          : new Date(this.model.getFullYear(), this.model.getMonth() + 1, 1);
    },
    prevMonth() {
      this.model =
        this.model.getMonth() - 1 < 0
          ? new Date(this.model.getFullYear() - 1, 11, 1)
          : new Date(this.model.getFullYear(), this.model.getMonth() - 1, 1);
    },
    range(start, stop, step = 1) {
      return Array(Math.ceil((stop - start) / step))
        .fill(start)
        .map((x, y) => x + y * step);
    }
  },

  computed: {
    model: {
      get() {
        return this.pickDate;
      },

      set(newValue) {
        this.$emit("input", newValue);
      }
    },

    displayInput() {
      return this.model.getDate() + " " + this.monthNames[this.model.getMonth()] + " " + this.model.getFullYear();
    },

    pickYears() {
      let min = this.model.getFullYear() - 20,
        max = this.model.getFullYear() + 20;

      return this.range(min, max);
    },

    days: {
      get() {
        let result = {},
          month = this.model.getMonth(),
          year = this.model.getYear(),
          date = new Date(year, month, 1);
        date.toLocaleString("en-us", { month: "short" });

        this.weekDays.forEach(week => {
          result[week] = [];
        });

        while (date.getMonth() == month) {
          let weekDay = this.weekDays[date.getDay()];

          result[weekDay].push(date.getDate());
          date.setDate(date.getDate() + 1);
        }

        return result;
      }
    }
  },

  mounted() {
    this.initPickDate();
    this.$nextTick(() => {});
  }
};
</script>

<style lang="sass" src="./styles.sass"></style>
