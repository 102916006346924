/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'zoom-minus': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 10a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z" _fill="#fff"/>'
  }
})
