/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'triple-line': {
    width: 19,
    height: 15,
    viewBox: '0 0 19 15',
    data: '<path pid="0" d="M17.5 0h-16a1.5 1.5 0 000 3h16a1.5 1.5 0 000-3zm0 6h-16a1.5 1.5 0 100 3h16a1.5 1.5 0 000-3zm0 6h-16a1.5 1.5 0 000 3h16a1.5 1.5 0 000-3z"/>'
  }
})
