<template lang="pug">
  form.edit-form-field(
    v-click-outside="handleClickOutside"
    :class="rootClasses"
    @submit.prevent="save"
  )
    .edit-form-field__box(v-if="isEdit")
      slot(
        name="field"
        v-bind="{ attrs, setValue, finishEditing, close }"
      )
        ui-input(
          ref="input"
          v-model="localValueModel"
          :placeholder="placeholder"
        )
    .edit-form-filed__preview(v-if="!isEdit")
      slot(
        name="preview"
        v-bind="{ attrs, startEditing, close }"
      )
        ui-input(
          v-model="localValueModel"
          :placeholder="placeholder"
          :readonly="true"
          @click.native="startEditing()"
        )
    .edit-form-field__controls(v-if="isEdit")
      ui-button.small.bali-hai(@click="save" :disabled="readonly || !canSave") {{ $t("ui.editFormRow.save") }}
      ui-button.small.bali-hai.o(@click="cancel" :disabled="readonly") {{ $t("ui.editFormRow.cansel") }}

</template>

<script>
export default {
  name: "UiEditFormField",

  props: {
    value: {
      type: [String, Number],
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      localValue: '',
      isEdit: false,
      locations: [],
    };
  },

  computed: {
    hasError() {
      return Boolean(this.errors?.length);
    },

    attrs() {
      return {
        value: this.localValueModel,
        placeholder: this.placeholder,
        readonly: this.readonly,
        hasError: this.hasError,
      };
    },

    localValueModel: {
      get() {
        return this.localValue;
      },
      set(value) {
        this.localValue = value;
        this.$emit('update:localValue', value);
      },
    },

    canSave() {
      return this.value !== this.localValueModel;
    },

    rootClasses(){
      return {
        'edit-form-field--edit': this.isEdit,
        'edit-form-field--error': this.hasError,
      };
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.localValue = this.value;
      },
    },
  },

  methods: {
    handleClickOutside() {
      if (this.isEdit) {
        this.save();
      }
    },

    setValue(value) {
      this.localValueModel = value;
    },

    startEditing(getFocusElement) {
      if (!this.readonly) {
        this.isEdit = true;

        this.$nextTick().then(() => {
          const element = getFocusElement ? getFocusElement() : this.$refs.input;

          if (element) {
            element.focus();
          }
        });
      }
    },

    close() {
      this.isEdit = false;
    },

    finishEditing() {
      this.close();
      this.localValue = this.value; // to reset an input value
    },

    cancel() {
      this.finishEditing();
    },

    save() {
      this.$emit('save', this.localValueModel);
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
