import { FORM_FIELD_OPTION_TYPES, FORM_FIELD_TYPES } from '@/constants.js';
import i18n from '@/i18n.js';
import helpers from '../../helpers/index.js';
import { cloneDeep } from 'lodash';

export const UNIQUE_KEY = '__uid__';

export const defineOption = (defaults = {}) => ({
  id: null,
  name: '',
  type: FORM_FIELD_OPTION_TYPES.DEFAULT,
  ...defaults,
});

export const defineEmptyOption = (counter = null) =>
  defineOption({
    name: `${i18n.t('formEditor.form.optionNameDefault')}${counter !== null ? ` ${counter}` : ''}`,
    [UNIQUE_KEY]: helpers.genUUID(),
  });

export const defineOptionForOther = () =>
  defineOption({
    name: i18n.t('formEditor.form.optionNameOther'),
    type: FORM_FIELD_OPTION_TYPES.OTHER,
    [UNIQUE_KEY]: helpers.genUUID(),
  });

export const defineOptionForAttachments = () =>
  defineOption({
    name: i18n.t('formEditor.form.optionNameAttachments'),
    type: FORM_FIELD_OPTION_TYPES.ATTACHMENTS,
    [UNIQUE_KEY]: helpers.genUUID(),
  });

const hasSomeOptionForAttachments = options =>
  options.some(option => option.type === FORM_FIELD_OPTION_TYPES.ATTACHMENTS);

const defineFieldConfigDefault = () => ({});

const defineFieldConfigOpenField = ({ hasAttachments = false, options = [] } = {}) => ({
  hasAttachments: hasAttachments || hasSomeOptionForAttachments(options),
});

const defineFieldConfigDate = defineFieldConfigOpenField;

const defineFieldConfigTime = defineFieldConfigOpenField;

const defineFieldConfigWithAvailableOptions = (
  availableTypes = Object.values(FORM_FIELD_OPTION_TYPES),
  { hasAttachments = false, options = [] } = {}
) => {
  const availableOptions = options.filter(option => availableTypes.includes(option.type));

  if (availableOptions && availableOptions.length === 0) {
    availableOptions.push(defineEmptyOption(1));
  }

  if (hasAttachments && !hasSomeOptionForAttachments(availableOptions)) {
    availableOptions.push(defineOptionForAttachments());
  }

  return {
    options: availableOptions,
  };
};

const defineFieldConfigMultiSelect = config =>
  defineFieldConfigWithAvailableOptions(Object.values(FORM_FIELD_OPTION_TYPES), config);

const defineFieldConfigCheckbox = defineFieldConfigMultiSelect;

const defineFieldConfigDropdown = config =>
  defineFieldConfigWithAvailableOptions(
    [FORM_FIELD_OPTION_TYPES.DEFAULT, FORM_FIELD_OPTION_TYPES.ATTACHMENTS],
    config
  );

const fieldConfigFactoryMap = Object.freeze({
  [FORM_FIELD_TYPES.OPEN_FIELD]: defineFieldConfigOpenField,
  [FORM_FIELD_TYPES.MULTI_SELECT]: defineFieldConfigMultiSelect,
  [FORM_FIELD_TYPES.CHECKBOX]: defineFieldConfigCheckbox,
  [FORM_FIELD_TYPES.DROPDOWN]: defineFieldConfigDropdown,
  [FORM_FIELD_TYPES.DATE]: defineFieldConfigDate,
  [FORM_FIELD_TYPES.TIME]: defineFieldConfigTime,
});

export const defineFieldConfig = (type, defaults) => {
  const configFactory = fieldConfigFactoryMap[type] ?? defineFieldConfigDefault;
  return configFactory(defaults);
};

export const defineFormField = (defaults = {}) => ({
  id: null,
  name: '',
  type: FORM_FIELD_TYPES.MULTI_SELECT,
  config: defineFieldConfig(FORM_FIELD_TYPES.MULTI_SELECT),
  required: false,
  ...defaults,
});

export const defineEmptyFormField = () =>
  defineFormField({
    name: i18n.t('formEditor.form.questionNameDefault'),
    [UNIQUE_KEY]: helpers.genUUID(),
  });

export const defineForm = (defaults = {}) => ({
  id: null,
  name: '',
  description: '',
  fields: [],
  sectionId: null,
  ...defaults,
});

export const defineEmptyForm = () =>
  defineForm({
    name: i18n.t('formEditor.form.nameDefault'),
    description: '',
    fields: [defineEmptyFormField()],
  });

export const cloneFormField = field =>
  cloneDeep({
    ...field,
    config: field.config.options
      ? {
          ...field.config,
          options: field.config.options.map(option => ({
            ...option,
            id: null,
            [UNIQUE_KEY]: helpers.genUUID(),
          })),
        }
      : { ...field.config },
    id: null,
    [UNIQUE_KEY]: helpers.genUUID(),
  });
