/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list': {
    width: 21,
    height: 18,
    viewBox: '0 0 21 18',
    data: '<g _fill="none" fill-rule="evenodd"><g transform="translate(.05 .097)" _fill="#34335C" fill-rule="nonzero"><ellipse pid="0" cx="2.3" cy="2.335" rx="2.3" ry="2.238"/><ellipse pid="1" cx="2.3" cy="8.514" rx="2.3" ry="2.238"/><ellipse pid="2" cx="2.3" cy="14.886" rx="2.3" ry="2.238"/><path pid="3" d="M7.25 3.843h12.5c.1 0 .2-.048.3-.097.1-.097.1-.195.1-.292V1.362c0-.243-.2-.389-.4-.389H7.25c-.25 0-.4.195-.4.39v2.091c0 .195.2.39.4.39zM19.8 7.054H7.3c-.25 0-.4.195-.4.39v2.091c0 .243.2.39.4.39h12.5c.1 0 .2-.05.3-.098.1-.097.1-.195.1-.292V7.443c0-.194-.2-.389-.4-.389zM19.8 13.427H7.3c-.25 0-.4.195-.4.39v2.091c0 .243.2.39.4.39h12.5c.1 0 .2-.05.3-.098.1-.097.1-.195.1-.292v-2.092c0-.243-.2-.389-.4-.389z"/></g></g>'
  }
})
