/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'media': {
    width: 21,
    height: 24,
    viewBox: '0 0 21 24',
    data: '<g fill-rule="nonzero" _fill="#34335C"><path pid="0" d="M.073 6.675V23.875h20.854v-17.2H.073zm19.267 1.65V19.95l-4.86-5.475-2.442 2.75-4.566-5.375-5.787 6.8V8.325H19.34zM2.002 3.4h17.02v1.65H2.002zM3.907.125h13.186v1.65H3.907z"/><ellipse pid="1" cx="15.994" cy="11.75" rx="1.538" ry="1.575"/></g>'
  }
})
