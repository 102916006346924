<template lang="pug">
  .avatar-select
    .header {{ name }}
    .body
      .change-avatar(
        :class="{'no-avatar': !cover}"
      )
        ui-avatar(
          :size="9"
          :user="avatarSettings"
        )

        template(v-if="!readonly")
          button.change {{ $t('update') }}

          ui-input.shadow(
            type="file"
            :accept="'image/*'"
            :multiple="false"
            @files="filesChange"
          )

    .footer(v-if="!readonly")
      .btn {{ uploadButtonText }}
        ui-input.shadow(
          type="file"
          :accept="'image/*'"
          :multiple="false"
          @files="filesChange"
        )
</template>

<script>
export default {
  name: 'UiAvatarSelect',

  props: {
    name: {
      type: String,
      default: undefined,
    },

    text: {
      type: String,
      default: undefined,
    },

    cover: {
      type: String,
      default: undefined,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    uploadText: {
      type: String,
      default: undefined,
    },

    onFileSelect: {
      type: Function,
      default: () => Promise.resolve(),
    },
  },

  computed: {
    avatarSettings() {
      return {
        avatar: this.cover,
        firstName: this.text,
      };
    },

    uploadButtonText() {
      return this.uploadText ?? this.$t('uploadPhoto');
    },
  },

  methods: {
    filesChange(files) {
      this.openModal({
        name: 'CropImage',
        params: {
          file: files[0],
          callback: ({ file, close }) =>
            new Promise(async (resolve, reject) => {
              try {
                await this.onFileSelect({ file });

                close();
                resolve();
              } catch (error) {
                reject(error);
              }
            }),
        },
      })
    },
  },
};
</script>

<style lang="sass" src="./styles.sass"></style>
