/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gear': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.66 4.03a7.95 7.95 0 0 0-1.4 0l-.1.02a.8.8 0 0 0-.52.37l-1.07 1.8-2.04-.5a.8.8 0 0 0-.7.17c-.36.3-.69.64-1 1l-.05.08a.8.8 0 0 0-.1.63l.52 2.03-1.8 1.1a.8.8 0 0 0-.37.6c-.04.48-.04.95 0 1.42l.02.1c.05.2.18.4.37.5l1.8 1.08-.5 2.04a.8.8 0 0 0 .17.7c.3.36.64.69 1 1l.08.05a.8.8 0 0 0 .63.1l2.03-.52 1.1 1.8a.8.8 0 0 0 .6.37c.48.04.95.04 1.42 0l.1-.02a.8.8 0 0 0 .5-.37l1.08-1.8 2.04.5c.24.05.5 0 .7-.17.36-.3.69-.64 1-1l.05-.08a.8.8 0 0 0 .1-.63l-.52-2.03 1.8-1.1a.8.8 0 0 0 .37-.6c.04-.48.04-.95 0-1.42l-.02-.1a.8.8 0 0 0-.37-.5l-1.8-1.08.5-2.04a.8.8 0 0 0-.17-.7c-.3-.36-.64-.69-1-1a.8.8 0 0 0-.7-.15l-2.04.52-1.1-1.8a.8.8 0 0 0-.6-.37zm-.87 1.56h.35l1.18 1.93c.18.3.53.44.87.36l2.2-.56.12.12.12.12-.53 2.2-.02.1a.8.8 0 0 0 .38.77l1.95 1.16v.35l-1.93 1.18-.09.06a.8.8 0 0 0-.27.81l.56 2.2-.12.12-.13.12-2.2-.53-.09-.02a.8.8 0 0 0-.77.38l-1.16 1.95h-.35l-1.18-1.93-.06-.09a.8.8 0 0 0-.81-.27l-2.2.56-.12-.12-.12-.13.53-2.2.02-.09a.8.8 0 0 0-.38-.77L5.59 12.2v-.35l1.93-1.18.09-.06a.8.8 0 0 0 .27-.81l-.56-2.2.12-.12.12-.12 2.2.53.1.02a.8.8 0 0 0 .77-.38l1.16-1.95zM12 8.83a3.17 3.17 0 1 1 0 6.34 3.17 3.17 0 0 1 0-6.34zM10.42 12a1.58 1.58 0 1 1 3.16 0 1.58 1.58 0 0 1-3.16 0z" _fill="#7F848B"/>'
  }
})
