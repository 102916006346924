/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'message': {
    width: 15,
    height: 13,
    viewBox: '0 0 15 13',
    data: '<path pid="0" d="M1.952 12.994a.93.93 0 0 1-.84-.497.957.957 0 0 1 .113-1.096c.12-.146.26-.313.418-.472.368-.373.653-.82.837-1.31a9.07 9.07 0 0 1-.424-.303C1.266 8.707.717 8.033.378 7.255c-.3-.689-.423-1.386-.363-2.073.058-.67.289-1.333.687-1.972C1.437 2.03 2.527 1.189 4.036.634c2.2-.81 4.436-.844 6.647-.104 1.456.488 2.558 1.248 3.369 2.324.64.85.956 1.833.916 2.843-.04 1.01-.432 1.965-1.134 2.77-1.001 1.146-2.34 1.904-4.09 2.317-.987.234-2.026.304-3.173.214-1.145.95-2.499 1.593-4.024 1.914a4.835 4.835 0 0 1-.36.059l-.07.01a1.14 1.14 0 0 1-.165.013zM7.504 1.209c-1.02 0-2.04.186-3.051.558-1.246.458-2.137 1.14-2.724 2.08-.601.965-.68 1.92-.242 2.924.256.587.684 1.106 1.308 1.587.154.118.318.23.493.348l.248.17.343.24-.102.405a4.952 4.952 0 0 1-1.155 2.132 7.904 7.904 0 0 0 3.217-1.622c.078-.066.363-.28.717-.248 1.063.092 2.014.035 2.907-.176 1.497-.353 2.628-.987 3.457-1.936 1.058-1.212 1.122-2.818.163-4.092-.656-.873-1.569-1.496-2.785-1.902a8.76 8.76 0 0 0-2.794-.468z" _fill="#34335C" fill-rule="nonzero"/>'
  }
})
