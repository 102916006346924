/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-white': {
    width: 11,
    height: 7,
    viewBox: '0 0 11 7',
    data: '<path pid="0" d="M10.734 1.457L4.973 6.756A.964.964 0 014.327 7a.964.964 0 01-.646-.244L.285 3.742a.823.823 0 01-.258-.828c.08-.299.324-.536.642-.621a.978.978 0 01.9.206l2.742 2.443L9.44.234a.977.977 0 01.886-.201.895.895 0 01.642.603.818.818 0 01-.227.822h-.007z" _fill="#fff" fill-rule="evenodd"/>'
  }
})
