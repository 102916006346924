/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checklist': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M14.846 6.5a.665.665 0 0 1 .341.614v8.169a.713.713 0 0 1-.208.519.727.727 0 0 1-.52.198H.73a.747.747 0 0 1-.5-.184.704.704 0 0 1-.23-.532V2.249c.004-.414.311-.714.73-.717h10.483c.41.003.72.31.721.716a.706.706 0 0 1-.716.718H1.465v11.6h12.258V7.108a.653.653 0 0 1 .328-.59.817.817 0 0 1 .795-.018zm.75-5.557c.34.236.419.652.187.99l-7.44 9.53a.698.698 0 0 1-.56.275.737.737 0 0 1-.511-.208L3.598 7.757c-.252-.265-.234-.675.04-.956.28-.288.69-.313.971-.059l3.11 3.192L14.63 1.08c.217-.31.632-.37.966-.138z" _fill="#34335C" fill-rule="evenodd"/>'
  }
})
