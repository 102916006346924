<template lang="pug">
  .ui.time-picker
    .body
      .select-wrap
        span  {{ pad(model.hh) }}
        select(
          :value="model.hh",
          @change="pickHour($event)")

          option(
            v-for="(n, i) in 24",
            :key="i",
            :value="i") {{ pad(i) }}

      .separator :

      .select-wrap
        span  {{ pad(model.mm) }}
        select(
          :value="model.mm",
          @change="pickMinute($event)")

          option(
            v-for="(n, i) in 60",
            :key="i",
            :value="i") {{ pad(i) }}
</template>

<script>
export default {
  props: {
    value: {
      require: true,
    },
  },

  methods: {
    pad(n) {
      return n < 10 ? '0' + n : n
    },

    pickHour(event) {
      const value = this.value || this.$moment()
      this.$emit('input', value.clone().set('hour', Number(event.target.value)))
    },

    pickMinute(event) {
      const value = this.value || this.$moment()
      this.$emit(
        'input',
        value.clone().set('minute', Number(event.target.value))
      )
    },
  },

  computed: {
    model() {
      const val = this.value || this.$moment()
      return {
        hh: val.hour(),
        mm: val.minute(),
      }
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
