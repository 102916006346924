/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calculation': {
    width: 95,
    height: 95,
    viewBox: '0 0 95 95',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M16 28.2h6.2v6.3c0 1.6 1.3 3 3 3s3-1.3 3-3v-6.2h6.3c1.6 0 3-1.3 3-3 0-1.6-1.3-3-3-3h-6.2v-6.2c0-1.6-1.3-3-3-3s-3 1.3-3 3v6.2h-6.2c-1.6 0-3 1.3-3 3-.1 1.6 1.3 2.9 2.9 2.9M61.6 28.2h16.3c1.6 0 3-1.3 3-3 0-1.6-1.3-3-3-3H61.6c-1.6 0-3 1.3-3 3s1.4 3 3 3M61.6 67h16.3c1.6 0 3-1.3 3-3s-1.3-3-3-3H61.6c-1.6 0-3 1.3-3 3s1.4 3 3 3M61.6 78.5h16.3c1.6 0 3-1.3 3-3s-1.3-3-3-3H61.6c-1.6 0-3 1.3-3 3s1.4 3 3 3M16.6 78.4c.6.6 1.3.9 2.1.9s1.5-.3 2.1-.9l4.4-4.4 4.4 4.4c.6.6 1.3.9 2.1.9s1.5-.3 2.1-.9c1.2-1.2 1.2-3 0-4.2l-4.4-4.4 4.4-4.4c1.2-1.2 1.2-3 0-4.2-1.2-1.2-3-1.2-4.2 0l-4.4 4.4-4.4-4.4c-1.2-1.2-3-1.2-4.2 0-1.2 1.2-1.2 3 0 4.2l4.4 4.4-4.4 4.4c-1.1 1.1-1.1 3 0 4.2" _fill="#34335C"/><path pid="1" d="M50.5 44.5h38.6V6H50.5v38.5zm0 44.5h38.6V50.5H50.5V89zM5.9 44.5h38.6V6H5.9v38.5zm0 44.5h38.6V50.5H5.9V89zM92 0H3C1.4 0 0 1.3 0 3v89c0 1.6 1.3 3 3 3h89c1.6 0 3-1.3 3-3V3c0-1.7-1.3-3-3-3z" _fill="#34335C" mask="url(#mask-2)"/></g>'
  }
})
