/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'subject': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.5 7.18h16.98c.32 0 .58-.49.58-1.1 0-.6-.26-1.08-.58-1.08H3.49c-.32 0-.58.49-.58 1.09 0 .6.26 1.09.58 1.09zm0 5.92h10.25c.32 0 .58-.5.58-1.1 0-.6-.26-1.08-.58-1.08H3.49c-.32 0-.58.48-.58 1.08 0 .6.26 1.1.58 1.1zm16.98 3.73H3.49c-.32 0-.58.49-.58 1.09 0 .6.26 1.09.58 1.09h16.99c.32 0 .58-.49.58-1.09 0-.6-.26-1.09-.58-1.09z" _fill="#888D93"/>'
  }
})
