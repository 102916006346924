import Vue from 'vue'
import { isPlainObject } from 'lodash'

import EventEmmiter from '@/eventEmitter'

const NotifyEmmiter = new EventEmmiter()

class Notify {
  emit(...args) {
    NotifyEmmiter.emit(...args)
    return this
  }

  on(...args) {
    NotifyEmmiter.on(...args)
    return this
  }

  success(data) {
    if (typeof data === 'string' || isPlainObject(data)) {
      return this.emit('success', data)
    } else if (Array.isArray(data)) {
      data.forEach(e => this.success(e))
    } else {
      debug('Error format notify:', data)
    }
  }

  error(data) {
    if (typeof data === 'string' || isPlainObject(data)) {
      return this.emit('error', data)
    } else if (Array.isArray(data)) {
      data.forEach(e => this.error(e))
    } else {
      debug('Error format notify:', data)
    }
  }
}

Vue.mixin({
  beforeCreate() {
    Vue.util.defineReactive(this, '$notify', new Notify())
  },
})
