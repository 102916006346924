<template lang="pug">
  .form.add.checklist-item-add(v-click-outside="cancel", :class="{drop: show}")
    .header
      ui-button.grey.small.create(
        @click="open",
        v-show="!show") {{ $t("sidebar.checklists.formCreateItem.addItem") }}
    
    .body(v-if="show")
      .ui.form.textarea.edit
        .body
          ui-textarea(
            v-model="name",
            ref="refInput",
            @enter="create",
            :placeholder="$t('sidebar.checklists.formCreateItem.addItem')",
            @keydown="handleKeydownText")

        .footer
          ui-button.success.small(@click="create", :disabled="!validate") {{ $t("sidebar.checklists.formCreateItem.create") }}
          ui-button.small.bali-hai.o(@click="cancel") {{ $t("sidebar.checklists.formCreateItem.cancel") }}
</template>

<script>
import {mapActions} from "vuex";

export default {
  props: {
    checklistId: {
      required: true
    }
  },

  data() {
    return {
      show: false,
      name: ""
    }
  },

  methods: {
    async create() {
      if(!this.validate) return;

      this.$emit("loaded", true);
      const items = this.name.split(/\r?\n/);
      this.cancel();

      for (let i = 0; i < items.length; i++) {
        if(!/([^\s])/.test(items[i])) continue;

        await this.addChecklistItem({
            name: items[i],
            checklistId: this.checklistId
          })
          .catch(error => {
            this.$notify.error(this.$utils.response(error));
          });
      }

      this.$emit("loaded", false);
    },

    open() {
      this.show = true;
      this.$nextTick(() => {
        this.$refs.refInput.focus();
      });
    },

    handleKeydownText(e) {
      switch (e.key) {
        case "Enter":
          e.preventDefault();
          this.create();
          this.open();
          break;

        default:
          break;
      }
    },

    cancel() {
      this.name = "";
      this.show = false;
    },

    ...mapActions("Checklists", [
      "addChecklistItem"
    ])
  },

  computed: {
    validate() {
      return /([^\s])/.test(this.name);
    }
  },

  events: {
    'checklist:open'(id) {
      if(this.checklistId == id) this.open()
    }
  },

  mounted() {
    this.$bus.on(`open-checklist-${this.checklistId}`, this.open);
  },

  beforeDestroy() {
    this.$bus.unsubscribe(`open-checklist-${this.checklistId}`, this.open);
  }
}
</script>
