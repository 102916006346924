/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mute': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.89 15.874l5.9 5.899a.719.719 0 01-1.017 1.016L1.211 1.227A.718.718 0 112.227.21l4.82 4.82h2.71L16.76.13a.72.72 0 011.13.589v15.155zm0 3.83L3.863 5.673a2.87 2.87 0 00-1.065 2.232v7.187a2.878 2.878 0 002.875 2.875h4.086l7.002 4.901a.717.717 0 001.13-.588v-2.578z" _fill="#000"/>'
  }
})
