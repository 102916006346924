/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.6 4a.8.8 0 1 1 0 1.6c-.38 0-.71.04-1 .12-.32.1-.57.23-.77.4-.41.4-.63 1.02-.63 1.88 0 .7.3 1.55.8 2.2a2.95 2.95 0 0 0 1.06.89l.14.05a.8.8 0 0 1-.46 1.53 3.4 3.4 0 0 1-.81-.37 4.54 4.54 0 0 1-1.22-1.14A5.48 5.48 0 0 1 5.6 8c0-1.27.36-2.33 1.15-3.05.39-.35.86-.61 1.42-.77.43-.12.9-.18 1.43-.18zm8.8 4c0-2.44-1.35-4-4-4s-4 1.56-4 4c0 2.32 1.84 4.8 4 4.8s4-2.48 4-4.8zm-.8 6.3a.8.8 0 0 0-1.6.1V16h-1.7a.8.8 0 0 0 .1 1.6H16v1.7a.8.8 0 0 0 1.6-.1v-1.6h1.7a.8.8 0 0 0-.1-1.6h-1.6v-1.7zm-3.18.09a.8.8 0 0 0-.82-.78l-.44.02c-.45.02-.87.05-1.26.1a3.5 3.5 0 0 0-3.1 3.47v1.32A1.6 1.6 0 0 0 10.4 20h3.3a.8.8 0 0 0-.1-1.6h-3.2v-1.33a1.9 1.9 0 0 1 1.68-1.76c.35-.04.73-.07 1.15-.09l.41-.01a.8.8 0 0 0 .78-.82zm-5.83.01a.8.8 0 0 1-.74.86l-.38.03-.19.02a1.9 1.9 0 0 0-1.68 1.76v1.33h1.6a.8.8 0 0 1 .1 1.6H5.6A1.6 1.6 0 0 1 4 18.52V17.2a3.5 3.5 0 0 1 3.1-3.48l.2-.02.43-.04a.8.8 0 0 1 .86.74zM12 8c0-1.6.69-2.4 2.4-2.4s2.4.8 2.4 2.4c0 1.52-1.25 3.2-2.4 3.2S12 9.52 12 8z" _fill="#7F848B"/>'
  }
})
