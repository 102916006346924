/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'projects': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" id="svgicon_projects_a" d="M41.294 107.298l-4.927-1.868a.52.52 0 0 1-.176.111l-4.223 1.603 9.515 3.611 9.515-3.611-4.223-1.603a.52.52 0 0 1-.176-.11l-4.927 1.867a.531.531 0 0 1-.378 0zm-10.96-4.155a.512.512 0 0 1-.334-.477c0-.211.132-.4.333-.477l10.961-4.155a.539.539 0 0 1 .378 0l10.96 4.155a.512.512 0 0 1 .334.477c0 .211-.132.4-.333.477l-4.645 1.76 4.645 1.763c.2.077.333.266.333.478 0 .211-.132.4-.333.478l-4.126 1.566 4.126 1.566c.2.077.333.266.333.477 0 .211-.132.4-.333.477l-10.962 4.155a.531.531 0 0 1-.376 0l-10.961-4.155a.513.513 0 0 1-.334-.477c0-.211.133-.4.334-.477l4.125-1.566-4.125-1.566a.513.513 0 0 1-.334-.478c0-.212.133-.401.334-.478l4.644-1.762zm11.337 8.64a.53.53 0 0 1-.376 0l-5.39-2.047-3.937 1.495 9.515 3.607 9.515-3.607-3.937-1.495zm-9.702-9.117l9.514 3.607 9.515-3.607-9.515-3.607z"/><path pid="1" id="svgicon_projects_c" d="M30 119V95h24v24z"/><clipPath id="svgicon_projects_b"><use xlink:href="#svgicon_projects_a"/></clipPath></defs><use _fill="#ff805d" xlink:href="#svgicon_projects_a" transform="translate(-30 -95)"/><g clip-path="url(#svgicon_projects_b)" transform="translate(-30 -95)"><use _fill="#ff805d" xlink:href="#svgicon_projects_c"/></g>'
  }
})
