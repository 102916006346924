/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M11.884 15.996c-.293-.049-.587-.087-.877-.149a11.403 11.403 0 0 1-3.079-1.26 16.448 16.448 0 0 1-4.053-3.175C2.444 9.857 1.253 8.144.502 6.15a6.846 6.846 0 0 1-.495-2.25A2.71 2.71 0 0 1 .75 1.828c.448-.459.88-.935 1.363-1.353.745-.64 1.555-.64 2.274.03.76.713 1.493 1.462 2.2 2.223a1.51 1.51 0 0 1 0 2.055c-.376.426-.792.814-1.189 1.222-.036.034-.074.064-.14.124a11.575 11.575 0 0 0 4.563 4.55l.947-.958c.233-.275.524-.493.853-.64a1.431 1.431 0 0 1 1.56.278c.795.756 1.57 1.534 2.325 2.333.53.569.548 1.445.04 2.033a22.427 22.427 0 0 1-1.542 1.613c-.381.35-.866.567-1.382.617-.037.008-.073.02-.109.032l-.63.008zm.236-.962c.532.03 1.05-.175 1.42-.559.358-.375.724-.742 1.087-1.113.047-.048.091-.097.132-.15a.653.653 0 0 0-.019-.938c-.292-.316-.601-.615-.904-.92-.395-.394-.785-.791-1.184-1.18a.64.64 0 0 0-.933-.025c-.06.049-.113.104-.168.16-.38.377-.755.76-1.137 1.133a.723.723 0 0 1-.907.151 11.763 11.763 0 0 1-.687-.367 11.827 11.827 0 0 1-2.65-2.21 9.55 9.55 0 0 1-1.753-2.492c-.226-.499-.177-.742.213-1.122.39-.38.809-.783 1.195-1.192a.61.61 0 0 0 .015-.894c-.047-.056-.096-.107-.145-.158L3.822 1.276c-.401-.394-.758-.399-1.159 0-.4.4-.778.751-1.143 1.15-.18.186-.324.403-.427.64a2.718 2.718 0 0 0-.019 1.568c.178.797.457 1.568.832 2.294A15.663 15.663 0 0 0 4.33 10.45a15.285 15.285 0 0 0 6.118 4.224c.536.202 1.1.321 1.672.352v.008z" _fill="#34335C" fill-rule="nonzero"/>'
  }
})
