<template lang="pug">
  .custom-select(
    ref="select"
    :tabindex="tabindex"
    @blur="handleClose"
  )

    .custom-select__selected(
      :class="{ open: open }"
      @click="toggleOpen"
      )
      .custom-select__value(v-if="value")
        ui-svg.custom-select__icon.icon(v-if="value?.icon" :name="value.icon") 
        | {{ value?.placeholder }}
      .custom-select__placeholder(v-else-if="placeholder")
        | {{ placeholder }}
      .custom-select__empty(v-else)


    .custom-select__items(
      v-if="open"
      v-drop="getPopupOptions()"
    )
      .custom-select__item(v-for="(option, i) of options"
        :key="i"
        @click="handleItemClick(option)"
      )
        ui-svg.custom-select__icon.icon(v-if="option?.icon" :name="option.icon") 
        |{{ option.placeholder }}

    ui-svg.custom-select__arrow(name="arrow")
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },

    value: {
      type: Object,
      default: null,
    },

    placeholder: {
      type: String,
      default: '',
    },

    tabindex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      open: false,
    };
  },

  methods: {
    getPopupOptions() {
      const { select } = this.$refs;

      if (!select) return {};

      return {
        flip: true,
        referenceElement: select,
      };
    },
    handleItemClick(option) {
      this.open = false;
      this.$emit('input', option);
    },
    handleClose() {
      this.open = false;
    },
    toggleOpen() {
      this.open = !this.open;
    },
  },
};
</script>

<style lang="sass" src="./styles.sass"></style>
