/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar': {
    width: 15,
    height: 16,
    viewBox: '0 0 15 16',
    data: '<path pid="0" d="M13.08 1.68H12V1.1a1 1 0 00-1-1.02 1 1 0 00-.99 1.02v.58H5V1.1A1 1 0 004 .08 1 1 0 003 1.1v.58H1.92c-1 0-1.8.84-1.8 1.85v.95H14.9v-.95a1.84 1.84 0 00-1.82-1.85zM.11 14.07c0 1.01.82 1.85 1.81 1.85H13.1c1 0 1.81-.84 1.81-1.85V5.65H.11v8.42zm9.7-5.27c0-.18.15-.32.31-.32h1.49c.18 0 .3.15.3.32v1c0 .18-.14.32-.3.32h-1.48a.31.31 0 01-.31-.32v-1zm0 2.97c0-.19.15-.32.31-.32h1.49c.18 0 .3.15.3.32v1c0 .18-.14.31-.3.31h-1.48a.31.31 0 01-.31-.31v-1zM6.45 8.8c0-.18.15-.32.31-.32h1.48c.18 0 .31.15.31.32v1c0 .18-.14.32-.3.32H6.74a.31.31 0 01-.31-.32v-1zm0 2.97c0-.19.15-.32.31-.32h1.48c.18 0 .31.15.31.32v1c0 .18-.14.31-.3.31H6.74a.31.31 0 01-.31-.31v-1zM3.08 8.8c0-.18.15-.32.31-.32h1.48c.18 0 .31.15.31.32v1c0 .18-.14.32-.3.32h-1.5a.31.31 0 01-.3-.32v-1zm0 2.97c0-.19.15-.32.31-.32h1.48c.18 0 .31.15.31.32v1c0 .18-.14.31-.3.31h-1.5a.31.31 0 01-.3-.31v-1z" _fill="#7A93A9"/>'
  }
})
