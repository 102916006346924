import Vue from 'vue'
import _ from 'lodash'

import '../directives'

Vue.directive('sync-width', {
  bind: function(el, binding, vnode) {
    vnode.context.$nextTick(() => {
      if (typeof binding.value === 'function') binding.value(el.scrollWidth)
    })
  },
  update: function(el, binding, vnode) {
    vnode.context.$nextTick(() => {
      if (typeof binding.value === 'function') binding.value(el.scrollWidth)
    })
  },
})

Vue.directive('detect-resize', {
  bind: function(el, binding) {
    let fn = _.get(binding, 'value', () => {})

    const observer = new ResizeObserver(() => {
      const rect = el.getBoundingClientRect()

      fn({
        width: rect.width,
        height: rect.height,
        el,
      })
    });
    observer.observe(el);
    el.__detectResize__ = observer;
  },

  unbind: function(el) {
    const observer = el.__detectResize__;
    observer.unobserve(el);
    delete el.__detectResize__;
  },
})

Vue.directive('on-dom-update', {
  update: function(el, binding, vnode) {
    if (typeof binding.value === 'function') {
      binding.value()
    }
  },
})

Vue.directive('on-resize', {
  bind: function(el, binding) {
    let fn = _.get(binding, 'value', () => {})
    const ro = new ResizeObserver(entries => {
      fn()
      // document.scrollingElement.scrollTop =
      //   document.scrollingElement.scrollHeight
    })

    ro.observe(document.scrollingElement)
    el.ro = ro
  },

  unbind: function(el) {
    delete el.ro
  },
})

Vue.directive('scroll', {
  inserted: function(el, binding) {
    let onScroll = function(e) {
      requestAnimationFrame(() => {
        if (binding.value(e, el)) el.removeEventListener('scroll', onScroll)
      })
    }

    let onTouchMove = function(e) {
      requestAnimationFrame(() => {
        if (binding.value(e, el))
          el.removeEventListener('touchmove', onTouchMove)
      })
    }

    el.addEventListener('scroll', onScroll, false, false)
    el.addEventListener('touchmove', onTouchMove, false, false)
  },
})

Vue.directive('onclick', {
  bind: function(el, binding) {
    el.onclick = binding.value
  },
})

// LOADED DERICTIVE

function handleLoaded(el, binding) {
  el.classList.add('loaded')

  if (binding.value) {
    el.classList.add('loaded-active')
  } else {
    el.classList.remove('loaded-active')
  }
}

Vue.directive('loaded', {
  bind: handleLoaded,
  update: handleLoaded,
})

// CHECK COSSISION ELEMENT DERICTIVE

function isCollide(parent, el, fn) {
  const parentR = parent.getBoundingClientRect(),
    elR = el.getBoundingClientRect()

  let state = []

  if (parentR.top - elR.top >= 0) {
    state.push('v-top')
  }

  if (parentR.right - elR.right <= 0) {
    state.push('h-right')
  }

  fn('collide', state)
  return state
}

Vue.directive('collision-scroll', {
  inserted: function(el, binding, vnode) {
    let parent = document.getElementsByClassName(binding.value)[0]

    if (!parent) {
      debug(
        `Collisions scroll not find scroll parent element: ${binding.value}`
      )
      return null
    }

    const scrollHandler = event => {
      isCollide(parent, el, (e, detail = {}) => {
        el.dispatchEvent(new CustomEvent(e, { detail, bubbles: true }))
      })
    }

    scrollHandler()
    parent.addEventListener('scroll', scrollHandler)

    el.$destroy = () => {
      parent.removeEventListener('scroll', scrollHandler)
    }
  },

  unbind: function(el) {
    if (el.$destroy) {
      el.$destroy()
    }
  },
})

let updateResizeFn = ({ vnode, el }) => {
  if (!el.shadow) return null

  vnode.context.$nextTick(() => {
    el.shadow.value = el.value
    el.style.height = `${el.shadow.scrollHeight}px`
    el.classList.add('auto-resize')
  })
}

Vue.directive('auto-resize', {
  bind: function(el, binding, vnode) {
    if (!binding.value) return

    vnode.context.$nextTick(() => {
      let shadow = document.createElement('textarea')

      shadow.style.visibility = 'hidden'
      shadow.style.opacity = '0'
      shadow.style.position = 'absolute'
      shadow.style.left = '0'
      shadow.style.right = '0'
      shadow.style.height = '0px'
      shadow.classList.add('shadow')

      vnode.elm.parentElement.appendChild(shadow)
      el.shadow = shadow

      updateResizeFn({ vnode, el })

      // setTimeout(() => {
      //   el.shadow.value = el.value;
      //   el.style.height = `${el.shadow.scrollHeight+5}px`;
      //   el.classList.add("auto-resize");
      // }, 100);
    })
  },

  update: function(el, binding, vnode) {
    if (!binding.value) return

    updateResizeFn({ vnode, el })

    // vnode.context.$nextTick(() => {
    //   el.shadow.value = el.value;
    //   el.style.height = `${el.shadow.scrollHeight+5+}px`;
    //   el.classList.add("auto-resize");
    // });
  },

  unbind: function(el, bind, vnode) {
    if (el.shadow) {
      el.shadow.remove()
    }
  },
})
