/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'message-notification': {
    width: 21,
    height: 26,
    viewBox: '0 21 26',
    data: `<path d="M11.0749 0.203857C11.2307 -0.0226746 11.5296 -0.0665588 11.7702 0.103149C12.0151 0.275543 12.0715 0.580109 11.9044 0.827362L6.5452 7.79883C6.46189 7.91223 6.32888 7.98636 6.18363 7.99829C6.16995 7.99951 6.15602 8 6.14234 8C6.00669 8 5.8744 7.94562 5.77429 7.84735L3.1283 5.08789C2.94656 4.8938 2.95952 4.59387 3.15687 4.38855C3.35926 4.17764 3.65361 4.15912 3.85648 4.34515L6.09624 6.67987L11.0749 0.203857ZM10.1768 4.44598C10.3498 4.34717 10.5816 4.3421 10.7526 4.43356C10.9135 4.5195 11.0038 4.67432 10.9999 4.8584L10.9999 10.5042C11.0004 10.6443 10.9466 10.7719 10.8488 10.8629C10.7521 10.9525 10.6223 11 10.4725 11L0.528622 11C0.391654 11 0.26283 10.9548 0.166582 10.8728C0.0594759 10.7816 0.000494003 10.6509 0 10.5049L0 1.49582C0.00320816 1.20966 0.225318 1.00229 0.528375 1L8.12158 1.00046C8.41871 1.00183 8.64255 1.21426 8.64378 1.49466C8.64526 1.77411 8.42216 1.98746 8.12503 1.99139L1.06095 1.99139L1.06095 10.0091L9.93893 10.0091L9.93893 4.85403C9.93005 4.69321 10.0186 4.53653 10.1768 4.44598Z" id="Combined-Shape-Copy" fill="#BFC2C5" fill-rule="evenodd" stroke="none" />`
  }
})
