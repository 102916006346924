/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'smile': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0c6.622 0 12 5.384 12 12s-5.378 12-12 12S0 18.616 0 12 5.378 0 12 0zm0 22.767c5.96 0 10.781-4.821 10.781-10.767S17.961 1.233 12 1.233C6.04 1.233 1.219 6.054 1.219 12S6.039 22.767 12 22.767zM7 9.132c-.604 0-1.093.595-1.07 1.302 0 .312-.209.566-.465.566S5 10.746 5 10.434C5 9.104 5.907 8 7 8c1.094 0 2 1.076 2 2.434 0 .312-.209.566-.465.566s-.465-.254-.465-.566c0-.707-.489-1.302-1.07-1.302zM17 8c1.115 0 2.022 1.076 2 2.434 0 .312-.21.566-.465.566-.257 0-.465-.254-.465-.566 0-.707-.489-1.302-1.07-1.302-.581 0-1.07.595-1.07 1.302 0 .312-.209.566-.465.566S15 10.746 15 10.434C15 9.104 15.884 8 17 8zm.663 5.052a.622.622 0 0 1 .302.753C16.958 16.36 14.615 18 12.02 18c-2.595 0-4.937-1.64-5.97-4.195a.576.576 0 0 1 .277-.753c.278-.134.58 0 .706.296.856 2.124 2.796 3.496 4.963 3.496 2.165 0 4.106-1.372 4.962-3.496a.507.507 0 0 1 .706-.296z" _fill="#34335C" fill-rule="evenodd"/>'
  }
})
