import * as locales from 'date-fns/locale';
import { LANGUAGES } from '@/constants';

export const DateFnsLocaleMap = Object.freeze({
  [LANGUAGES.NETHERLANDS]: locales.nl,
  [LANGUAGES.FRENCH]: locales.fr,
  [LANGUAGES.PORTUGUESE]: locales.pt,
  [LANGUAGES.BULGARIAN]: locales.bg,
  [LANGUAGES.TURKISH]: locales.tr,
  [LANGUAGES.POLISH]: locales.pl,
  [LANGUAGES.UKRAINIAN]: locales.uk,
  [LANGUAGES.ENGLISH]: locales.enGB,
});

export const resolveDateFnsLocale = language => DateFnsLocaleMap[language];
