/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shared': {
    width: 10,
    height: 10,
    viewBox: '0 0 10 10',
    data: '<path pid="0" d="M9.219.78a2.667 2.667 0 0 0-3.771 0L3.96 2.269a3.443 3.443 0 0 1 1.446.157l.843-.843c.29-.29.675-.449 1.084-.449.41 0 .795.16 1.084.45.29.289.45.673.45 1.083 0 .41-.16.795-.45 1.084L6.767 5.4a1.52 1.52 0 0 1-1.084.45c-.41 0-.794-.16-1.084-.45a1.516 1.516 0 0 1-.327-.482.76.76 0 0 0-.498.222l-.439.44a2.667 2.667 0 0 0 4.234.621l1.65-1.65a2.667 2.667 0 0 0 0-3.77z" _fill="#fff"/><path pid="1" d="M5.701 7.75A3.42 3.42 0 0 1 4.6 7.567l-.85.849c-.289.29-.674.449-1.083.449-.41 0-.794-.16-1.084-.45a1.52 1.52 0 0 1-.45-1.083c0-.41.16-.795.45-1.085l1.65-1.65c.29-.289.674-.448 1.083-.448a1.532 1.532 0 0 1 1.411.931.758.758 0 0 0 .5-.222l.438-.44a2.667 2.667 0 0 0-4.234-.622l-1.65 1.65a2.666 2.666 0 1 0 3.77 3.772l1.486-1.486c-.11.01-.222.016-.335.016z" _fill="#fff"/>'
  }
})
