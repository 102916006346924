/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'smile-2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 4a8 8 0 1 1 0 16 8 8 0 0 1 0-16zm0 1.6a6.4 6.4 0 1 0 0 12.8 6.4 6.4 0 0 0 0-12.8zm3.12 8.36a.8.8 0 1 0-1.44-.72c-.4.8-.93 1.16-1.68 1.16-.75 0-1.28-.35-1.68-1.16a.8.8 0 1 0-1.44.72C9.55 15.29 10.62 16 12 16c1.38 0 2.45-.71 3.12-2.04zM10 8.8a1.2 1.2 0 1 1 0 2.4 1.2 1.2 0 0 1 0-2.4zm5.2 1.2a1.2 1.2 0 1 0-2.4 0 1.2 1.2 0 0 0 2.4 0z" _fill="#7F848B"/>'
  }
})
