<template lang="pug">
  .ui.warning-block(:class="{active: list.length > 0}")
    .item-warning(
      v-for="(item, i) in list",
      :key="i")
      ui-svg(name="warning")
      .message(v-html="item")
</template>

<script>
export default {
  props: {
    list: {
      require: false,
      default: () => [],
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
