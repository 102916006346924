<template lang="pug">
  .media.item.folder(
    :title="folder.name",
    :class="[{selected, 'block-context': blockContextMenu}, view]",
    @click="handleOpen"
    @contextmenu.prevent="handleContextMenu"
  )

    template(v-if="view == VIEWS.BLOCK")
      .body
        .controls
          ui-checkbox.circle-blue(:value="selected", @click.stop="$emit('select')")

        .preview
          FolderPreview(:folder="folder" size="large")

      .footer
        .progress(:style="{width: `${folder.percentDownload}%`}")
        .name
          span {{ folder.name }}

    template(v-else)
      .name-column(:style="{ width: columnSizes.length ? `${columnSizes[0].size}%` : false }")
        .check(@click.stop="$emit('select')")
          ui-checkbox.circle-blue(:value="selected")
        
        .preview
          FolderPreview(:folder="folder")

        .name
          span {{ folder.name }}

      .time.time-column(:style="{ width: columnSizes.length ? `${columnSizes[1].size}%` : false }")
        span(v-if="folder.createdAt") {{ folder.createdAt | moment("DD-MM-YYYY, HH:mm") }}
      
      .size-column(:style="{ width: columnSizes.length ? `${columnSizes[2].size}%` : false }")
        .size
          span
        
      .actions(
        :style="{ width: columnSizes.length ? `${columnSizes[3].size}%` : false }"
        @click.stop="handleContextMenu"
      )
        ui-svg.rotate-left.grey(name="dots")

      .progress(:style="{width: `${folder.percentDownload}%`}")
</template>

<script>
import { mapActions } from 'vuex';
import FolderPreview from '@/components/FolderPreview.vue';

const VIEWS = {
  BLOCK: 'block',
  LINE: 'line',
}

export default {
  components: {
    FolderPreview,
  },

  props: {
    folder: {
      type: Object,
      required: true,
    },

    selected: {
      type: Boolean,
      default: false,
    },

    view: {
      type: String,
      default: VIEWS.BLOCK,
    },

    blockContextMenu: {
      type: Boolean,
      default: false,
    },
    columnSizes: {
      type: Array,
      default () {
        return []
      }
    },
  },

  data() {
    return {
      VIEWS,
    }
  },

  methods: {
    handleOpen(e) {
      this.$emit('open')
    },

    handleContextMenu(e) {
      if (this.blockContextMenu) return

      let list = []

      if (this.folder.canEdit)
        list = [
          ...list,
          {
            placeholder: this.$t('mediaPanel.ctxMenu.rename'),
            callback: () => {
              this.openModal({
                name: 'RenameMedia',
                params: {
                  name: this.folder.name,
                  callback: newName =>
                    this.updateFolder({
                      folderId: this.folder.id,
                      name: newName,
                    }),
                },
              })
            },
          },

          {
            placeholder: this.$t('mediaPanel.ctxMenu.moveTo'),
            callback: () => {
              this.$emit('select', this.folder.id)
              this.$emit('move-to-folder')
            },
          },
        ]

      if (this.folder.canDelete)
        list = [
          ...list,
          {
            placeholder: this.$t('mediaPanel.ctxMenu.delete'),
            callback: () => {
              // HANDLE DELETE

              this.openModal({
                name: 'ApproveRemove',
                params: {
                  title: this.$t('mediaPanel.modalApproveDelete.title'),
                  description: this.$t(
                    'mediaPanel.modalApproveDelete.description'
                  ),
                  button: this.$t('mediaPanel.modalApproveDelete.button'),
                  callback: () => {
                    this.deleteFolder(this.folder.id).catch(error =>
                      this.$notify.error(this.$utils.response(error))
                    )
                  },
                },
              })
            },
          },
        ]

      if (list.length == 0) return

      this.openModal({
        name: 'ContextMenu',
        params: {
          // scroll: 'collision-list-media',
          el: e.target,
          position: 'bottom left',
          list,
        },
      })
    },

    ...mapActions('Folders', ['deleteFolder', 'updateFolder']),
  },
}
</script>
