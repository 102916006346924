/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sort': {
    width: 17,
    height: 18,
    viewBox: '0 0 17 18',
    data: '<path pid="0" d="M3.68.1h1.38l3.28 8H6.47l-.65-1.69H2.88l-.63 1.7H.4L3.68.1zm.66 2.32l-.92 2.5h1.84l-.92-2.5zM1.4 16.32l3.84-4.81h-3.7V9.95h5.73v1.56L3.5 16.32h3.9v1.63h-6v-1.63zm14.43-4.01h-1.7V1.01c0-.5-.4-.91-.87-.91h-.92c-.47 0-.86.41-.86.91v11.3h-1.7c-.35 0-.5.24-.31.54l3 4.88c.18.3.48.3.67 0l3-4.88c.18-.3.03-.54-.31-.54z" _fill="#34335C"/>'
  }
})
