/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'leave': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<g _fill="#FFF" fill-rule="evenodd"><path pid="0" d="M13.194 11.317c-.34 0-.637.29-.637.62v3.18H7.721V3.883L1.892 1.198h10.665V4.42c0 .33.297.62.637.62s.636-.29.636-.62V.62c0-.33-.296-.62-.636-.62H.636C.297 0 0 .29 0 .62v15.076c0 .33.297.62.636.62h1.329L7.72 19v-2.685h5.473c.34 0 .594-.289.636-.62v-3.758c0-.33-.296-.62-.636-.62"/><path pid="1" d="M15.684 7.096H10v2.809h5.684V12L19 8.479 15.684 5z"/></g>'
  }
})
