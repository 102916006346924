/* eslint-disable */
require('./add-user')
require('./archive')
require('./arrow-down-circle')
require('./arrow-down')
require('./arrow-right')
require('./arrow-up')
require('./arrow')
require('./arrows-up-down')
require('./attemdees')
require('./back')
require('./bg')
require('./blocks')
require('./bonnet')
require('./calculation')
require('./calendar-hour')
require('./calendar')
require('./calendar-2')
require('./camera')
require('./cancel-circle-o')
require('./cancel-circle')
require('./cancel')
require('./chats')
require('./check-white')
require('./check')
require('./checkbox-checked')
require('./checklist')
require('./clip')
require('./clipboard')
require('./clock-history')
require('./clock')
require('./cloud-upload')
require('./cog-circle')
require('./copy')
require('./cross')
require('./delete')
require('./deleted-item')
require('./description')
require('./document-example')
require('./document')
require('./documents')
require('./dots')
require('./download-light')
require('./download')
require('./drag-horizontal')
require('./dropbox')
require('./duplicate')
require('./edit-checklist')
require('./emoji')
require('./en')
require('./envelope')
require('./faq')
require('./file-secure')
require('./filter')
require('./folder-plus')
require('./folder')
require('./form-fields')
require('./form')
require('./fr')
require('./funnel')
require('./gear')
require('./gift')
require('./google-drive')
require('./google')
require('./help')
require('./import-file')
require('./import')
require('./invoice')
require('./kanban')
require('./label')
require('./leave')
require('./link')
require('./list-clock')
require('./list')
require('./location')
require('./lock')
require('./logout')
require('./mail')
require('./media')
require('./message-notification')
require('./message-pop-circle')
require('./message-pop')
require('./message')
require('./move-to-folder')
require('./mute')
require('./nl')
require('./one-drive')
require('./pdf-icon')
require('./pencil')
require('./people')
require('./phone')
require('./planning-calendar')
require('./pl')
require('./play')
require('./plus-bold')
require('./plus-thin')
require('./plus')
require('./projects-circle')
require('./projects')
require('./pt')
require('./radio-checked')
require('./questionnaire')
require('./registration-hours')
require('./reload')
require('./rename')
require('./resend')
require('./rocket')
require('./schedule')
require('./search')
require('./send-o')
require('./send')
require('./settings')
require('./shape')
require('./share')
require('./shared')
require('./simple-file')
require('./smile-2')
require('./smile')
require('./sort-block')
require('./sort-list')
require('./sort')
require('./spin')
require('./star')
require('./subject')
require('./template')
require('./tr')
require('./trash')
require('./triangle')
require('./triple-line')
require('./uk')
require('./unmute')
require('./upload-arrow')
require('./upload')
require('./user-circle')
require('./user-contacts')
require('./user-plus-fill')
require('./user-plus')
require('./user')
require('./video')
require('./warning')
require('./zoom-loupe')
require('./zoom-minus')
require('./zoom-plus')
