<template lang="pug">
  .ui.toggle(
    :class="{active: model}",
    @click="toggle")

    input(
      type="checkbox",
      v-model="model")
     
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  methods: {
    toggle() {
      this.model = !this.model;
    }
  },

  computed: {
    model: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  }
};

</script>

<style lang="sass" src="./styles.sass"></style>
