/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrows-up-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M11.2 9.38H4.81c-.72 0-1.08.83-.57 1.32l3.2 3.07c.3.3.82.3 1.14 0l3.18-3.07c.5-.48.16-1.32-.57-1.32h.01zm0-2.77H4.81c-.72 0-1.08-.83-.57-1.32l3.2-3.07a.85.85 0 011.14 0l3.18 3.07c.5.5.16 1.32-.57 1.32h.01z" _fill="#7A93A9"/>'
  }
})
