/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'label': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<defs><path pid="0" id="svgicon_label_a" d="M0 .52h16.48V17H0z"/></defs><g _fill="none" fill-rule="evenodd"><g><mask id="svgicon_label_b" _fill="#fff"><use xlink:href="#svgicon_label_a"/></mask><path pid="1" d="M4.407 1.78h1.585c.835 0 1.635.331 2.225.922l5.159 5.157.03.032c.668.667 1.159 1.163 1.453 1.533.294.37.362.568.362.694s-.069.324-.363.694c-.289.362-.772.851-1.42 1.5l-.004.002-.032.031-1.523 1.539a.595.595 0 0 0-.046.043c-.666.666-1.162 1.156-1.532 1.451-.369.294-.568.363-.694.363s-.324-.068-.694-.362c-.37-.294-.866-.785-1.532-1.452a.77.77 0 0 0-.076-.067L2.182 8.737a3.147 3.147 0 0 1-.923-2.225V4.927c0-.94.004-1.64.057-2.109.053-.469.145-.658.234-.747.088-.089.278-.182.747-.235.47-.053 1.168-.057 2.11-.057zm0-1.26C3.464.52 2.748.52 2.156.586c-.593.066-1.113.211-1.497.596-.384.385-.529.905-.595 1.496C-.002 3.27 0 3.986 0 4.928v1.584c0 1.168.465 2.29 1.291 3.116l5.19 5.19a.675.675 0 0 0 .079.068c.629.629 1.12 1.121 1.567 1.478.466.372.937.637 1.481.636.544 0 1.014-.265 1.48-.637.45-.36.942-.853 1.576-1.487a.595.595 0 0 0 .064-.061l1.529-1.542a.818.818 0 0 0 .04-.04c.666-.666 1.174-1.17 1.546-1.635.371-.466.636-.936.637-1.48 0-.543-.264-1.013-.636-1.479-.364-.457-.864-.954-1.511-1.601l-.036-.037-5.19-5.19A4.41 4.41 0 0 0 5.993.521H4.407z" _fill="#FFF" mask="url(#svgicon_label_b)"/></g><path pid="2" d="M4.407 3.778a1.26 1.26 0 1 1 0 2.519 1.26 1.26 0 0 1 0-2.52" _fill="#FFF"/></g>'
  }
})
