<template lang="pug">
  .calendar-date(
    :class="classes",
    @click="e => $emit('click', e)")
    | {{ day.format('D') }}
</template>

<script>
export default {
  props: {
    day: {
      require: true,
    },

    selectedDate: {
      require: true,
    },

    dateView: {
      require: true,
    },
  },

  computed: {
    classes() {
      return {
        today: this.day.isSame(this.$moment(), 'day'),
        selected: this.day.isSame(this.selectedDate, 'day'),
        grey: !this.dateView.isSame(this.day, 'month'),
      }
    },
  },
}
</script>
