/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'triangle': {
    width: 6,
    height: 4,
    viewBox: '0 0 6 4',
    data: '<path pid="0" d="M2.565.197L.135 3.07A.565.565 0 0 0 .57 4H5.43c.48 0 .745-.559.436-.929L3.437.203a.574.574 0 0 0-.872-.006z" _fill="#FF805D" fill-rule="nonzero"/>'
  }
})
