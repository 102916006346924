/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mail': {
    width: 24,
    height: 17,
    viewBox: '0 0 24 17',
    data: '<path pid="0" d="M2.512 0A2.525 2.525 0 0 0 0 2.512v11.162a2.525 2.525 0 0 0 2.512 2.512h18.976A2.525 2.525 0 0 0 24 13.674V2.512A2.525 2.525 0 0 0 21.488 0H2.512zm.209 1.674h18.558L12.55 9.28c-.339.294-.742.295-1.08 0L2.72 1.674zM1.674 2.983l5.922 5.145-5.922 5.529V2.983zm20.652.008v10.675l-5.904-5.538 5.904-5.137zm-7.178 6.236l5.625 5.285H3.218l5.651-5.276 1.5 1.3a2.512 2.512 0 0 0 3.28 0l1.5-1.31z" _fill="#34335C" fill-rule="evenodd"/>'
  }
})
