<template lang="pug">
  .ui.button-c(:class="rootClasses" @click="onClick")
    slot
</template>

<script>
export default {
  props: {
    // TODO: define other button kinds
    /**
     * The kind of the button
     * @values bali-hai, bali-hai-o, green, border-input, dodger-light-blue, o-white-grey
     */
    kind: {
      type: String,
      default: undefined,
    },

    // TODO: define other button sizes
    /**
     * The size of the button
     * @values wide, big, medium
     */
    size: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    rootClasses() {
      const { kind, size } = this;
      return {
        [kind]: Boolean(kind),
        [size]: Boolean(size),
      };
    },
  },

  methods: {
    onClick(event) {
      this.$emit('click', event)
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
