<template lang="pug">
  .ui.input.formula-input(:class="{ error }" @click="e => $emit('click', e)")
    input(
      ref="input",
      type="text",
      :value="model",
      :tabindex="tabindex",
      :name="name",
      :placeholder="placeholder",
      :readonly="readonly",
      :disabled="deactivated",
      @paste.prevent="handlePaste"
      @input="handleInput"
      @focus="focused($event)",
      @keyup.enter="e => $emit('enter', e)",
      @blur="lostFocus($event)"
    )

    .formula-input__tip-wrapper(
      v-if="isTipShown && formula && amount"
      v-drop="getPopupOptions()"
    )
      .formula-input__tip {{ amount ? '€' + $utils.formatNumToStr(amount) : '' }}

    slot
</template>

<script>

import { getClipboardData } from '@/helpers/clipboard'
import { extractNumberValue, extractNumberValueFromFormula, checkNumberValidity } from '@/helpers/numbers'
import { extractFormulaValue, checkFormulaValidity } from '@/helpers/formula'

const checkFormula = value => /^=/.test(value);
const formatNumber = number => `${number}`.replace(".", ",");

export default {
  props: {
    tabindex: {
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    amount: {
      require: false,
      default: null
    },
    formula: {
      require: false,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * ATTENTION: please, use this prop instead of "disabled"
     *
     * TODO: Replace "deactivated" prop by "disabled"
     * Needs to check all usages of this component and make sure that nothings
     * broken
     */
    deactivated: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      require: false,
      default: null
    },
    error: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: "",
      isTipShown: false
    }
  },

  computed: {
    isFormulaMode() {
      return checkFormula(this.model);
    },
  },

  methods: {
    getPopupOptions() {
      const { input } = this.$refs;

      if (!input) return {};

      return {
        flip: true,
        placement: 'top',
        strategy: 'absolute',
        referenceElement: input,
      };
    },

    focused(e) {
      this.$emit('focus', e);
      this.isTipShown = true;
    },

    lostFocus(e) {
      this.$emit('blur', e)
      this.isTipShown = false;
    },

    handleInput(event) {
      const { value } = event.target;
      const oldValue = this.model;

      const isFormulaValue = checkFormula(value);
      const isSwitchFromFormula = this.isFormulaMode;

      let newValue = value;

      if (isFormulaValue) {
        newValue = extractFormulaValue(value);
      } else if (isSwitchFromFormula) {
        newValue = extractNumberValueFromFormula(newValue);
        if (newValue) newValue = formatNumber(newValue);
      } else {
        // the old logic from `./price.vue`
        const countCommas = (newValue ? newValue.match(/\,/g) || [] : []).length;
        const compInt = this.$utils.formatStrToNum(newValue);

        if (newValue && /\./g.test(newValue)) {
          newValue = countCommas == 0 ? formatNumber(newValue) : oldValue;
        } else if (Number.isNaN(compInt)) {
          newValue = oldValue;
        }
      }

      this.model = newValue || '';

      // update the input value manually
      event.target.value = this.model;

      if (isFormulaValue) {
        this.$emit('update:formula', this.model);
      } else {
        this.$emit('update:amount', this.$utils.formatStrToNum(this.model));
      }
    },

    handlePaste(event) {
      const currentValue = `${this.model ?? ''}`;
      const { input } = this.$refs;
      const { selectionStart, selectionEnd } = input;

      const { isFormulaMode } = this;

      const text = getClipboardData(event, "text");
      const fullText = currentValue.substring(0, selectionStart) + text + currentValue.substring(selectionEnd);
      const isFormulaValue = checkFormula(text);
      const isFormulaUpdate = isFormulaMode || (isFormulaValue && !currentValue);

      let newValue = '';
      let isValid = true;

      if (isFormulaUpdate) {
        newValue = extractFormulaValue(fullText);
        isValid = newValue !== null && checkFormulaValidity(newValue);
      } else {
        newValue = extractNumberValue(fullText);
        isValid = newValue !== null && checkNumberValidity(newValue);
      }

      if (!isValid) return;

      const oldChars = currentValue.length - selectionEnd + selectionStart;
      const newChars = `${newValue}`.length - oldChars;

      if (isFormulaUpdate) {
        this.model = newValue;
        this.$emit('update:formula', newValue);
      } else {
        this.model = formatNumber(newValue);
        this.$emit('update:amount', newValue);
      }

      this.$nextTick().then(() => {
        const caretPosition = selectionStart + newChars;
        input.setSelectionRange(caretPosition, caretPosition);
      });
    },

    focus() {
      this.$refs.input.focus();
    },

    verify(value) {
      return checkFormulaValidity(value) || checkNumberValidity(value);
    }
  },

  created() {
    this.$watch(
      () => [this.formula, this.amount],
      ([formula, amount]) => {
        if (formula) {
          const string = extractFormulaValue(formula);

          if (string !== formula) {
            this.model = string;
            this.$emit('update:formula', string);
          } else if (!this.model && string) {
            this.model = string;
          } 
        } else {
          const number = this.$utils.formatStrToNum(this.model);

          if (number && amount && number !== amount) {
            this.model = this.$utils.formatNumToStr(amount);
            this.$emit('update:amount', number);
          } else if (!this.model && amount) {
            this.model = this.$utils.formatNumToStr(amount);
          }
        }
      },
      { immediate: true },
    );
  },

  mounted() {
    if(this.autofocus) {
      this.focus();
    }
  }
}
</script>

<style lang="scss" scoped>
.formula-input__tip-wrapper {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  padding: 0.2em 0;
  font-size: 0.8em;
}

.formula-input__tip {
  border-radius: 0.2em;
  background-color: $planning-tooltip-background;
  padding: 0.2em 0.4em;
}
</style>