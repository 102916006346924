/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'google-drive': {
    width: 18,
    height: 15,
    viewBox: '0 0 18 15',
    data: '<path pid="0" d="M12.264 0H6.306l5.716 9.407h5.958L12.264 0zM17.981 10.066H6.625l-2.95 4.931h11.357l2.95-4.93zM8.753 5.332L5.73.387 0 9.804l3.024 4.946 5.73-9.418z" _fill="#34335C"/>'
  }
})
