import Provider from '@provider'
import {
  ChecklistItemModel,
  CommentModel,
  ChecklistItemLogModel,
  UserModel,
  LabelModel,
} from '@/models'
import { convertZonedDateToBackendDate } from '@/helpers/dates/index.js';

export default {
  addChecklistItemMembers: ({ checklistId, checklistItemId, synchronize, userIds }) => {
    const data = {
      synchronize: synchronize,
    };

    if (userIds.length === 1) {
      data.user_id = userIds[0];
    } else {
      data.user_ids = userIds;
    }

    return Provider.api.post(`/checklists/${checklistId}/items/${checklistItemId}/members`, data);
  },

  deleteChecklistItemMembers: ({ checklistId, checklistItemId, synchronize, userIds }) => {
    const params = {
      synchronize: synchronize
    }

    if (userIds.length === 1) {
      params.user_id = userIds[0];
    } else {
      params.user_ids = userIds
    }

    return Provider.api
      .delete(`/checklists/${checklistId}/items/${checklistItemId}/members`, {
        params,
      })
      .then(response => response.data.map(e => new UserModel(e)));
  },

  getChecklistItemComments: ({
    offset,
    limit,
    checklistId,
    checklistItemId,
  }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/checklists/${checklistId}/items/${checklistItemId}/comments`, {
          params: {
            offset,
            limit,
          },
        })
        .then(response => resolve(response.data.map(e => new CommentModel(e))))
        .catch(reject)
    })
  },

  getChecklistItemLogs: ({ offset, limit, checklistId, checklistItemId }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/checklists/${checklistId}/items/${checklistItemId}/logs`, {
          params: {
            offset,
            limit,
          },
        })
        .then(response =>
          resolve(
            response.data.map(
              e =>
                new ChecklistItemLogModel({
                  ...e,
                  checklist_item_id: checklistItemId,
                })
            )
          )
        )
        .catch(reject)
    })
  },

  addChecklistItem: ({ checklistId, name, startAt, endAt, userIds, synchronize, emails, location, description }) => {
    const start_at = convertZonedDateToBackendDate(startAt);
    const end_at = convertZonedDateToBackendDate(endAt);

    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/checklists/${checklistId}/items`, {
          name,
          start_at,
          end_at,
          user_ids: userIds,
          synchronize: synchronize,
          emails: emails,
          location: location,
          description: description
        })
        .then(response => resolve(new ChecklistItemModel(response.data)))
        .catch(reject)
    })
  },

  getChecklistItems: ({ checklistId }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/checklists/${checklistId}/items`)
        .then(response =>
          resolve(response.data.map(e => new ChecklistItemModel(e)))
        )
        .catch(reject)
    })
  },

  getAllChecklistItems: ({
    projectIds,
    projectStatusIds,
    labelsIds,
    dateFrom,
    dateTill,
    userIds,
  }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .post(`/checklists/items`, {
          project_ids: projectIds,
          company_project_status_ids: projectStatusIds,
          label_ids: labelsIds,
          date_from: dateFrom,
          date_till: dateTill,
          user_ids: userIds,
        })
        .then(response =>
          resolve(response.data.map(e => new ChecklistItemModel(e)))
        )
        .catch(reject)
    })
  },

  getChecklistItem: ({ checklistId, itemId }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .get(`/checklists/${checklistId}/items/${itemId}`)
        .then(response => resolve(new ChecklistItemModel(response.data)))
        .catch(reject)
    })
  },

  updateChecklistItem: ({
    checklistId,
    newChecklistId,
    itemId,
    checked,
    name,
    position,
    startAt,
    endAt,
    eventId,
    synchronize,
    emails,
    location,
    description,
  }) => {
    const start_at = convertZonedDateToBackendDate(startAt);
    const end_at = convertZonedDateToBackendDate(endAt);

    return new Promise((resolve, reject) => {
      Provider.api
        .put(`/checklists/${checklistId}/items/${itemId}`, {
          checked,
          name,
          position,
          start_at,
          end_at,
          checklist_id: newChecklistId,
          eventId,
          synchronize,
          emails,
          location,
          description,
        })
        .then(response => resolve(new ChecklistItemModel(response.data)))
        .catch(reject)
    })
  },

  deleteChecklistItem: ({ checklistId, itemId }) => {
    return new Promise((resolve, reject) => {
      Provider.api
        .delete(`/checklists/${checklistId}/items/${itemId}`)
        .then(resolve)
        .catch(reject)
    })
  },

  addChecklistItemLabel: ({ checklistId, checklistItemId, labelId }) => {
    return Provider.api
      .post(`/checklists/${checklistId}/items/${checklistItemId}/labels`, {
        label_id: labelId,
      })
      .then(response => response.data.map(e => new LabelModel(e)));
  },

  deleteChecklistItemLabel: ({ checklistId, checklistItemId, labelId }) => {
    return Provider.api
      .delete(`/checklists/${checklistId}/items/${checklistItemId}/labels`, {
        params: {
          label_id: labelId,
        },
      })
      .then(response => response.data.map(e => new LabelModel(e)));
  },

  cloneChecklistItems: ({ checklistId, checklistItemIds }) => {
    return Provider.api
      .post(`/checklists/${checklistId}/items/clone`, {
        checklistitem_ids: checklistItemIds,
      })
      .then(response => response.data.map(e => new ChecklistItemModel(e)));
  },
}
