/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pl': {
    width: 16,
    height: 16,
    viewBox: '0 0 640 480',
    data: '<g fill-rule="evenodd"><path pid="0" fill="#fff" d="M640 480H0V0h640z"/><path pid="1" fill="#dc143c" d="M640 480H0V240h640z"/></g>'
  }
})
