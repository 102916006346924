<template lang="pug">
  .ui.users-counter(v-if="count > 0")
    span +{{ count }}
</template>

<script>
export default {
  props: {
    count: {
      default: null,
      type: Number,
      require: false
    }
  }
};
</script>

<style lang="sass" src="./styles.sass">
