<template lang="pug">
.checklists-item-wrap(
  v-dragg-item='getDraggChecklistItem',
)
  RoundedCheckbox(
    class="checklists-item-wrap__checkbox"
    :checked="checked"
    @click="onCheckboxClick"
  )
  ChecklistItem(
    class="checklists-item-wrap__item"
    :checklist="checklist"
    :item="item"
  )
</template>

<script>
import ChecklistItem from './ChecklistItem';
import RoundedCheckbox from '@/ui-components/RoundedCheckbox'

export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    item: {
      required: true,
    },
    checklist: {
      required: true,
    },
  },

  components: {
    ChecklistItem,
    RoundedCheckbox,
  },

  computed: {
    getDraggChecklistItem() {
      return JSON.stringify({
        id: this.item.id,
        parentId: this.checklist.id,
        namespace: 'dragg-checklist-item',
        params: {
          preventClick: true,
          // stickyParent: true,
        },
      })
    },
  },

  methods: {
    onCheckboxClick(event) {
      this.$emit('checkbox:click', event);
    },
  },
}
</script>
