<template lang="pug">
  .zoom.zoom-c.month(ref="zoomList")
    .item(
      v-for="(week, i) in weeks",
      ref="zoomItems"
      :key="i")

      .title(@click="handleClickOnWeek(week.w)")
        span {{ getTitle(week) }}
      
      .list
        .item(
          v-for="(day, j) in week.days",
          :key="j",
          @click="handleClickOnDay(day)",
          :style="getDayStyle(day, 3, 'em')",
          :class="{ active: day.isSame($utils.$time.now, 'day'), holiday: !day.isBusinessDay()}")

          span
            | {{ day | moment('dd') }}
            br
            | {{ day | moment('DD') }}
</template>

<script>
import ZoomMixin from './mixin'
import GridMixin from '../mixins/grid'

export default {
  mixins: [ZoomMixin, GridMixin],

  data() {
    // the short month
    const shortestPeriodDate = '2022-02-01';
    const delimiter = 'month';
    const startAt = this.$moment(shortestPeriodDate, 'YYYY-MM-DD').startOf(delimiter);
    const endAt = this.$moment(shortestPeriodDate, 'YYYY-MM-DD').endOf(delimiter);

    return {
      view: {
        startAt,
        endAt,

        periods: 1,

        delimiter: 'month',

        zoomHover: val => {
          return {
            startAt: val.clone().startOf('day'),
            endAt: val.clone().endOf('day'),
          }
        },

        roundGridStartAt: momentDate => {
          return momentDate.clone().startOf('day');
        },

        roundGridEndAt: momentDate => {
          return momentDate.clone().endOf('day');
        },

        getGridStartAt: endAt => {
          const startAt = endAt.clone().subtract(1, 'days');
          return startAt.hours() === 23 && startAt.minutes() === 59
            ? startAt.add(1, 'minutes')
            : startAt;
        },

        getGridEndAt: startAt => {
          const endAt = startAt.clone().add(1, 'days');
          return endAt.hours() === 0 && endAt.minutes() === 0
            ? endAt.subtract(1, 'minutes')
            : endAt;
        },
      },
    }
  },

  computed: {
    weeks() {
      return Array.from(
        this.$moment
          .range(
            this.periodsRange.startAt.clone(),
            this.periodsRange.endAt.clone()
          )
          .by('weeks')
      ).map(w => {
        return {
          w,
          days: Array.from(
            this.$moment
              .range(w.clone().startOf('week'), w.clone().endOf('week'))
              .by('days')
          ),
        }
      })
    },

    items() {
      return []
        .concat(...this.weeks.map(e => e.days))
        .sort((a, b) => a.valueOf() - b.valueOf())
    },

    firstItem() {
      return this.items[0]?.startOf('day');
    },

    lastItem() {
      return this.items[this.items.length - 1]?.endOf('day');
    },
  },

  methods: {
    getTitle(week) {
      let title = this.$t('planningComponent.week') + week.w.format(' w')
      if (week.days.find(e => e.date() == 15)) title += week.w.format(' - MMM')

      return title
    },
  },
}
</script>
