<template lang="pug">
  .ui.avatar(:style="style" :class="rootClasses")
    .img-wrapper(:style="{backgroundImage: (this.user && this.user.avatar) ? `url(${this.user.avatar})` : null}")
    span(:style="initialsStyle") {{ initials }}
</template>

<script>
export default {
  name: 'UiAvatar',

  props: {
    user: {
      require: true
    },
    size: {
      type: Number,
      default: 3,
      require: false
    },

    /**
     * The size of the avatar border
     * @values small
     */
    borderSize: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    rootClasses() {
      const { borderSize } = this;
      return {
        [`avatar--border-size_${borderSize}`]: borderSize,
      };
    },

    style() {
      return {
        width: `${this.size}em`,
        height: `${this.size}em`,
        minWidth: `${this.size}em`,
        minHeight: `${this.size}em`
      };
    },

    initials() {
      const { firstName, lastName } = this.user || {};
      const initials = [firstName, lastName]
        .filter((name) => Boolean(name?.trim()))
        .map(name => name.trim().charAt(0).toUpperCase());

      return initials.length > 1
        ? initials.map((initial) => `${initial}.`).join('')
        : initials.join('');
    },

    initialsStyle() {
      const fontSize = Math.round(this.size / 2.8 * 100) / 100;
      return {
        fontSize: `${fontSize}em`,
      };
    }
  }
};
</script>

<style lang="sass" src="./styles.sass">
