/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'kanban': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<g clip-path="url(#a)" fill-rule="evenodd" clip-rule="evenodd" _fill="#7F848B"><path pid="0" d="M10.45 1.9c0-1.05.85-1.9 1.9-1.9h4.75c1.05 0 1.9.85 1.9 1.9v11.4a1.9 1.9 0 01-1.9 1.9h-4.75a1.9 1.9 0 01-1.9-1.9V1.9zm6.65 11.4h-4.75V1.9h4.75v11.4zM0 1.9C0 .85.85 0 1.9 0h4.75c1.05 0 1.9.85 1.9 1.9v15.2a1.9 1.9 0 01-1.9 1.9H1.9A1.9 1.9 0 010 17.1V1.9zm6.65 15.2H1.9V1.9h4.75v15.2z"/></g><defs><clipPath id="a"><path pid="1" _fill="#fff" d="M0 0h19v19H0z"/></clipPath></defs>'
  }
})
