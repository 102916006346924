import VueI18n from '@/i18n'

function searchError(obj, fn) {
  if (obj instanceof Object) {
    for (let key in obj) {
      searchError(obj[key], fn)
    }
  } else if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      searchError(obj[i], fn)
    }
  } else if (Number(obj)) {
    const l = VueI18n.t('responses.' + obj)

    if (l) {
      fn(l)
    }
  }
}

function genUUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

function getExtention(name) {
  return /[.]/.exec(name) ? /[^.]+$/.exec(name)[0] : '?'
}

function createForm(data) {
  let formData = new FormData()

  for (let key in data) {
    if (Array.isArray(data[key])) {
      data[key].forEach(dataItem => {
        formData.append(`${key}[]`, dataItem);
      });
    } else if (data[key]) {
      formData.append(key, data[key])
    }
  }

  return formData
}

function getEventCoordinate(event, key) {
  const isTouchEvent = 'changedTouches' in event;

  if (isTouchEvent) {
    const firstTouch = event.changedTouches[0];
    return firstTouch[key];
  }

  return event[key];
}

function getEventPageX(event) {
  return getEventCoordinate(event, 'pageX');
}

function getEventPageY(event) {
  return getEventCoordinate(event, 'pageY');
}

function checkMinimalViewportAspectRatio(ratio) {
  const { innerWidth, innerHeight } = window;
  return innerWidth / innerHeight >= ratio;
}

function checkMobileDevice(mobileDetect) {
  const isMobile = mobileDetect.phone();
  const isTablet = mobileDetect.tablet();
  const minimalAspectRatio = 3 / 2; // 3:2
  return isMobile || isTablet && !checkMinimalViewportAspectRatio(minimalAspectRatio);
}

function isApplePlatform() {
  return /Mac|iPod|iPhone|iPad/.test(window.navigator.platform);
}

export default {
  searchError,
  genUUID,
  getExtention,
  createForm,
  getEventPageX,
  getEventPageY,
  checkMobileDevice,
  isApplePlatform,
}
