/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bonnet': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.35 12.28L3.66 8.35l7.69-3.48 7.69 3.48-7.69 3.93zm5.91 3.27l-4.98 3.36c-.59.39-1.27.39-1.86 0l-4.98-3.36v-4.38l5.66 2.91a.5.5 0 00.5 0l5.66-2.9v4.37zm3.99-8.13l-9.67-4.38a.6.6 0 00-.46 0L1.45 7.42c-.27.12-.45.45-.45.84 0 .4.16.72.43.87l2.64 1.35v5.64c0 .33.16.63.39.78l5.34 3.63c.98.63 2.12.63 3.1 0l5.34-3.63a.94.94 0 00.39-.78v-5.64l1.48-.78v5.25c0 .51.3.9.68.9.36.03.66-.36.68-.84V8.95c.14-.18.23-.42.23-.69 0-.39-.18-.72-.45-.84z" _fill="#7F848B"/>'
  }
})
