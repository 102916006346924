import Vue from 'vue'
import CompanyProjectStatusProvider from '@provider/companyProjectStatus'

import * as TYPES from '@/store/types'

// initial state
const state = {
  statuses: [],
  loaded: true,
}

// getters
const getters = {
  projectStatus: state => search => {
    return state.statuses.find(status => {
      return status.id === search
    })
  },

  findProjectStatusById: state =>
    state.statuses.reduce((out, item) => {
      out[item.id] = item
      return out
    }, {}),

  getterProjectStatus: state => ({ id }) => {
    return state.statuses.find(status => {
      return status.id === id
    })
  },

  statuses: state => state.statuses,
  loadedStatuses: state => state.loaded,
}

// actions
const actions = {
  getStatuses({ commit }) {
    return new Promise((resolve, reject) => {
      CompanyProjectStatusProvider.getStatuses()
        .then(statuses => {
          commit('INIT_STATUSES', statuses)
          commit('SWITCH_LOADED', false)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  addStatus({ commit }, form) {
    return new Promise((resolve, reject) => {
      CompanyProjectStatusProvider.addStatus(form)
        .then(newStatus => {
          commit(TYPES.COMPANYPROJECTSTATUS.INIT_STATUSES, newStatus)
          resolve(newStatus)
        })
        .catch(error => reject(error))
    })
  },

  deleteStatus({ commit }, id) {
    return new Promise((resolve, reject) => {
      CompanyProjectStatusProvider.deleteStatus(id)
        .then(newStatus => {
          commit('DELETE_STATUS', id)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  updateStatus({ commit }, form) {
    return new Promise((resolve, reject) => {
      CompanyProjectStatusProvider.updateStatus(form)
        .then(status => {
          commit(TYPES.COMPANYPROJECTSTATUS.INIT_STATUSES, status)
          resolve(status)
        })
        .catch(error => reject(error))
    })
  },

  updatePositionStatus({ state, commit }, { id, position }) {
    return new Promise((resolve, reject) => {
      let status = state.statuses.find(e => e.id == id),
        save = { ...status }

      commit(TYPES.COMPANYPROJECTSTATUS.UPDATE_POSITION, { id, position })

      CompanyProjectStatusProvider.updateStatus({
        id,
        position,
      })
        .then(status => {
          commit(TYPES.COMPANYPROJECTSTATUS.INIT_STATUSES, status)
          resolve(status)
        })
        .catch(error => {
          commit(TYPES.COMPANYPROJECTSTATUS.INIT_STATUSES, save)
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  [TYPES.COMPANYPROJECTSTATUS.INIT_STATUSES](state, data) {
    let list = Array.isArray(data) ? data : [data],
      ids = list.map(e => e.id)

    state.statuses = [
      ...state.statuses.filter(e => !ids.includes(e.id)),
      ...list.filter(e => !e.isDeleted),
    ]
  },

  DELETE_STATUS(state, id) {
    const index = state.statuses.findIndex(e => e.id === id)
    Vue.delete(state.statuses, index)
  },

  SWITCH_LOADED(state, status = false) {
    state.loaded = status
  },

  [TYPES.COMPANYPROJECTSTATUS.UPDATE_POSITION](state, { id, position }) {
    let status = state.statuses.find(e => e.id == id)
    if (status < 0) return

    let cards = [{ id: 0, position: 0 }, ...state.statuses]
        .filter(e => e.id != id)
        .sort((a, b) => a.position - b.position),
      nextCard = cards[position],
      prevCard = cards[position - 1],
      newPos = 1

    if (!prevCard && nextCard) {
      newPos = nextCard.position - 0.0000005
    } else if (!nextCard && prevCard) {
      newPos = prevCard.position + 1
    } else if (prevCard && nextCard) {
      newPos = (prevCard.position + nextCard.position) / 2
    }

    status.position = newPos
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
