/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-circle': {
    width: 29,
    height: 30,
    viewBox: '0 0 29 30',
    data: '<defs><path pid="0" id="svgicon_user-circle_a" d="M0 0h13.612v14.082H0z"/></defs><g _fill="none" fill-rule="evenodd"><path pid="1" d="M14.796 29.694c7.681 0 13.908-6.442 13.908-14.388S22.477.918 14.796.918C7.115.918.888 7.36.888 15.306s6.227 14.388 13.908 14.388zm0-1.837c-6.7 0-12.133-5.62-12.133-12.55 0-6.933 5.432-12.552 12.133-12.552 6.7 0 12.133 5.62 12.133 12.551 0 6.932-5.432 12.551-12.133 12.551z" fill-opacity=".4" _fill="#FF805D" fill-rule="nonzero"/><g _fill="#FF805D"><path pid="2" d="M14.796 9.009c1.407 0 2.536 1.168 2.536 2.624 0 1.455-1.13 2.624-2.536 2.624-1.407 0-2.537-1.17-2.537-2.624 0-1.456 1.13-2.624 2.537-2.624m0 6.297c1.955 0 3.55-1.65 3.55-3.673s-1.595-3.674-3.55-3.674c-1.955 0-3.551 1.651-3.551 3.674 0 2.022 1.596 3.673 3.55 3.673M16.563 20.939H9.317c.008-.401.004-.823.06-1.133.084-.46.225-.796.517-1.096.585-.6 1.962-1.077 4.902-1.077h.002c.214 0 .417.003.614.008l.24.008c.106.003.209.008.31.013.099.005.199.01.294.017l.148.011c1.858.144 2.828.543 3.294 1.02.292.3.433.636.516 1.096.056.31.052.732.061 1.133h-3.712zm4.634-1.347c-.11-.612-.353-1.213-.816-1.688-.847-.869-2.28-1.295-4.829-1.362l-.055-.002c-.226-.006-.457-.01-.7-.01h-.001c-.244 0-.476.004-.701.01l-.056.002a19.698 19.698 0 0 0-1.31.081c-.17.017-.332.037-.49.058l-.02.002c-1.447.195-2.39.588-3.008 1.22-.464.476-.706 1.077-.817 1.689-.11.61-.108 1.244-.108 1.898 0 .305.224.55.5.55h12.019c.277 0 .501-.245.501-.55 0-.654.002-1.287-.109-1.898z"/></g></g>'
  }
})
