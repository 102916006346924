/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'invoice': {
    width: 23,
    height: 27,
    viewBox: '0 0 23 27',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.888 27H2.083A2.09 2.09 0 0 1 .61 26.39 2.072 2.072 0 0 1 0 24.925V2.075C0 1.524.22.996.61.607A2.09 2.09 0 0 1 2.084 0h18.805c.552 0 1.082.22 1.472.608.391.389.61.916.611 1.466v22.851c0 .55-.22 1.078-.61 1.466a2.09 2.09 0 0 1-1.473.609zM2.083 1.112a.961.961 0 0 0-.966.962v22.85a.97.97 0 0 0 .966.962h18.805a.962.962 0 0 0 .966-.962V2.075a.961.961 0 0 0-.966-.962H2.083zm7.073 21.475c.88.624 1.934.96 3.015.962a5.196 5.196 0 0 0 4-1.87.555.555 0 0 0-.48-.91.56.56 0 0 0-.378.198 4.092 4.092 0 0 1-6.854-.903h4.677a.557.557 0 1 0 0-1.113H8.13a4.074 4.074 0 0 1 0-1.16h5.005a.557.557 0 1 0 0-1.113H8.46a4.074 4.074 0 0 1 1.986-1.992 4.099 4.099 0 0 1 5.102 1.398.56.56 0 0 0 .776.146.555.555 0 0 0 .147-.773 5.202 5.202 0 0 0-3.082-2.12 5.221 5.221 0 0 0-3.713.492 5.185 5.185 0 0 0-2.419 2.849H5.725a.557.557 0 1 0 0 1.112h1.278a5.225 5.225 0 0 0 0 1.161H5.725a.557.557 0 1 0 0 1.113h1.531a5.19 5.19 0 0 0 1.9 2.523zm4.31-14.406H5.212a.558.558 0 0 1-.559-.556c0-.307.25-.556.559-.556h8.256c.308 0 .558.249.558.556 0 .307-.25.556-.558.556zM5.212 5.335h12.825a.557.557 0 1 0 0-1.113H5.21a.558.558 0 0 0-.558.557c0 .307.25.556.558.556z" _fill="#34335C"/>'
  }
})
