<template lang="pug">
  .group-planning(:style="style")
</template>

<script>
export default {
  inject: ['planningScrollbar'],

  computed: {
    style() {
      return {
        width: this.planningScrollbar.width + 'px',
      }
    },
  },
}
</script>
