/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus-bold': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M6 0c-.685 0-1.241.556-1.241 1.241V4.76H1.24a1.242 1.242 0 0 0 0 2.482H4.76v3.518a1.242 1.242 0 0 0 2.482 0V7.24h3.518a1.242 1.242 0 0 0 0-2.482H7.24V1.24C7.241.556 6.685 0 6 0z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
