/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-hour': {
    width: 30,
    height: 29,
    viewBox: '0 0 30 29',
    data: '<path pid="0" d="M4.095 25.342h11.471c1.605 2.673 4.744 4.085 7.865 3.54 3.121-.546 5.564-2.934 6.121-5.985.558-3.05-.888-6.118-3.623-7.686V6.943c-.003-2.132-1.716-3.888-3.895-3.992v-.129C22.033 1.263 20.74 0 19.144 0c-1.595 0-2.89 1.263-2.89 2.822v.118H9.676v-.118C9.675 1.263 8.38 0 6.787 0 5.19 0 3.896 1.263 3.895 2.822v.128C1.946 3.047.334 4.473.045 6.361A3.834 3.834 0 0 0 0 6.95v14.39c.003 2.21 1.834 4 4.095 4.002zm23.597-3.749c0 3.021-2.506 5.47-5.597 5.47-3.091 0-5.597-2.449-5.597-5.47 0-3.022 2.506-5.471 5.597-5.471 3.09.003 5.594 2.45 5.597 5.471zm-5.597-7.404c-2.337 0-4.542 1.054-5.977 2.856a7.275 7.275 0 0 0-1.367 6.364H4.095c-1.169-.001-2.116-.927-2.117-2.069V11.128h21.973v3.286a7.728 7.728 0 0 0-1.856-.225zM18.23 2.822a.902.902 0 0 1 .913-.89c.503 0 .911.398.912.89v2.172a.902.902 0 0 1-.912.89.902.902 0 0 1-.913-.89V2.822zm-12.357 0c0-.492.408-.892.912-.892s.913.4.913.892v2.172a.902.902 0 0 1-.913.891.902.902 0 0 1-.912-.891V2.822zm16.22 14.288c.546 0 .99.433.99.967v2.549h2.607c.546 0 .989.433.989.966a.978.978 0 0 1-.99.967h-3.596a.978.978 0 0 1-.989-.967v-3.515c0-.534.443-.967.99-.967z" _fill="#34335C" fill-rule="nonzero"/>'
  }
})
