/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'faq': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.29 19.86c0 .4-.32.71-.72.71H6.14a.71.71 0 01-.71-.71V8.3c0-.1.02-.2.06-.3a.58.58 0 01.15-.21L9 4.44V6.3c0 .39-.32.71-.71.71H6.86v1.43h1.43c1.18 0 2.14-.96 2.14-2.14V3.43h7.14c.4 0 .72.32.72.71v15.72zM17.57 2h-7.26a2.14 2.14 0 00-1.52.63L4.63 6.79A2.14 2.14 0 004 8.3v11.55C4 21.04 4.96 22 6.14 22h11.43c1.18 0 2.14-.96 2.14-2.14V4.14c0-1.18-.96-2.14-2.14-2.14z" _fill="#7F848B"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M6.86 11.29h10V9.86h-10v1.43zm0 2.85h10v-1.43h-10v1.43zm0 2.86h10v-1.43h-10V17z" _fill="#7F848B"/>'
  }
})
