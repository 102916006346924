/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right': {
    width: 7,
    height: 12,
    viewBox: '0 0 7 12',
    data: '<defs><path pid="0" d="M3.599 8.996a1.05 1.05 0 0 0 .585-.254L8.606 4.9a1.08 1.08 0 0 0 .141-1.527 1.045 1.045 0 0 0-1.51-.093L3.5 6.528-.237 3.28a1.045 1.045 0 0 0-1.51.093A1.08 1.08 0 0 0-1.606 4.9l4.422 3.842c.216.189.499.28.783.254z" id="svgicon_arrow-right_a"/></defs><use _fill="#FFF" transform="matrix(0 1 1 0 -2.5 2.5)" xlink:href="#svgicon_arrow-right_a" fill-rule="evenodd"/>'
  }
})
