/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'registration-hours': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<g _fill="#FF805D" fill-rule="evenodd"><path pid="0" d="M14.318 29c7.797 0 14.117-6.442 14.117-14.388S22.115.224 14.318.224C6.522.224.201 6.666.201 14.612S6.521 29 14.318 29zm0-1.837c-6.801 0-12.314-5.619-12.314-12.55C2.004 7.68 7.517 2.06 14.318 2.06s12.315 5.62 12.315 12.551c0 6.932-5.514 12.551-12.315 12.551z" fill-opacity=".4" fill-rule="nonzero"/><path pid="1" d="M20.717 14.506c0 3.679-2.931 6.661-6.546 6.661-3.616 0-6.546-2.982-6.546-6.661 0-3.679 2.93-6.661 6.546-6.661 3.615 0 6.546 2.982 6.546 6.661zM14.17 6.404c-4.398 0-7.962 3.628-7.962 8.102s3.564 8.101 7.962 8.101c4.397 0 7.962-3.627 7.962-8.101 0-4.474-3.565-8.102-7.962-8.102zm4.777 7.382h-4.07V9.645a.714.714 0 0 0-.707-.72.714.714 0 0 0-.708.72v4.861c0 .398.317.72.708.72h4.777a.713.713 0 0 0 .708-.72.714.714 0 0 0-.708-.72z" fill-rule="nonzero"/></g>'
  }
})
