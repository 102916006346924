<template lang="pug">
  .ui.find-and-select-user
    .search
      ui-scrollbar.box-selected(@click="focus")
        .search-icon(v-if="inputPlaceholder")

        .item(
          v-for="(item, i) in value",
          :key="i",
          :data-test-name="`${item.firstName} ${item.lastName}`",
          @click="pickUser(item)"
        )
          .c-item
            .name {{ item.firstName }} {{ item.lastName }}
            button.cross
            
        input.search-input(
          :autofocus="true",
          ref="input",
          :placeholder="inputPlaceholder",
          @input="search")

      ui-button.success.submit(@click="$emit('submit')", :disabled="disableLabel") {{ label }}

    ui-scrollbar.list
      .item(
        v-for="(item, i) in list",
        :key="i",
        :data-test-name="`${item.firstName} ${item.lastName}`",
        @click="pickUser(item)"
      )
        .selected(:class="{active: item.selected}")

        ui-avatar(
          :size="2.3",
          :user="item")

        .name {{ item.firstName }} {{ item.lastName }}

</template>

<script>
import helpers from '@/helpers'

export default {
  props: {
    users: {
      default: [],
      require: false,
    },

    value: {
      default: [],
      require: false,
    },

    label: {
      default: '',
    },

    disableLabel: {
      default: false,
    },

    placeholder: {
      default: '',
    },
  },

  methods: {
    search(val) {
      this.$emit('search', val.target.value)
    },

    focus() {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },

    pickUser(user) {
      this.$emit(
        'input',
        this.value.find(e => e.id === user.id)
          ? this.value.filter(e => e.id !== user.id)
          : [...this.value, user]
      )
    },
  },

  computed: {
    inputPlaceholder() {
      return this.value.length > 0 ? null : this.placeholder
    },

    list() {
      return this.users.map(e => {
        return {
          ...e,
          selected: this.value.find(user => user.id === e.id) ? true : false,
        }
      })
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
