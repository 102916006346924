<template lang="pug">
  .ui.form.input.autofill(
    :class="{edit, empty, disabled}",
    v-click-outside="cancel")

    .body(@click="e => $emit('body-click', e)")
      slot(name="before")
      ui-input(
        ref="uiInput"
        @click="onEdit",
        :name="name",
        v-model="model",
        :disabled="!editable",
        @enter="save",
        :placeholder="placeholder")

      slot(name="after")

    .footer
      ui-button.small.bali-hai(
        @click="save",
        :disabled="disabled") {{ saveBtn || $t('ui.editFormRow.save')}}

      ui-button.small.bali-hai.o(
        @click="cancel") {{ cancelBtn || $t("ui.editFormRow.cansel") }}
</template>

<script>
import FormMixin from "../default";

export default {
  mixins: [FormMixin],

  props: {
    type: {
      type: String,
      default: 'text'
    }
  }
}
</script>