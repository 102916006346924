export default {
  computed: {
    breadcrumbs() {
      let list = []

      this.getRecursiveParentFolder(this.activeFolder, list)
      if (this.accessMode) list.push(this.accessFolder)

      return this._.reverse(list)
    },
  },

  methods: {
    handleClickBreadcrumbs(folderId) {
      this.handleUnselectMedia()

      if (folderId === undefined) {
        this.accessMode = false
        this.activeFolder = undefined
        return
      }

      // const f = this.folders.find(e => e.id == folderId)
      // this.activeFolder = f && f.parentId ? f.parentId : undefined
      this.activeFolder = folderId
    },

    getRecursiveParentFolder(folderId, arr) {
      const f = this.folders.find(e => e.id == folderId)
      if (f) {
        arr.push({ ...f })

        if (f.parentId) {
          this.getRecursiveParentFolder(f.parentId, arr)
        }
      }
    },
  },
}
