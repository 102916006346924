import { INTEGRATION_DETAIL_STATUS } from '@/constants.js';

class IntegrationModel {
  constructor(data) {
    this.id = Number(data.id);
    this.slug = data.slug;
    this.name = data.name;
    this.shortName = data.short_name;
    this.description = data.description;
    this.icon = data.icon === 'default' ? null : data.icon;
    this.version = data.version;

    this.price = data.price;

    this.integrated = data.integrated ?? INTEGRATION_DETAIL_STATUS.NOT_INTEGRATED;
    this.order = data.order;
    this.paymentPeriod = data.payment_period;
  }
}

export class MockedIntegrationModel extends IntegrationModel {
  constructor(data) {
    super(data)

    this.id = data.id;
    this.isFree = data.is_free;
    this.onRequest = data.on_request;

    // remove a status for mocked integrations
    this.integrated = undefined;
  }
}

export default IntegrationModel
