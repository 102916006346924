/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkbox-checked': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.86 3h12.3A2.86 2.86 0 0121 5.86v12.3A2.85 2.85 0 0118.16 21H5.86A2.86 2.86 0 013 18.16V5.86A2.86 2.86 0 015.86 3zm6.04 12.7l5.8-5.95c.4-.39.4-1.04.04-1.44a.97.97 0 00-1.41 0l-5.1 5.23-2.52-2.59a.97.97 0 00-1.41 0c-.4.4-.4 1.04 0 1.45l3.21 3.3c.18.2.46.3.7.3.24 0 .5-.1.7-.3z" _fill="#888D93"/>'
  }
})
