<template lang="pug">
  .ui.edit-form-row(
    v-click-outside="onClickOutside",
    :class="[{edit, empty, clickable}, type]")

    .input-box(
      v-if="type==='location'",
      @click="onEdit")
      .icon
      ui-icon(v-if="icon", :class="icon")
      ui-svg(v-if="svg", :class="svg.class", :name="svg.name")
      ui-input(
        ref="input"
        :class="{'no-radius': locations.length > 0}",
        @input="handleInputLocation"
        v-model="model",
        :placeholder="placeholder",
        :readonly="!edit",
        @enter="save",
        @click="handleInputClick")

      ui-svg.dark.edit(
        name="pencil",
        @click="onEdit",
        v-if="editable")

    .input-box(
      v-if="type==='text'",
      @click="onEdit")
      ui-svg(v-if="svg", :class="svg.class", :name="svg.name")
      ui-icon(v-if="icon", :class="icon")
      ui-input(
        ref="input"
        v-model="model",
        :placeholder="placeholder",
        :readonly="!edit",
        @enter="save",
        @click="handleInputClick")

      ui-svg.dark.edit(
        name="pencil",
        @click="onEdit",
        v-if="editable")
    
    .textarea-box(v-if="type==='textarea'")
      ui-input(
        ref="input"
        type="textarea",
        v-model="model",
        @click="onEdit",
        :autoresize="autoresize",
        :placeholder="placeholder",
        :readonly="!edit")

    .controls(v-if="edit")
      .list-add.locations(v-if="locations.length > 0")
        .list
          .item(v-for="(item, i) in locations", :key="i", @click="model=item.description")
            | {{ item.description }}

      .list-wrapper.autofill
        slot(name="autofill" v-bind="{ finishEditing }")

      ui-button.small.bali-hai(@click="save", :disabled="disabled") {{ $t("ui.editFormRow.save") }}
      ui-button.small.bali-hai.o(@click="cancel") {{ $t("ui.editFormRow.cansel") }}
</template>

<script>
export default {
  props: {
    icon: {
      default: null,
    },

    svg: {
      default: null,
    },

    required: {
      type: Boolean,
      default: false,
    },

    value: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    editable: {
      type: Boolean,
      default: false,
    },

    autoresize: {
      type: Boolean,
      default: false,
    },

    clickable: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'text',
    },
  },

  data() {
    return {
      model: '',
      edit: false,
      locations: [],
    }
  },

  methods: {
    /**
     * @public
     */
    focus() {
      if (!this.editable) return;

      this.edit = true;
      this.$refs.input.focus();
    },

    handleInputClick(e) {
      if (this.clickable && !this.edit && this.value) {
        this.$emit('click')
        e.preventDefault()
        e.stopPropagation()
      }
    },

    handleInputLocation() {
      this.$placeAutocomplete.find(this.model).then(res => {
        this.locations = res
      })
    },

    onClickOutside() {
      if (this.edit) {
        this.save()
      }
    },

    onEdit() {
      if (this.editable) {
        this.edit = true
      }
    },

    finishEditing() {
      this.edit = false
      this.model = this.value // to reset an input value
    },

    cancel(e) {
      this.edit = false
      this.model = this.value
    },

    save(e) {
      if (this.disabled) {
        return null
      }

      this.edit = false
      if (this.value !== this.model) {
        this.$emit('update', this.model)
      }
    },
  },

  watch: {
    value() {
      this.model = this.value
    },
    model() {
      this.$emit('change', this.model)
    },
  },

  computed: {
    disabled() {
      return this.required ? this.model.length === 0 : false
    },
    empty() {
      return !this.model || this.model.length === 0
    },
  },

  mounted() {
    this.model = this.value
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
