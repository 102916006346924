<template lang="pug">
.ui-edit-field(:class="rootClasses")
  .ui-edit-field__value
    slot

  ui-svg.ui-edit-field__icon.dark(
    v-if="!readonly"
    name="pencil"
    @click="handleClick"
  )
</template>

<script>
export default {
  name: "UiEditField",

  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rootClasses() {
      return {
        "ui-edit-field--readonly": this.readonly,
      };
    },
  },

  methods: {
    handleClick() {
      if (this.readonly) return;

      this.$emit('click');
    },
  },
};
</script>

<style lang="sass" src="./styles.sass"></style>
