<template lang="pug">
.ui.stick(:class="rootClasses")
  ui-svg.stick__icon(v-if="icon" :name="icon")

  span.stick__text
    slot
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: undefined,
    },

    /**
     * The kind of the stick
     * @values info-light
     */
    kind: {
      type: String,
      default: undefined
    },

    label: {
      require: false,
      default: null
    }
  },

  computed: {
    rootClasses() {
      const { kind } = this;
      return {
        [`stick--kind_${kind}`]: Boolean(kind),
      };
    },
  },
};
</script>

<style lang="sass" src="./styles.sass"></style>
