<template lang="pug">
.checklist-item-pictograms(:class="{'checklist-item-pictograms--is_inline' : inline}" )
  // TODO: change the display condition
  .checklist-item-pictograms__item(
    v-if="totalFormFields > 0 && formsEnabled"
  )
    ui-svg(
      :kind="hasAllAnswers ? 'success' : 'dark'"
      name="form-fields"
      size="smaller"
    )

    span.checklist-item-pictograms__counter(
      :class="{ 'checklist-item-pictograms__counter--kind_success': hasAllAnswers }"
    )
      | {{ formCounter }}

  .checklist-item-pictograms__item(v-if="countComments > 0")
    ui-svg(kind="dark" name="message")

  .checklist-item-pictograms__item(v-if="countFiles > 0")
    ui-svg(kind="dark" name="clip")
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ChecklistItemPictograms',

  props: {
    countComments: {
      type: Number,
      default: 0,
    },

    countFiles: {
      type: Number,
      default: 0,
    },

    totalFormAnswers: {
      type: Number,
      default: 0,
    },

    totalFormFields: {
      type: Number,
      default: 0,
    },

    inline: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(['formsEnabled']),

    hasAllAnswers() {
      const { totalFormAnswers, totalFormFields } = this;
      return totalFormAnswers >= totalFormFields;
    },

    formCounter() {
      const { totalFormAnswers, totalFormFields } = this;
      if (this.hasAllAnswers) return `${totalFormFields}/${totalFormFields}`;
      return `${totalFormAnswers}/${totalFormFields}`;
    },
  },
};
</script>

<style lang="scss">
.checklist-item-pictograms {
  display: flex;
  align-items: center;
  // TODO: remove !important declaration when pure-marking rules are removed
  margin-bottom: auto !important;
  // TODO: remove !important declaration when pure-marking rules are removed
  padding-top: 0.5em !important;
}

.checklist-item-pictograms__item {
  margin: auto 0em;
  margin-left: 0.7em;
  display: flex;
  align-items: center;
}

.checklist-item-pictograms__counter {
  color: $primary-color;
  margin-top: 0.1em;
  margin-left: 0.35em;
  line-height: 1;
  font-size: 0.9em;
}

.checklist-item-pictograms__counter--kind_success {
  color: $green-color;
}

.checklist-item-pictograms--is_inline {
  padding: 0 !important;
  margin-bottom: 0 !important;
  cursor: pointer;

  .checklist-item-pictograms__item {
    margin: 0;
    gap: 0.3em;
  }

  .checklist-item-pictograms__counter {
    margin: 0;
    font-size: unset;
  }

}
</style>
