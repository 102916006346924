import './polyfills'

import md5 from 'md5'
window.TRANSACTION_ID = md5(new Date().getTime())

import Vue from 'vue'
import i18n from './i18n'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import VueEvents from 'vue-plugin-events'

import VueEsc from 'vue-esc'

import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

import VueAnalytics from 'vue-analytics'

import AsyncComputed from 'vue-async-computed'
import VueContentPlaceholders from 'vue-content-placeholders'

import PortalVue from 'portal-vue'
import VShowSlide from 'v-show-slide'

/** Moment */

import Moment from 'moment'

import 'moment/locale/nl'
import 'moment/locale/fr'
import VueMoment from 'vue-moment'
import 'moment-business-days'
import { extendMoment } from 'moment-range'
const moment = extendMoment(Moment)

import VueColumnsResizable from './plugins/vue-columns-resizable'
Vue.use(VueColumnsResizable)

/**
 * Mixins
 */

import ModalManagerMixin from '@/mixins/modalManager'
Vue.mixin(ModalManagerMixin)

import VueEventsMixin from '@/mixins/events'
import VueDraggMixin from '@/mixins/vueDragg'
import VuexSocketsMixin from '@/mixins/vuexSockets'

import LoadedMixin from '@/mixins/loaded'
Vue.mixin(LoadedMixin)

import HotJarMixin from '@/mixins/hotjar'

/**
 * Components
 */

import SvgIcon from 'vue-svgicon'
import '@/components/icons'

import '@/ui-components'

import MediaPanel from '@/components/MediaPanel/Index.vue'
Vue.component('MediaPanel', MediaPanel)

import GlobalPlanning from '@/components/GlobalPlanning'
Vue.component('GlobalPlanning', GlobalPlanning)

import ChecklistsComponent from '@/components/checklists/Index.vue'
Vue.component('checklists-block', ChecklistsComponent)

Vue.component('options-pop', () => import('@/components/OptionsPop.vue'))

import { Emoji } from 'emoji-mart-vue-fast'
Vue.component('emoji', Emoji)

import UserTitle from '@/components/UserTitle.vue'
Vue.component('user-title', UserTitle)

import PageTitle from '@/components/user/PageTitle/Index.vue'
Vue.component('page-title', PageTitle)

import CalendarPicker from '@/components/CalendarPicker/Index.vue'
Vue.component('calendar-picker', CalendarPicker)

import LanguageSelect from '@/components/LanguageSelect.vue'
Vue.component('language-select', LanguageSelect)

Vue.component('app-banner', () => import('@/components/AppBanner/Index.vue'))

/**
 * Filters
 */

import '@/filters'

/**
 * Plugins
 */

import './plugins/performance'

import '@/middlewares'

import '@/plugins/app'

import SentryPlugin from '@/plugins/sentry'
import VIntercom from '@/plugins/intercom'
import VOneSignal from '@/plugins/onesignal'
import PlaceAutocomplete from '@/plugins/placeAutocomplete'
import Utils from '@/plugins/utils/index.js'
import VersionUpdater from '@/plugins/versionUpdater'

import ConfettiPlugin from '@/plugins/confetti'

import VScrollSync from '@/plugins/scrollSync'
import VDrop from '@/plugins/drop'

import '@/plugins/scrollSaver'
import '@/plugins/bus'
import '@/plugins/notify'
import '@/plugins/clickOutside'
import '@/plugins/derictives'
import '@/plugins/draggable'
import '@/plugins/quill'
import '@/plugins/tooltip'

import modals from '@/modals'
import router from '@/routes'
import store from '@/store'

import App from '@/components/App.vue'
import { GOOGLE_MAPS_URL, SOCKET_URL, isProduction } from './config'

/**
 * Create general instance vue
 */
Vue.use(VueLodash, { lodash })
  .use(SvgIcon, {
    tagName: 'svg-icon',
    defaultWidth: '1em',
    defaultHeight: '1em',
  })
  .use(VueEvents)
  .use(VueEsc)
  .use(VueContentPlaceholders)
  .use(AsyncComputed)
  .use(VueMoment, { moment })
  .use(PortalVue)
  .use(VShowSlide)
  .use(modals)
  .use(VersionUpdater)
  .use(
    new VueSocketIO({
      connection: isProduction
        ? SocketIO(SOCKET_URL)
        : SocketIO('/', { path: SOCKET_URL }),
    })
  )
  .use(SentryPlugin, {
    key: process.env.VUE_APP_SENTRY_KEY,
    project: process.env.VUE_APP_SENTRY_PROJECT,
  })
  .use(VIntercom, {
    app_id: process.env.VUE_APP_INTERCOM_APP_ID,
    hide_default_launcher: true,
  })
  .use(VOneSignal, {
    appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
    subdomainName: process.env.VUE_APP_ONESIGNAL_SUBDOMAIN,
  })
  .use(PlaceAutocomplete, {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    url: GOOGLE_MAPS_URL,
  })
  .use(Utils)
  .use(VScrollSync)
  .use(VDrop)
  .use(ConfettiPlugin)

if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
    router,
  })
}

Vue.config.keyCodes.comma = 188
Vue.config.productionTip = false

window.store = store
window.app = new Vue({
  render: h => h(App),
  i18n,
  router,
  store,
  mixins: [VueDraggMixin, HotJarMixin, VuexSocketsMixin, VueEventsMixin],

  mounted() {
    this.$onesignal.addListenerForNotificationOpened(res => {
      const data = res.data

      if (data.chatId && data.projectId) {
        this.$router.push({
          name: 'projectChat',
          params: data,
        })
      } else if (data.chatId) {
        this.$router.push({
          name: 'teamChat',
          params: data,
        })
      }
    })
  },

  created() {
    this.$router.beforeEach((to, from, next) => {
      this.$events.emit('route:change', to, from)
      next()
    })

    this.$router.afterEach((to, from) => {
      this.$events.emit('route:updated', to, from);
    });

    window.addEventListener('keydown', e => {
      this.$events.emit(`keydown:${e.keyCode}`, e)
    })
  },
}).$mount('#app')
