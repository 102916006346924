<template lang="pug">
  .group-sidebar-planning
    .name
      span.text(v-tooltip="tooltipOptions") {{ params.name }}
      .edit(@click="handleClickOnEdit")
        ui-svg(name="pencil" kind="dark")

    .drop(@click="handleToggleGroup")
      ui-svg(
        name="arrow"
        kind="dark"
        :class="isHide ? 'rotate-right' : 'rotate-down'"
      )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    params: {
      require: false,
      default: null,
    },
  },

  computed: {
    isHide() {
      return this.hiddenPlanningGroup(this.params.id)
    },

    tooltipOptions() {
      return { text: this.$utils.clearTags(this.params.name), textOverflow: true }
    },

    ...mapGetters('PlanningResourceGroups', ['hiddenPlanningGroup']),
  },

  methods: {
    handleClickOnEdit() {
      this.openModal({
        name: 'PlanningChangeGroup',
        params: {
          ...this.params,
        },
      })
    },

    handleToggleGroup() {
      this.toggleViewPlanningResourceGroup({
        id: this.params.id,
        value: !this.isHide,
      })
    },

    ...mapActions('PlanningResourceGroups', [
      'toggleViewPlanningResourceGroup',
    ]),
  },
}
</script>
