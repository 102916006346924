/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emoji': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<defs><path pid="0" d="M1387.333 787.167c-.919 0-1.666-.748-1.666-1.667 0-.92.747-1.667 1.666-1.667.92 0 1.667.748 1.667 1.667 0 .92-.747 1.667-1.667 1.667zm.834 3.333a4.171 4.171 0 0 1-4.167 4.167 4.171 4.171 0 0 1-4.167-4.167h1.667c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5h1.667zm-9.167-5c0-.92.747-1.667 1.667-1.667.919 0 1.666.748 1.666 1.667 0 .92-.747 1.667-1.666 1.667-.92 0-1.667-.748-1.667-1.667zm5-7.5c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10z" id="svgicon_emoji_a"/></defs><use _fill="ivory" xlink:href="#svgicon_emoji_a" transform="translate(-1374 -778)" fill-rule="evenodd"/>'
  }
})
