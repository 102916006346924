/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video': {
    width: 18,
    height: 10,
    viewBox: '0 0 18 10',
    data: '<path pid="0" d="M11.27 9.88H1.52a1.4 1.4 0 01-1.4-1.4V1.52c0-.78.64-1.4 1.4-1.4h9.73c.78 0 1.4.64 1.4 1.4v6.93c.02.79-.62 1.43-1.38 1.43zm2.35-7v4.24l3.9 2.21c.17.1.36-.02.36-.21V.88c0-.19-.19-.28-.35-.21l-3.91 2.21z" _fill="#96969C"/>'
  }
})
