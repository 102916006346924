/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pdf-icon': {
    width: 24,
    height: 26,
    viewBox: '0 0 24 26',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.478 0H3.483a3.51 3.51 0 00-2.465 1.008A3.407 3.407 0 000 3.44V22.52a3.42 3.42 0 001.02 2.432 3.524 3.524 0 002.463 1.014h12.992a.756.756 0 00.607-.313l6.706-6.607a.503.503 0 00.116-.186.608.608 0 00.057-.27V3.44a3.407 3.407 0 00-1.018-2.43A3.51 3.51 0 0020.478 0zM6.604 13.692H5.39v1.837a.729.729 0 01-.722.712.717.717 0 01-.723-.712v-5.084c0-.393.324-.712.723-.712h1.936c.718 0 1.381.377 1.74.99a1.954 1.954 0 010 1.98 2.014 2.014 0 01-1.74.989zm7.13 1.97c.377-.371.589-.875.588-1.4v-2.55c0-.526-.212-1.03-.588-1.404a2.039 2.039 0 00-1.421-.589h-1.951a.717.717 0 00-.723.712v5.098c0 .393.324.712.723.712h1.95a2.02 2.02 0 001.422-.579zm1.628-.133V10.43c0-.393.323-.712.723-.712h3.251c.4 0 .723.319.723.712a.717.717 0 01-.723.712h-2.529v1.125h1.677a.729.729 0 01.722.712c0 .189-.076.37-.212.503a.727.727 0 01-.51.209h-1.677v1.837a.717.717 0 01-.722.712.717.717 0 01-.723-.712zm1.836 5.781v2.222l4.291-4.23h-2.24a2.065 2.065 0 00-1.447.586 2.005 2.005 0 00-.605 1.422zm-4.322-9.597v2.549a.56.56 0 01-.563.555h-1.229v-3.674h1.229c.31.008.56.256.563.563v.007zm-5.865.396a.556.556 0 00.171-.396v-.007a.538.538 0 00-.168-.397.555.555 0 00-.41-.151H5.39v1.11h1.214a.572.572 0 00.407-.159z" _fill="#454276"/>'
  }
})
