import Vue from 'vue'
import { MockedIntegrationModel } from '@/models/Integration';
import integrationsProvider from '@/provider/v1/integrations';
import * as TYPES from '@/store/types';
import { keyBy, isUndefined } from 'lodash';
import {
  INTEGRATIONS,
  CALENDAR_INTEGRATIONS,
  INTEGRATION_DETAIL_STATUS,
  INTEGRATION_PAYMENT_PERIOD,
} from '@/constants';

const getMockedIntegrations = () => {
  return [
    {
      id: 'mock-10',
      icon: require('@/assets/img/marketplace/woub-formulieren.jpg'),
      name: 'Woub Formulieren',
      short_name: 'Woub Formulieren',
      slug: INTEGRATIONS.WOUB_FORMS,
      description: 'Maak eenvoudig formulieren aan in Woub.',
      is_free: false,
      on_request: false,
      price: 5,
      payment_period: INTEGRATION_PAYMENT_PERIOD.PER_TEAM_MEMBER_PER_MONTH,
      order: -2,
    },
    {
      id: "mock-1",
      icon: require("@/assets/img/marketplace/woub-translate.jpg"),
      name: "Woub Translate",
      short_name: "Woub Translate",
      slug: INTEGRATIONS.WOUB_TRANSLATE,
      description: "Automatische tekst vertalingen tussen een aantal buitenlandse talen.",
      is_free: false,
      on_request: false,
      price: 5,
      payment_period: INTEGRATION_PAYMENT_PERIOD.PER_TEAM_MEMBER_PER_MONTH,
      order: -1,
    },
    {
      id: "mock-2",
      icon: require("@/assets/img/marketplace/google-calendar.jpg"),
      name: "Google Calendar",
      short_name: "Google",
      slug: CALENDAR_INTEGRATIONS.GOOGLE,
      description: " Sync je Google-agenda in beide richtingen",
      is_free: true,
      on_request: false,
      price: null,
      period: null,
      order: null,
    },
    {
      id: "mock-3",
      icon: require("@/assets/img/marketplace/outlook-calendar.jpg"),
      name: "Outlook Calendar",
      short_name: "Outlook",
      slug: CALENDAR_INTEGRATIONS.OUTLOOK,
      description: "Sync je Outlook-agenda in beide richtingen",
      is_free: true,
      on_request: false,
      price: null,
      period: null,
      order: null,
    },
    {
      id: "mock-5",
      icon: require("@/assets/img/marketplace/teamleader.jpg"),
      name: "Teamleader",
      short_name: "Teamleader",
      slug: "teamleader",
      description: "Gewonnen offertes komen automatisch in Woub",
      is_free: false,
      on_request: true,
      price: null,
      period: null,
      order: null,
    },
    {
      id: "mock-6",
      icon: "https://woub-storage.s3.eu-central-1.amazonaws.com/static/integrations/logos/pipedrive.jpg",
      name: "Pipedrive",
      short_name: "Pipedrive",
      slug: "pipedrive",
      description: "Gewonnen deals komen automatisch in Woub",
      is_free: false,
      on_request: true,
      price: null,
      period: null,
      order: null,
    },
    {
      id: "mock-9",
      icon: require("@/assets/img/marketplace/wordpress.jpg"),
      name: "Wordpress",
      short_name: "Wordpress",
      slug: "wordpress",
      description: "Koppel je website formulier met Woub",
      is_free: false,
      on_request: true,
      price: null,
      period: null,
      order: null,
    },
  ].map(mockedIntegration => new MockedIntegrationModel(mockedIntegration));
}

const updateListWithItems = (oldList, newItems, uniqueKey) => {
  const hashTable = keyBy(oldList, uniqueKey);

  newItems.forEach(item => {
    hashTable[item[uniqueKey]] = item;
  });

  return Object.values(hashTable);
}

const state = {
  integrations: getMockedIntegrations(),
  details: [],
  loadingIntegrations: {},
};

const getters = {
  getIntegrationBySlug: state => slug => state.integrations.find(
    integration => integration.slug === slug
  ),

  getIntegrationsByIds: state => integrationIds => state.integrations.filter(
    integration => integrationIds.includes(integration.id)
  ),

  getIntegrationDetailByIntegrationId: state => integrationId => state.details.find(
    detail => detail.integrationId === integrationId
  ),

  getIntegrationDetailsByIntegrationIds: state => integrationIds => {
    const detailsByIntegrationIds = new Map(
      state.details.map(detail => [detail.integrationId, detail])
    );

    return integrationIds.reduce((details, integrationId) => {
      const detail = detailsByIntegrationIds.get(integrationId);
      if (detail) {
        details.push(detail);
      }
      return details;
    }, []);
  },

  getIntegrationDetailByIntegrationSlug: (
    _state,
    {
      getIntegrationBySlug,
      getIntegrationDetailByIntegrationId
    }
  ) => slug => {
    const integration = getIntegrationBySlug(slug);
    const integrationDetail = getIntegrationDetailByIntegrationId(integration.id);
    return integrationDetail;
  },

  getIntegrationDetailsByIds: state => integrationDetailIds => state.details.filter(
    detail => integrationDetailIds.includes(detail.id)
  ),

  getIntegrationLoading: state => key => Boolean(state.loadingIntegrations[key]),

  activeIntegrations: state =>
    state.integrations.filter(
      ({ integrated }) => integrated === INTEGRATION_DETAIL_STATUS.INTEGRATED
    ),
};

const actions = {
  getIntegrations({ commit }, { cancelToken }) {
    return integrationsProvider
      .getIntegrations({ cancelToken })
      .then(integrations => {
        commit(TYPES.INTEGRATIONS.INIT_INTEGRATIONS, integrations);
      });
  },

  async fetchIntegrationInfo({ dispatch }, { integrationSlug }) {
    const results = await Promise.allSettled([
      dispatch('getIntegration', { integrationSlug }),
      dispatch('getIntegrationDetail', { integrationSlug })
    ]);

    results.forEach(({ reason }) => {
      if (reason) {
        throw new Error(reason);
      }
    });
  },

  getIntegration({ commit }, { integrationSlug }) {
    return integrationsProvider
      .getIntegration({ integrationSlug })
      .then(integration => {
        commit(TYPES.INTEGRATIONS.INIT_INTEGRATIONS, integration);
      });
  },

  getIntegrationDetail({ commit }, { integrationSlug }) {
    return integrationsProvider
      .getIntegrationDetail({ integrationSlug })
      .then(integrationDetail => {
        if (!integrationDetail) return;

        commit(TYPES.INTEGRATIONS.INIT_INTEGRATION_DETAILS, integrationDetail);
      });
  },

  updateIntegrationSettings(
    {
      commit,
      getters
    },
    {
      integrationSlug,
      companyProjectStatusId,
      defaultUsersToProjectChat,
      defaultProjectUserIds,
      createGeneralProjectChat,
      generalProjectChatUserIds,
      remoteCreate,
      watchCompanyProjectStatusId,
      watchCreateEstimateClientStatusId,
      projectStatusId,
      syncStatusIdService,
      syncStatusIdClient,
      templateId,
      projectNumber,
      autoCreate,
      administration,
      whenQuoteAcceptSetToCompanyProjectStatusId,
      listForContactCompanyProjectStatusId,

      projectOrganizationIds,
      watchSyncProjectServiceStatusId,
      watchChangeProjectServiceStatusId,
      watchChangeProjectClientStatusId,
      remoteCreateProject,
      watchCreateProjectClientStatusId,
      watchCreateProjectServiceStatusId,

      actionCompanyProjectStatusId,
      office,
      autoCreateInTwinfield,

      articles,
      articlesUrenGroups,
      articlesMaterialGroups,
    },
  ) {
    const integrationDetail = getters.getIntegrationDetailByIntegrationSlug(integrationSlug);
    const { integrationId } = integrationDetail ?? {};

    commit(TYPES.INTEGRATIONS.SET_LOADING_INTEGRATION, { key: integrationSlug, loading: true });
    return integrationsProvider
      .updateIntegrationSettings({
        integrationSlug,
        companyProjectStatusId,
        defaultUsersToProjectChat,
        defaultProjectUserIds,
        createGeneralProjectChat,
        generalProjectChatUserIds,
        remoteCreate,
        watchCompanyProjectStatusId,
        watchCreateEstimateClientStatusId,
        projectStatusId,
        syncStatusIdService,
        syncStatusIdClient,
        templateId,
        projectNumber,
        autoCreate,
        administration,
        whenQuoteAcceptSetToCompanyProjectStatusId,
        listForContactCompanyProjectStatusId,
        projectOrganizationIds,
        watchSyncProjectServiceStatusId,
        watchChangeProjectServiceStatusId,
        watchChangeProjectClientStatusId,
        remoteCreateProject,
        watchCreateProjectClientStatusId,
        watchCreateProjectServiceStatusId,

        actionCompanyProjectStatusId,

        office,
        autoCreateInTwinfield,

        articles,
        articlesUrenGroups,
        articlesMaterialGroups,
      })
      .then(() => {
        commit(TYPES.INTEGRATIONS.UPDATE_INTEGRATION_DETAIL, {
          integrationId,
          companyProjectStatusId,
          defaultUsersToProjectChat,
          defaultProjectUserIds,
          createGeneralProjectChat,
          generalProjectChatUserIds,
          templateId,
          projectNumber,
          autoCreate,
          administration,

          watchSyncProjectServiceStatusId,
          watchChangeProjectServiceStatusId,
          watchChangeProjectClientStatusId,
          remoteCreateProject,
          watchCreateProjectClientStatusId,
          watchCreateProjectServiceStatusId,

          actionCompanyProjectStatusId,

          office,
          autoCreateInTwinfield,

          articles,
          articlesUrenGroups,
          articlesMaterialGroups,
        });
      })
      .finally(() => {
        commit(TYPES.INTEGRATIONS.SET_LOADING_INTEGRATION, { key: integrationSlug, loading: false });
      });
  },

  stopIntegration({ getters, commit }, { integrationSlug }) {
    const integrationDetail = getters.getIntegrationDetailByIntegrationSlug(integrationSlug);
    const { integrationId } = integrationDetail ?? {};

    commit(TYPES.INTEGRATIONS.SET_LOADING_INTEGRATION, { key: integrationSlug, loading: true });

    return integrationsProvider
      .stopIntegration({
        integrationSlug,
      })
      .then((result) => {
        if (!result) return;
        commit(TYPES.INTEGRATIONS.UPDATE_INTEGRATION_DETAIL, {
          integrationId,
          status: INTEGRATION_DETAIL_STATUS.STOPPED,
        });
      })
      .finally(() => {
        commit(TYPES.INTEGRATIONS.SET_LOADING_INTEGRATION, { key: integrationSlug, loading: false });
      });
  },

  syncNowIntegration({ getters, commit, dispatch }, { integrationSlug }) {

    commit(TYPES.INTEGRATIONS.SET_LOADING_INTEGRATION, { key: integrationSlug, loading: true });

    return integrationsProvider
      .startIntegration({
        integrationSlug
      })
      .then((result) => {
        if (!result) return;
        dispatch('getIntegrationDetail', { integrationSlug })
      })
      .finally(() => {
        commit(TYPES.INTEGRATIONS.SET_LOADING_INTEGRATION, { key: integrationSlug, loading: false });
      });
  }
};

const mutations = {
  [TYPES.INTEGRATIONS.INIT_INTEGRATIONS](state, data) {
    const integrations = Array.isArray(data) ? data : [data];

    state.integrations = updateListWithItems(state.integrations, integrations, 'id');
  },

  [TYPES.INTEGRATIONS.UPDATE_INTEGRATION_STATUS](state, { integrationId, status }) {
    const integration = state.integrations.find(integration => integration.id === integrationId);

    if (integration) {
      integration.integrated = status;
    }
  },

  [TYPES.INTEGRATIONS.INIT_INTEGRATION_DETAILS](state, data) {
    const details = Array.isArray(data) ? data : [data];

    state.details = updateListWithItems(state.details, details, 'integrationId');
  },

  [TYPES.INTEGRATIONS.UPDATE_INTEGRATION_DETAIL](state, { integrationId, ...updatedFields }) {
    const detail = state.details.find(detail => detail.integrationId === integrationId);

    if (detail) {
      Object.entries(updatedFields).forEach(([key, value]) => {
        if (!isUndefined(value)) {
          detail[key] = value;
        }
      });
    } else {
      console.warn(`The integration detail does not exist. Integration id: ${integrationId}`);
    }
  },

  [TYPES.INTEGRATIONS.SET_LOADING_INTEGRATION](state, { key, loading }) {
    if (key in state.loadingIntegrations) {
      state.loadingIntegrations[key] = loading;
    } else {
      Vue.set(state.loadingIntegrations, key, loading);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
