/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'blocks': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.44 4c1.48 0 2.67 1.2 2.67 2.67v1.77c0 1.48-1.2 2.67-2.67 2.67H6.67A2.67 2.67 0 0 1 4 8.44V6.67C4 5.19 5.2 4 6.67 4h1.77zm8 8.89c.46 0 .84.34.89.78v1.89h1.78a.89.89 0 0 1 .1 1.77h-1.88v1.78a.89.89 0 0 1-1.77.1v-1.88h-1.78a.89.89 0 0 1-.1-1.77h1.87v-1.78c0-.5.4-.9.88-.9zm-8 0c1.48 0 2.67 1.2 2.67 2.67v1.77c0 1.48-1.2 2.67-2.67 2.67H6.67A2.67 2.67 0 0 1 4 17.33v-1.77c0-1.48 1.2-2.67 2.67-2.67h1.77zm-1.77 1.78h1.77c.5 0 .9.4.9.89v1.77c0 .5-.4.9-.9.9H6.67c-.5 0-.9-.4-.9-.9v-1.77c0-.5.4-.9.9-.9zm13.33-8C20 5.19 18.8 4 17.33 4h-1.77a2.67 2.67 0 0 0-2.67 2.67v1.77c0 1.48 1.2 2.67 2.67 2.67h1.77c1.48 0 2.67-1.2 2.67-2.67V6.67zm-11.56-.9H6.67c-.5 0-.9.4-.9.9v1.77c0 .5.4.9.9.9h1.77c.5 0 .9-.4.9-.9V6.67c0-.5-.4-.9-.9-.9zm7.12 0h1.77c.5 0 .9.4.9.9v1.77c0 .5-.4.9-.9.9h-1.77c-.5 0-.9-.4-.9-.9V6.67c0-.5.4-.9.9-.9z" _fill="#7F848B"/>'
  }
})
