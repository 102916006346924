<template lang="pug">
  .greed.week(:style="style")
    .item(
      v-for="(day, i) in items",
      :key="i",
      :style="getDayStyle(day, 12, 'em')"
      :class="getDayClasses(day)")

      .line-down
      .lines-dotted
        .line-down-dotted
        .line-down-dotted
        .line-down-dotted
        .line-down-dotted
</template>

<script>
import GreedMixin from './mixin'
import GridMixin from '../mixins/grid'

export default {
  mixins: [GreedMixin, GridMixin]
}
</script>
