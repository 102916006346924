<template lang="pug">
  tr
    calendar-date(
      v-for="(date, key) in week",
      :key="key",
      :date="date",
      :is-selected="isSelected === date",
      @select-date="selectDate")
</template>

<script>
import CalendarDate from './CalendarDate'
export default {
  components: {
    CalendarDate,
  },

  props: {
    week: {
      type: Array,
      default: () => [],
    },

    isSelected: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    selectDate(date) {
      this.$emit('select-date', date)
    },
  },
}
</script>
