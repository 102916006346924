<template lang="pug">
UiSearchDropListUsers(
  v-bind="$attrs"
  v-model="modelUserIds"
  :users="users"
  :placeholder="placeholder"
  v-on="inheritedListeners"
)
  template(#suffix)
    slot(name="suffix")
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    users: {
      type: Array,
      default: () => [],
    },

    value: {
      type: Array,
      required: true,
    },

    placeholder: {
      default: '',
    },
  },

  computed: {
    modelUserIds: {
      get() {
        return this.value?.map(userId => ({ id: userId }));
      },
      set(users) {
        this.$emit('update:value', users.map(({ id }) => id));
      },
    },

    inheritedListeners() {
      const { 'update:value': _, ...inheritedListeners } = this.$listeners;
      return inheritedListeners;
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
