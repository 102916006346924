import Vue from 'vue'

const SCROLL_SPEED = 10

Vue.directive('table-drag-col', {
  inserted(el, bind, vnode) {
    if (!bind.value) return

    /**
     * Initial steps
     */
    const params = JSON.parse(bind.value)
    let group = params?.namespace,
      scroll = null,
      parent = document.getElementById(params?.parent),
      id = params?.id

    // Validation params
    if (!parent || !id || !group) {
      debug('Bad params in table-dragg item', parent, id, group)
      return
    }

    el.setAttribute('data-table-drag-item', id)
    el.classList.add('table-drag-item')

    /**
     * Variables
     */

    const blockY = params?.blockY

    let shadow = null,
      isPressed = false,
      isDragged = false,
      scrollInterval = null,
      startDragPos = { x: 0, y: 0 },
      elBoundsPos = { x: 0, y: 0 }

    /**
     * Handle drop on element
     */
    const findAndEmitNewPosition = () => {
      const hoverElement = parent.querySelector(
        '.table-drag-item.pointer-focus'
      )
      if (!hoverElement) return

      let l = hoverElement.parentNode.childNodes

      let fetIn = () => {
        for (let i = 0; i < l.length; i++) if (l[i] == hoverElement) return i

        return 0
      }

      let index = fetIn()

      el.dispatchEvent(
        new CustomEvent('table-drag-drop', {
          detail: { el, position: index + 1, id },
        })
      )
    }

    /**
     * Handle end dragg
     */
    const mouseUp = event => {
      isPressed = false

      if (isDragged) {
        try {
          findAndEmitNewPosition()
        } catch (error) {
          debug('Error mouse up table dragg active', error)
        }
      }

      isDragged = false

      document.removeEventListener('mouseup', mouseUp)
      document.removeEventListener('mousemove', mouseMove)

      parent.classList.remove('table-drag-active', 'drag-left', 'drag-right')

      Array.from(
        document.querySelectorAll('.table-drag-item.pointer-focus')
      ).forEach(el => el.classList.remove('pointer-focus'))

      clearScrollInterval()

      if (shadow) document.body.removeChild(shadow)
    }

    /**
     * Handle start dragg
     */
    const mouseDown = event => {
      isPressed = true

      scroll = document.getElementById(params?.scroll)

      shadow = document.createElement('div')
      shadow.classList.add('table-drag-shadow')
      document.body.appendChild(shadow)

      let elBounds = el.getBoundingClientRect()

      shadow.style.width = elBounds.width + 'px'
      shadow.style.height = elBounds.height + 'px'

      elBoundsPos.x = elBounds.x - event.x
      elBoundsPos.y = elBounds.y - event.y + elBounds.height / 2

      startDragPos = {
        x: event.x,
        y: event.y,
      }

      document.addEventListener('mouseup', mouseUp)
      document.addEventListener('mousemove', mouseMove)
    }

    /**
     * Handle move dragg
     */
    const mouseMove = event => {
      event.preventDefault()

      window.requestAnimationFrame(() => {
        if (!shadow || !isPressed) return

        if (
          !(
            Math.abs(startDragPos.x - event.x) > 10 ||
            Math.abs(startDragPos.y - event.y) > 10
          )
        ) {
          return null
        }

        if (!isDragged) {
          isDragged = true

          parent.classList.add('table-drag-active')

          // el.dispatchEvent(new CustomEvent('dragg-start', el))

          if (scroll && !scrollInterval)
            scrollInterval = setInterval(() => {
              let scrollElRect = scroll.getBoundingClientRect(),
                xOfScroll = window.mouse.x - scrollElRect.left

              if (scroll.scrollWidth > scroll.clientWidth) {
                if (xOfScroll > scroll.clientWidth - 100)
                  scroll.scrollLeft += SCROLL_SPEED
                else if (xOfScroll < 100) scroll.scrollLeft -= SCROLL_SPEED
              } else clearScrollInterval()
            }, 20)
        }

        if (startDragPos.x > event.x) {
          parent.classList.add('drag-left')
          parent.classList.remove('drag-right')
        } else {
          parent.classList.add('drag-right')
          parent.classList.remove('drag-left')
        }

        if (blockY) shadow.style.top = el.getBoundingClientRect().top + 'px'
        // el.style.top = `${event.y + elBounds.y - el.clientHeight / 2}px`
        else shadow.style.top = event.clientY + 'px'

        shadow.style.left = event.clientX + elBoundsPos.x + 'px'
      })
    }

    const clearScrollInterval = () => {
      if (scrollInterval) {
        clearInterval(scrollInterval)
        scrollInterval = null
      }
    }

    /**
     * Add listeners
     */

    el.addEventListener('mousedown', mouseDown)

    el.addEventListener('mouseenter', event => {
      event.target.classList.add('pointer-focus')
    })

    el.addEventListener('mouseleave', event => {
      event.target.classList.remove('pointer-focus')
    })
  },
})
