<template lang="pug">
.checklist-item(
  v-onclick='() => handleClickOnItem()',
  :class='{ checked: item.checked }'
)
  .border-container
    .header
    .body
      // TODO: replace with ./ChecklistItemName.vue
      ui-form-textarea(:editable='false', :autoresize='true', :value='item.name')
        template(v-slot:before)
          ui-checkbox.grey(
            @click='e => e.stopPropagation()',
            v-model='checkModel'
          )

        template(v-slot:after)
          ChecklistItemPictograms(
            :count-comments="item.countComments"
            :count-files="item.countFiles"
            :total-form-answers="item.totalFormAnswers"
            :total-form-fields="item.totalFormFields"
          )

    .footer
      Duedate(:task='item')
      .users-in-task(v-if='item.users.length > 0')
        ui-avatar(
          v-for='user in item.users',
          :key='user.id',
          :user='user',
          :size='1.6'
        )
</template>

<script>
import { mapActions } from 'vuex'

import ChecklistItemName from './ChecklistItemName.vue';
import ChecklistItemPictograms from './ChecklistItemPictograms.vue';
import Duedate from './Duedate'

export default {
  name: 'ChecklistItem',

  components: {
    ChecklistItemName,
    ChecklistItemPictograms,
    Duedate,
  },

  props: {
    item: {
      required: true,
    },
    checklist: {
      required: true,
    },
  },

  computed: {
    checkModel: {
      get() {
        return this.item.checked
      },
      set(value) {
        if (!this.item.canChangeStatus) {
          return null
        }

        this.updateChecklistItem({
          checked: value,
          checklistId: this.item.checklistId,
          itemId: this.item.id,
        }).catch(error => {
          this.$notify.error(this.$utils.response(error))
        })
      },
    },
  },

  methods: {
    handleClickOnItem() {
      this.openModal({
        name: 'UpdateChecklistItem',
        params: {
          itemId: this.item.id,
          checklist: this.checklist,
          canChangeProject: false,
          params: {
            preventClick: true,
          },
        },
      })
    },

    ...mapActions('Checklists', ['updateChecklistItem']),
  },
}
</script>
