/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download-light': {
    width: 14,
    height: 16,
    viewBox: '0 0 14 16',
    data: '<path pid="0" d="M13.92 15.35c0 .33-.28.61-.62.61H.62a.62.62 0 01-.62-.61c0-.34.28-.61.62-.61H13.3a.6.6 0 01.62.6zM6.96 0a.62.62 0 00-.62.61v9.94L3.9 8.15a.63.63 0 00-.88 0 .61.61 0 000 .88l3.5 3.45c.12.12.28.18.44.18.16 0 .32-.06.44-.18l3.5-3.45a.61.61 0 000-.87.63.63 0 00-.88 0l-2.44 2.4V.62A.62.62 0 006.96 0z" _fill="#7F848B"/>'
  }
})
