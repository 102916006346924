/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trash': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M10.784 1.881a.486.486 0 1 1 0 .973h-.99v7.232c0 .985-.798 1.784-1.783 1.784H3.34a1.784 1.784 0 0 1-1.784-1.784V2.854h-.99a.487.487 0 0 1 0-.973h3.05v-.616C3.616.638 4.123.13 4.751.13H6.6a1.135 1.135 0 0 1 1.135 1.135v.616h3.049zm-6.195-.616v.616h2.173v-.616a.162.162 0 0 0-.162-.162H4.752a.162.162 0 0 0-.163.162zm4.217 8.838l.015-7.249h-6.29v7.249c0 .448.362.81.81.81h4.654a.811.811 0 0 0 .81-.81zM6.892 9.73a.487.487 0 0 1-.487-.487V4.395a.486.486 0 1 1 .973 0v4.848a.487.487 0 0 1-.486.487zm-2.433 0a.487.487 0 0 1-.486-.487V4.395a.486.486 0 1 1 .973 0v4.848a.487.487 0 0 1-.487.487z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
