export default {
  id: null,
  statuses: [],
  projectStatuses: [],
  users: [],
  labels: [],
  startAt: '',
  endAt: '',
  sort: {
    type: null,
    field: null,
  },
  fields: {
    name: {
      fixed: true,
      position: 1,
      width: 200,
      sortable: true,
      visible: true,
      require: true,
    },
    number: {
      position: 2,
      width: null,
      sortable: true,
      visible: true,
    },
    status: {
      position: 3,
      width: null,
      sortable: true,
      visible: true,
    },
    projectStatuses: {
      position: 4,
      width: 150,
      sortable: true,
      visible: true,
    },
    users: {
      position: 5,
      width: null,
      sortable: false,
      visible: true,
    },
    labels: {
      position: 6,
      width: 200,
      sortable: false,
      visible: true,
    },
    startAt: {
      position: 7,
      width: null,
      sortable: true,
      visible: true,
    },
    endAt: {
      position: 8,
      width: null,
      sortable: true,
      visible: true,
    },
    checklists: {
      position: 9,
      width: null,
      sortable: true,
      visible: true,
    },
    contactPerson: {
      position: 10,
      width: null,
      sortable: true,
      visible: true,
    },
    contactPersonPhone: {
      position: 11,
      width: null,
      sortable: true,
      visible: true,
    },
    contactPersonEmail: {
      position: 12,
      width: null,
      sortable: true,
      visible: true,
    },
    location: {
      position: 13,
      width: null,
      sortable: true,
      visible: true,
    },
    createdAt: {
      position: 14,
      width: 200,
      sortable: true,
      visible: false,
    },
    archivedAt: {
      position: 15,
      width: 200,
      sortable: true,
      visible: false,
    },
    totalRevenue: {
      position: 16,
      width: 200,
      sortable: true,
      visible: false,
    },
    totalCosts: {
      position: 17,
      width: 200,
      sortable: true,
      visible: false,
    },
    hoursBudget: {
      position: 18,
      width: 200,
      sortable: true,
      visible: false,
    },
    hoursRegisteredCount: {
      position: 19,
      width: 200,
      sortable: true,
      visible: false,
    },
    hoursPlannedCount: {
      position: 19,
      width: 200,
      sortable: true,
      visible: false,
    },
    hoursRemaining: {
      position: 20,
      width: 200,
      sortable: true,
      visible: false,
    },
    profit: {
      position: 21,
      width: 200,
      sortable: true,
      visible: false,
    },
    profitPercents: {
      position: 22,
      width: 200,
      sortable: true,
      visible: false,
    },
    totalInvoiced: {
      position: 23,
      width: 200,
      sortable: true,
      visible: false,
    },
    totalNotInvoiced: {
      position: 24,
      width: 200,
      sortable: true,
      visible: false,
    },
  },
}
