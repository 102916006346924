/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'document-example': {
    width: 12,
    height: 14,
    viewBox: '0 0 12 14',
    data: '<path pid="0" d="M11.88 3.7a.52.52 0 00-.03-.05l-.02-.02a.16.16 0 00-.03-.03L8.14.24a.38.38 0 00-.11-.07.39.39 0 00-.13-.02H1.97C.93.15.09.95.09 1.95v10.09c0 1 .84 1.8 1.88 1.8h8.06c1.04 0 1.88-.8 1.88-1.8v-8.2c0-.05-.01-.1-.04-.14zM8.25 1.35l2.33 2.13H8.25V1.35zm1.77 11.77H1.97a1.1 1.1 0 01-1.13-1.08V1.96c0-.6.5-1.08 1.13-1.08H7.5v2.96c0 .2.17.36.37.36h3.28v7.84c0 .6-.5 1.08-1.12 1.08h-.01zM9.5 8.65c0 .2-.17.36-.38.36H2.88a.37.37 0 01-.37-.36c0-.2.17-.36.37-.36h6.24c.1 0 .2.03.27.1s.11.16.11.26zm0 2.12c0 .2-.17.36-.38.36H2.88a.37.37 0 01-.37-.36c0-.2.17-.36.37-.36h6.24c.1 0 .2.04.27.1.07.07.11.17.11.26z" _fill="#34335C"/>'
  }
})
