/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clip': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.206 11.445l-6.662 5.979-3.587 3.245c-2.306 2.05-5.979 2.05-8.2 0-2.305-2.05-2.305-5.38 0-7.43l3.588-3.246.683-.598 7.345-6.576c1.538-1.367 4.015-1.367 5.466 0 1.538 1.366 1.538 3.587 0 4.953l-7.942 7.09-2.392 2.135a2.078 2.078 0 0 1-2.733 0c-.769-.684-.769-1.794 0-2.477l8.2-7.43h.085a.826.826 0 0 0 0-1.197c-.342-.341-1.025-.341-1.367 0l-8.2 7.345c-1.536 1.367-1.536 3.588 0 4.954 1.538 1.367 4.015 1.367 5.467 0l10.25-9.224-.855-.769.854.77c2.306-2.05 2.306-5.382 0-7.432-2.306-2.05-5.978-2.05-8.2 0l-11.7 10.506c-3.075 2.733-3.075 7.174 0 9.907 3.075 2.733 7.943 2.733 11.018 0l10.249-9.224a.826.826 0 0 0 0-1.196c-.342-.427-1.025-.427-1.367-.085z" _fill="#34335C" fill-rule="evenodd"/>'
  }
})
