/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'zoom-loupe': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9 2a7 7 0 1 0 0 14A7 7 0 0 0 9 2zm7.03 12.62a9 9 0 1 0-1.41 1.41l3.67 3.68a1 1 0 0 0 1.42-1.42l-3.68-3.67zM5 9a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1z" _fill="#fff"/>'
  }
})
