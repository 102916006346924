<template lang="pug">
  .ui.date-input(:class="rootClasses")
    UiInputC.pointer(
      :value="viewDate"
      :placeholder="placeholder"
      readonly
      :error="error"
      @click.native="openCalendarModal"
    )
      template(v-if="!readonly && clearable && localDate" #after)
        .after-icon.small(@click.stop="clearDate")
          ui-svg(name="cancel" kind="bali-hai")
</template>

<script>
import { isMoment } from 'moment';

export default {
  name: 'UiDateInput',

  props: {
    /**
     * Date as Moment object
     * @type {import('moment').Moment}
     */
    date: {
      type: Object,
      default: null,
    },

    placeholder: {
      type: String,
      default: '',
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localDate: null,
    };
  },

  computed: {
    rootClasses() {
      const { error } = this;
      return {
        'date-input--has_error': error,
      };
    },

    viewDate() {
      if (isMoment(this.localDate)) {
        return this.localDate.format('DD MMM YYYY');
      }

      return null
    },
  },

  watch: {
    date: {
      immediate: true,
      handler(date) {
        if (isMoment(date)) {
          this.localDate = date.clone();
        } else {
          this.localDate = null;
        }
      }
    }
  },

  methods: {
    updateDate(date) {
      this.localDate = date;
      this.$emit('update:date', this.localDate);
    },

    openCalendarModal(e) {
      if (this.readonly) return;

      this.openModal({
        name: 'Calendar',
        params: {
          el: e.target,
          date: this.localDate,
          position: '1 7',
          callback: date => {
            this.updateDate(date)
          },
        },
      })
    },

    clearDate() {
      this.updateDate(null);
    },
  },
};
</script>

<style lang="sass" src="./styles.sass"></style>
