/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dots': {
    width: 16,
    height: 16,
    viewBox: '0 0 5 23',
    data: '<g fill-rule="evenodd" clip-rule="evenodd" _fill="#34335c"><path pid="0" d="M0 11.5C0 10.1 1.1 9 2.5 9S5 10.1 5 11.5 3.9 14 2.5 14 0 12.9 0 11.5zM0 20.5C0 19.1 1.1 18 2.5 18S5 19.1 5 20.5 3.9 23 2.5 23 0 21.9 0 20.5zM0 2.5C0 1.1 1.1 0 2.5 0S5 1.1 5 2.5 3.9 5 2.5 5 0 3.9 0 2.5z"/></g>'
  }
})
