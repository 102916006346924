/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.34 2.68h.95a2.71 2.71 0 012.69 2.73v13.83c0 1.51-1.21 2.74-2.7 2.74H5.69A2.71 2.71 0 013 19.24V5.41c0-1.5 1.2-2.73 2.69-2.73h.9V1h1.79v1.68h7.16V1h1.8v1.68zm1.17 17.48c.27 0 .67-.41.67-.92V8.08H4.8v11.16c0 .5.4.92 1.12.92h12.6z" _fill="#888D93"/><path pid="1" d="M13.11 12.74H6.98v5.14h6.13v-5.14z" _fill="#888D93"/>'
  }
})
