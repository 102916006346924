import * as TYPES from '@/store/types';

// initial state
const state = {
  checkedItems: [],
  checkedItemIds: new Set(),
  showPanel: false,
  showPanelPermanently: false,
  isShowTaskOptions: false,
};

// actions
const actions = {
  setFlag: ({ commit }, value) => {
    commit(TYPES.TASK_OPTIONS.SET_MODAL_FLAG, value);
  },
  showTaskHeaderOptions: ({ commit }, value) => {
    commit(TYPES.TASK_OPTIONS.SHOW_TASK_OPTIONS, value);
  },
  clear: ({ commit }) => {
    commit(TYPES.TASK_OPTIONS.UCHECK_ALL);
  },
  tooglePanel: ({ commit, state }, value) => {
    if (!state.showPanelPermanently && !value) commit(TYPES.TASK_OPTIONS.TOGGLE_PANEL, value);
  },
  toogleItems: ({ commit }, { checked, items, fromPanel }) => {
    commit(TYPES.TASK_OPTIONS.TOGGLE_ALL_ITEMS, { checked, items, fromPanel });
  },
  updateCheckedItems: ({ commit }, { item, checked }) => {
    commit(TYPES.TASK_OPTIONS.UPDATE_ITEM, { item, checked });
  },
};
// mutations
const mutations = {
  [TYPES.TASK_OPTIONS.SET_MODAL_FLAG](state, value) {
    state.showPanelPermanently = value;
  },
  [TYPES.TASK_OPTIONS.SHOW_TASK_OPTIONS](state, value) {
    state.isShowTaskOptions = value;
  },
  [TYPES.TASK_OPTIONS.UCHECK_ALL](state) {
    state.checkedItems = [];
    state.checkedItemIds = new Set();
  },
  [TYPES.TASK_OPTIONS.TOGGLE_PANEL](state, value) {
    state.showPanel = value;
  },
  [TYPES.TASK_OPTIONS.TOGGLE_ALL_ITEMS](state, { checked, items, fromPanel }) {
    if (checked) {
      state.checkedItems = [...items];
      state.checkedItemIds = new Set(items.map(i => i.id));
    } else {
      state.checkedItems = [];
      state.checkedItemIds = new Set();
      state.showPanel = fromPanel ? true : false;
    }
  },
  [TYPES.TASK_OPTIONS.UPDATE_ITEM](state, { item, checked }) {
    if (checked) {
      state.showPanel = true;
    }
    const { id, users, checklistId } = item;
    if (checked) {
      state.checkedItems.push({ id, users, checklistId });
      state.checkedItemIds = new Set([...state.checkedItemIds, id]);
    } else {
      state.checkedItems = state.checkedItems.filter(i => i.id !== id);
      
      const newIds = new Set([...state.checkedItemIds]);
      newIds.delete(id);
      state.checkedItemIds = newIds;

      if (!state.checkedItems.length) {
        state.showPanel = false;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
