/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.28.2C4.65.05-.02 4.53 0 9.98a9.9 9.9 0 0 0 9.96 9.84A9.9 9.9 0 0 0 20 10.05c.02-5.5-4.52-9.72-9.72-9.85zm4.04 9.47c.17.14.33.3.46.48l-.18.23a.93.93 0 0 1-.24.27 531.73 531.73 0 0 1-6.06 3.49c-.5.29-.9.07-.9-.5-.02-2.32-.02-4.63 0-6.95 0-.57.4-.77.9-.48 2.02 1.14 4.02 2.3 6.02 3.46z" _fill="#80848B"/>'
  }
})
