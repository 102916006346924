import VueI18n from '../../i18n'
import TitleBuilder from '@/plugins/titleBuilder'

export default (to, _, next) => {
  TitleBuilder.init()

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title)

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags)

  if (nearestWithTitle) {
    TitleBuilder.add(VueI18n.t(nearestWithTitle.meta.title))
  }

  TitleBuilder.compile()

  Array.from(
    document.querySelectorAll('[data-vue-router-controlled]')
  ).map(el => el.parentNode.removeChild(el))

  if (!nearestWithMeta) return next()

  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key])
      })

      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    .forEach(tag => document.head.appendChild(tag))

  next()
}
