<template lang="pug">
  label.rounded-checkbox(:class="{active: checked}")
    input#checkbox(type='checkbox' :checked="checked"  @change="onChange")
    .rounded-checkbox__round
      ui-svg(
        name="check-white"
      ).white
</template>
  
<script>
  export default {
    props: {
      checked: {
        require: false,
        default: false,
        type: Boolean
      }
    },
    methods: {
      onChange(event) {
        this.$emit('click', event.target.checked)
      },
    },
  }
</script>
  
<style lang="sass" scoped src="./styles.sass"></style>
  