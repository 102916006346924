/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus-thin': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<g _fill="#FFF"><path pid="0" d="M.85 5.9a.84.84 0 1 0 0 1.7h11.8a.84.84 0 1 0 0-1.7H.84z"/><path pid="1" d="M7.6.85a.84.84 0 1 0-1.7 0v11.8a.84.84 0 1 0 1.7 0V.84z"/></g>'
  }
})
