<template lang="pug">
div(
  v-click-outside="e => $emit('close', e)"
)
  ui-input(
    v-if="input.show"
    v-model='name',
    :placeholder='input.placeholder'
    :maxlength="maxlength",
  )
  .ui.find-member(
    :class="{ 'in-border': inBorder }"
  )
    .header
      //- ui-input.icons.search(
      //-   v-model="filter.search",
      //-   @input="search",
      //-   :placeholder="$t('ui.selectMembers.searchPlaceholder')")

    .body
      ui-scrollbar.list.members
        .item(
          v-for="(member, i) in listCanSelect",
          :key="i",
          @click.prevent="pickUser(member)")

          ui-avatar(:user="member")
          .name {{ member.fullName }}

    .footer
      UiButton.bali-hai.wide(
        @click="handleSubmit"
      ) {{ $t("ui.selectMembers.ready") }}
</template>

<script>
export default {
  props: {
    value: {
      require: false,
      type: String,
      default: '',
    },

    listUsers: {
      require: false,
      type: Array,
      default: () => [],
    },

    listIgnore: {
      require: false,
      type: Array,
      default: () => [],
    },

    input: {
      type: Object,
      default: {
        show: false,
        placeholder: '',
      },
    },

    inBorder: {
      type: Boolean,
      default: false,
    },

    maxlength: {
      type: Number,
      require: false,
      default: 524288,
    },
  },

  data() {
    return {
      filter: [],
      name: '',
    }
  },

  computed: {
    listCanSelect() {
      const ids = [...this.listIgnore]

      return this.listUsers.filter(e => {
        if (ids.includes(e.id)) return false

        return this.model.length > 0
          ? e.fullName.toLowerCase().includes(this.model.toLowerCase())
          : true
      })
    },

    model: {
      get() {
        return !this.input.show ? this.value : this.name
      },

      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },

  methods: {
    pickUser(user) {
      // setTimeout(() => {
      //   this.model = this.model.find(e => e.id === user.id)
      //     ? this.model.filter(e => e.id !== user.id)
      //     : [...this.model, user]
      // }, 100)
      this.model = user.fullName
      this.$emit('pick', user)
    },

    handleSubmit() {
      this.$emit('submit', this.model)
    },

    search(val) {
      // this.filter.search = val
      // if (this.localSearch) {
      //   this.filter.result = this.listUsers.filter(e =>
      //     e.fullName.toLowerCase().includes(this.filter.search.toLowerCase())
      //   )
      // }
      // this.$emit('search', val)
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
