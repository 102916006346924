/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'duplicate': {
    width: 68,
    height: 68,
    viewBox: '0 0 68 68',
    data: '<path pid="0" d="M55.6 50.8H29.2a12 12 0 01-12-12V12.4a12 12 0 0112-12h26.4a12 12 0 0112 12v26.4a12 12 0 01-12 12zm4.83-38.36a4.8 4.8 0 00-4.8-4.8h-26.4a4.8 4.8 0 00-4.8 4.8v26.4a4.8 4.8 0 004.8 4.8h26.4a4.8 4.8 0 004.8-4.8v-26.4zm-7.22 16.8h-7.24v7.2a2.38 2.38 0 01-2.38 2.38h-2.4a2.38 2.38 0 01-2.38-2.38v-7.2h-7.25a2.38 2.38 0 01-2.37-2.37v-2.45a2.38 2.38 0 012.38-2.38h7.24V14.8a2.38 2.38 0 012.38-2.38h2.4a2.38 2.38 0 012.38 2.38v7.24h7.24a2.38 2.38 0 012.38 2.38v2.45a2.38 2.38 0 01-2.38 2.38zm-40.79 31.2h21.56a4.8 4.8 0 004.79-4.72h7.22A12 12 0 0134 67.6H12.4a12 12 0 01-12-12V34a12 12 0 0112-12v7.3a4.8 4.8 0 00-4.78 4.8v21.54a4.8 4.8 0 004.8 4.8z" _fill="#34335C"/>'
  }
})
