<template lang="pug">
  .ui-tag(:class="rootClasses")
    slot
</template>

<script>
export default {
  name: 'UiTag',

  props: {
    /**
     * The kind of tag
     * @values success, error
     */
    kind: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    rootClasses() {
      const kindModifier = `ui-tag--kind_${this.kind}`;

      return {
        [kindModifier]: Boolean(this.kind),
      };
    }
  },
};
</script>

<style lang="sass" src="./styles.sass"></style>
