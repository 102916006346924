/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-plus': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#FF805D" fill-rule="evenodd"><path pid="0" d="M9.5 1.571A3.917 3.917 0 0 1 13.429 5.5 3.917 3.917 0 0 1 9.5 9.429 3.917 3.917 0 0 1 5.571 5.5 3.917 3.917 0 0 1 9.5 1.571M9.5 0A5.512 5.512 0 0 0 4 5.5C4 8.528 6.472 11 9.5 11S15 8.528 15 5.5 12.528 0 9.5 0M16.487 13a.871.871 0 0 0-.858.884v1.744h-1.744a.873.873 0 0 0-.025 1.744h1.769v1.744a.872.872 0 1 0 1.744.024v-1.768h1.744a.872.872 0 0 0 .024-1.744h-1.768v-1.744a.874.874 0 0 0-.86-.884h-.026z"/><path pid="1" d="M9.566 12c-4.454 0-6.843.612-8.206 1.994-.682.69-1.038 1.562-1.2 2.45C-.003 17.331 0 18.25 0 19.2c0 .442.33.8.736.8H12.14c.407.006.741-.347.747-.789.006-.441-.319-.805-.726-.811H1.515c.012-.582.007-1.194.09-1.644.122-.668.329-1.157.758-1.592.86-.87 2.884-1.564 7.203-1.564 1.436 0 2.626.074 3.601.21.403.057.772-.25.827-.688.053-.439-.23-.841-.634-.9h-.007C12.296 12.074 11.05 12 9.566 12z"/></g>'
  }
})
