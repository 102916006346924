<template lang="pug">
  .media-panel
    .header
      .control-panel
        .header
          template(v-if="!selectedMedia")
            .control-item(
              v-if="canEditMedia",
              @click="handleCreateFolder")

              ui-svg.dark.thumb(name="folder-plus")
              .placeholder {{ $t('mediaPanel.newFolder') }}

            .control-item(@click="handleClickUploadButton")
              ui-svg.dark.thumb(name="upload-arrow")
              .placeholder {{ $t('mediaPanel.upload') }}
              .hidden(v-if="refreshStateInputs")
                ui-input.shadow(
                  ref="fileSelectRef",
                  type="file",
                  @files="filesChange",
                  :multiple="true")

                input(
                  ref="folderSelectRef",
                  type="file",
                  directory,
                  webkitdirectory,
                  mozdirectory,
                  @change="handleInputUploadFolder")

              ui-svg.arrow.dark.rotate-down(name="arrow")

          template(v-else)
            .control-item(
              v-if="showDownloadInControl && !selectedFilesHasLink",
              @click="handleDownloadSelectedMedia")

              ui-svg.dark.thumb(name="download")
              .placeholder {{ $t('mediaPanel.downloadFiles') }}

            template(v-if="canMoveSelectedMedia")
              .control-item(@click="handleMoveSelectedMedia")
                ui-svg.dark.thumb(name="move-to-folder")
                .placeholder {{ $t('mediaPanel.moveToFolder') }}

              .control-item(@click="handleDeleteSelectedMedia")
                ui-svg.dark.thumb(name="trash")
                .placeholder {{ $t('mediaPanel.deleteFiles') }}

        .body
        .footer
          .control-view(@click="handleClickSort")
            ui-svg.thumb(name="sort" kind="dark")

          .control-view(@click="toggleShowMediaStyle")
            ui-svg.thumb(
              v-if="showMediaStyle == VIEWS.BLOCK"
              name="sort-list"
              kind="dark"
            )

            ui-svg.thumb(
              v-else-if="showMediaStyle == VIEWS.LINE"
              name="sort-block"
              kind="dark"
            )

    .body(
      ref="body",
      @dragenter="dragDrop = true",
      @mouseleave="dragDrop=false",
      @dragover.prevent,
      @drop="handleDropFile") 

      .header(:class="{ 'active-search': !!searchInput }")
        .title
          span.pointer(@click="handleClickBreadcrumbs(undefined)") {{ $t('mediaPanel.mediaAndDocument') }}
       
        .count-selected(
          v-show="selectedMedia",
          @click="handleUnselectMedia")

          span {{ selectedMedia }} {{ $t('mediaPanel.selected') }}
          ui-svg.grey(name="cross")

        .search-input
          ui-svg.bali-hai.search(name="search")
          input(
            v-model="searchInput",
            type="text",
            name="searchTopBar",
            @input="handleSearch",
            :placeholder="$t('mediaPanel.search')")

          ui-svg.pointer.clear(
            name="cancel-circle"
            kind="bali-hai"
            @click="searchInput=''"
          )

      .select-all(v-if="!showLineTableHeader")
        label(for="select-all-media") {{ $t('mediaPanel.selectAll') }}
        ui-checkbox.circle-blue(@click.stop="selectAll" id="select-all-media" :value="allSelected") 

      .breadcrumbs
        .access-mode(v-if="accessMode")
          ui-svg(name="lock" kind="dark")

        .item(
          v-for="folder in breadcrumbs",
          :key="folder.id",
          @click="handleClickBreadcrumbs(folder.id)")

          span {{ folder.name }}
          ui-svg.arrow.rotate-right(name="arrow")    

      .body
        .add-file-zone(
          v-click-outside="() => {dragDrop=false}",
          :class="{ 'whithout-opacity': showAddFileZone && !dragDrop }",
          v-if="showAddFileZone && !searchInput")

          .info
            .img
              ui-svg.lock(
                v-if="accessMode"
                name="lock"
                kind="dark"
              )
              img.add-file-zone__image(:src="$options.dropFileSrc" alt="drop file")

            template(v-if="dragDrop")
              .title(v-if="accessMode") {{ $t('mediaPanel.draggAndDropTextLock') }}
              .title(v-else) {{ $t('mediaPanel.draggAndDropText') }}

            template(v-else)
              .title  {{ $t('mediaPanel.draggEmptyFolder.title') }}
              .description(v-html="$t('mediaPanel.draggEmptyFolder.description')")      

        splitpanes.default-theme.line-table-header(
          v-if="showLineTableHeader"
          @resize="resizeColumn"
          :class="{ 'selected': allSelected }"
        )
          pane.name-pane(:size="columnSizes[0].size" min-size="30")
            .check
              ui-checkbox(
                id="select-all-media"
                kind="circle-blue"
                :value="allSelected"
                @click.stop="selectAll"
              )
            .preview
              ui-svg(name="document-example" kind="dark")
            .name
              span {{ $t('mediaPanel.lineHeader.name') }}
          pane.time(:size="columnSizes[1].size" min-size="10")
            span {{ $t('mediaPanel.lineHeader.created') }}
          pane(:size="columnSizes[2].size" min-size="5")
            .size
              span {{ $t('mediaPanel.lineHeader.size') }}
          pane(:size="columnSizes[3].size" min-size="9" max-size="9")
            .actions

        .gradient-preloader.preloader(
          v-if="loadedData",
          :class="showMediaStyle")

          template(v-if="showMediaStyle == VIEWS.LINE")
            .item(v-for="i in 11", :key="i")
              .square
              .rectangle

          template(v-else)
            .item(v-for="i in 20", :key="i")
              .square

        ui-scrollbar.list-media.collision-list-media(
          v-if="!loadedData",
          orientate="v",
          :class="[showMediaStyle]")

          template(v-for="(entity, i) in allMediaList")
            //- Folders
            template(v-if="entity.fIs == 'folder'")
              FolderItem(
                :key="i",
                :folder="entity",
                :view="showMediaStyle",
                :blockContextMenu="selectedMedia > 0",
                @open="handleOpenFolder(entity.id)",
                @select="handleSelectFolder(entity.id)",
                @move-to-folder="handleMoveSelectedMedia",
                :selected="selectedFolders.includes(entity.id)"
                :columnSizes="columnSizes"
              )

            //- Files
            template(v-else-if="entity.fIs == 'file'")
              FileItem(
                :key="i",
                :file="entity",
                :view="showMediaStyle",
                :blockContextMenu="selectedMedia > 0",
                @select="handleSelectFile(entity.id)",
                @share-to-chat="handleShareToChat(entity.id)"
                @move-to-folder="handleMoveSelectedMedia",
                :selected="selectedFiles.includes(entity.id)"
                :columnSizes="columnSizes"
              )

            //- Special access folder
            template(v-else-if="entity.fIs == 'accessFolder'")
              FolderItem.virtual(
                :key="i",
                v-if="!accessMode && !activeFolder && searchInput.length == 0 && canViewAccess",
                :folder="entity",
                :view="showMediaStyle",
                :blockContextMenu="true",
                @open="handleOpenAccessFolder"
                :columnSizes="columnSizes"
              )

        template(v-if="computedFiles.length + computedFolders.length == 0")
          .empty-search(v-if="searchInput")
            .title {{ $t('mediaPanel.emptySearch.title') }}
            .description(v-html="$t('mediaPanel.emptySearch.description')")

          .empty-media(v-else-if="activeFolder===undefined && accessMode == false")
            .description(v-html="$t('mediaPanel.draggFileForDownload')")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import dropFileSrc from '@/assets/img/shareFilesProejctInfo.svg';

/** Components */
import FileItem from './FileItem'
import FolderItem from './FolderItem'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

/** Mixins */
import breadcrumpsMix from './mixins/breadcrumbs'
import sortMix from './mixins/sort'
import { MediaFileModel } from '@/models'

import { iterateGroupActions } from '@/helpers/iterator.js';
import { isSafari } from '@/helpers/browser.js';

const VIEWS = {
  BLOCK: 'block',
  LINE: 'line',
}

export default {
  dropFileSrc,

  name: 'MediaPanel',

  mixins: [breadcrumpsMix, sortMix],

  props: {
    files: {
      type: Array,
      default: () => [],
    },

    folders: {
      type: Array,
      default: () => [],
    },

    canViewAccess: {
      type: Boolean,
      default: false,
    },

    canEditMedia: {
      type: Boolean,
      default: false,
    },

    loadedData: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    FileItem,
    FolderItem,
    Splitpanes,
    Pane,
  },

  data() {
    return {
      searchInput: '',
      media: [],
      selectedFiles: [],
      selectedFolders: [],
      activeFolder: undefined,
      accessMode: false,
      dragDrop: false,
      ignoreUpload: ['.DS_Store'],
      VIEWS,
      refreshStateInputs: true,
      columnSizes: [{ size: 55 }, { size: 23 }, { size: 13 }, { size: 9 }],
    }
  },

  computed: {
    ...mapGetters('App', ['showMediaStyle']),
    ...mapGetters('Projects', ['getProjectById']),
    ...mapGetters('Chats', ['getChatById']),

    showLineTableHeader() {
      if (this.showMediaStyle != VIEWS.LINE) return false

      return (
        this.computedFiles.length + this.computedFolders.length != 0 ||
        (this.activeFolder == undefined && this.accessMode == false)
      )
    },

    showAddFileZone() {
      if (this.dragDrop) return true
      if (this.activeFolder === undefined && this.accessMode == false)
        return false
      if (this.computedFiles.length + this.computedFolders.length == 0)
        return true

      return false
    },

    accessFolder() {
      return {
        name: this.$t('mediaPanel.accessFolder'),
        access: true,
        id: 0,
        parentId: null,
        filesCount: this.files.filter(e => e.access).length,
        foldersCount: this.folders.filter(e => e.access).length,
        fIs: 'accessFolder',
        createdAtM: (this.chat || this.project).createdAtM,
      }
    },

    selectedMedia() {
      return this.selectedFiles.length + this.selectedFolders.length
    },

    selectedFilesHasLink() {
      if (!this.selectedFiles || !this.selectedFiles.length) {
        return false
      }

      return this.selectedFiles.some(id => {
        return this.files.find(file => file.id == id && file.isLink)
      })
    },

    allMediaList() {
      return [
        this.accessFolder,

        ...this.computedFolders.map(e => {
          return { ...e, fIs: 'folder' }
        }),

        ...this.computedFiles.map(e => {
          return { ...e, fIs: 'file' }
        }),
      ].sort((a, b) =>
        this.$utils.$sort.fnSortByDate(
          { name: a.name, date: a.createdAtM },
          { name: b.name, date: b.createdAtM },
          this.sortMediaBy
        )
      )
    },

    computedFiles() {
      return this.files.filter(e => {
        const matchWithActiveFolder = Number(e.folderId ?? 0) === Number(this.activeFolder ?? 0);
        const matchWithAccessMode = this.accessMode == e.access;
        const matchWithSearch = this.searchInput.length > 0
          ? e.fileName.toLowerCase().includes(this.searchInput.toLowerCase())
          : true;
        return matchWithActiveFolder && matchWithAccessMode && matchWithSearch;
      });
    },

    computedFolders() {
      return this.folders.filter(e => {
        const matchWithActiveFolder = Number(e.parentId ?? 0) === Number(this.activeFolder ?? 0);
        const matchWithAccessMode = this.accessMode == e.access;
        const matchWithSearch = this.searchInput.length > 0
          ? e.name.toLowerCase().includes(this.searchInput.toLowerCase())
          : true;
        return matchWithActiveFolder && matchWithAccessMode && matchWithSearch;
      });
    },

    allSelected() {
      const selectedFileIds = new Set(this.selectedFiles);
      const selectedFolderIds = new Set(this.selectedFolders);
      const isAllFilesSelected = this.computedFiles.every(file => selectedFileIds.has(file.id));
      const isAllFoldersSelected = this.computedFolders.every(folder =>
        selectedFolderIds.has(folder.id)
      );
      return (
        (selectedFileIds.size > 0 || selectedFolderIds.size > 0) &&
        isAllFilesSelected &&
        isAllFoldersSelected
      );
    },

    showDownloadInControl() {
      return this.selectedFolders.length + this.selectedFiles.length > 0
    },

    chat() {
      return this.getChatById(this.$route.params.chatId)
    },

    project() {
      return this.getProjectById(this.$route.params.projectId)
    },

    canMoveSelectedMedia() {
      const files = this.selectedFiles.filter(id => {
        let file = this.files.find(e => e.id == id)
        return (
          file && (!file.canDelete || file.canDeleteTillTime <= this.$moment())
        )
      })

      const folders = this.selectedFolders.filter(id => {
        let folder = this.folders.find(e => e.id == id)
        return folder && !folder.canDelete
      })

      return (!files || !files.length) && (!folders || !folders.length)
    }
  },

  mounted() {
    let columnSizes = localStorage.getItem('project_column_sizes')
    if (columnSizes) {
      columnSizes = JSON.parse(columnSizes)
    }

    if (columnSizes && columnSizes.length) {
      if (!columnSizes[3]) {
        if (columnSizes[2].size >= 14) {
          columnSizes[2].size -= 9
        } else if (columnSizes[0].size >= columnSizes[1].size) {
          columnSizes[0].size -= 9
        } else {
          columnSizes[1].size -= 9
        }

        columnSizes.push({ size: 9 })
        localStorage.setItem(
          'project_column_sizes',
          JSON.stringify(columnSizes)
        )
      }
      this.columnSizes = columnSizes
    }
  },

  methods: {
    async scanFiles(item, arr) {
      if (item.isFile && this.ignoreUpload.includes(item.name)) return

      arr.push({ entry: item, id: null })

      if (item.isDirectory) {
        const entries = await new Promise(resolve =>
          item.createReader().readEntries(resolve)
        )

        for (let i = 0; i < entries.length; i++) {
          await this.scanFiles(entries[i], arr)
        }
      }
    },

    resizeColumn($event) {
      this.columnSizes = $event
      localStorage.setItem('project_column_sizes', JSON.stringify($event))
    },

    handleSearch() {},

    refreshInputs() {
      this.refreshStateInputs = false
      setTimeout(() => (this.refreshStateInputs = true), 100)
    },

    async handleDropFile(event) {
      event.stopPropagation()
      event.preventDefault()

      this.dragDrop = false

      if (!event.dataTransfer.files) return

      let mediaList = [],
        items = Array.from(event.dataTransfer.items).map(e =>
          e.webkitGetAsEntry()
        ).filter(i=>i)

      for (let i = 0; i < items.length; i++) {
        await this.scanFiles(items[i], mediaList)
      }

      if (mediaList.filter(e => e.entry.isDirectory).length > 0) {
        this.openModal({
          name: 'UploadFoldersProgress',
          params: {
            activeFolder: this.activeFolder,
            accessMode: this.accessMode,
            mediaList: mediaList,
          },
        })
        return
      }

      let filesList = []
      for (let i = 0; i < mediaList.length; i++) {
        if (!mediaList[i].entry.isFile) continue
        filesList.push(
          await new Promise(resolve => mediaList[i].entry.file(resolve))
        )
      }

      this.openModal({
        name: 'UploadFiles',
        params: {
          folderId: this.activeFolder,

          files: filesList.map(file => {
            return {
              file,
              name: file.name,
              type: this.$utils.getExtention(file.name),
              access: this.accessMode,
              errors: [],
              success: false,
              progress: 0,
              edit: false,
            }
          }),
          callback: file => { },
        },
      })
    },

    handleClickUploadButton(e) {
      this.openModal({
        name: 'UploadFileMenu',
        params: {
          el: e.target,
          position: '1 7',
          list: [
            {
              icon: 'document',
              placeholder: this.$t('mediaPanel.ctxMenuUpload.files'),
              callback: () => {
                this.$refs.fileSelectRef.$refs.fileUpload.click()
              },
            },
            {
              icon: 'folder',
              placeholder: this.$t('mediaPanel.ctxMenuUpload.folder'),
              callback: () => {
                this.$refs.folderSelectRef.click()
              },
              delimiter: true,
            },
            {
              icon: 'one-drive',
              placeholder: this.$t('mediaPanel.ctxMenuUpload.oneDrive'),
              callback: () => {
                this.openModal({
                  name: 'AddFileLink',
                  params: {
                    folderId: this.activeFolder,
                    projectId: this.$route.params.projectId,
                    linkType: MediaFileModel.linkTypes.ONE_DRIVE,
                    access: this.accessMode,
                  },
                })
              },
            },
            {
              icon: 'google-drive',
              placeholder: this.$t('mediaPanel.ctxMenuUpload.googleDrive'),
              callback: () => {
                this.openModal({
                  name: 'AddFileLink',
                  params: {
                    folderId: this.activeFolder,
                    projectId: this.$route.params.projectId,
                    linkType: MediaFileModel.linkTypes.GOOGLE_DRIVE,
                    access: this.accessMode,
                  },
                })
              },
            },
            {
              icon: 'dropbox',
              placeholder: this.$t('mediaPanel.ctxMenuUpload.dropbox'),
              callback: () => {
                this.openModal({
                  name: 'AddFileLink',
                  params: {
                    folderId: this.activeFolder,
                    projectId: this.$route.params.projectId,
                    linkType: MediaFileModel.linkTypes.DROPBOX,
                    access: this.accessMode,
                  },
                })
              },
            },
            {
              icon: 'link',
              placeholder: this.$t('mediaPanel.ctxMenuUpload.link'),
              callback: () => {
                this.openModal({
                  name: 'AddFileLink',
                  params: {
                    folderId: this.activeFolder,
                    projectId: this.$route.params.projectId,
                    linkType: MediaFileModel.linkTypes.LINK,
                    access: this.accessMode,
                  },
                })
              },
            },
          ],
        },
      })
    },

    handleInputUploadFolder(event) {
      let foldersList = [],
        filesList = []

      for (const key in event.target.files) {
        const file = event.target.files[key]
        if (typeof file !== 'object') continue

        const fileFolders = file.webkitRelativePath.split('/'),
          fileName = fileFolders.pop(),
          parentP = fileFolders.join('/')

        if (['.DS_Store'].includes(fileName)) continue

        foldersList.push(parentP)

        filesList.push({
          parent: parentP,
          fileName,
          file,
        })
      }

      this.openModal({
        name: 'UploadFoldersProgress',
        params: {
          activeFolder: this.activeFolder,
          accessMode: this.accessMode,
          foldersList: this._.union(foldersList),
          filesList,
        },
      })

      this.refreshInputs()
    },

    toggleShowMediaStyle() {
      this.updateShowMediaStyle(
        this.showMediaStyle == VIEWS.BLOCK ? VIEWS.LINE : VIEWS.BLOCK
      )
    },

    handleMoveSelectedMedia() {
      const files = [
        ...this.selectedFiles.map(id => this.files.find(e => id == e.id)),
      ],
        folders = [
          ...this.selectedFolders.map(id => this.folders.find(e => id == e.id)),
        ]

      this.openModal({
        name: 'MoveMediaToFolder',
        params: {
          folders: this.folders,
          accessFolder: this.canViewAccess ? this.accessFolder : undefined,
          selectedMedia: this.selectedMedia,
          ignoreFolders: [
            ...files.map(e => e.folderId),
            ...folders.map(e => e.id),
          ],
          callback: newFolderId =>
            this.handleMoveSelectedMediaAction(newFolderId, files, folders),
        },
      })

      this.handleUnselectMedia()
    },

    handleMoveSelectedMediaAction(newFolderId, files, folders) {
      let defAccess = 0,
        defFolderId = newFolderId

      switch (newFolderId) {
        case 0:
          defAccess = 1
          break

        case 'default':
          defFolderId = 0
          break
      }

      return new Promise((resolve, reject) => {
        Promise.all([
          ...files
            .filter(e => e.canEdit)
            .map(e =>
              this.updateFile({
                id: e.id,
                folderId: defFolderId,
                access: defAccess,
              })
            ),

          ...folders
            .filter(e => e.canEdit)
            .map(e =>
              this.updateFolder({
                folderId: e.id,
                parentId: defFolderId,
                access: defAccess,
              })
            ),
        ])
          .then(resolve)
          .catch(reject)
      })
    },

    handleOpenAccessFolder() {
      this.accessMode = true
      this.handleUnselectMedia()
    },

    handleOpenFolder(folderId) {
      this.activeFolder = folderId
      this.searchInput = ''
      this.handleUnselectMedia()
    },
    filesChange(files) {
      let uploadFiles = [...files].map(file => {
        return {
          file,
          name: file.name,
          type: this.$utils.getExtention(file.name),
          access: this.accessMode,
          errors: [],
          success: false,
          progress: 0,
          edit: false,
        }
      })

      this.openModal({
        name: 'UploadFiles',
        params: {
          files: uploadFiles,
          folderId: this.activeFolder,
        },
      })

      this.refreshInputs()
    },

    handleCreateFolder(event) {
      this.openModal({
        name: 'CreateFolder',
        params: {
          el: event.target,
          parentId: this.activeFolder,
          access: this.accessMode,
        },
      })
    },

    /**
     * Handle click on btn unselect files nad folders
     */
    handleUnselectMedia() {
      this.selectedFolders = []
      this.selectedFiles = []
      this.searchInput = ''
    },

    /**
     * Handle click on btn download selected files and folders
     */
    async handleDownloadSelectedMedia() {
      iterateGroupActions(
        [...this.selectedFiles],
        fileId => this.downloadFileById(fileId),
        isSafari ? 1 : 10,
        isSafari ? 10 : 100
      );

      if (this.selectedFolders.length > 0) {
        this.openModal({ name: 'DownloadFoldersProgress' })
      }

      this.selectedFolders.forEach(folderId => {
        const folder = this.folders.find(e => e.id == folderId)
        if (!folder) return

        setTimeout(() => {
          this.$events.emit('folders:addDownload', folder)
        }, 100)

        this.downloadZipFolder({ folder }).then(() => {
          this.$events.emit('folders:successDownload', folder)
          // this.selectedFiles = this._.remove(this.selectedFiles, fileId)
        })
      })

      // this.selectedFolders = []
      this.handleUnselectMedia()
    },
    /**
     * Select All Folders and Files
     */
    selectAll() {
      if (this.allSelected) {
        this.selectedFolders = [];
        this.selectedFiles = [];
      } else {
        this.selectedFiles = this.computedFiles.map(files => files.id);
        this.selectedFolders = this.computedFolders.map(folder => folder.id);
      }
    },
    /**
     * Handle click on btn delete selected files and folders
     */
    handleDeleteSelectedMedia() {
      this.openModal({
        name: 'ApproveRemove',
        params: {
          title: this.$t('mediaPanel.modalApproveDelete.title'),
          description: this.$t('mediaPanel.modalApproveDelete.description'),
          button: this.$t('mediaPanel.modalApproveDelete.button'),
          callback: () => {
            // HANDLE DELETE

            this.selectedFiles
              .filter(fileId => {
                let file = this.files.find(e => e.id == fileId)
                return (
                  file &&
                  file.canDelete &&
                  file.canDeleteTillTime > this.$moment()
                )
              })
              .forEach(fileId => {
                this.deleteFile(fileId).then(() => {
                  this.selectedFiles = this._.remove(this.selectedFiles, fileId)
                })
              })

            this.selectedFolders
              .filter(folderId => {
                let folder = this.folders.find(e => e.id == folderId)
                return folder && folder.canDelete
              })
              .forEach(folderId => {
                this.deleteFolder(folderId).then(() => {
                  this.selectedFolders = this._.remove(
                    this.selectedFolders,
                    folderId
                  )
                })
              })
          },
        },
      })
    },

    handleSelectFile(id) {
      this.selectedFiles = this._.xor(this.selectedFiles, [id])
    },

    handleSelectFolder(id) {
      this.selectedFolders = this._.xor(this.selectedFolders, [id])
    },

    handleShareToChat(id) {
      this.openModal({
        name: 'MediaShareToChats',
        params: this.$utils.defineNonReactive({
          projectId: this.project.id,
          onSubmit: ({ chatIds }) =>
            this.sendFilesToChats({ fileIds: [id], chatIds })
              .then(() => {
                this.$notify.success(this.$utils.response('success'));
              })
              .catch(error => {
                this.$notify.error(this.$t('responses.0076'));
                throw error;
              }),
        }),
      });
    },

    ...mapActions('Chats', ['sendFilesToChats']),
    ...mapActions('App', ['updateShowMediaStyle']),
    ...mapActions('Files', ['deleteFile', 'downloadFileById', 'updateFile']),
    ...mapActions('Folders', [
      'deleteFolder',
      'updateFolder',
      'downloadZipFolder',
    ]),
  },
}
</script>

<style lang="sass" src="./styles/src.sass"></style>
