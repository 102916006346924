<template lang="pug">
  quill-editor.ui-quill(
    v-model="model"
    ref="editor",
    :class="rootClasses",
    :options="editorOptions",
    :disabled="readonly",
    :style="rootStyles"
  )
</template>

<script>
import { upperFirst } from 'lodash';

export default {
  name: 'UiQuill',

  props: {
    placeholder: {
      type: String,
      default: '',
    },

    value: {
      type: String,
      default: '',
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    /**
     * @values small
     */
    size: {
      type: String,
      default: undefined,
    },

    /**
     * @values grey
     */
    kind: {
      type: String,
      default: undefined,
    },

    saveFormat: {
      type: Boolean,
      default: false,
    },

    modules: {
      type: Object,
      default: undefined,
    },

    hideToolbar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      subscriptions: [],
    };
  },

  computed: {
    model: {
      set(newVal) {
        this.$emit('input', newVal)
      },

      get() {
        return this.value
      },
    },

    editorOptions() {
      const { modules } = this;

      return {
        theme: 'snow',
        placeholder: this.placeholder,

        ...(modules ? { modules } : null),
      };
    },

    rootClasses() {
      const { size, kind, saveFormat, hideToolbar } = this;
      return {
        [`${size}`]: Boolean(size),
        [`${kind}`]: Boolean(kind),
        'save-format': saveFormat,
        'hide-toolbar': hideToolbar,
      };
    },

    rootStyles() {
      return {
        '--ql-tooltip-label-url': `"${this.$t('visitUrl')}:"`,
        '--ql-tooltip-label-url-modify': `"${this.$t('enterLink')}:"`,
        '--ql-tooltip-label-action': upperFirst(`"${this.$t('modify')}"`),
        '--ql-tooltip-label-action-editing': upperFirst(`"${this.$t('save')}"`),
        '--ql-tooltip-label-remove': upperFirst(`"${this.$t('delete')}"`),
      };
    },
  },

  methods: {
    blur() {
      this.$refs.editor.quill.root.blur()
    },

    setCursorAfterLength(length) {
      this.$nextTick(() => {
        const range = this.$refs.editor.quill.getSelection()
        this.$refs.editor.quill.setSelection(length, 1)
      })
    },

    enterNewLine() {
      if (!this.$refs.editor.quill) return

      const range = this.$refs.editor.quill.getSelection()
      this.$refs.editor.quill.insertText(range.index, '\n', 'user')
      this.$refs.editor.quill.setSelection(range.index + 1, 'silent')
    },
  },

  mounted() {
    const handler = event => {
      switch (event.keyCode) {
        case 13:
          if (event.ctrlKey || event.shiftKey) {
            // this.save(event)
          } else {
            this.enterNewLine()
          }

          break

        default:
          break
      }
    };

    const { container } = this.$refs.editor?.quill || {};

    if (container) {
      container.addEventListener('keydown', handler);

      this.subscriptions.push(() => {
        container.removeEventListener('keydown', handler);
      });
    } else {
      console.warn('[UiQuill] The quill container does not exist');
    }
  },

  beforeDestroy() {
    this.subscriptions.forEach((unsubscribe) => unsubscribe());
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>