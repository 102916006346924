/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'form-fields': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.63 2.35c0 .68-.49 1.35-1.31 1.35C.49 3.7 0 3.2 0 2.35 0 1.51.5 1 1.32 1c.82 0 1.31.67 1.31 1.35zm1.49.85V1.5H14v1.7H4.12zM14 12.5H4.12v-1.7H14v1.7zM1.32 8.43c.82 0 1.31-.68 1.31-1.36 0-.67-.49-1.35-1.31-1.35C.49 5.73 0 6.24 0 7.08c0 .85.5 1.36 1.32 1.36zm1.31 3.2c0 .68-.49 1.36-1.31 1.36C.49 13 0 12.5 0 11.65c0-.85.5-1.35 1.32-1.35.82 0 1.31.67 1.31 1.35zM14 8.28H4.12v-1.7H14v1.7z" _fill="#34335C"/>'
  }
})
