<template lang="pug">
  .media.item.file(
    :title="file.fileName",
    :class="[{selected, 'block-context': blockContextMenu}, view]",
    @click="handleOpen"
    @contextmenu.prevent="handleContextMenu"
  )

    template(v-if="view == VIEWS.BLOCK")
      .body
        .controls(@click.stop="handleClickControls")
          ui-checkbox.circle-blue(:value="selected", @click.stop="$emit('select')")

        .preview
          img(
            loading="lazy",
            v-if="file.isImage",
            :src="file.previewUrl")

          .atachment.file-ext(
            v-if="file.isAtachment",
            :class="[file.ext]")

          .atachment.file-ext(
            v-else-if="file.isLink",
            :class="getLinkClass(file.linkType)"
          )

      .footer
        .progress(:style="{width: `${file.percentDownload}%`}")
        .name
          span {{ file.fileName }}

        .time {{ file.createdAt | moment("DD-MM-YYYY, HH:mm") }}
  
    template(v-else)
      .name-column(:style="{ width: columnSizes.length ? `${columnSizes[0].size}%` : false }")
        .check(@click.stop="$emit('select')")
          ui-checkbox.circle-blue(:value="selected")

        .preview
          img(
            loading="lazy",
            v-if="file.isImage",
            :src="file.previewUrl")
          
          .atachment.file-ext(
            v-if="file.isAtachment",
            :class="[file.ext]")

          .atachment.file-ext(
            v-else-if="file.isLink",
            :class="getLinkClass(file.linkType)"
          )
        
        .name
          span {{ file.fileName }}

      .time.time-column(:style="{ width: columnSizes.length ? `${columnSizes[1].size}%` : false }")
        span {{ file.createdAt | moment("DD-MM-YYYY, HH:mm") }}

      .size-column(:style="{ width: columnSizes.length ? `${columnSizes[2].size}%` : false }")
        .size
          span {{ file.size | prettyBytes }}

      .actions(
        :style="{ width: columnSizes.length ? `${columnSizes[3].size}%` : false }"
        @click.stop="handleContextMenu"
      )
        ui-svg.rotate-left.grey(name="dots")

      .progress(:style="{width: `${file.percentDownload}%`}")
</template>

<script>
import { mapActions } from 'vuex'
import { MediaFileModel } from '@/models'

const VIEWS = {
  BLOCK: 'block',
  LINE: 'line',
}

export default {
  props: {
    file: {
      require: true,
      type: Object,
    },

    selected: {
      type: Boolean,
      default: false,
    },

    view: {
      type: String,
      default: VIEWS.BLOCK,
    },

    blockContextMenu: {
      type: Boolean,
      default: false,
    },
    columnSizes: {
      type: Array,
      default () {
        return []
      }
    },
  },

  data() {
    return {
      VIEWS,
    }
  },

  methods: {
    handleOpen(e) {
      this.$emit('open')
      this.handleClickControls(e)
    },

    handleContextMenu(e) {
      if (this.blockContextMenu) return

      let list = []
      if (!this.file.isLink) {
        list.push({
          placeholder: this.$t('mediaPanel.ctxMenu.download'),
          callback: () => {
            this.downloadFileById(this.file.id).catch(error =>
              this.$notify.error(this.$utils.response(error))
            )
          },
        })
      }

      if (this.file.canEdit) {
        if (this.file.isLink) {
          list.push({
            placeholder: this.$t('mediaPanel.ctxMenu.rename'),
            callback: () => {
              this.openModal({
                name: 'AddFileLink',
                params: {
                  id: this.file.id,
                  folderId: this.file.folderId,
                  projectId: this.file.projectId,
                  access: this.file.access,
                  name: this.file.fileName,
                  link: this.file.link,
                  linkType: this.file.linkType,
                },
              })
            },
          })
        } else {
          list.push({
            placeholder: this.$t('mediaPanel.ctxMenu.rename'),
            callback: () => {
              this.openModal({
                name: 'RenameMedia',
                params: {
                  name: this.file.fileName,
                  callback: newName =>
                    this.updateFile({ id: this.file.id, fileName: newName }),
                },
              })
            },
          })
        }

        list.push({
          placeholder: this.$t('mediaPanel.ctxMenu.moveTo'),
          callback: () => {
            this.$emit('select', this.file.id)
            this.$emit('move-to-folder')
          },
        })
      }

      list.push({
        placeholder: this.$t('mediaPanel.ctxMenu.shareToChat'),
        callback: () => {
          this.$emit('share-to-chat');
        },
      });

      if (this.file.canDelete && this.file.canDeleteTillTime > this.$moment())
        list = [
          ...list,
          {
            placeholder: this.$t('mediaPanel.ctxMenu.delete'),
            callback: () => {
              // HANDLE DELETE

              this.openModal({
                name: 'ApproveRemove',
                params: {
                  title: this.$t('mediaPanel.modalApproveDelete.title'),
                  description: this.$t(
                    'mediaPanel.modalApproveDelete.description'
                  ),
                  button: this.$t(
                    'mediaPanel.modalApproveDelete.button'
                  ),
                  callback: () => {
                    this.deleteFile(this.file.id).catch(error =>
                      this.$notify.error(this.$utils.response(error))
                    )
                  },
                },
              })
            },
          },
        ]

      this.openModal({
        name: 'ContextMenu',
        params: {
          // scroll: 'collision-list-media',
          el: e.target,
          position: 'bottom left',
          list,
        },
      })
    },

    handleClickControls(e) {
      if (this.file.isLink) {
        window.open(this.file.link)
      } else if (this.file.isImage || this.file.isVideo || this.file.isOffice || this.file.isPdf) {
        this.openModal({
          name: 'ViewMedia',
          params: {
            file: this.file,
            ...this.$route.params,
          },
        });
        return
      }
    },

    getLinkClass(linkType) {
      return MediaFileModel.linkClasses[linkType]
    },

    ...mapActions('Files', [
      'viewFile',
      'downloadFileById',
      'deleteFile',
      'updateFile',
    ]),
  },
}
</script>
