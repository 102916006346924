<template lang="pug">
img.folder-preview(
  :class="rootClasses"
  :src="image.src"
  :alt="image.alt"
)
</template>

<script>
import folderFilledSrc from '@/assets/img/folder-filled.svg';
import folderFilledLockSrc from '@/assets/img/folder-filled-lock.svg';
import folderEmptySrc from '@/assets/img/folder-empty.svg';
import folderEmptyLockSrc from '@/assets/img/folder-empty-lock.svg';

export default {
  name: 'FolderPreview',

  props: {
    folder: {
      type: Object,
      required: true,
    },

    /**
     * @values large
     */
    size: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    image() {
      const { filesCount, foldersCount, access } = this.folder;
      const contentCount = filesCount + foldersCount;

      if (contentCount > 0 && access) {
        return { src: folderFilledLockSrc, alt: 'filled private folder' };
      } else if (contentCount > 0 && !access) {
        return { src: folderFilledSrc, alt: 'filled folder' };
      } else if (contentCount <= 0 && access) {
        return { src: folderEmptyLockSrc, alt: 'private folder' };
      }
      return { src: folderEmptySrc, alt: 'folder' };
    },

    rootClasses() {
      const { size } = this;
      return {
        [`folder-preview--size_${size}`]: Boolean(size),
      };
    },
  },
};
</script>

<style lang="scss">
.folder-preview {
  font-size: inherit;
  width: 2em; // 30px
  height: 2em; // 30px
}

.folder-preview--size_large {
  width: 6em; // 90px
  height: 6em; // 90px
}
</style>
