/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attemdees': {
    width: 12,
    height: 16,
    viewBox: '0 0 12 16',
    data: '<g _fill="#34335C" fill-rule="evenodd"><path pid="0" d="M.667 2.365A2.366 2.366 0 0 1 3.042 0h5.915c1.311 0 2.376 1.06 2.376 2.365v11.27A2.366 2.366 0 0 1 8.957 16H3.042a2.372 2.372 0 0 1-2.375-2.365V2.365zm1.185 0v11.27c0 .649.533 1.18 1.19 1.18h5.915c.662 0 1.19-.527 1.19-1.18V2.365c0-.649-.533-1.18-1.19-1.18H3.042c-.662 0-1.19.527-1.19 1.18z"/><path pid="1" d="M3.333 5.667h5.334a.667.667 0 0 0 0-1.334H3.333a.667.667 0 0 0 0 1.334zM3.333 8.667h5.334a.667.667 0 0 0 0-1.334H3.333a.667.667 0 0 0 0 1.334zM3.333 12h5.334a.667.667 0 0 0 0-1.333H3.333a.667.667 0 1 0 0 1.333z" fill-rule="nonzero"/></g>'
  }
})
