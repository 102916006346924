/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'import': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M16.28 5.38h-3.32v1.98h3.32c.98 0 1.76.5 1.76.94v8.78c0 .44-.76.94-1.76.94H3.72c-.98 0-1.76-.5-1.76-.94V8.32c0-.44.76-.94 1.76-.94h3.32V5.4H3.72C1.64 5.4 0 6.68 0 8.3v8.78C0 18.72 1.64 20 3.72 20h12.56c2.08 0 3.72-1.28 3.72-2.92V8.3c0-1.64-1.64-2.92-3.72-2.92z" _fill="#34335C"/><path pid="1" d="M12.98 9.52c-.26 0-.5.1-.7.3l-1.24 1.24V.98c0-.54-.44-.98-.98-.98s-.98.44-.98.98V11.1l-1.3-1.3a.97.97 0 0 0-.7-.3 1.002 1.002 0 0 0-.7 1.7l2.94 2.96c.2.2.44.3.7.3.26 0 .5-.1.7-.3l2.96-2.96c.38-.38.38-1.02 0-1.4-.2-.18-.44-.28-.7-.28z" _fill="#34335C"/>'
  }
})
