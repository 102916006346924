<template lang="pug">
  .duedate-task(
    @click="$emit('click', $event)",
    v-show="!hideDuedate",
    :disabled="!task.canEdit")

    .body
      .check-date(:class="classes")
        ui-svg.clock-icon(name="clock")

        .text-data
          template(v-if="startAt")
            .start-at.item {{ startAt }}
            .separator -

          .end-at.item {{ endAt }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ChecklistItemDuedate',

  props: {
    task: {
      require: true,
    },
  },

  computed: {
    classes() {
      return {
        ...this.$utils.duedate.getClasses(this.task),
        completed: this.task.checked,
      }
    },

    hideDuedate() {
      return !this.task.startAt && !this.task.endAt
    },

    startAt() {
      return this.$utils.duedate.toFormat(this.task.startAt, '00:00').full
    },

    endAt() {
      return this.$utils.duedate.toFormat(this.task.endAt, '23:59').full
    },

    ...mapGetters('User', ['permissions']),
  },
}
</script>
