<template lang="pug">
  .ui.drop-select(:class="rootClasses")
    select(
      v-model="model",
      :name="name"
      :required="!!placeholder"
      :disabled="readonly"
      :class="{ 'with-placeholder': !!placeholder, readonly }"
      @change="handleChange"
      @blur="handleBlur"
    )

      option(
        v-if="placeholder",
        :value="undefined", disabled, selected) {{ placeholder }}
      //- option(
      //-   selected="true",
      //-   :value="null") {{ $t("modals.createChecklist.null") }}

      template(v-for="(optionOrGroup, index) in filterList")
        optgroup(
          v-if="optionOrGroup.list",
          :key="index",
          :label="optionOrGroup.placeholder"
          :disabled="optionOrGroup.disabled"
        )
          option(
            v-for="option in optionOrGroup.list"
            :key="option.id"
            :value="option.id"
            :disabled="option.disabled"
          ) {{ option.placeholder }}

        option(
          v-else
          :key="index",
          :value="optionOrGroup.id"
          :disabled="optionOrGroup.disabled"
        ) {{ optionOrGroup.placeholder }}

    ui-svg.arrow.rotate-down(name="arrow" kind="bali-hai")
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
    },

    value: {
      require: false,
    },

    placeholder: {
      type: String,
      default: '',
    },

    name: {
      type: String,
      default: '',
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rootClasses() {
      const { error } = this;
      return {
        'drop-select--is_error': error,
      };
    },

    filterList() {
      return this.list.filter(optionOrGroup => {
        if (optionOrGroup?.list) {
          const groupOptions = optionOrGroup.list.filter(
            groupOption => Boolean(groupOption?.placeholder)
          );
          return Boolean(groupOptions.length);
        }
        return Boolean(optionOrGroup?.placeholder);
      });
    },

    model: {
      get() {
        return this.value
      },

      set(newValue) {
        if (this.readonly) return;

        this.$emit('input', newValue)
        this.$emit('pick', newValue)
        this.$emit('select', newValue)
      },
    },
  },

  methods: {
    handleChange(event) {
      if (this.readonly) return;

      this.$emit('update', event);
    },

    handleBlur(event) {
      this.$emit('blur', event);
    },
  },
};
</script>

<style lang="sass" src="./styles.sass"></style>
