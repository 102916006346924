/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'resend': {
    width: 256,
    height: 301,
    viewBox: '0 0 256 301',
    data: '<path pid="0" d="M128 300.1C57.3 300.1 0 242.8 0 172S57.3 43.9 128 43.9V0l96 64-96 55.7V63.9C68.4 63.9 19.9 112.4 19.9 172c0 59.6 48.5 108.1 108.1 108.1 59.6 0 108.1-48.1 108.1-108.1H256c0 71-57.3 128.1-128 128.1z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
