<template lang="pug">
  .ui.form.textarea(
    :class="{edit, empty, disabled}",
    v-click-outside="cancel")

    .body(@click="e => $emit('body-click', e)")
      slot(name="before")
      ui-textarea(
        ref="uiInput",
        @click="onEdit",
        v-model="model",
        :autoresize="autoresize",
        :placeholder="placeholder",
        :readonly="!edit")

      slot(name="after")

    .footer
      ui-button.small.bali-hai(
        @click="save",
        :disabled="disabled"
      ) {{ saveBtn || $t('ui.editFormRow.save')}}

      ui-button.small.bali-hai.o(
        @click="cancel"
      ) {{ cancelBtn || $t("ui.editFormRow.cansel") }}
</template>

<script>
import FormMixin from "../default";

export default {
  mixins: [FormMixin],

  props: {
    autoresize: {
      type: Boolean,
      default: false,
    },
    resize: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
