/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera': {
    width: 11,
    height: 9,
    viewBox: '0 0 11 9',
    data: '<path pid="0" d="M7.21 5.24c0 .94-.76 1.7-1.71 1.7a1.7 1.7 0 111.71-1.7zM11 3.14v4.2a1.54 1.54 0 01-1.56 1.55H1.56A1.56 1.56 0 010 7.34v-4.2a1.54 1.54 0 011.56-1.55h.9a.8.8 0 00.77-.56l.12-.4A.87.87 0 014.18 0h2.64c.38 0 .72.25.83.62l.12.4c.1.34.41.57.77.57h.9c.86 0 1.56.7 1.56 1.55zm-2.81 2.1c0-1.48-1.2-2.68-2.69-2.68a2.68 2.68 0 102.69 2.68z" _fill="#96969C"/>'
  }
})
