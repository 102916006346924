<template lang="pug">
  .user-page-title.page-title
    .title
      slot(name="before")

      .text
        .data
          .data-title
            span {{ title }}

          .data-subtitle {{ subtitle }}

        .data-additional
          slot(name="title")

    slot(name="after")
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
