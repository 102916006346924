import _ from 'lodash'

class UserRules {
  constructor(data = {}) {
    this.company = {
      canEdit: _.get(data, 'company.can_edit', undefined),
      canDelete: _.get(data, 'company.can_delete', undefined),
      canManageMembers: _.get(data, 'company.can_manage_members', undefined),
      canView: _.get(data, 'company.can_view', undefined),
      canUseIntegrations: _.get(data, 'company.can_use_integrations', undefined),
    }

    this.chat = {
      canArchive: _.get(data, 'chat.can_archive', undefined),
      canCreateProjectChat: _.get(
        data,
        'chat.can_create_project_chat',
        undefined
      ),
      canCreateTeamChat: _.get(data, 'chat.can_create_team_chat', undefined),
      canCreateMessage: _.get(data, 'chat.can_create_message', undefined),
      canDelete: _.get(data, 'chat.can_delete', undefined),
      canEdit: _.get(data, 'chat.can_edit', undefined),
      canInviteToChat: _.get(data, 'chat.can_invite_to_chat', undefined),
      canInviteToChatExternal: _.get(
        data,
        'chat.can_invite_to_chat_external',
        undefined
      ),
      canLeave: _.get(data, 'chat.can_leave', undefined),
      canRestore: _.get(data, 'chat.can_restore', undefined),
      canView: _.get(data, 'chat.can_view', undefined),
    }

    this.project = {
      canArchive: _.get(data, 'project.can_archive', undefined),
      canCreate: _.get(data, 'project.can_create', undefined),
      canDelete: _.get(data, 'project.can_delete', undefined),
      canEdit: _.get(data, 'project.can_edit', undefined),
      canInviteToProject: _.get(
        data,
        'project.can_invite_to_project',
        undefined
      ),
      canLeave: _.get(data, 'project.can_leave', undefined),
      canMove: _.get(data, 'project.can_move', undefined),
      canRestore: _.get(data, 'project.can_restore', undefined),
      canView: _.get(data, 'project.can_view', undefined),
      canViewDashboard: _.get(data, 'project.can_view_dashboard', undefined),
      canViewInfo: _.get(data, 'project.can_view_info', undefined),
      canViewManagementInfo: _.get(
        data,
        'project.can_view_management_info',
        undefined
      ),
    }

    this.user = {
      canCreate: _.get(data, 'user.can_create', undefined),
      canView: _.get(data, 'user.can_view', undefined),
      canEdit: _.get(data, 'user.can_edit', undefined),
      canDelete: _.get(data, 'user.can_delete', undefined),
    }

    this.tasks = {
      canViewInfo: _.get(data, 'project.can_view_info', undefined),
    }

    this.planning = {
      canUse: _.get(data, 'planning.can_use_planning', undefined),
      canUsePlanningManagement: _.get(
        data,
        'planning.can_use_planning_management',
        undefined
      ),
      planItem: {
        canCreate: _.get(
          data,
          'planning.planningPlanitems.can_create',
          undefined
        ),
        canEdit: _.get(data, 'planning.planningPlanitems.can_edit', undefined),
        canDelete: _.get(
          data,
          'planning.planningPlanitems.can_delete',
          undefined
        ),
      },
      resource: {
        canCreate: _.get(
          data,
          'planning.planningResource.can_create',
          undefined
        ),
        canEdit: _.get(data, 'planning.planningResource.can_edit', undefined),
        canDelete: _.get(
          data,
          'planning.planningResource.can_delete',
          undefined
        ),
      },
      resourceGroup: {
        canCreate: _.get(
          data,
          'planning.planningResourceGroups.can_create',
          undefined
        ),
        canEdit: _.get(
          data,
          'planning.planningResourceGroups.can_edit',
          undefined
        ),
        canDelete: _.get(
          data,
          'planning.planningResourceGroups.can_delete',
          undefined
        ),
      },
    }

    this.companyProjectStatus = {
      canCreate: _.get(data, 'companyprojectstatus.can_create', undefined),
      canView: _.get(data, 'companyprojectstatus.can_view', undefined),
      canEdit: _.get(data, 'companyprojectstatus.can_edit', undefined),
      canDelete: _.get(data, 'companyprojectstatus.can_delete', undefined),
      canMove: _.get(data, 'companyprojectstatus.can_move', undefined),
    }

    this.comment = {
      canCreate: _.get(data, 'comment.can_create', undefined),
      canView: _.get(data, 'comment.can_view', undefined),
      canEdit: _.get(data, 'comment.can_edit', undefined),
      canDelete: _.get(data, 'comment.can_delete', undefined),
    }

    this.checklist = {
      canCreate: _.get(data, 'checklist.can_create', undefined),
      canView: _.get(data, 'checklist.can_view', undefined),
      canEdit: _.get(data, 'checklist.can_edit', undefined),
      canDelete: _.get(data, 'checklist.can_delete', undefined),
    }

    this.checklistItem = {
      canCreate: _.get(data, 'checklistitem.can_create', undefined),
      canView: _.get(data, 'checklistitem.can_view', undefined),
      canEdit: _.get(data, 'checklistitem.can_edit', undefined),
      canDelete: _.get(data, 'checklistitem.can_delete', undefined),
      canChangeStatus: _.get(
        data,
        'checklistitem.can_change_status',
        undefined
      ),
    }

    this.checklistItem = {
      canCreate: _.get(data, 'checklistitem.can_create', undefined),
      canView: _.get(data, 'checklistitem.can_view', undefined),
      canEdit: _.get(data, 'checklistitem.can_edit', undefined),
      canDelete: _.get(data, 'checklistitem.can_delete', undefined),
      canChangeStatus: _.get(
        data,
        'checklistitem.can_change_status',
        undefined
      ),
    }

    this.registration = {
      canCreate: _.get(data, 'registration.can_create', undefined),
      canView: _.get(data, 'registration.can_view', undefined),
      canEdit: _.get(data, 'registration.can_edit', undefined),
      canDelete: _.get(data, 'registration.can_delete', undefined),
      canUse: _.get(data, 'registration.can_use', undefined),
      canViewReports: _.get(data, 'registration.can_view_reports', undefined),
    }

    this.file = {
      canCreate: _.get(data, 'file.can_create', undefined),
      canView: _.get(data, 'file.can_view', undefined),
      canEdit: _.get(data, 'file.can_edit', undefined),
      canDelete: _.get(data, 'file.can_delete', undefined),
    }

    this.filters = {
      canUse: _.get(data, 'filters.can_use', undefined),
    }

    // this.printDiff()
  }

  printDiff() {
    for (const modKey in this) {
      for (const perKey in this[modKey]) {
        if (this[modKey][perKey] === undefined)
          debug(`[!] Rule undefined: ${modKey}.${perKey}`)
      }
    }
  }
}

export default UserRules
