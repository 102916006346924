/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M7.01 0a.7.7 0 0 0-.63.4L4.56 4.13l-3.97.65a.71.71 0 0 0-.56.5.76.76 0 0 0 .17.74L3.03 9l-.63 4.14c-.04.27.07.54.28.7.21.17.5.2.73.07L6.99 12l3.57 1.91c.24.13.52.1.73-.06a.75.75 0 0 0 .28-.7L10.95 9l2.84-2.96c.2-.2.26-.48.18-.75a.71.71 0 0 0-.56-.5l-3.96-.66L7.63.4a.7.7 0 0 0-.62-.4z" _fill="#FFCC5B"/>'
  }
})
