/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help': {
    width: 8,
    height: 12,
    viewBox: '0 0 8 12',
    data: '<path pid="0" d="M3.983 8.5c-.255 0-.455-.086-.6-.257-.145-.171-.218-.4-.218-.686 0-.582.107-1.068.322-1.456.179-.324.428-.675.748-1.055l.2-.23c.313-.355.542-.649.687-.883.145-.234.217-.5.217-.797a.922.922 0 0 0-.356-.763c-.238-.188-.56-.282-.966-.282a2.42 2.42 0 0 0-1.034.223l-.3.146c-.271.14-.598.319-.979.539-.336.183-.614.274-.834.274-.244 0-.45-.105-.618-.317A1.187 1.187 0 0 1 0 2.194c0-.206.038-.38.113-.523a1.27 1.27 0 0 1 .357-.403C.98.868 1.568.557 2.235.334A6.303 6.303 0 0 1 4.243 0c.72 0 1.366.126 1.94.377.574.251 1.02.597 1.339 1.037.319.44.478.934.478 1.482 0 .423-.084.808-.252 1.157a3.83 3.83 0 0 1-.6.908l-.26.277c-.19.198-.417.423-.68.674-.44.4-.77.734-.99 1.003a1.92 1.92 0 0 0-.418.9.866.866 0 0 1-.287.505.804.804 0 0 1-.53.18zm.026 3.5c-.438 0-.799-.142-1.083-.427-.284-.285-.426-.643-.426-1.073 0-.43.142-.788.426-1.073C3.21 9.142 3.571 9 4.009 9c.426 0 .78.142 1.065.427.284.285.426.643.426 1.073 0 .43-.142.788-.426 1.073-.284.285-.64.427-1.065.427z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
