/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'questionnaire': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.67 13.58H8.1v-3.14h12.6v3.14h-.02zM6.77 11.6L5.5 12.93c-.06.08-.15.12-.27.12-.1 0-.2-.04-.28-.12l-.64-.7c-.14-.16-.14-.4.01-.53.16-.14.4-.14.53.02l.4.4.98-1.03c.14-.16.37-.16.53-.02.14.13.16.37 0 .53zm14.39-2.14H3.49c-.27 0-.49.21-.49.49v4.12c0 .28.22.5.5.5h17.66c.27 0 .49-.22.49-.5V9.95c0-.28-.22-.5-.5-.5zM8.08 7.12h7.84V3.98H8.08v3.14zM3.5 8.1h12.92c.27 0 .49-.21.49-.49V3.5c0-.27-.22-.49-.5-.49H3.5c-.28 0-.5.22-.5.5v4.1c0 .27.22.5.5.5zm4.58 11.94h4.85V16.9H8.08v3.14zm5.34-4.12H3.5c-.27 0-.49.21-.49.49v4.1c0 .27.22.49.5.49h9.92c.28 0 .5-.22.5-.5v-4.1a.5.5 0 00-.5-.48z" _fill="#7F848B"/>'
  }
})
