/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location': {
    width: 17,
    height: 24,
    viewBox: '0 0 17 24',
    data: '<path pid="0" d="M8.5.125c4.6 0 8.35 3.75 8.35 8.35 0 2.95-3.3 9.274-6.05 14.05a2.615 2.615 0 0 1-2.3 1.324c-.95 0-1.825-.5-2.3-1.325C3.45 17.75.15 11.45.15 8.474.15 3.876 3.9.126 8.5.126zm.675 21.474c3.7-6.374 5.8-11.149 5.8-13.124a6.476 6.476 0 0 0-12.949 0c0 1.975 2.099 6.75 5.8 13.124.2.351.549.4.674.4s.475-.049.675-.4zM8.5 4.175a4.3 4.3 0 1 1 0 8.599 4.3 4.3 0 0 1 0-8.6zm0 6.725a2.427 2.427 0 0 0 2.425-2.425A2.412 2.412 0 0 0 8.5 6.051c-1.35 0-2.425 1.1-2.425 2.424 0 1.35 1.1 2.425 2.425 2.425z" _fill="#34335C" fill-rule="evenodd"/>'
  }
})
