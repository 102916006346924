import { keyBy, isNil} from 'lodash';
import ImportFilesProvider from '@provider/importFiles'
import * as TYPES from '@/store/types'
import { IMPORT_FILE_STATUS, IMPORT_STATUS } from '@/constants'
import { ImportFileModel } from '@/models'
import Vue from "vue"


const getTimerKey = (type, projectId) => isNil(projectId) ? type : `${type}-${projectId}`

const state = {
  imports: [],
  files: [],
  timers: {},
}

const getters = {
  canPlanTimer: (state, getters) => fileId => getters.currentRecord(fileId) !== getters.totalRecords(fileId),
  getImportsByParams: state => ({type} = {}) => state.imports
    .filter((imports) => !isNil(type) && imports.type === type),
  getFilesByParams: state => ({ projectId, type } = {}) => state.files
    .filter((file) => {
      if (!isNil(type) && file.type !== type) return false
      if (!isNil(projectId) && file.projectId !== projectId) return false
      return true
    }),
  currentRecord: (state) => (fileId) => state.imports
    .filter((importData) => importData.fileId === fileId).length,
  totalRecords: (state) => (fileId) => state.imports
    .filter((importData) => importData.fileId === fileId)[0]?.totalRecords || 0,
  getFileImports: (state) => (fileId) => state.imports
    .filter((importData) => importData.fileId === fileId),
  loadingFile: (state) => state.files
    .find(({ status }) => status === IMPORT_FILE_STATUS.PENDING) || null,
  lastFileId: (sate) => state.files.reduce(
    (result, currentValue) => result.id > currentValue.id ? result : currentValue
  )
}

const actions = {
  planTimer({ commit, dispatch, getters }, { type, projectId, fileId }) {
    const key = getTimerKey(type, projectId)
    const timer =
      setTimeout(() => {
        Promise.allSettled([
          dispatch('getImportedData', { type, projectId }),
          dispatch('getImportedFiles', { type, projectId }),
        ]).finally(() => {
          if (getters.canPlanTimer(fileId)) {
            dispatch('planTimer', { type, projectId, fileId })
          } else {
            commit(TYPES.IMPORTS.RESET_TIMER, { key })
          }
        })
      }, 5000)
    commit(TYPES.IMPORTS.SET_TIMER, { key, value: timer })
  },

  clearTimer({commit, state}, { type, projectId }) {
    const key = getTimerKey(type, projectId)
    const timer = state.timers[key]
    clearTimeout(timer)
    commit(TYPES.IMPORTS.RESET_TIMER, { key })
  },

  getImportedData({ commit }, { type, projectId }) {
    return new Promise((resolve, reject) => {
      ImportFilesProvider.getImportedData({ type, projectId })
        .then(imports => {
          commit(TYPES.IMPORTS.INIT_IMPORTS, {type, imports})
          resolve(imports)
        })
        .catch(reject)
    })
  },

  getImportedFiles({ commit }, { type, projectId }) {
    return new Promise((resolve, reject) => {
      ImportFilesProvider.getImportedFiles({ type, projectId })
        .then(files => {
          commit(TYPES.IMPORTS.INIT_FILES, files.reverse())
          resolve(files)
        })
        .catch(reject)
    })
  },

  addImportsFile({ rootGetters, commit }, { form }) {
    return new Promise((resolve, reject) => {
      ImportFilesProvider.addImportsFile(form)
        .then(file => {
          commit(
            TYPES.IMPORTS.ADD_FILE,
            new ImportFileModel(Object.assign(file, {
              company: rootGetters['Companies/meCompany'],
              status: 0,
              user: rootGetters['User/me']
            }))
          )
          resolve(file)
        })
        .catch(reject)
    })
  },

  processImportCsvSocketEvent({ state, commit, dispatch }, importData) {
    if (importData.status === IMPORT_STATUS.DELETED) {
      dispatch('removeImportData', importData)

      const { modelId, modelType } = importData;
      if (modelType === "App\\Models\\ChecklistItem") {
        // TODO: implement deleting a checklist item (where to get a checklist id?)
      } else if (modelType === "App\\Models\\Checklist") {
        dispatch('Checklists/deleteChecklistLocally', Number(modelId), { root: true });
      }
    } else if (state.imports.some(({ id }) => id === importData.id)) {
      commit(TYPES.IMPORTS.UPDATE_IMPORT, importData)
    } else {
      commit(TYPES.IMPORTS.ADD_IMPORT, importData)
    }
  },

  processImportCsvFileSocketEvent({ state, commit, dispatch }, importFile) {
    if (importFile.status === IMPORT_STATUS.DELETED) {
      dispatch('removeFile', importFile)
    }
  },

  removeFile({ getters, commit }, importFile) {
    commit(TYPES.IMPORTS.REMOVE_FILE, importFile.id)

    commit(TYPES.IMPORTS.REMOVE_IMPORT_BY_FILE_ID, importFile.id)
  },

  removeImportData({ getters, commit }, importData) {
    commit(TYPES.IMPORTS.REMOVE_IMPORT_DATA, importData.id)

    if (getters.getFileImports(importData.fileId).length === 0) {
      commit(TYPES.IMPORTS.REMOVE_FILE, importData.fileId)
    }
  },

  removeAllImportData({ commit }) {
    commit(TYPES.IMPORTS.REMOVE_ALL_IMPORT_DATA)
  },

  downloadFileById(_, { fileId, fileName }) {
    return ImportFilesProvider.downloadImportsFile(fileId, fileName)
  },

  rollbackFile(_, { fileId }) {
    return ImportFilesProvider.rollbackFile(fileId)
  },

  setFileStatus({ commit }, {fileId, status}) {
    commit(TYPES.IMPORTS.SET_FILE_STATUS, {fileId, status});
  }
}

const mutations = {
  [TYPES.IMPORTS.INIT_IMPORTS](state, {type, imports}) {
    type = Number(type)
    if (imports.length === 0) {
      state.imports = state.imports.filter((importResult) => importResult.type !== type)
    } else {
      const currentImports = keyBy(state.imports, 'id');
      const newImports = keyBy(imports, 'id');
      const result = Object.assign(currentImports, newImports);
      state.imports = Object.values(result);
    }
  },
  [TYPES.IMPORTS.ADD_IMPORT](state, importData) {
    state.imports.push(importData)

    let items = state.imports.filter(({ fileId }) => fileId === importData.fileId)
    items.forEach((item) => {
      item.totalRecords = importData.totalRecords
    });
  },
  [TYPES.IMPORTS.UPDATE_IMPORT](state, importData) {
    state.imports = [
      ...state.imports.filter(({ id }) => id !== importData.id),
      importData
    ]
  },
  [TYPES.IMPORTS.REMOVE_ALL_IMPORT_DATA](state){
    state.imports = []
  },
  [TYPES.IMPORTS.REMOVE_IMPORT_DATA](state, importDataId) {
    state.imports = state.imports.filter(({ id }) => id !== importDataId)
  },
  [TYPES.IMPORTS.REMOVE_IMPORT_BY_PROJECT_ID](state, projectId) {
    state.imports = state.imports.filter((importData) => importData.projectId !== projectId)
  },
  [TYPES.IMPORTS.REMOVE_IMPORT_BY_FILE_ID](state, fileId) {
    state.imports = state.imports.filter((importData) => importData.fileId !== fileId)
  },
  [TYPES.IMPORTS.INIT_FILES](state, files) {
    state.files = files
  },
  [TYPES.IMPORTS.ADD_FILE](state, file) {
    state.files.unshift(file)
  },
  [TYPES.IMPORTS.REMOVE_FILE](state, fileId) {
    state.files = state.files.filter(({ id }) => id !== fileId)
  },
  [TYPES.IMPORTS.SET_FILE_STATUS](state, { fileId, status }) {
    let file = state.files.find(({ id }) => id === fileId)

    if (file) {
      file.status = status
    }
  },
  [TYPES.IMPORTS.SET_TIMER](state, { key, value }) {
    Vue.set(state.timers, key, value)
  },
  [TYPES.IMPORTS.RESET_TIMER](state, { key }) {
    Vue.delete(state.timers, key)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
