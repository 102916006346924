/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down-circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2 11.98a9.98 9.98 0 1119.97 0 9.98 9.98 0 01-19.97 0zm5.93-1.24l4.05 4.05 4.06-4.05H7.93z" _fill="#888D93"/>'
  }
})
