/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shape': {
    width: 4,
    height: 4,
    viewBox: '0 0 4 4',
    data: '<path pid="0" d="M3.89 3.36L2.53 2 3.89.64a.374.374 0 1 0-.53-.53L2 1.47.64.11a.374.374 0 1 0-.53.53L1.47 2 .11 3.36a.374.374 0 1 0 .53.53L2 2.53l1.36 1.36a.374.374 0 1 0 .53-.53z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
