<template lang="pug">
span.app-loader(:class="classList")
  img(:src="require('@/assets/img/loader.svg')")
</template>

<script>
export default {
  name: "AppLoader",

  props: {
    /**
     * The size of the app loader
     * @values bigger, large
     */
    size: {
      type: String,
      default: null
    }
  },

  computed: {
    classList() {
      return {
        [`app-loader--size_${this.size}`]: Boolean(this.size)
      };
    }
  }
};
</script>

<style lang="sass">
$app-loader-size: 1em !default
$app-loader-size-bigger: 1.1em !default
$app-loader-size-large: 2em !default

.app-loader
  vertical-align: middle
  display: inline-flex
  width: $app-loader-size
  height: $app-loader-size

  img
    width: 100%
    height: 100%
    display: block
    animation-name: spinner
    animation-duration: 0.8s
    animation-iteration-count: infinite
    animation-timing-function: linear

.app-loader--size_bigger
  width: $app-loader-size-bigger
  height: $app-loader-size-bigger

.app-loader--size_large
  width: $app-loader-size-large
  height: $app-loader-size-large
</style>
