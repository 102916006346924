/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'schedule': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.99 2a10 10 0 11-.02 19.99A10 10 0 0111.98 2zm0 17.99a8 8 0 100-15.98 8 8 0 000 15.98z" _fill="#888D93"/><path pid="1" d="M12.99 11.58V8a1 1 0 00-1-1 1 1 0 00-1 1v4c0 .26.1.51.29.7l3.54 3.54a.992.992 0 001.41-.01 1 1 0 000-1.4L13 11.58z" _fill="#888D93"/>'
  }
})
