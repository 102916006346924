/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pencil': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M11.404 1.136L10.86.593A2.017 2.017 0 0 0 9.434 0a2.02 2.02 0 0 0-1.427.593l-7.399 7.4a.878.878 0 0 0-.252.504l-.354 3.017a.432.432 0 0 0 .43.48h.05l3.017-.353a.841.841 0 0 0 .505-.253l7.399-7.399a2.003 2.003 0 0 0 0-2.853zM1.682 8.194l6.085-6.086L9.89 4.23l-6.086 6.085-2.121-2.121zm-.467.833l1.755 1.755-1.995.227.24-1.982zM10.76 3.36l-.228.227-2.121-2.121.227-.228a1.1 1.1 0 0 1 1.566 0l.543.543a1.1 1.1 0 0 1 .328.783c.013.303-.1.58-.315.796z" _fill="#34335C" fill-rule="evenodd"/>'
  }
})
