/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dropbox': {
    width: 19,
    height: 16,
    viewBox: '0 0 19 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.4 3L4.7 6l4.7 3-4.7 3L0 8.975l4.7-3L0 3l4.7-3 4.7 3zm-4.724 9.95l4.7-3 4.7 3-4.7 3-4.7-3zM14.1 5.975l-4.7 3L14.075 12l4.7-3-4.7-3 4.7-3-4.7-3L9.4 3l4.7 2.975z" _fill="#34335C"/>'
  }
})
