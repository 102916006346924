/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link': {
    width: 19,
    height: 8,
    viewBox: '0 0 19 8',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.678 3.94c0-1.26 1.03-2.28 2.304-2.28H8.51V0H3.982C1.779 0 0 1.76 0 3.94c0 2.18 1.779 3.94 3.982 3.94H8.51V6.22H3.982c-1.273 0-2.304-1.02-2.304-2.28zM14.855 0h-4.527v1.66h4.527c1.273 0 2.304 1.02 2.304 2.28 0 1.26-1.03 2.28-2.304 2.28h-4.527v1.66h4.527c2.203 0 3.982-1.76 3.982-3.94 0-2.18-1.779-3.94-3.982-3.94zM5.48 4.78a.85.85 0 0 1-.849-.84c0-.46.384-.84.849-.84h7.903a.85.85 0 0 1 .849.84.85.85 0 0 1-.849.84H5.48z" _fill="#34335C"/>'
  }
})
