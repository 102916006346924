/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cancel-circle': {
    width: 16,
    height: 15,
    viewBox: '0 0 16 15',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 5.97l2.19-2.18c1-1 2.52.52 1.53 1.53L9.52 7.5l2.2 2.19c.99 1-.54 2.52-1.53 1.53l-2.2-2.2-2.17 2.2c-1.01.99-2.52-.54-1.53-1.53l2.18-2.2-2.18-2.17c-1-1.01.52-2.54 1.53-1.53L8 5.97zM8 0a7.5 7.5 0 110 15.01A7.5 7.5 0 018 0z" _fill="#7A93A9"/>'
  }
})
