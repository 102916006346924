<template lang="pug">
.checklist-item-name(
  :class="classList"
)
  AppLoader.checklist-item-name__loader(
    v-if="loading"
    size="bigger"
  )

  ui-checkbox.checklist-item-name__checkbox.grey(
    v-else
    v-model="checkedModel"
  )

  button(type="button" @click="onNameClick").checklist-item-name__text
    AppTruncate(
      :text="name"
      :max-lines="2"
      type="end"
      title-tip
    )
</template>

<script>
import AppLoader from '@/components/AppLoader.vue';
import AppTruncate from '@/components/AppTruncate.vue';
import { createModelWrapper } from '@/helpers/components/model.js';

export default {
  name: 'ChecklistItemName',

  components: {
    AppLoader,
    AppTruncate,
  },

  props: {
    name: {
      type: String,
      default: '',
    },

    checked: {
      type: Boolean,
      default: false,
    },

    autoresize: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    checkedModel: createModelWrapper('checked'),

    classList() {
      const { checked } = this;
      return {
        'checklist-item-name--checked': checked,
      };
    },
  },

  methods: {
    onNameClick(event) {
      this.$emit('name:click', event);
    },
  },
};
</script>

<style lang="sass" src="./styles/ChecklistItemName.sass"></style>
