/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 8,
    height: 8,
    viewBox: '0 0 8 8',
    data: '<path pid="0" d="M.092 4.35A.406.406 0 0 1 0 4.105c0-.07.03-.175.092-.245l.431-.49c.123-.14.308-.14.43 0l.032.035L2.677 5.47c.061.07.154.07.215 0L7.015.605h.031c.123-.14.308-.14.431 0l.43.49c.124.14.124.35 0 .49l-4.922 5.81c-.062.07-.123.105-.216.105-.092 0-.154-.035-.215-.105l-2.4-2.94-.062-.105z" _fill="#FF805D" fill-rule="evenodd"/>'
  }
})
