/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'description': {
    width: 12,
    height: 10,
    viewBox: '0 0 12 10',
    data: '<g _fill="#34335C" fill-rule="nonzero"><path pid="0" d="M.688 1.333h10.624a.667.667 0 0 0 0-1.333H.688a.667.667 0 0 0 0 1.333zM.688 4h10.624a.667.667 0 0 0 0-1.333H.688A.667.667 0 1 0 .688 4zM.688 6.667h10.624a.667.667 0 0 0 0-1.334H.688a.667.667 0 0 0 0 1.334zM.704 9.333H6.63A.667.667 0 1 0 6.63 8H.704a.667.667 0 1 0 0 1.333z"/></g>'
  }
})
