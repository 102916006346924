<template lang="pug">
  ui-drop-select.language-select(
    v-model="languageModel"
    :disabled="disabled"
    :placeholder="placeholder"
    :list="languageOptions"
    :name="name"
    v-on="inheritedListeners"
  )
</template>

<script>
import { LANGUAGES, FLAGS } from '@/constants';

export default {
  name: 'LanguageSelect',

  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    withText: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    languageOptions() {
      return Object.values(LANGUAGES).map((language) => ({
        id: language,
        placeholder: this.withText ? this.$t(`languages.${language}`, language) : FLAGS[language],
      }));
    },
    languageModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    inheritedListeners() {
      const { input: _, ...inheritedListeners } = this.$listeners;
      return inheritedListeners;
    },
  },
};
</script>