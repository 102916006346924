/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drag-horizontal': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.46 9.9c-.85 0-1.54-.65-1.54-1.45 0-.8.69-1.45 1.54-1.45.85 0 1.54.65 1.54 1.45 0 .8-.69 1.45-1.54 1.45m0 7.05c-.85 0-1.54-.65-1.54-1.45 0-.8.69-1.45 1.54-1.45.85 0 1.54.65 1.54 1.45 0 .8-.69 1.45-1.54 1.45M12.01 9.9c-.85 0-1.54-.65-1.54-1.45 0-.8.69-1.45 1.54-1.45.85 0 1.54.65 1.54 1.45 0 .8-.69 1.45-1.54 1.45m0 7.05c-.85 0-1.54-.65-1.54-1.45 0-.8.69-1.45 1.54-1.45.85 0 1.54.65 1.54 1.45 0 .8-.69 1.45-1.54 1.45M4.56 9.9c-.85 0-1.54-.65-1.54-1.45 0-.8.7-1.45 1.54-1.45.85 0 1.54.65 1.54 1.45 0 .8-.69 1.45-1.54 1.45m0 7.05c-.85 0-1.54-.65-1.54-1.45 0-.8.7-1.45 1.54-1.45.85 0 1.54.65 1.54 1.45 0 .8-.69 1.45-1.54 1.45" _fill="#888D93"/>'
  }
})
