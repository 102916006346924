<template lang="pug">
  .item(v-show="permissions.checklist.canCreate")
    .header
      ui-svg.dark.icon(name="checklist")
      .name
        span.pointer(
          :class="{ transparent: showEditForm }",
          @click="handleClickToEditName") {{ $t('pages.projectInfo.checklist') }} 

      .form-edit-name(
        v-if="showEditForm",
        v-click-outside="() => showEditForm=false")

        ui-form-input(
          ref="formName",
          @update="(value) => updateNameChacklist(value)",
          :editable="true",
          @softUpdate="showEditForm=false",
          :value="$t('pages.projectInfo.checklist')",
          @cancel="showEditForm=false")

      //- ui-form-input(
      //-   @update="(value) => updateNameChacklist(value)",
      //-   :editable="true",
      //-   :value="$t('pages.projectInfo.checklist')")

      //-   template(v-slot:before)
      //-     ui-svg.dark.icon(name="checklist")

      //-   template(v-slot:after)
          //- .controls
          //-   ui-button.circle.bali-hai(
          //-     @click="openChecklist")
          //-     span(ref="buttonCreate")
          //-     ui-svg.white(name="plus")

      .controls
        AddChecklistButton

    .body
      .controls
      .list
        .header
          ui-button.grey.small.create(
            @click="createChecklist") {{ $t("sidebar.checklists.formCreateItem.addItem") }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AddChecklistButton from './AddChecklistButton.vue'

export default {
  components: {
    AddChecklistButton,
  },

  data() {
    return {
      showEditForm: false,
    }
  },

  methods: {
    handleClickToEditName(e) {
      this.showEditForm = true

      this.$nextTick(() => {
        this.$refs.formName.focus()
      })
    },

    updateNameChacklist(name) {
      this.showEditForm = false

      this.addChecklist({
        projectId: this.$route.params.projectId,
        name,
      })
        .then(newChecklist => {
          this.$events.emit('checklist:open', newChecklist.id)
        })
        .catch(error => {
          this.$notify.error(this.$utils.response(error))
        })
    },

    createChecklist() {
      this.addChecklist({
        projectId: this.$route.params.projectId,
        name: this.$t('pages.projectInfo.checklist'),
      })
        .then(checklist => {
          setTimeout(() => {
            this.$bus.emit(`open-checklist-${checklist.id}`)
          }, 200)
        })
        .catch(error => {
          this.$notify.error(this.$utils.response(error))
        })
    },

    ...mapActions('Checklists', ['addChecklist']),
  },

  computed: {
    ...mapGetters('User', ['permissions']),
  },
}
</script>
