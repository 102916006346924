import EventEmmiter from "@/eventEmitter";

class Modals {
  /**
   * Required function for vue plugin 
   * 
   * @param {Vue} Vue 
   * @param {Object} langs 
   * @param {String} defaultLang 
   */
  install(Vue) {
    var modalsEmmiter = new EventEmmiter();

    Vue.mixin({
      beforeCreate: function() {
        Vue.util.defineReactive(this, '$modals', modalsEmmiter);
      }
    });
  }
}

export default new Modals();
