/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow': {
    width: 11,
    height: 6,
    viewBox: '0 0 11 6',
    data: '<path pid="0" d="M5.599.004c.216.022.42.11.585.254L10.606 4.1a1.08 1.08 0 0 1 .141 1.527 1.045 1.045 0 0 1-1.51.093L5.5 2.472 1.763 5.72a1.045 1.045 0 0 1-1.51-.093A1.08 1.08 0 0 1 .394 4.1L4.816.258c.216-.189.499-.28.783-.254z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
