<template lang="pug">
  svg-icon.ui.svg(
    :name="name",
    :color="color",
    :original="original"
    :class="rootClasses"
    @click="handleClick")
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      require: true
    },
    color: {
      type: String,
      require: false
    },
    original: {
      type: Boolean,
      default: false
    },

    /**
     * The size of the icon
     * @values small, smaller, larger
     */
    size: {
      type: String,
      default: undefined,
    },

    /**
     * The kind of the icon
     * * @values success, grey, light-grey, dark-grey, theme, theme-blue, dark, white, primary, bali-hai, light-success, status-blue, dark-to-green
     */
    kind: {
      type: String,
      default: undefined,
    },

    /**
     * The rotation of the icon
     * @values up, right, bottom, left
     */
    rotate: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    rootClasses() {
      const { kind, size, rotate } = this;

      return {
        [kind]: Boolean(kind),
        [`svg--size_${size}`]: Boolean(size),
        [`svg--rotate_${rotate}`]: Boolean(rotate),
      };
    },
  },

  methods: {
    handleClick(event) {
      this.$emit('click', event)
    }
  }
};
</script>

<style lang="sass" src="./styles.sass">
