/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rocket': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.5 1.14a1 1 0 0 0-1 0c-.6.35-1.46.98-2.32 1.9A7.87 7.87 0 0 0 6.8 9.3l-1.2 1.27-.13.16a3.4 3.4 0 0 0-.78 2.17v3.62c.1.89 1.15 1.31 1.78.65L8.53 15c.93 1.5 2.15 2.49 3.47 2.49 1.32 0 2.54-.98 3.47-2.49l2.05 2.17c.63.66 1.68.24 1.77-.65l.01-.13V12.9c0-.8-.28-1.57-.78-2.17l-.14-.16-1.2-1.27c.03-.27.03-.55.03-.83 0-2.06-.9-3.88-2.39-5.45-.86-.9-1.72-1.54-2.32-1.9zM9.9 18.47c-.05-.55-.5-.97-1.03-.97-.57 0-1.04.5-1.04 1.1v2.33c.07.55.5.97 1.04.97.58 0 1.04-.5 1.04-1.1v-2.33zm3.14 1.1c-.06-.55-.5-.97-1.04-.97-.58 0-1.04.5-1.04 1.1v2.33c.06.55.5.97 1.04.97.58 0 1.04-.5 1.04-1.1v-2.33zm2.09-2.07c.53 0 .97.42 1.03.97l.01.13v2.2c0 .6-.47 1.1-1.04 1.1-.54 0-.98-.42-1.04-.97V18.6c0-.6.46-1.1 1.04-1.1zM12 3.4l-.05.04c-.44.33-.89.71-1.3 1.15a5.7 5.7 0 0 0-1.78 3.89c0 3.43 1.79 6.82 3.13 6.82 1.34 0 3.13-3.4 3.13-6.82 0-1.4-.66-2.7-1.78-3.9a9.92 9.92 0 0 0-1.3-1.14L12 3.4zm-4.9 8.73l.15-.17c.1.4.23.79.37 1.16-.2.04-.38.14-.53.3l-.3.32v-.97c.03-.24.14-.46.3-.64zm9.65-.17c-.1.4-.23.79-.37 1.16.2.04.38.14.53.3l.3.32v-.97a1.12 1.12 0 0 0-.3-.64l-.16-.17zm-3.7-5.46c0-.6-.47-1.1-1.05-1.1-.58 0-1.04.5-1.04 1.1 0 .6.46 1.1 1.04 1.1.58 0 1.04-.5 1.04-1.1z" _fill="#30B15D"/>'
  }
})
