import Vue from 'vue'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import TRANSACTION_ID from './transactionId'

class SentryManager {
  constructor(props) {
    this.config = {
      key: props.key,
      project: props.project,
    }

    this.active = this.config.key && this.config.project
  }

  boot() {
    if (!this.active) return null

    Sentry.init({
      dsn: `https://${this.config.key}@sentry.io/${this.config.project}`,
      integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    })
  }

  bindEmail(email = 'anonymous') {
    if (!this.active) return null

    Sentry.configureScope(scope => {
      scope.setUser({ email })
      scope.setTag('transaction_id', TRANSACTION_ID)
      debug(`[+] Bind sentry user email: ${email}`)
    })
  }
}

export default {
  install(VueM, config) {
    let sentryManager = new SentryManager(config)
    sentryManager.boot()

    Vue.mixin({
      beforeCreate: function() {
        Vue.util.defineReactive(this, '$sentry', sentryManager)
      },
    })
  },
}
