/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'one-drive': {
    width: 20,
    height: 11,
    viewBox: '0 0 20 11',
    data: '<path pid="0" d="M17.79 6.821c.002-.04.006-.078.006-.118 0-1.54-1.399-2.787-3.124-2.787-.55 0-1.065.127-1.514.35-.515-.908-1.568-1.532-2.787-1.532-1.725 0-3.124 1.248-3.124 2.787 0 .152.017.3.043.445a2.332 2.332 0 0 0-.37-.032c-1.323 0-2.396 1.11-2.396 2.48 0 1.371 1.073 2.482 2.397 2.482h10.658c1.265 0 2.29-.914 2.29-2.042 0-1.065-.913-1.938-2.08-2.033z" _fill="#34335C"/><path pid="1" d="M4.208 9.61a2.695 2.695 0 0 1-1.54.478C1.195 10.088 0 8.918 0 7.475c0-1.301.972-2.377 2.243-2.577a3.386 3.386 0 0 1-.016-.308c0-1.908 1.58-3.454 3.527-3.454.67 0 1.292.186 1.825.502A4.125 4.125 0 0 1 10.882 0c2.045 0 3.74 1.463 4.054 3.379a4.031 4.031 0 0 0-1.569.208c-.685-.873-1.79-1.398-2.996-1.398-1.984 0-3.606 1.428-3.679 3.208-1.522.12-2.725 1.428-2.725 3.018 0 .423.086.827.24 1.194h.001z" _fill="#34335C"/>'
  }
})
