
<template lang="pug">
  .ui.radio(
    :class="rootClasses"
    @click="computedVal = valueP"
  )
    .flex-container
      .input-radio(:class="{active: computedVal==valueP}")

      label
        template(v-if="label") {{ label }}
        slot(v-else)
</template>

<script>
// TODO: refactor the component markup using the ui-svg component for markers
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    valueP: {
      default: ''
    },
    value: {
      require: true
    },

    /**
     * @values small
     */
    size: String,

    /**
     * @values theme-blue, success, error, green
     */
    kind: String,
  },

  computed: {
    rootClasses() {
      const { kind, size } = this;
      return {
        [kind]: Boolean(kind),
        [`ui-radio--size_${size}`]: Boolean(size),
      };
    },

    computedVal: {
      get() {
        return this.value;
      },

      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>

<style lang="sass" src="./styles.sass"></style>
