/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'message-pop': {
    width: 19,
    height: 17,
    viewBox: '0 0 19 17',
    data: '<defs><path pid="0" id="svgicon_message-pop_a" d="M49.86 620.472c0 .454-.177.89-.492 1.211a1.668 1.668 0 0 1-1.19.503h-7.85l-.327.309-3.06 2.867v-3.176h-2.119a1.67 1.67 0 0 1-1.19-.504 1.727 1.727 0 0 1-.492-1.212v-7.557c.001-.944.754-1.708 1.682-1.71H48.18c.928.002 1.68.766 1.681 1.71v7.557zM39 616.54a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0m2.5 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm2.5-1.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0m-9.178-6.5c-.75 0-1.467.303-1.997.842a2.895 2.895 0 0 0-.825 2.031v7.557a2.895 2.895 0 0 0 .824 2.033 2.8 2.8 0 0 0 1.998.842h.978v2.511c0 .744.274 1.146.69 1.146.25 0 .55-.145.875-.45l3.409-3.207h7.41a2.8 2.8 0 0 0 1.994-.845 2.9 2.9 0 0 0 .822-2.03v-7.557c0-.762-.296-1.492-.825-2.03a2.798 2.798 0 0 0-1.997-.843z"/><path pid="1" id="svgicon_message-pop_c" d="M30 630v-24h24v24z"/><clipPath id="svgicon_message-pop_b"><use xlink:href="#svgicon_message-pop_a"/></clipPath></defs><use xlink:href="#svgicon_message-pop_a" transform="translate(-32 -610)"/><g clip-path="url(#svgicon_message-pop_b)" transform="translate(-32 -610)"><use _fill="#fff" xlink:href="#svgicon_message-pop_c"/></g>'
  }
})
