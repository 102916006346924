<template lang="pug">
  .ui.select-multiple(
    :class="rootClasses"
  )
    .body
      .find
        ui-svg.bali-hai(name="search")
        input(v-model="search",
          ref="searchInput",
          :placeholder="placeholderS")

      ui-scrollbar.list(orientate="v")
        .item(
          v-for="item in listView",
          :key="item.id",
          @click="selectItem(item)")
          
          ui-checkbox.circle-theme-blue-o(
            :value="value.includes(item.id)",
            :label="item.placeholder")

      .select-all
        ui-checkbox.circle-theme-blue-o(
          @change="handleChangeSelectAll",
          :value="value.length == listView.length",
          :label="$t('forms.selectAll')")

        .separator
        .close(@click="$emit('close')") {{ $t('forms.close') }}
</template>

<script>
export default {
  data() {
    return {
      drop: false,
      search: '',
    }
  },

  props: {
    value: {
      require: false,
    },

    list: {
      require: false,
      type: Array,
      default: () => [],
    },

    autofocus: {
      require: false,
      type: Boolean,
      default: false,
    },

    searchPlaceholder: {
      require: false,
      type: String,
      default: '',
    },

    hideSearch: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rootClasses() {
      const { hideSearch } = this;
      return {
        'hide-search': hideSearch,
      };
    },

    model: {
      set(newVal) {
        this.$emit('input', newVal)
      },

      get() {
        return this.value
      },
    },

    placeholderS() {
      return this.searchPlaceholder || this.$t('forms.search')
    },

    listView() {
      return this.list.filter(e => {
        return this.search.length > 0
          ? e.placeholder.toLowerCase().includes(this.search.toLowerCase())
          : true
      })
    },

    selectedItemView() {
      let f = this.list.find(e => e.id == this.value)
      return f ? f.placeholder : ''
    },
  },

  mounted() {
    if (this.autofocus) {
      this.handleClickOnInput()
    }
  },

  methods: {
    handleChangeSelectAll(value) {
      this.model = value ? this.listView.map(e => e.id) : []
    },

    selectItem(item) {
      this.$emit('pick', item)
      this.model = this._.xor(this.model, [item.id])
    },

    handleClickOnInput() {
      this.$nextTick(() => {
        this.$refs.searchInput.focus()
      })
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
