<template lang="pug">
  .checklists-component(v-if="loaded")
  .checklists-component(v-else)
    .body(v-dragg-list="getDraggChecklistList")
      StubItem(v-if="list.length == 0 && hasStub")
      .controls.chat-controls(v-if="isCompact")
        ui-button.grey.small.action-list(
          v-if="hideCheckedItems",
          @click="hideChecklistItems"
        ) {{ $t('sidebar.checklists.hideitems') }}

        ui-button.grey.small.action-list(
          v-if="getterFlagHideChecklistCheckedItems && countCheckedItems",
          @click="showChecklistItems"
        ) {{ $t("sidebar.checklists.showItems") }} ({{countCheckedItems}})
      Item(
        v-for="(item, i) in list",
        :key="`${item.position}@${item.id}`",
        :checklist="item",
        :first="i==0",
        :can-create="canCreate",
        :size="size"
      )

    .footer
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import StubItem from './StubItem'
import Item from './Item'

import { ChecklistSizeVariants } from './constants.js'

export default {
  components: {
    Item,
    StubItem,
  },

  props: {
    hasStub: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },

    /**
     * @values compact, regular
     */
    size: {
      type: String,
      default: ChecklistSizeVariants.REGULAR,
    },
  },

  data() {
    return {
      loaded: true,
    }
  },

  computed: {
    hideCheckedItems() {
      return (
        this.countCheckedItems > 0 && !this.getterFlagHideChecklistCheckedItems
      )
    },
    countCheckedItems() {
      return this.getterCountCheckedItems({
        chatId: this.$route.params.chatId,
        projectId: this.$route.params.projectId,
      })
    },
    isCompact() {
      return this.size === ChecklistSizeVariants.COMPACT;
    },
    list() {
      return [
        ...(this.$route.params.chatId
          ? this.getterChatChecklists(Number(this.$route.params.chatId))
          : this.getterProjectChecklists(Number(this.$route.params.projectId))),
      ]
        .filter(e => {
          return e.access && !this.permissions.project.canViewManagementInfo
            ? false
            : true
        })
        .sort((a, b) => a.position - b.position)
    },

    getDraggChecklistList() {
      return JSON.stringify({
        id: 'checklists',
        namespace: 'dragg-checklist',
      })
    },
    ...mapGetters('Checklists', [
      'getterProjectChecklists',
      'getterChatChecklists',
      'getterCountCheckedItems',
      'getterFlagHideChecklistCheckedItems',
    ]),

    ...mapGetters('User', ['permissions']),
  },

  methods: {
    async fetchData() {
      if (this.$route.params.chatId) {
        await this.getChatChecklists({ chatId: this.$route.params.chatId })
      } else {
        await this.getProjectChecklists({
          projectId: this.$route.params.projectId,
        })
      }

      this.loaded = false
    },

    ...mapActions('Projects', ['getProjectChecklists']),

    ...mapActions('Chats', ['getChatChecklists']),
    ...mapActions('Checklists', ['hideChecklistItems','showChecklistItems'])
  },
}
</script>

<style lang="sass" src="./styles/src.sass"></style>
