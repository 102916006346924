export default {
  mounted() {
    document.addEventListener("drag", event => {
      this.$events.emit("events:drag", event);
    });

    document.addEventListener("drop", event => {
      event.stopPropagation();
      event.preventDefault();

      this.$events.emit("events:drop", event);
    });

    document.addEventListener("dragover", event => {
      event.stopPropagation();
      event.preventDefault();

      this.$events.emit("events:dragover", event);
    });

    document.addEventListener("visibilitychange", () => {
      this.$events.emit(document.hidden ? "app:unfocus" : "app:focus");
    }, false);
  }
}