import VueQuillEditor from 'vue-quill-editor';
import { computePosition, flip, shift, getScrollParents } from '@floating-ui/dom';

const Quill = VueQuillEditor.Quill;
const Tooltip = Quill.imports['ui/tooltip'];

// patch tooltip behavior
Tooltip.prototype.position = function (reference) {
  const editorRootBounds = this.quill.root.getBoundingClientRect();
  const scrollParents = getScrollParents(this.root);

  const virtualReferenceElement = {
    getBoundingClientRect() {
      const left = reference.left + editorRootBounds.left;
      const top = reference.top + editorRootBounds.top;
      const { width, height } = reference;

      return {
        width,
        height,
        left,
        top,
        right: left + width,
        bottom: top + height,
        x: left,
        y: top,
      };
    },
  };

  const overflowOptions = {
    padding: 5,
    boundary: scrollParents[0],
  };

  computePosition(virtualReferenceElement, this.root, {
    placement: 'top',
    strategy: 'absolute',
    middleware: [flip(overflowOptions), shift(overflowOptions)],
  }).then(({ x, y }) => {
    Object.assign(this.root.style, {
      left: `${x}px`,
      top: `${y}px`,
    });
  });

  return 0;
};
