import Vue from "vue";
import EventEmmiter from "@/eventEmitter";

const busEmitter = new EventEmmiter();
const eventBus = new Vue();

Vue.mixin({
  beforeCreate() {
    Vue.util.defineReactive(this, '$bus', busEmitter);
    Vue.util.defineReactive(this, 'bus', eventBus);
  }
});

export default busEmitter;