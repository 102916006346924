/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'template': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.85 20.98h-9.5a1.1 1.1 0 01-1.12-1.07v-8.14c0-.59.5-1.07 1.13-1.07h9.49c.62 0 1.13.48 1.13 1.07v8.14c0 .59-.51 1.07-1.13 1.07zm0-12.84h-9.5a1.1 1.1 0 01-1.12-1.07v-3c0-.6.5-1.07 1.13-1.07h9.49c.62 0 1.13.48 1.13 1.07v3c0 .59-.51 1.07-1.13 1.07zM5.39 20.98H3.13A1.1 1.1 0 012 19.9V4.07C2 3.47 2.5 3 3.13 3h2.26c.62 0 1.13.48 1.13 1.07v15.84c0 .59-.5 1.07-1.13 1.07z" _fill="#34335C"/>'
  }
})
