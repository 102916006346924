<template lang="pug">
  .ui.checkbox(
    :class="rootClasses"
    @click="handleClick",
  )
    .input-checkbox
      input(
        :id="id"
        v-model="model",
        type="checkbox"
        :disabled="disabled"
      )

      ui-svg(name="check", :class="[ color ]")

    label(v-if="$slots.label || $scopedSlots.label || label")
      slot(name="label") {{ label }}
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: undefined,
    },

    color: {
      type: String,
      default: 'theme',
    },

    label: {
      type: String,
      default: '',
    },

    readonly: {
      type: Boolean,
      require: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    value: {
      require: false,
      default: null,
    },

    /**
     * @values grey, green, grey-white, error
     */
    kind: {
      type: String,
      default: undefined,
    },

    size: {
      type: String,
      default: undefined,
    },

    rounded: {
      type: Boolean,
      default: false,
    },

    fontWeight: {
      type: String,
      default: null,
      validator: (v) => ['bold'].indexOf(v) !== -1,
    },
  },

  computed: {
    rootClasses() {
      const { kind, size, label, rounded, disabled } = this;
      return {
        [kind]: Boolean(kind),
        [size]: Boolean(size),
        'has-label': Boolean(label),
        active: Boolean(this.model),
        rounded,
        disabled,
      };
    },

    model: {
      get() {
        return this.value
      },

      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },

  methods: {
    handleClick(e) {
      this.$emit('change', !this.model)
      this.$emit('click', e)

      this.model = !this.model
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
