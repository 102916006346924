<template lang="pug">
  .ui.scrollbar-wrapper.scroll(
    ref="scroll"
    v-scroll="handleScroll"
    :class="[`orientate-${orientate}`, classList]"
  )
    slot
</template>

<script>
export default {
  name: 'ScrollbarWrapper',

  props: {
    orientate: {
      type: String,
      default: 'all',
    },

    dark: {
      type: Boolean,
      default: false,
    },

    sticky: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classList() {
      return {
        'scrollbar-wrapper--dark': this.dark,
      };
    },
  },

  methods: {
    handleScroll(...data) {
      this.$emit('scroll', ...data)
    },

    scrollToEnd() {
      setTimeout(() => {
        this.$refs.scroll.scrollTop = this.$refs.scroll.scrollHeight
      }, 100)
    },
  },

  mounted() {
    if (this.sticky) {
      this.$refs.scroll.addEventListener('scroll', e => {
        const scrollHeight = this.$refs.scroll.scrollTop
      })
    }
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
