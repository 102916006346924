/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clock': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<path pid="0" d="M19.13 10.5a8.63 8.63 0 11-17.26 0 8.63 8.63 0 0117.26 0zM10.5 0a10.5 10.5 0 100 21 10.5 10.5 0 000-21zm6.3 9.57h-5.37V4.2a.93.93 0 00-1.86 0v6.3c0 .52.41.93.93.93h6.3a.93.93 0 100-1.86z" _fill="#34335C"/>'
  }
})
