/**
 * Handler resize desktop
 */
function upScale() {
  const
    size = 1.6,
    screenCssPixelRatio = (window.outerWidth - 8) / window.innerWidth;

    document.body.style.fontSize = `${size*screenCssPixelRatio}vh`;
  // document.body.style.fontSize = `15px`;
}

window.addEventListener("resize", upScale);
upScale();


/**
 * Save actual mouse position
 */
window.mouse = {x: 0, y: 0};
window.addEventListener("mousemove", event => {
  window.mouse = {
    x: event.clientX,
    y: event.clientY
  };
});