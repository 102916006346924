<template lang="pug">
  .greed.day(:style="style")
    .item(v-for="i in items")
      .end-date.line-down
      .mid-date.line-down-dotted
</template>

<script>
import GreedMixin from './mixin'

export default {
  mixins: [GreedMixin]
}
</script>