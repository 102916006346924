/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cancel': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6.59 8L.29 1.7A1 1 0 111.71.3L8 6.58l6.3-6.3a1 1 0 011.4 1.42L9.42 8l6.3 6.3a1 1 0 01-1.42 1.4L8 9.42l-6.3 6.3A1 1 0 11.3 14.3L6.58 8z"/>'
  }
})
