<template lang="pug">
  .ui.select-members(
    :class="{droped: isDropdownVisible}",
    ref="select"
    v-click-outside="() => onHide()")

    .selected.members(@click="toggleDrop(true)")
      //- ui-svg.user-icon(name="user-plus-fill")
      slot(name="after-input")
      ui-scrollbar.list(orientate="v", v-if="model.length > 0")
        .item(
          v-for="(item, i) in model",
          :key="i",
          @click.prevent="pickUser(item)")

          ui-avatar(:user="item", :size="2")
          .main
            .name {{ item.firstName }}
            ui-svg(v-if="!readonly" name="cancel" kind="dark")

      .placeholder(v-else) {{ inputPlaceholder }}
      slot(name="before-input")
        ui-button.bali-hai.btn-plus.circle
          ui-svg.white(name="plus")

    transition(name="slide")
      .drop(
        v-if="(listUsers.length > 0 || filter.search.length > 0) && isDropdownVisible"
        v-drop="getPopupOptions()"
      )
        .header
          ui-input.icons.search(
            ref="searchInput"
            :autofocus="true",
            v-model="filter.search",
            @input="search",
            :placeholder="$t('ui.selectMembers.searchPlaceholder')")

        .body
          ui-scrollbar.list.members
            .item(
              v-for="(member, i) in listCanSelect",
              :key="i",
              @click.prevent="pickUser(member)")

              ui-avatar(:user="member", :class="{'hide-block': !member.roleId}")
              .name {{ member.fullName }}

        .footer
          ui-button.bali-hai(@click="handleSubmit") {{ $t("ui.selectMembers.ready") }}

    //- transition(name="slide")
    //-   .drop.empty-members(
    //-     v-if="meCompanyMembers.length === 0 && selectMembersDrop")
    //-     .title {{ $t("modals.inviteMemberToProject.notFoundMembers") }}
    //-     .description {{ $t("modals.inviteMemberToProject.notFoundMembersDescription") }}
    //-     ui-button.bali-hai(@click="handleClickToEmployees") {{ $t("modals.inviteMemberToProject.inviteMembers") }}
</template>

<script>
export default {
  props: {
    value: {
      require: true,
      type: Array,
      default: () => [],
    },

    blockDrop: {
      require: false,
      type: Boolean,
      default: false,
    },

    listUsers: {
      require: false,
      type: Array,
      default: () => [],
    },

    listIgnore: {
      require: false,
      type: Array,
      default: () => [],
    },

    localSearch: {
      require: false,
      type: Boolean,
      default: true,
    },

    placeholder: {
      type: String,
    },

    submitOnHide: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filter: {
        result: [],
        search: '',
      },

      drop: false,
    }
  },

  computed: {
    isDropdownVisible() {
      return this.drop && !this.readonly;
    },

    listCanSelect() {
      const ids = [
        ...this.model.map(e => e.id),
        ...this.listIgnore,
        // ...this.projectMembers.map(e => e.id),
      ]

      return (this.filter.search.length > 0
        ? this.filter.result
        : this.listUsers
      ).filter(e => !ids.includes(e.id))

      return []
    },

    model: {
      get() {
        return this.value
      },

      set(newValue) {
        this.$emit('input', newValue)
      },
    },


    inputPlaceholder() {
      return this.placeholder ? this.placeholder : this.$t("ui.selectMembers.selectMembersPlaceholder")
    },
  },

  methods: {
    getPopupOptions() {
      const { select } = this.$refs;

      if (!select) return {};

      return {
        referenceElement: select,
      };
    },

    focus() {
      this.$refs.searchInput?.focus();
    },

    pickUser(user) {
      if (this.readonly) return;

      setTimeout(() => {
        this.model = this.model.find(e => e.id === user.id)
          ? this.model.filter(e => e.id !== user.id)
          : [...this.model, user]
        this.filter.search = '';
        this.focus();
      }, 100)
    },

    handleSubmit() {
      this.$emit('submit', this.model)
      this.toggleDrop(false)
    },

    toggleDrop(val) {
      if (this.blockDrop) return
      this.drop = val
    },

    search(val) {
      this.filter.search = val

      if (this.localSearch) {
        this.filter.result = this.listUsers.filter(e =>
          e.fullName.toLowerCase().includes(this.filter.search.toLowerCase())
        )
      }

      this.$emit('search', val)
    },

    onHide() {
      if (this.submitOnHide && !this.readonly) {
        this.handleSubmit()
      } else {
        this.toggleDrop(false)
      }
    }
  },

  mounted() {
    if (this.blockDrop) this.drop = true
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
