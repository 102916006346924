<template lang="pug">
.user-sidebar-planning
  .line-name
    ui-avatar(
      :user='params.user',
      :size='2',
      :class="{'hide-block': !params.user.id}")

    .name(v-tooltip="tooltipOptions") {{ params.user.fullName }}
</template>

<script>
export default {
  props: {
    params: {
      require: false,
      default: null,
    },
  },

  computed: {
    tooltipOptions() {
      return { text: this.$utils.clearTags(this.params.name), textOverflow: true }
    },
  },
}
</script>
