<template lang="pug">
  .ui.time-input
    ui-input(
      ref="input"
      @click="handleClickOnTimeInput"
      @blur="onBlur"
      @tab="handleTimeInput"
      @enter="handleTimeInput"
      :value="viewTime"
      :placeholder="placeholder"
      :readonly="readonly"
      :has-error="error"
    )
</template>

<script>
import { isMoment } from 'moment';

export default {
  name: 'UiTimeInput',

  props: {
    /**
     * Time as Moment object
     * @type {import('moment').Moment}
     */
    time: {
      type: Object,
      default: null,
    },

    ignoreTimes: {
      require: false,
      default: () => [],
    },

    placeholder: {
      type: String,
      default: '',
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localTime: null,
    };
  },

  computed: {
    viewTime() {
      if (isMoment(this.localTime)) {
        const time = this.localTime.format('HH:mm');
        return this.ignoreTimes.includes(time) ? null : time;
      }

      return null
    },
  },

  watch: {
    time: {
      immediate: true,
      handler(time) {
        if (isMoment(time)) {
          this.localTime = time.clone();
        } else {
          this.localTime = null;
        }

        // reset local state of an input element inside the ui-input component, if the viewTime is not changed
        this.$refs.input?.$forceUpdate();
      }
    }
  },

  methods: {
    hasChange(time) {
      const isSameTime =
        this.time === time
        || (this.time && time && this.time.format('HH:mm') === time.format('HH:mm'));
      return !isSameTime;
    },

    handleClickOnTimeInput() {
      if (this.readonly) return;
      if (!this.viewTime) {
        this.updateTime(this.$moment().seconds(0).milliseconds(0));
      }
    },

    handleTimeInput(event) {
      if (this.readonly) return;
      const newTime = this.$utils.$time.getValidHHmmTime(event.target.value);

      if (this.hasChange(newTime)) { 
        this.updateTime(newTime);
      }

      // reset input value
      event.target.value = this.viewTime;
    },

    onBlur(event) {
      this.handleTimeInput(event);
      this.$emit('blur', event);
    },

    updateTime(time) {
      this.localTime = time;
      this.$emit('update:time', this.localTime);
    },
  },
};
</script>

<style lang="sass" src="./styles.sass"></style>
