<template lang="pug">
  .ui.select-status(
    ref="select"
    :class="{drop, archived, disabled}",
    :data-placement="placement"
    v-click-outside="handleClickOutside")

    .header(@click="dropUp")
      .item.selected(v-if="selectedItem")
        .name {{ selectedItem.name }}
        .select-status__icon
          ui-svg.rotate-down(name="arrow" kind="light-grey")

      .item.select(v-else)
        .name
          slot(name="placeholderText")
            | {{ $t("ui.selectStatus.select") }}
        .select-status__icon
          ui-svg.rotate-down(name="arrow" kind="light-grey")

    .footer
      transition(name="slide")
        ui-scrollbar.list(
          v-if="drop"
          v-drop="getPopupOptions()"
          :data-placement="placement"
        )
          .item.select-status__list-item(
            v-for="(status, i) in sortedItems",
            @click="pickStatus(status.id)",
            v-if="status.id !== model",
            :key="i")

            .name {{ status.name }}

          .item.select-status__list-item.create(@click="handleOpenCreatePhase")
            ui-svg(name="plus")
            .name
              slot(name="createItemText")
                | {{ $t("ui.selectStatus.createProject") }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      require: true,
    },
    archived: {
      require: false,
      default: false,
      type: Boolean,
    },
    disabled: {
      require: false,
      default: false,
      type: Boolean,
    },
    items: {
      type: Array,
      required: true
    },
    createNewEntityModalName: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      drop: false,
      placement: null,
    }
  },

  methods: {
    getPopupOptions() {
      const { select } = this.$refs;

      if (!select) return {};

      const computeStyle = (styles, { placement }) => {
        this.placement = placement;
        return styles;
      }

      return {
        flip: true,
        computeStyle,
        referenceElement: select,
      };
    },

    dropUp() {
      this.drop = true
    },

    pickStatus(id) {
      this.model = id
      this.drop = false
      this.placement = null
    },

    handleClickOutside() {
      if (!this.isCreateNewEntityModalOpen) {
        this.drop = false
        this.placement = null
      }
    },

    handleOpenCreatePhase() {
      this.openModal({ name: this.createNewEntityModalName })
    },
  },

  computed: {
    selectedItem() {
      return this.items.find(e => e.id === this.model)
    },

    sortedItems() {
      return this.items.slice().sort((a, b) => a.position - b.position)
    },

    model: {
      get() {
        return this.value
      },

      set(newVal) {
        this.$emit('select', newVal)
      },
    },

    isCreateNewEntityModalOpen() {
      return this.listModals.some(({ name }) => name === this.createNewEntityModalName)
    },

    ...mapGetters('ModalsManager', ['listModals'])
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
