/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'form': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.25 3C6.02 3 5 4.08 5 5.4v11.2C5 17.92 6.02 19 7.25 19h7.48c1.23 0 2.25-1.08 2.25-2.4V5.4c0-1.32-1.02-2.4-2.25-2.4H7.25zm1.5 3.2h4.48c.2 0 .4.08.53.23.14.15.22.36.22.57a.8.8 0 01-.22.57.73.73 0 01-.53.23H8.75c-.2 0-.4-.08-.54-.23A.83.83 0 018 7c0-.21.09-.42.23-.57a.72.72 0 01.53-.23zm2.24 3.2H8.75c-.2 0-.4.08-.53.23a.83.83 0 00-.23.57c0 .21.08.42.22.57.14.15.34.23.54.23h2.24c.2 0 .39-.08.53-.23a.83.83 0 00.21-.57.83.83 0 00-.22-.57.72.72 0 00-.52-.23zm2.24 0c.2 0 .4.08.53.23.14.15.22.36.22.57 0 .21-.07.42-.21.57a.73.73 0 01-.54.23.72.72 0 01-.53-.23.83.83 0 01-.21-.57c0-.21.08-.42.22-.57a.72.72 0 01.52-.23zm0 3.2H8.75c-.2 0-.4.09-.53.23a.83.83 0 000 1.13c.14.15.33.24.53.24h4.48c.2 0 .4-.09.53-.24a.83.83 0 000-1.13.72.72 0 00-.53-.23z" _fill="#30B15D"/>'
  }
})
