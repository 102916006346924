/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'upload-arrow': {
    width: 14,
    height: 17,
    viewBox: '0 0 14 17',
    data: '<g fill-rule="nonzero" _fill="#34335C"><path pid="0" d="M2.954 6.658h2.168v4.551a.55.55 0 0 0 .546.567h2.664a.55.55 0 0 0 .546-.567V6.66h2.168c.461 0 .717-.55.444-.921L7.444.317a.533.533 0 0 0-.87 0l-4.047 5.42c-.29.371-.034.92.427.92z"/><path pid="1" d="M13.607 10.767H11.49a.394.394 0 0 0-.392.407v2.603c0 .07-.052.124-.12.124H3.022c-.068 0-.12-.053-.12-.124v-2.603c0-.23-.17-.407-.392-.407H.393a.394.394 0 0 0-.393.407v5.33c0 .23.17.407.393.407h13.214a.394.394 0 0 0 .393-.407v-5.33c0-.23-.17-.407-.393-.407z"/></g>'
  }
})
