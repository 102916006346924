<template lang="pug">
  button.ui.button(
    :class="rootClasses"
    :type="type"
    :disabled="disabled",
    @click="e => $emit('click', e)")
    slot
</template>

<script>
export default {
  props: {
    // TODO: define other modifiers as props

    /**
     * The kind of the button
     * @values success, danger, info-light, grey, grey-transparent,
     * integration-promo, integration-promo-underline
     */
    kind: {
      type: String,
      default: undefined,
    },

    /**
     * The size of the button
     * @values small, large
     */
    size: {
      type: String,
      default: undefined,
    },

    /**
     * The shape of the button
     * @values circle
     */
    shape: {
      type: String,
      default: undefined,
    },

    /**
     * Outlined style
     */
    outlined: {
      type: Boolean,
      default: false
    },

    /**
     * The native type of the button
     */
    type: {
      type: String,
      // FIXME: replace to 'button' after refactoring all buttons
      default: 'submit'
    },

    fullWidth: Boolean,

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    rootClasses() {
      const { kind, outlined, size, shape, fullWidth } = this;
      return {
        [kind]: Boolean(kind),
        [size]: Boolean(size),
        o: Boolean(outlined),
        [shape]: Boolean(shape),
        'full-width': fullWidth,
      };
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>