<template lang="pug">
  .ui.input(
    :class="rootClasses"
    @click="e => $emit('click', e)"
  )
    slot(name="after-input")

    input(
      :tabindex="tabindex",
      v-if="type=='text' || type=='password' || type=='email' || type=='number'",
      ref="input",
      :name="name",
      :type="type",
      :placeholder="placeholder",
      :maxlength="maxlength",
      v-model="model",
      :readonly="readonly",
      :disabled="deactivated",
      @keyup="e => $emit('keyup', e)",
      @keyup.enter="e => $emit('enter', e)",
      @keydown.tab="e => $emit('tab', e)",
      @blur="e => $emit('blur', e)")

    textarea(
      :autofocus="autofocus",
      :tabindex="tabindex",
      v-if="type=='textarea'",
      ref="textareaInput",
      :name="name",
      :placeholder="placeholder",
      v-model="model",
      :style="{'resize': resize ? 'vertical' : 'none'}",
      :readonly="readonly",
      :disabled="deactivated",
      :rows="rows"
      @keyup="e => $emit('keyup', e)",
      @keydown="e => $emit('keydown', e)",
      @keyup.enter="e => $emit('enter', e)",
      @blur="e => $emit('blur', e)")

    .file-upload(v-if="type=='file'", @click="$refs.fileUpload.click()")
      input(
        ref="fileUpload",
        v-if="!refreshInputFile",
        type="file",
        :value="model",
        :multiple="multiple",
        :accept="accept"
        @change="handleSelectFiles")
      .placeholder {{ placeholder }}
    
    slot
</template>

<script>
import autoResize from 'autosize'

export default {
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      require: false,
      default: null,
    },
    autoresize: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      require: false,
      default: false,
    },
    /**
     * ATTENTION: please, use this prop instead of "disabled"
     *
     * TODO: Replace "deactivated" prop by "disabled"
     * Needs to check all usages of this component and make sure that nothings
     * broken
     */
    deactivated: {
      type: Boolean,
      default: false
    },
    resize: {
      type: Boolean,
      require: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      require: false,
      default: false,
    },
    accept: {
      type: String,
      require: false,
      default: null,
    },
    name: {
      type: String,
      require: false,
      default: null,
    },
    maxlength: {
      type: Number,
      require: false,
      default: 524288,
    },
    rows: {
      type: [String, Number],
      default: undefined,
    },
    /**
     * @values small
     */
    size: {
      type: String,
      default: null
    },
    /**
     * @values gray-text, blue-text
     */
    kind: {
      type: String,
      default: undefined,
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      refreshInputFile: false,
    }
  },

  computed: {
    model: {
      get() {
        return this.value
      },

      set(newValue) {
        this.$emit('input', newValue)

        this.autoResizeInput()
      },
    },
    rootClasses() {
      const { size, hasError, kind } = this;
      return {
        [`size_${size}`]: Boolean(size),
        [`ui-input--kind_${kind}`]: Boolean(kind),
        error: Boolean(hasError),
      };
    }
  },

  watch: {
    value: {
      handler() {
        this.autoResizeInput();
      },
    },
  },

  methods: {
    blur() {
      this.$nextTick(() => {
        switch (this.type) {
          case 'text':
          case 'email':
          case 'password':
          case 'number':
            if (this.$refs.input) {
              this.$refs.input.blur()
            }
            break

          case 'textarea':
            if (this.$refs.textareaInput) {
              this.$refs.textareaInput.blur()
            }
            break

          default:
            break
        }
      })
    },

    focus() {
      this.$nextTick(() => {
        switch (this.type) {
          case 'text':
          case 'email':
          case 'password':
          case 'number':
            this.$refs.input.focus()
            break

          case 'textarea':
            this.$refs.textareaInput.focus()
            break

          default:
            break
        }
      })
    },

    select() {
      this.$nextTick(() => {
        switch (this.type) {
          case 'text':
          case 'email':
          case 'password':
          case 'number':
            this.$refs.input.select()
            break

          case 'textarea':
            this.$refs.textareaInput.select()
            break

          default:
            break
        }
      })
    },

    handleSelectFiles(event) {
      this.$emit('files', event.target.files)
      this.refreshInputFile = true
      this.$nextTick(() => {
        this.refreshInputFile = false
      })
    },

    autoResizeInput() {
      if (this.type === 'textarea' && this.autoresize) {
        this.$nextTick(() => {
          autoResize.update(this.$refs.textareaInput)
        })
      }
    },
  },

  mounted() {
    if (this.type === 'textarea' && this.autoresize) {
      autoResize(this.$refs.textareaInput)
      this.autoResizeInput()
    }

    if (this.autofocus) {
      this.focus()
    }
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
