/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'import-file': {
    width: 29,
    height: 35,
    viewBox: '0 0 29 35',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.93 14.04v-5.2c0-.77.32-1.55.86-2.1l5.8-5.9a2.77 2.77 0 012-.84h8.73C27.35 0 29 1.7 29 3.8v19.26c0 2.1-1.65 3.8-3.68 3.8h-4.9A10.46 10.46 0 0110.35 35C4.68 35 .03 30.21.03 24.35c0-4.97 3.4-9.17 7.9-10.31zm18.5 9.02V3.8c0-.63-.5-1.15-1.11-1.15H17.4v5.01a2.1 2.1 0 01-2.07 2.14h-4.87v3.94c5.58.04 10.12 4.72 10.23 10.47h4.65c.57 0 1.07-.52 1.07-1.15zm-8.3 1.33c0 4.38-3.47 8-7.77 8a7.9 7.9 0 01-7.76-8c0-4.38 3.5-8 7.76-8a7.92 7.92 0 017.77 8z" _fill="#34335C"/><path pid="1" d="M6.32 23.06h5l-.85-.88c-.5-.52-.5-1.36 0-1.84.5-.52 1.32-.52 1.79 0l3.04 3.13c.25.26.36.59.36.92 0 .33-.15.7-.36.92l-3.04 3.13c-.25.26-.57.37-.9.37-.32 0-.64-.1-.89-.37-.5-.51-.5-1.36 0-1.84l.9-.88H6.31a1.3 1.3 0 01-1.29-1.33c0-.74.58-1.33 1.3-1.33z" _fill="#34335C"/>'
  }
})
