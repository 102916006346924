class OneSignalManager {
  constructor(config) {
    this.config = {
      ...config,
      persistNotification: false,
      notificationClickHandlerMatch: "origin"
    };

    window.OneSignal = [];
  }

  boot() {
    if(this.config.appId && this.config.subdomainName) {
      let scriptEl = document.createElement("script");
      scriptEl.setAttribute("src","https://cdn.onesignal.com/sdks/OneSignalSDK.js");
      scriptEl.setAttribute('async','');
      scriptEl.addEventListener("load", () => {
        debug("[+] Load OneSignal script")
        window.OneSignal = OneSignal || [];
        this.pushSignal(["init", this.config]);
      });

      document.head.appendChild(scriptEl);
    } else {
      debug("[!] OneSignal disabled");
    }
  }

  pushSignal(...args) {
    window.OneSignal.push(...args);
  }

  sendTag(...args) {
    window.OneSignal.push(() => {
      OneSignal.sendTag(...args);
    });
  }

  addListenerForNotificationOpened(callback) {
    this.pushSignal(["addListenerForNotificationOpened", callback]);
  }

  notificationDisplay(callback) {
    this.pushSignal(() => {
      window.OneSignal.on('notificationDisplay', callback);
    });
  }
}

export default {
  install(Vue, config) {
    let onesignal = new OneSignalManager(config);

    Vue.mixin({
      beforeCreate: function () {
        Vue.util.defineReactive(this, "$onesignal", onesignal);
      }
    });
  }
};