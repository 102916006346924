<template lang="pug">
  .calendar-picker
    calendar-month-header(v-model="viewDate")
    calendar-month(
      :date="viewDate",
      :selectedDate="value",
      @select-date="handleSelectDay")
</template>

<script>
import CalendarMonthHeader from './CalendarMonthHeader'
import CalendarMonth from './CalendarMonth'

export default {
  name: 'CalendarView',
  components: {
    CalendarMonthHeader,
    CalendarMonth,
  },

  props: {
    value: {
      default: null,
    },
  },

  data() {
    return {
      viewDate: this.$moment()
    }
  },

  watch: {
    value() {
      this.viewDate = this.$moment(this.value).clone()
    }
  },

  computed: {
    model: {
      get() {
        return this.$moment(this.value)
      },

      set(newValue) {
        this.viewDate  = newValue
        this.$emit('input', newValue.toDate())
        this.$emit('update', newValue.toDate())
      },
    },
  },

  mounted() {
    if(this.value) {
      this.viewDate = this.model.clone()
    }
  },

  methods: {
    handleSelectDay(day) {
      this.$emit('input', day)
      this.viewDate = day
    }
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
