<template lang="pug">
  .ui.find-list(
    v-click-outside="() => drop = false",
    :class="{ drop: isDropdownVisible }")
    .header(@click="handleClickOnInput")
      slot(name="before")
      .placeholder(
        v-if="selectedItemView"
      ) {{ selectedItemView }}
      .placeholder.empty(
        v-else
      ) {{ placeholder }}
      slot(name="after")
    
    .body(v-if="isDropdownVisible")
      .find
        ui-svg.bali-hai(name="search")
        input(
          v-model="search",
          ref="searchInput",
          :placeholder="searchPlaceholder")

      ui-scrollbar.list(orientate="v")
        .item(
          v-for="item in listView",
          :key="item.id",
          @click="selectItem(item)"
          :data-element-dropdown-item="item.placeholder"
        ) {{ item.placeholder }}

</template>

<script>
export default {
  data() {
    return {
      drop: false,
      search: '',
    }
  },

  props: {
    value: {
      require: false,
    },

    list: {
      require: false,
      type: Array,
      default: () => [],
    },

    autofocus: {
      require: false,
      type: Boolean,
      default: false,
    },

    searchPlaceholder: {
      require: false,
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isDropdownVisible() {
      return this.drop && !this.readonly;
    },

    model: {
      set(newVal) {
        this.$emit('input', newVal)
      },

      get() {
        return this.value
      },
    },

    listView() {
      return this.list.filter(e => {
        return this.search.length > 0
          ? e.placeholder.toLowerCase().includes(this.search.toLowerCase())
          : true
      })
    },

    selectedItemView() {
      let f = this.list.find(e => e.id == this.value)
      return f ? f.placeholder : ''
    },
  },

  mounted() {
    if(this.autofocus) {
      this.handleClickOnInput()
    }
  },

  methods: {
    selectItem(item) {
      if (this.readonly) return;

      this.model = item.id
      this.$emit('select', item)
      this.drop = false
    },

    handleClickOnInput() {
      if (this.readonly) return;

      this.drop = true
      this.$nextTick(() => {
        this.$refs.searchInput?.focus?.();
      });
    },
  },
}
</script>

<style lang="sass" src="./styles.sass"></style>
