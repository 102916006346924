/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gift': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.5 6.33a2.5 2.5 0 014.5-1.5 2.5 2.5 0 114 3h2.93c.68 0 1.24.56 1.24 1.25v1.84c0 .69-.56 1.25-1.24 1.25h-.1v7a1 1 0 01-1 1H6.33c-.64 0-1.16-.53-1.16-1.17v-6.83H5c-.64 0-1.17-.53-1.17-1.17V9c0-.64.53-1.17 1.17-1.17h3c-.31-.41-.5-.93-.5-1.5zm1 0a1.5 1.5 0 013 0v1.5H10a1.5 1.5 0 01-1.5-1.5zm4 0a1.5 1.5 0 111.5 1.5h-1.5v-1.5zM5 8.83c-.1 0-.17.08-.17.17v2c0 .1.08.17.17.17h5.5V8.83H5zm1.33 10.34a.17.17 0 01-.16-.17v-6.83h4.33v7H6.33zm11.5-7H13.5v7h4.33v-7zm1.34-1.25c0 .14-.11.25-.24.25H13.5V8.83h5.43c.13 0 .24.11.24.25v1.84zm-6.67 8.25V8.83h-1v10.34h1z" _fill="#7F848B"/>'
  }
})
