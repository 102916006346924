/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder': {
    width: 16,
    height: 14,
    viewBox: '0 0 16 14',
    data: '<path pid="0" d="M15 2H9.205L7.5.295A1 1 0 0 0 6.795 0H1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z" _fill="#34335C"/>'
  }
})
