/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logout': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.8 4.8A.8.8 0 0 0 12 4H7.04A3.2 3.2 0 0 0 4 7.2v9.76A3.2 3.2 0 0 0 7.2 20h4.9a.8.8 0 0 0-.1-1.6H7.08a1.6 1.6 0 0 1-1.48-1.6V7.08A1.6 1.6 0 0 1 7.2 5.6h4.9a.8.8 0 0 0 .7-.8zm4.57 4.23l-.08-.06a.8.8 0 0 0-1.06 1.2l1.04 1.03H7.9a.8.8 0 0 0 .1 1.6h9.27l-1.04 1.03-.06.08a.8.8 0 0 0 1.2 1.06l2.4-2.4.05-.07.01-.01.02-.02.03-.04v-.01A.78.78 0 0 0 20 12a.7.7 0 0 0-.03-.21.86.86 0 0 0-.09-.2.78.78 0 0 0-.11-.16l-2.4-2.4z" _fill="#7F848B"/>'
  }
})
