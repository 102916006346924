/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'back': {
    width: 19,
    height: 16,
    viewBox: '0 0 19 16',
    data: '<defs><path pid="0" d="M307.875 148.028l-6.406 7.064-1.175-1.296 4.987-5.5h-14.617c-.46 0-.83-.41-.83-.917 0-.506.37-.916.83-.916h14.617l-4.987-5.5 1.175-1.296 6.406 7.064a.983.983 0 0 1 0 1.297" id="svgicon_back_a"/><path pid="1" d="M287.083 158v-22h23.834v22z" id="svgicon_back_c"/><clipPath id="svgicon_back_b"><use xlink:href="#svgicon_back_a"/></clipPath></defs><g transform="translate(-289 -140)"><use xlink:href="#svgicon_back_a"/><g clip-path="url(#svgicon_back_b)"><use xlink:href="#svgicon_back_c"/></g></g>'
  }
})
