/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock': {
    width: 12,
    height: 15,
    viewBox: '0 0 12 15',
    data: '<path pid="0" d="M2.831 5.87V3.287C2.831 1.472 4.437 0 6.42 0c1.98 0 3.587 1.473 3.587 3.288V5.87h.727c.679 0 1.23.585 1.23 1.304v6.524c0 .72-.551 1.303-1.23 1.303h-8.63c-.678 0-1.228-.585-1.228-1.303V7.173c0-.72.55-1.304 1.229-1.304h.727zM2.18 7.173v6.521h8.477v-6.52H2.18zM8.7 5.87V3.29c0-1.069-.998-1.984-2.281-1.984-1.286 0-2.283.914-2.283 1.983V5.87h4.565zM5.767 9.642c0-.36.29-.652.653-.652.36 0 .652.29.652.652v1.957a.65.65 0 0 1-.652.652.65.65 0 0 1-.653-.652V9.642z" _fill="#34335C" fill-rule="evenodd"/>'
  }
})
