/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'deleted-item': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.99 11.976a5.987 5.987 0 1 0-4.235-1.753 5.948 5.948 0 0 0 4.235 1.752zm3.561-9.55A5.037 5.037 0 0 1 9.868 9.2L2.78 2.106a5.037 5.037 0 0 1 6.773.317l-.002.003zm-7.445.356l-.004-.004h.007a1.116 1.116 0 0 0-.003.004zm7.093 7.087L2.106 2.782a5.037 5.037 0 0 0 7.093 7.087z" _fill="#8A8F95"/>'
  }
})
