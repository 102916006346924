/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rename': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<g _fill="#34335C" fill-rule="nonzero"><path pid="0" d="M11.942.882L2.415 10.53a1.106 1.106 0 0 0-.315.685l-.35 4.208c-.027.307.076.612.283.839.206.227.498.355.803.354h.067l4.24-.275a1.08 1.08 0 0 0 .701-.319l9.525-9.644c.56-.567.876-1.337.876-2.14 0-.802-.315-1.572-.876-2.14L16.16.883a2.962 2.962 0 0 0-4.218 0zM6.595 14.173l-2.575.165.212-2.538 7.1-7.189 2.351 2.382-7.088 7.18zm9.234-9.35l-.611.619-2.352-2.382.611-.618a.808.808 0 0 1 1.152 0l1.2 1.215a.832.832 0 0 1 0 1.166zM20.914 19.582H1.086c-.6 0-1.086.493-1.086 1.1 0 .608.486 1.1 1.086 1.1h19.828c.6 0 1.086-.492 1.086-1.1 0-.607-.486-1.1-1.086-1.1z"/></g>'
  }
})
