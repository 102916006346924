/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M10.703 1.751h-3.05v-.616A1.135 1.135 0 006.52 0H4.67c-.627 0-1.135.508-1.135 1.135v.616H.486a.486.486 0 100 .973h.99v7.233c0 .985.798 1.783 1.784 1.783h4.67c.985 0 1.783-.798 1.783-1.783V2.724h.99a.487.487 0 000-.973zm-6.195-.616c0-.09.072-.162.162-.162H6.52a.162.162 0 01.162.162v.616H4.508v-.616zm4.216 8.838a.811.811 0 01-.81.81H3.26a.81.81 0 01-.811-.81V2.724H8.74l-.016 7.249zm-3.86-.86a.487.487 0 01-.972 0V4.265a.486.486 0 11.973 0v4.848zm2.433 0a.487.487 0 01-.973 0V4.265a.486.486 0 11.973 0v4.848z" _fill="#34335C" fill-rule="evenodd"/>'
  }
})
