/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add-user': {
    width: 15,
    height: 13,
    viewBox: '0 0 15 13',
    data: '<g fill-rule="evenodd"><path pid="0" d="M3.041 6.554h5.155c1.65 0 3.041 1.109 3.041 2.41V12H0V8.964c0-1.301 1.392-2.41 3.041-2.41zM5.62 0c1.7 0 3.092 1.301 3.092 2.892 0 1.59-1.391 2.891-3.092 2.891S2.526 4.482 2.526 2.892 3.918 0 5.619 0z" _stroke="#34335C"/><path pid="1" d="M15 4.193c0 .289-.258.578-.619.578H13.3v1.012c0 .29-.31.578-.619.578-.36 0-.618-.289-.618-.578V4.771h-1.083c-.36 0-.618-.29-.618-.578 0-.338.258-.579.618-.579h1.083v-1.06c0-.289.258-.578.618-.578.31 0 .619.29.619.578v1.06h1.082c.361 0 .619.241.619.579z" _fill="#34335C"/></g>'
  }
})
