/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download': {
    width: 9,
    height: 12,
    viewBox: '0 0 9 12',
    data: '<defs><path pid="0" d="M8.75 4h-2.5V0H2.5v4H0l4.375 4.667L8.75 4zM0 10v1.333h8.75V10H0z" id="svgicon_download_a"/></defs><use _fill="#34335C" fill-rule="nonzero" xlink:href="#svgicon_download_a" transform="translate(0 .311)"/>'
  }
})
