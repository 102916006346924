<template lang="pug">
  .calendar-month-header
    ui-button.circle.grey(@click="handlePrevMonth")
      ui-svg.dark.rotate-left(name="arrow")

    .title {{ value.format('MMM YYYY') }}

    ui-button.circle.grey(@click="handleNextMonth")
      ui-svg.dark.rotate-right(name="arrow")
</template>

<script>
export default {
  props: {
    value: {
      require: true,
    },
  },

  methods: {
    handlePrevMonth() {
      this.$emit('input', this.value.clone().subtract(1, 'month'))
    },

    handleNextMonth() {
      this.$emit('input', this.value.clone().add(1, 'month'))
    },
  },
}
</script>
