import Vue from 'vue'

const SHADOW_CLASS = 'virtual-scroll-shadow'

function updateShadow(el, vnode) {
  vnode.context.$nextTick(() => {
    const shadowEl = el.querySelector(`.${SHADOW_CLASS}`),
      wrapperEl = el.querySelector('.vue-recycle-scroller__item-wrapper')

    if (!shadowEl || !wrapperEl) return

    const height = Math.floor(
      parseFloat(window.getComputedStyle(wrapperEl).height)
    )

    shadowEl.style.top = height + 'px'
    shadowEl.style.width = null

    vnode.context.$nextTick(() => {
      shadowEl.style.width = wrapperEl.scrollWidth + 'px'

      setTimeout(() => {
        shadowEl.style.width = wrapperEl.scrollWidth + 'px'
      });
    })
  })
}

Vue.directive('virtual-scroll-shadow', {
  bind(el, _, vnode) {
    const shadowEl = document.createElement('div')
    shadowEl.classList.add(SHADOW_CLASS)
    el.appendChild(shadowEl)

    updateShadow(el, vnode)
  },

  update(el, _, vnode) {
    updateShadow(el, vnode)
  },
})
