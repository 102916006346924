/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'funnel': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15 3.83c0-.46-.4-.83-.88-.83H1.88a.85.85 0 00-.88.83c0 .46.4.84.88.84h12.25c.48 0 .87-.38.87-.84zm-3.5 3.34c.48 0 .88.37.88.83 0 .46-.4.83-.88.83h-7A.85.85 0 013.62 8c0-.46.4-.83.88-.83h7zm-2.63 4.16c.49 0 .88.38.88.84 0 .46-.4.83-.88.83H7.13a.85.85 0 01-.88-.83c0-.46.4-.84.88-.84h1.75z" _fill="#34335C"/>'
  }
})
