/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'warning': {
    width: 12,
    height: 11,
    viewBox: '0 0 12 11',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.96.458a.673.673 0 0 0-.548.316L.088 9.494a.6.6 0 0 0 .002.63c.12.195.34.315.578.314h10.647c.238 0 .459-.119.578-.314a.601.601 0 0 0 .002-.63L6.567.774a.65.65 0 0 0-.256-.238.699.699 0 0 0-.35-.078zm.032 2.773c.394 0 .713.31.713.693l-.143 2.495a.563.563 0 0 1-.57.554.563.563 0 0 1-.571-.554l-.143-2.495c0-.383.32-.693.714-.693zm.713 4.851a.703.703 0 0 0-.713-.693.703.703 0 0 0-.714.693c0 .383.32.693.714.693.394 0 .713-.31.713-.693z" _fill="#FFAC00"/>'
  }
})
