/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder-plus': {
    width: 18,
    height: 15,
    viewBox: '0 0 18 15',
    data: '<path pid="0" d="M17.985 3.21a.31.31 0 0 0 .007-.086 1.136 1.136 0 0 0-1.142-1.121H8.857c-.367 0-.74-.014-1.11-.006L6.412.21C6.317.084 6.216.004 6.052 0H1.2C.99 0 .79.028.598.124.226.31.006.711 0 1.126c-.002.126.004.252.004.378v12.26c0 .032-.004.064-.004.096.008.5.335.952.818 1.082.165.044.329.038.495.038h15.523c.097 0 .192-.006.287-.026.507-.104.858-.58.866-1.094.007-.46-.004-.923-.004-1.383V3.21zm-6.549 5.884H9.589v1.869a.608.608 0 0 1-.595.6c-.32.014-.594-.286-.594-.6V9.094H6.553c-.333 0-.58-.276-.594-.6-.014-.325.283-.601.594-.601H8.4V6.024c0-.336.274-.587.594-.6.321-.015.595.286.595.6v1.869h1.847c.333 0 .58.276.594.6.014.325-.283.601-.594.601z" _fill="#34335C" fill-rule="nonzero"/>'
  }
})
