/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list-clock': {
    width: 32,
    height: 28,
    viewBox: '0 0 32 28',
    data: '<path pid="0" d="M1.96 14h4.29c1.05 0 1.9-.89 1.9-1.97s-.85-1.96-1.9-1.96h-4.3c-1.05 0-1.9.88-1.9 1.96s.85 1.96 1.9 1.96zM2 .14h19.54a1.96 1.96 0 110 3.92H2.01A1.96 1.96 0 112 .14zm.02 23.79H6.3c1.05 0 1.91-.88 1.91-1.96 0-1.09-.86-1.97-1.91-1.97H2.02c-1.06 0-1.91.88-1.91 1.97 0 1.08.85 1.96 1.91 1.96zM21 6a11.01 11.01 0 000 22 11.01 11.01 0 000-22zm0 19.8a8.81 8.81 0 010-17.6 8.81 8.81 0 010 17.6z" _fill="#34335C"/><path pid="1" d="M22.1 16.41V11.5a1.1 1.1 0 00-2.2 0V17c0 .37.18.71.49.91l3.3 2.2c.18.13.4.19.6.19a1.1 1.1 0 00.61-2.01l-2.8-1.88z" _fill="#34335C"/>'
  }
})
