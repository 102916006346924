/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'simple-file': {
    width: 10,
    height: 12,
    viewBox: '0 0 10 12',
    data: '<path pid="0" d="M8.56 3.8a2.65 2.65 0 01-1.83-.72A2.23 2.23 0 016 1.47V1.1L5.97.43 5.94 0H1.71C.77 0 0 .72 0 1.6v8.8c0 .88.77 1.6 1.71 1.6H8.3c.94 0 1.71-.72 1.71-1.6V3.8H8.56z" _fill="#96969C"/><path pid="1" d="M10 3.07v.06H8.56c-.25 0-.47-.04-.69-.12a1.69 1.69 0 01-1.14-1.54v-.02L6.69.53 6.67.15 6.66 0h.05l.06.05L10 3.07z" _fill="#96969C"/>'
  }
})
