<template lang="pug">
  .ui.users-row-avatars(:class="rootClasses")
    .item(
      v-for="(item, i) in list",
      :key="i",
      v-if="chMax(i)",
      :title="getTitle(item)",
      :class="getItemClasses(item)",
      @click="$emit('select', item)")

      ui-avatar(
        :size="sizeAvatar",
        :border-size="borderSize",
        :user="item")

    slot
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: []
    },
    max: {
      type: Number,
      default: 6
    },
    sizeAvatar: {
      type: Number,
      default: 2.7
    },
    borderSize: {
      type: String,
      default: undefined,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rootClasses() {
      const { dense } = this;
      return {
        'users-row-avatars--is_dense': dense,
      };
    },
  },

  methods: {
    chMax(i) {
      return this.max ? this.max > i : true;
    },

    getItemClasses(item) {
      const { active: check, removed } = item;
      return {
        check,
        removed,
      };
    },

    getTitle(item) {
      return item.name || item.firstName;
    }
  }
};
</script>

<style lang="sass" src="./styles.sass">
