/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-secure': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<g fill-rule="nonzero" _fill="#34335C"><path pid="0" d="M18.151 14.974a1.25 1.25 0 0 0-2.5 0c0 .443.234.833.599 1.068l-.625 2.552h2.526l-.599-2.552c.365-.209.599-.625.599-1.068z"/><path pid="1" d="M23.958 10.052h-1.666V8.125a5.401 5.401 0 0 0-5.391-5.39 5.401 5.401 0 0 0-5.39 5.39v1.927H9.817a.923.923 0 0 0-.912.912v10.39c0 .495.417.912.912.912h6.588v.78H1.953V6.095h3.203a.923.923 0 0 0 .912-.912V1.953h8.281a.923.923 0 0 0 .911-.911.923.923 0 0 0-.911-.912H5.182c-.234 0-.468.104-.65.26L.39 4.532a.904.904 0 0 0-.26.651v18.776c0 .495.416.912.91.912h16.277a.923.923 0 0 0 .911-.912v-1.692h5.73a.923.923 0 0 0 .91-.912v-10.39a.906.906 0 0 0-.91-.912zM13.333 8.125a3.545 3.545 0 0 1 3.542-3.542 3.545 3.545 0 0 1 3.542 3.542v1.927h-7.084V8.125zm9.714 12.292H10.755v-8.542h12.292v8.542z"/></g>'
  }
})
